import { Sequencer } from './types';

export interface SequencerState {
    sequencers?: Sequencer[];
    loading?: boolean;
}

export type SequencerAction =
    { type: 'set-sequencers', payload: Sequencer[] | undefined } |
    { type: 'set-loading', payload?: boolean } |
    { type: 'clear' };

export const sequencerReducer = (state: SequencerState, action: SequencerAction): SequencerState => {
    switch (action.type) {
        case 'set-sequencers':
            return { ...state, sequencers: action.payload, loading: false };
        case 'set-loading':
            return { ...state, loading: action.payload ?? true };
        case 'clear':
            return {};
        default:
            return state;
    }
};
