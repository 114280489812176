import { useEffect, useReducer } from 'react';
import { useNetwork } from '../network/network-context';
import { loadSequencers } from './sequencer-service';
import { sequencerReducer, SequencerState } from './sequencer-state';
import { useClient } from '../client/client-context';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { Network } from '../network/network-types';
import { Sequencer } from './types';

export const useSequencer = (network: Network): SequencerState => {
    const { hubNetwork } = useNetwork();
    const { clientStateMap, handleClientError } = useClient();
    const [ sequencerData, sequencerDataDispatch ] = useReducer(sequencerReducer, { loading: true });
    const cancelAndSetSequencersPromise = useCancelablePromise<Sequencer[]>();

    const clientState = hubNetwork && clientStateMap[hubNetwork?.chainId];

    useEffect(() => {
        sequencerDataDispatch({ type: 'clear' });
        cancelAndSetSequencersPromise();
    }, [ cancelAndSetSequencersPromise ]);

    useEffect(() => {
        if (network.type !== 'RollApp' || (clientState && !clientState.client && !clientState.connecting)) {
            sequencerDataDispatch({ type: 'set-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetSequencersPromise(loadSequencers(clientState.client, network))
            .then((sequencers) => sequencerDataDispatch({ type: 'set-sequencers', payload: sequencers }))
            .catch((error) => {
                sequencerDataDispatch({ type: 'set-sequencers', payload: undefined });
                handleClientError(error);
            });
    }, [ cancelAndSetSequencersPromise, clientState, handleClientError, network, network.type ]);

    return sequencerData;
};
