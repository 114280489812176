import { useEffect, useState } from 'react';

export type KEY_TYPE = 'Enter' | 'Escape' | 'Backspace' | 'Shift' | 'Alt';

const useKeyPress = (targetKey: KEY_TYPE | string): boolean => {
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    useEffect(() => {
        const downHandler = ({ key }: KeyboardEvent) => {
            if (key === targetKey) {
                setKeyPressed(true);
            }
        };

        const upHandler = ({ key }: KeyboardEvent) => {
            if (key === targetKey) {
                setKeyPressed(false);
            }
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [targetKey]);
    return keyPressed;
};

export default useKeyPress;
