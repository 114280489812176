import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import './chart-tooltip.scss';

interface SimpleChartTooltipProps extends Partial<TooltipProps<string | number | (string | number)[], string | number>> {
    className?: string;
    getTooltipContent: (name: string) => ReactNode;
}

export const SimpleChartTooltip: React.FC<SimpleChartTooltipProps> = ({ payload, className, getTooltipContent }) => {
    const tooltipContent = payload?.[0]?.name && getTooltipContent(payload[0].name.toString());
    return !tooltipContent ? null : (<div className={classNames('chart-tooltip simple', className)}>{tooltipContent}</div>);
};

export default SimpleChartTooltip;
