import React from 'react';
import StakeDashboard from '../../staking/stake-dashboard/stake-dashboard';
import { StakingContextProvider } from '../../staking/staking-context';
import { useNetworkDashboard } from '../network-dashboard-context';

export const NetworkStaking: React.FC = () => {
    const { network } = useNetworkDashboard();

    // todo: change the structure
    return <>
        <StakingContextProvider network={network}>
            <StakeDashboard />
        </StakingContextProvider>
    </>;
};

export default NetworkStaking;
