import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { useNetwork } from '../../network-context';

const TotalIbcTransfersProperty: React.FC = () => {
    const { rollapps } = useNetwork();

    const { currentValue, diffWeek } = useMemo(
        () => rollapps.reduce(({ currentValue, diffWeek }, rollapp) => ({
            currentValue: currentValue + (rollapp.ibcTransfers?.value.totalIn || 0) + (rollapp.ibcTransfers?.value.totalOut || 0),
            diffWeek: diffWeek + (rollapp.ibcTransfers?.diffWeek || 0),
        }), { currentValue: 0, diffWeek: 0 }),
        [ rollapps ],
    );

    return (
        <Property label='Total IBC Transfers on RollApps (7d)'>
            <StatisticsChange period='week' currentValue={currentValue} previousValue={currentValue - diffWeek}>
                {formatNumber(diffWeek)}
            </StatisticsChange>
        </Property>

        // <PercentageChangeProperty
        //     label='Total IBC Transfers (7d)'
        //     loading={state.loading}
        //     period='week'
        //     compareDiffs
        //     fetchComparableValues={(value) => value.totalIn + value.totalOut}
        //data={state.analytics?.ibcTransfers}
        // />

    );
};

export default TotalIbcTransfersProperty;


