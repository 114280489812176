/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { TokenMetadata as Metadata } from '../cosmos/bank';

export const protobufPackage = "dymensionxyz.dymension.denommetadata";

export interface CreateDenomMetadataProposal {
  title: string;
  description: string;
  tokenMetadata: Metadata[];
}

export interface UpdateDenomMetadataProposal {
  title: string;
  description: string;
  tokenMetadata: Metadata[];
}

function createBaseCreateDenomMetadataProposal(): CreateDenomMetadataProposal {
  return { title: "", description: "", tokenMetadata: [] };
}

export const CreateDenomMetadataProposal = {
  encode(message: CreateDenomMetadataProposal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.tokenMetadata) {
      Metadata.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDenomMetadataProposal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDenomMetadataProposal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.title = reader.string();
          break;
        case 2:
          message.description = reader.string();
          break;
        case 3:
          message.tokenMetadata.push(Metadata.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateDenomMetadataProposal {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      tokenMetadata: Array.isArray(object?.tokenMetadata)
        ? object.tokenMetadata.map((e: any) => Metadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CreateDenomMetadataProposal): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    if (message.tokenMetadata) {
      obj.tokenMetadata = message.tokenMetadata.map((e) => e ? Metadata.toJSON(e) : undefined);
    } else {
      obj.tokenMetadata = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateDenomMetadataProposal>, I>>(object: I): CreateDenomMetadataProposal {
    const message = createBaseCreateDenomMetadataProposal();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.tokenMetadata = object.tokenMetadata?.map((e) => Metadata.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateDenomMetadataProposal(): UpdateDenomMetadataProposal {
  return { title: "", description: "", tokenMetadata: [] };
}

export const UpdateDenomMetadataProposal = {
  encode(message: UpdateDenomMetadataProposal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.tokenMetadata) {
      Metadata.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDenomMetadataProposal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDenomMetadataProposal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.title = reader.string();
          break;
        case 2:
          message.description = reader.string();
          break;
        case 3:
          message.tokenMetadata.push(Metadata.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateDenomMetadataProposal {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      tokenMetadata: Array.isArray(object?.tokenMetadata)
        ? object.tokenMetadata.map((e: any) => Metadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateDenomMetadataProposal): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    if (message.tokenMetadata) {
      obj.tokenMetadata = message.tokenMetadata.map((e) => e ? Metadata.toJSON(e) : undefined);
    } else {
      obj.tokenMetadata = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDenomMetadataProposal>, I>>(object: I): UpdateDenomMetadataProposal {
    const message = createBaseUpdateDenomMetadataProposal();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.tokenMetadata = object.tokenMetadata?.map((e) => Metadata.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
