import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import { analyticsReducer, AnalyticsState } from '../../../analytics/analytics-state';
import { loadPoolAnalytics } from '../../statistics/analytics/pool-analytics-service';
import { PoolAnalytics } from '../../statistics/analytics/pool-analytics-types';
import { Asset } from '../assets-types';

interface AssetDashboardContextProps {
    asset: Asset;
    children: ReactNode;
}

interface AssetDashboardContextValue {
    asset: Asset;
    analyticsState: AnalyticsState<PoolAnalytics>;
}

export const AssetDashboardContext = createContext<AssetDashboardContextValue>({} as AssetDashboardContextValue);

export const useAssetDashboard = (): AssetDashboardContextValue => useContext(AssetDashboardContext);

export const AssetDashboardContextProvider: React.FC<AssetDashboardContextProps> = ({ asset, children }) => {
    const [ analyticsState, analyticsStateDispatch ] = useReducer(analyticsReducer, { loading: true });
    const cancelAndSetAnalyticsPromise = useCancelablePromise<PoolAnalytics>();

    useEffect(() => {
        const assetPoolAnalyticsPromise =
            loadPoolAnalytics<keyof PoolAnalytics>(asset.pools[0].id.toString(), { liquidity: [ 'day', 'month', 'total' ] });

        cancelAndSetAnalyticsPromise(assetPoolAnalyticsPromise)
            .then((analytics) => analyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => analyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [ asset.pools, cancelAndSetAnalyticsPromise ]);

    return (
        <AssetDashboardContext.Provider value={{ asset, analyticsState }}>
            {children}
        </AssetDashboardContext.Provider>
    );
};
