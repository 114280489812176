import React, { ReactElement } from 'react';
import Addresses from '../../../../shared/components/addresses/addresses';
import Button from '../../../../shared/components/button/button';
import Icon from '../../../../shared/components/icon/icon';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../assets/icons/external-link.svg';
import useCopyToClipboard from '../../../../shared/hooks/use-copy-to-clipboard';
import { getTimeLeftText, getTimeOffset } from '../../../../shared/utils/date-utils';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { convertToHexAddress } from '../../../wallet/wallet-service';
import IbcStatusBadge from '../ibc-status-badge/ibc-status-badge';
import { getStatusTooltipInfo } from '../ibc-status-service';
import { IbcTransferDetails } from '../ibc-status-types';
import './ibc-transfer-status.scss';

interface IbcTransferStatusProps {
    transfer: IbcTransferDetails;
}

const IbcTransferStatus: React.FC<IbcTransferStatusProps> = ({ transfer }) => {
    const { hubNetwork, getNetwork } = useNetwork();
    const copyToClipboard = useCopyToClipboard();

    const renderHashProperty = (): ReactElement => {
        const baseExploreTxUrl = transfer.status === 'Initiated' ? transfer.sourceNetwork?.exploreTxUrl : hubNetwork?.exploreTxUrl;
        const exploreTxUrl = baseExploreTxUrl ? baseExploreTxUrl + transfer.hash : undefined;

        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    Transaction Hash
                    <InfoIndicator indicatorSize='small'>
                        A transaction hash for the transfer status <b>initiated</b> is associated with source chain.<br />
                        ֿOnce the transfer reaches the transaction has will be associated with <b>Dymension Hub.</b>
                    </InfoIndicator>
                </span>
                <span className='property-value external-link-property' onClick={() => window.open(exploreTxUrl, '_blank')}>
                    <span className='ellipsis'>{transfer.hash}</span>
                    {exploreTxUrl && (
                        <Icon className='external-link-icon'>
                            <ExternalLinkIcon />
                        </Icon>
                    )}
                    <Button
                        size='small'
                        buttonType='icon'
                        onClick={(event) => {
                            event.stopPropagation();
                            copyToClipboard(transfer.hash, 'Transaction Hash');
                        }}
                    >
                        <CopyIcon />
                    </Button>
                </span>
            </div>
        );
    };

    const renderStatusProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>Status</span>
                <span className='property-value'>
                    <IbcStatusBadge transfer={transfer} showBaseStatus />
                </span>
            </div>
        );
    };

    const renderBlockProperty = (): ReactElement => {
        const exploreBlockUrl = `${hubNetwork?.explorerUrl}block/${transfer.blockHeight}`;

        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    Block
                    <InfoIndicator indicatorSize='small'>The number of the block in which the transaction is recorded in <b>Dymension Hub</b></InfoIndicator>
                </span>
                {transfer.status === 'Initiated' ? (
                    <span className='secondary-text'>The transaction has not yet arrived to Dymension</span>
                ) : (
                    <span className='property-value external-link-property' onClick={() => window.open(exploreBlockUrl, '_blank')}>
                        {transfer.blockHeight}
                        {exploreBlockUrl && (
                            <Icon className='external-link-icon'>
                                <ExternalLinkIcon />
                            </Icon>
                        )}
                    </span>
                )}
            </div>
        );
    };

    const renderTimeProperty = (): ReactElement => {
        const timeOffset = getTimeOffset(transfer.time);
        return (
            <div className='transfer-property date-property'>
                <span className='property-label'>
                    Time<InfoIndicator indicatorSize='small'>The transaction's creation timestamp</InfoIndicator>
                </span>
                <span className='property-value'>
                    {new Date(transfer.time).toUTCString().replace('GMT', 'UTC')}
                    <span className='secondary-text time-left'>
                        ({getTimeLeftText(timeOffset, true, true)} ago)
                    </span>
                </span>
            </div>
        );
    };

    const renderNetworkAddressProperty = (direction: 'Source' | 'Destination'): ReactElement => {
        const network = direction === 'Source' ? transfer.sourceNetwork : transfer.destinationNetwork;
        const address = direction === 'Source' ? transfer.sender : transfer.receiver;
        const hexAddress = direction === 'Source' ? transfer.hexSender : transfer.hexReceiver;
        const channel = (direction === 'Source' && transfer.type === 'In') ? transfer.destinationChannel :
            (direction === 'Destination' && transfer.type === 'Out') ? transfer.sourceChannel : '';
        const channelLabel = channel.charAt(0).toUpperCase() + channel.slice(1).replace('-', ' ');
        const addresses = [ address ];
        if (network?.type === 'EVM' || network?.evm) {
            try {
                addresses.unshift(hexAddress || convertToHexAddress(address));
            } catch {}
        }
        return (
            <div className='transfer-property network-address-property'>
                <span className='property-label'>{direction}</span>
                <span className='property-value'>
                    {network?.chainName && <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />}
                    <span className='name-address'>
                        {network?.chainName || channelLabel}
                        <span className='address'>(<Addresses canCopy addresses={addresses} />)</span>
                    </span>
                </span>
            </div>
        );
    };

    const renderAmountProperty = (): ReactElement => {
        const assetNetwork = transfer.coins?.ibc ? getNetwork(transfer.coins.ibc.networkId) : transfer.sourceNetwork;

        return (
            <div className='transfer-property'>
                <span className='property-label'>Amount</span>
                <span className='property-value'>
                    {assetNetwork && transfer.coins &&
                        <img className='asset-logo' src={getCurrencyLogoPath(transfer.coins.currency, assetNetwork)} alt='asset logo' />}
                    {transfer.coins ? formatPrice(transfer.coins.amount, transfer.coins.currency.displayDenom) :
                        formatPrice(transfer.amount, transfer.denom.split('/').pop(), { notation: 'compact' })}
                </span>
            </div>
        );
    };

    const renderEibcOrderProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    eIBC Order ID
                    <InfoIndicator indicatorSize='small'>The onchain ID associated with the eIBC order</InfoIndicator>
                </span>
                <span className='property-value'>
                    {!transfer.eibcOrderId ? <span className='secondary-text'>No eIBC involved</span> : <>
                        {transfer.eibcOrderId}
                        <Button
                            size='small'
                            buttonType='icon'
                            onClick={() => copyToClipboard(transfer.eibcOrderId || '', 'eIBC order ID')}
                        >
                            <CopyIcon />
                        </Button>
                    </>}
                </span>
            </div>
        );
    };

    const renderEibcFulFillerAddressProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    eIBC FulFiller Address
                    <InfoIndicator indicatorSize='small'>
                        The address which fulfilled this eIBC order i.e provided the liquidity facilitating the withdrawal of funds from the RollApp
                    </InfoIndicator>
                </span>
                <span className='property-value'>
                    {transfer.fulfillerAddress && (
                        <Addresses
                            canCopy
                            addresses={[ convertToHexAddress(transfer.fulfillerAddress), transfer.fulfillerAddress ]}
                            shortAddress={false}
                            className='fulfiller-address-property-value'
                        />
                    )}
                    <Button
                        size='small'
                        buttonType='icon'
                        onClick={() => copyToClipboard(transfer.eibcOrderId || '', 'eIBC fulfiller address')}
                    >
                        <CopyIcon />
                    </Button>
                </span>
            </div>
        );
    };


    const renderEibcFeeProperty = (): ReactElement => {
        const assetNetwork = transfer.eibcFeeCoins?.ibc ? getNetwork(transfer.eibcFeeCoins.ibc.networkId) : transfer.sourceNetwork;

        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    eIBC fee
                    <InfoIndicator indicatorSize='small'>The additional fee the eIBC fulfiller earns for fulfilling the order</InfoIndicator>
                </span>
                <span className='property-value'>
                      {assetNetwork && transfer.eibcFeeCoins && <>
                          <img
                              className='asset-logo'
                              src={getCurrencyLogoPath(transfer.eibcFeeCoins.currency, assetNetwork)}
                              alt='asset logo'
                          />
                          {formatPrice(transfer.eibcFeeCoins.amount, transfer.eibcFeeCoins.currency.displayDenom)}
                      </>}
                </span>
            </div>
        );
    };

    const renderSystemStatusProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    System Status
                    <InfoIndicator indicatorSize='small'>The original system status with precise transport information.</InfoIndicator>
                </span>
                <span className='property-value'>
                    <IbcStatusBadge transfer={transfer} getTooltipInfo={() => getStatusTooltipInfo(transfer.status)} />
                </span>
            </div>
        );
    };

    return (
        <div className='section ibc-transfer-status'>
            {renderHashProperty()}
            {renderStatusProperty()}
            {renderBlockProperty()}
            {renderTimeProperty()}
            <span className='border' />
            {renderNetworkAddressProperty('Source')}
            {renderNetworkAddressProperty('Destination')}
            {renderAmountProperty()}
            <span className='border' />
            {renderEibcOrderProperty()}
            {transfer.fulfillerAddress && renderEibcFulFillerAddressProperty()}
            {transfer.eibcFeeCoins && renderEibcFeeProperty()}
            {renderSystemStatusProperty()}
        </div>
    );
};

export default IbcTransferStatus;
