import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './icon.scss';

interface IconProps {
    children: ReactElement;
    iconColorMode?: 'fill' | 'stroke' | 'original';
    className?: string;
    color?: 'primary' | 'secondary';
}

const Icon: React.FC<IconProps> = ({ children, className, iconColorMode = 'fill', color = 'primary' }) => {
    return <span className={classNames('icon-container', className, color, `icon-color-${iconColorMode}`)}>{children}</span>;
};

export default Icon;
