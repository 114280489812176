import { GovProposalContent, ProposalType } from '../governance-types';

export interface CreateProposalState {
    type?: ProposalType;
    content?: GovProposalContent;
    valid?: boolean;
    error?: any;
}

export type CreateProposalAction =
    { type: 'set-type', payload: ProposalType } |
    { type: 'init-content', payload: { data: GovProposalContent, valid?: boolean } } |
    { type: 'update-content', payload: { data: Partial<GovProposalContent>, valid?: boolean } } |
    { type: 'set-error', payload: any } |
    { type: 'clear' };

export const createProposalReducer = (state: CreateProposalState, action: CreateProposalAction): CreateProposalState => {
    switch (action.type) {
        case 'init-content':
            return { ...state, content: { ...action.payload.data, ...state.content } };
        case 'update-content':
            return {
                ...state,
                content: state.content ? { ...state.content, ...action.payload.data } : undefined,
                valid: action.payload.valid ?? state.valid,
            };
        case 'set-type':
            return { ...state, type: action.payload };
        case 'set-error':
            return { ...state, error: action.payload };
        case 'clear':
            return {};
        default:
            return state;
    }
};

