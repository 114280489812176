import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Select, { SelectProps } from '../select/select';
import Input, { InputProps } from '../input/input';
import './controls-composer.scss';

interface ControlsComposerProps {
    children: ReactNode;
    className?: string;
    reverse?: boolean;
}

const ControlsComposer: React.FC<ControlsComposerProps> = ({ className, children, reverse }) => {
    const controls = validateAndGetChildrenAsArray<InputProps & SelectProps>(children, Input, Select);
    const controlsContainerClassName = classNames('controls-container', className, {
        large: controls.some((child) => child.props.controlSize === 'large'),
    });
    return <div className={controlsContainerClassName}>{reverse ? [ ...controls ].reverse() : children}</div>;
};

export default ControlsComposer;
