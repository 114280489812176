import { useEffect, useState } from 'react';
import { readStream } from '../../../../shared/utils/file-utils';
import { RollappHighlight } from './rollapp-highlights-types';

interface RollappHighlightsValue {
    highlights: RollappHighlight[];
    loading: boolean;
}

export const useRollappHighlights = (): RollappHighlightsValue => {
    const [ highlights, setHighlights ] = useState<RollappHighlight[]>([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_GET_HIGHLIGHTS_URL}`)
            .then((response) => response?.body ? readStream(response.body).catch(() => '') : undefined)
            .then((responseText) => JSON.parse(responseText || '{}') as RollappHighlight[])
            .then(setHighlights)
            .finally(() => setLoading(false));
    }, []);

    return { highlights, loading };
};
