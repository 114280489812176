import { useCallback } from 'react';
import { useSnackbar } from '../components/snackbar/snackbar-context';


const useCopyToClipboard = (): ((text: string, dataName?: string) => void) => {
    const { showSuccessMessage, showErrorMessage } = useSnackbar();

    return useCallback((text: string, dataName?: string): void => {
        navigator.clipboard.writeText(text)
            .then(() => showSuccessMessage({
                content: `Copied${dataName ? ` ${dataName}` : ''} to clipboard`,
                key: text,
                duration: 3000,
                closeAction: false,
            }))
            .catch((error) => {
                console.error(error);
                showErrorMessage({
                    content: `Copied${dataName ? ` ${dataName}` : ''} to clipboard failed, please try again later`,
                    duration: 5000,
                });
            });
    }, [ showErrorMessage, showSuccessMessage ]);
};

export default useCopyToClipboard;
