import classNames from 'classnames';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../../shared/components/button/button';
import Link from '../../../../shared/components/link/link';
import Property from '../../../../shared/components/property/property';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import useCopyToClipboard from '../../../../shared/hooks/use-copy-to-clipboard';
import { useAmm } from '../../../amm/amm-context';
import { Asset } from '../../../amm/assets/assets-types';
import { getMainCurrency } from '../../../currency/currency-service';
import { Network } from '../../../network/network-types';
import './network-info.scss';

interface NetworkInfoProps {
    network: Network;
    className?: string;
}

const NetworkInfo: React.FC<NetworkInfoProps> = ({ network, className }) => {
    const { ammState } = useAmm();
    const copyToClipboard = useCopyToClipboard();

    const nativeAsset = useMemo((): Asset | undefined => {
        const mainCurrency = network && getMainCurrency(network);
        return ammState.assets?.find((asset) =>
            asset.currency.baseDenom === mainCurrency?.baseDenom && asset.network.chainId === network?.chainId);
    }, [ ammState.assets, network ]);


    return <div className={classNames('network-info section small', className)}>
        <Property label='Network ID' className='info-property' valueClassName='info-property-value'>
            <Button
                size='small'
                buttonType='icon'
                className='info-property-value-content'
                onClick={() => copyToClipboard(network.chainId || '', 'network ID')}
            >
                {network.chainId}&nbsp;&nbsp;
                <CopyIcon />
            </Button>
        </Property>

        {network.website && (
            <Property label='Website' className='info-property' valueClassName='info-property-value'>
                <Link url={network.website} external className='info-property-value-content' size='small'>
                    <span className='ellipsis'>{network.website}</span>
                </Link>
            </Property>
        )}

        {network.xAccount && (
            <Property label='X (Twitter)' className='info-property' valueClassName='info-property-value'>
                <Link url={`https://twitter.com/${network.xAccount}`} external className='info-property-value-content' size='small'>
                    <span className='ellipsis'>@{network.xAccount}</span>
                </Link>
            </Property>
        )}

        {network.rpc && (
            <Property label='RPC' className='info-property' valueClassName='info-property-value'>
                <Button
                    size='small'
                    buttonType='icon'
                    className='info-property-value-content'
                    onClick={() => copyToClipboard(network.rpc || '', 'RPC')}
                >
                    <span className='ellipsis'>{network.rpc}</span>&nbsp;&nbsp;
                    <CopyIcon />
                </Button>
            </Property>
        )}

        {network.evm?.rpc && (
            <Property label='Evm RPC' className='info-property' valueClassName='info-property-value'>
                <Button
                    size='small'
                    buttonType='icon'
                    className='info-property-value-content'
                    onClick={() => copyToClipboard(network.evm?.rpc || '', 'Evm RPC')}
                >
                    <span className='ellipsis'>{network.evm.rpc}</span>&nbsp;&nbsp;
                    <CopyIcon />
                </Button>
            </Property>
        )}

        {nativeAsset && (
            <Property label='Native Asset' className='info-property' valueClassName='info-property-value'>
                <NavLink
                    className='network-native-denom info-property-value-content'
                    to={`/amm/asset/${encodeURIComponent(nativeAsset.key)}`}
                >
                    {nativeAsset.currency.displayDenom || getMainCurrency(network).displayDenom}
                </NavLink>
            </Property>
        )}

        {network.explorerUrl && (
            <Property label='Explorer' className='info-property' valueClassName='info-property-value'>
                <Link url={network.explorerUrl} external className='info-property-value-content' size='small'>
                    <span className='ellipsis'>{network.explorerUrl}</span>
                </Link>
            </Property>
        )}

        {network.description && (
            <Property label='Description' className='info-property' valueClassName='info-property-value text'>
                {network.description}
            </Property>
        )}
    </div>;
};

export default NetworkInfo;
