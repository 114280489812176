import { roundNumber } from '../../shared/utils/number-utils';
import { isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { getConnectedPools } from './amm.service';
import { AmmParams, Pool } from './types';

export const estimateSwapAmountIn = (
    pools: Pool[],
    params: AmmParams,
    tokenIn: CoinsAmount,
    tokenOut: CoinsAmount,
): number => {
    const connectedPools = getConnectedPools(pools, params, tokenIn, tokenOut);
    for (const pool of connectedPools) {
        const [ assetIn, assetOut ] = isCoinsEquals(pool.assets[0], tokenIn) ? pool.assets : [ ...pool.assets ].reverse();
        const tokenOutAmount = calcOutAmtGivenIn(tokenIn, assetIn, assetOut, params.swapFee, params.takerFee);
        tokenIn = { ...assetOut, amount: tokenOutAmount };
    }
    return tokenIn.amount;
};

export const estimateSwapAmountOut = (
    pools: Pool[],
    params: AmmParams,
    tokenIn: CoinsAmount,
    tokenOut: CoinsAmount,
): number => {
    const connectedPools = getConnectedPools(pools, params, tokenIn, tokenOut).reverse();
    for (const pool of connectedPools) {
        const [ assetIn, assetOut ] = isCoinsEquals(pool.assets[1], tokenOut) ? pool.assets : [ ...pool.assets ].reverse();
        const tokenInAmount = calcInAmtGivenOut(tokenOut, assetIn, assetOut, params.swapFee, params.takerFee);
        tokenOut = { ...assetIn, amount: tokenInAmount };
    }
    return tokenOut.amount;
};

export const calcJoinPoolNoSwapShares = (pool: Pool, tokensIn: CoinsAmount[]): number => {
    let minShareRatio = Number.MAX_VALUE;
    tokensIn.forEach((token) => {
        const poolAmount = pool.assets.find((asset) => isCoinsEquals(asset, token))?.amount || 0;
        const shareRatio = token.amount / poolAmount;
        minShareRatio = Math.min(minShareRatio, shareRatio);
    });
    return Math.floor(minShareRatio * Number(pool.totalShares));
};

export const calcJoinPoolShares = (pool: Pool, tokenIn: CoinsAmount, swapFee: number): number => {
    const tokenInPoolAssetAmount = pool.assets.find((asset) => isCoinsEquals(asset, tokenIn))?.amount || 0;
    if (!tokenInPoolAssetAmount) {
        return 0;
    }
    const tokenAmountInAfterFee = tokenIn.amount * (1 - swapFee) / 2;
    const poolAmountOut = -solveConstantFunctionInvariant(
        tokenInPoolAssetAmount + tokenAmountInAfterFee,
        tokenInPoolAssetAmount,
        Number(pool.totalShares),
    );
    return Math.floor(Math.max(0, poolAmountOut));
};

const calcOutAmtGivenIn = (
    tokenIn: CoinsAmount,
    poolAssetIn: CoinsAmount,
    poolAssetOut: CoinsAmount,
    swapFee: number,
    takerFee: number,
): number => {
    const tokenAmountInAfterFee = tokenIn.amount * (1 - takerFee) * (1 - swapFee);
    const poolPostSwapInBalance = poolAssetIn.amount + tokenAmountInAfterFee;
    const tokenAmountOut = solveConstantFunctionInvariant(poolAssetIn.amount, poolPostSwapInBalance, poolAssetOut.amount);
    if (tokenAmountOut < 0) {
        return poolAssetIn.amount;
    }
    return roundNumber(tokenAmountOut, poolAssetOut.currency.decimals, true);
};

const calcInAmtGivenOut = (
    tokenOut: CoinsAmount,
    poolAssetIn: CoinsAmount,
    poolAssetOut: CoinsAmount,
    swapFee: number,
    takerFee: number,
): number => {
    const poolPostSwapOutBalance = poolAssetOut.amount - tokenOut.amount;
    const tokenAmountIn = -solveConstantFunctionInvariant(poolAssetOut.amount, poolPostSwapOutBalance, poolAssetIn.amount);
    if (tokenAmountIn < 0) {
        return poolAssetIn.amount;
    }
    const tokenAmountInBeforeFee = tokenAmountIn / (1 - swapFee) / (1 - takerFee);
    return roundNumber(tokenAmountInBeforeFee, poolAssetIn.currency.decimals, undefined, true);
};

const solveConstantFunctionInvariant = (
    tokenBalanceFixedBefore: number,
    tokenBalanceFixedAfter: number,
    tokenBalanceUnknownBefore: number,
) => tokenBalanceUnknownBefore * (1 - (tokenBalanceFixedBefore / tokenBalanceFixedAfter));
