import { useEffect, useState } from 'react';

type WindowSize = { width: number; height: number, isMobile: boolean, isTablet: boolean };

const MOBILE_WIDTH = 720;
const TABLET_WIDTH = 1024;

const useWindowSize = (): WindowSize => {
    const [ windowSize, setWindowSize ] = useState<WindowSize>({ width: 0, height: 0, isMobile: false, isTablet: false });

    useEffect(() => {
        const handleResize = (): void => setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: window.innerWidth <= MOBILE_WIDTH,
            isTablet: window.innerWidth <= TABLET_WIDTH,
        });
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
