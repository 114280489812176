import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { analyticsReducer, AnalyticsState } from '../analytics/analytics-state';
import { NetworksAnalytics } from '../network/statistics/analytics/network-analytics-types';
import { loadNetworksAnalytics } from '../network/statistics/analytics/network-analytics-service';
import { Network } from '../network/network-types';
import { RollappState } from '../rollapp/rollapp-state';
import { useRollapp } from '../rollapp/use-rollapp';
import { SequencerState } from '../sequencer/sequencer-state';
import { useSequencer } from '../sequencer/use-sequencer';
import { RollappAnalytics } from '../rollapp/statistics/rollapp-statistics-types';
import { HubAnalytics } from '../hub/statistics/hub-statistics-types';

interface NetworkDashboardContextProps {
    network: Network;
    children: ReactNode;
}

interface NetworkDashboardContextValue {
    network: Network;
    sequencerData: SequencerState;
    rollappData: RollappState;
    analyticsState: AnalyticsState<RollappAnalytics & HubAnalytics>;
}

export const NetworkDashboardContext = createContext<NetworkDashboardContextValue>({} as NetworkDashboardContextValue);

export const useNetworkDashboard = (): NetworkDashboardContextValue => useContext(NetworkDashboardContext);

export const NetworkDashboardContextProvider: React.FC<NetworkDashboardContextProps> = ({ network, children }) => {
    const [ analyticsState, analyticsStateDispatch ] = useReducer(analyticsReducer, { loading: true });
    const sequencerData = useSequencer(network);
    const rollappData = useRollapp(network);
    const cancelAndSetAnalyticsPromise = useCancelablePromise<NetworksAnalytics>();

    useEffect(() => {
        const networkAnalyticsPromise =
            loadNetworksAnalytics<keyof (RollappAnalytics & HubAnalytics)>(network.chainId, {
                activeAddresses: [ 'day', 'month', 'total' ],
                totalSupply: [ 'day', 'month', 'total' ],
                ibcTransfers: [ 'day', 'month', 'total' ],
                tokenBurns: [ 'month', 'total' ],
                rollappsCount: [ 'month' ],
            });

        cancelAndSetAnalyticsPromise(networkAnalyticsPromise)
            .then((analytics) => analyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => analyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [ cancelAndSetAnalyticsPromise, network.chainId ]);

    return (
        <NetworkDashboardContext.Provider value={{ network, rollappData, sequencerData, analyticsState }}>
            {children}
        </NetworkDashboardContext.Provider>
    );
};
