import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../../shared/components/alert/alert';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { getFeeCurrency } from '../../../currency/currency-service';
import { useWallet } from '../../../wallet/wallet-context';
import { PROPOSAL_VOTE_OPTIONS } from '../../governance-types';
import { useProposal } from '../proposal-context';
import './vote-dialog.scss';

const VoteDialog: React.FC<DialogProps> = ({ className, ...otherDialogOptions }) => {
    const navigate = useNavigate();
    const { networkWalletMap } = useWallet();
    const { network, proposalState, voteTxState, broadcastVoteTx, setVoteOption } = useProposal();

    const networkWallet = networkWalletMap[network.chainId];

    const getTransferFee = (): ReactElement | string => {
        if (proposalState.loading || voteTxState.feeLoading) {
            return <Spinner size='small' />;
        }
        const feeDenom = getFeeCurrency(network).displayDenom;
        if (!networkWallet || !voteTxState.fee || voteTxState.fee.coins.amount === undefined) {
            return feeDenom ? `- ${feeDenom}` : '';
        }
        if (voteTxState.fee.coins.amount < 0.0001) {
            return `< 0.0001 ${feeDenom}`;
        }
        return `${formatNumber(voteTxState.fee.coins.amount || 0, { minimumFractionDigits: 2, maximumFractionDigits: 4 })} ${feeDenom}`;
    };

    const renderAlert = (): ReactElement => {
        if (proposalState?.canVoteLoading) {
            return <Spinner className='can-vote-loader' />;
        }
        if (proposalState.canVote) {
            return <Alert type='info'>Your voting power is dictated by the number of tokens you've staked.</Alert>;
        }
        return (
            <Alert type='warning'>
                To vote in {network.chainName}, you must initially stake your assets.<br />
                Your voting power is dictated by the number of tokens you've staked.

                <Button
                    className='vote-dialog-confirm-button'
                    onClick={() => navigate('../../staking', { relative: 'path' })}
                >
                    Stake
                </Button>
            </Alert>
        );
    };

    return (
        <Dialog className={classNames('vote-dialog', className)} closable {...otherDialogOptions}>
            <DialogTitle>Vote for proposal #{proposalState.proposal?.id}</DialogTitle>
            <DialogContent>
                <p className='vote-proposal-title'>{proposalState.proposal?.title}</p>

                {renderAlert()}

                <div className='dialog-vote-options-container'>
                    {PROPOSAL_VOTE_OPTIONS.map((option) => (
                        <button
                            className={
                                classNames('dialog-vote-option', option.toLowerCase(), { active: option === proposalState.voteOption })}
                            key={option}
                            disabled={!proposalState.canVote}
                            onClick={() => setVoteOption(option)}
                        >
                            {option}
                        </button>
                    ))}
                </div>

                <Button
                    className='vote-dialog-confirm-button'
                    disabled={!proposalState.voteOption || voteTxState.broadcasting || !proposalState.canVote}
                    loading={voteTxState.broadcasting}
                    onClick={() => broadcastVoteTx()}
                >
                    Confirm
                </Button>
                <p className='transaction-fee'>Estimated fee <span className='transaction-fee-value'>{getTransferFee()}</span></p>
            </DialogContent>
        </Dialog>
    );
};

export default VoteDialog;
