import { LOCAL_NETWORKS_KEY, Network, NetworkApp } from './network-types';
import { exportJSON, readStream } from '../../shared/utils/file-utils';

export const getLocalNetworks = (): Network[] => {
    const networks = JSON.parse(localStorage.getItem(LOCAL_NETWORKS_KEY) || '[]') as Network[];
    localStorage.setItem(LOCAL_NETWORKS_KEY, JSON.stringify(networks));
    return networks;
};

export const getNetworkLogoPath = (network: Network) => {
    if (/^https?:\/\//i.test(network.logo)) {
        return network.logo;
    }
    return network.custom ?
        require('../../assets' + network.logo) :
        `${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}${network.logo}`;
};

export const getNetworkAppImagePath = (network: Network, appIndex: number): string => {
    const app = network.apps?.[appIndex];
    if (!app) {
        return '';
    }
    if (/^https?:\/\//i.test(app.image)) {
        return app.image;
    }
    return `${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}${app.image}`;
};

export const exportNetwork = (network: Network): void => {
    if (network.custom) {
        network = {
            ...network,
            logo: `/logos/${network.chainId}.png`,
            custom: undefined,
            currencies: network.currencies.map((currency) => ({ ...currency, logo: `/logos/${network.chainId}.png` })),
        };
    }
    exportJSON(network, 'network.json');
};

export const getNetworkData = async <T>(network: Network, dataType: string, single?: boolean): Promise<T> => {
    const response = await fetch(`${process.env.REACT_APP_FETCH_NETWORK_DATA}?networkId=${network.chainId}&dataType=${dataType}&single=${single}`)
        .catch((error) => {
            console.error('Failed to fetch network data', { error });
        });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    return JSON.parse(responseText || (single ? '{}' : '[]')) as T;
};

export const getNetworkDataItem = async <T>(network: Network, dataType: string, itemId: string | number): Promise<T> => {
    const response = await fetch(`${process.env.REACT_APP_FETCH_NETWORK_DATA_ITEM}?networkId=${network.chainId}&dataType=${dataType}&itemId=${itemId}`)
        .catch((error) => {
            console.error('Failed to fetch network data item', { error });
        });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    return JSON.parse(responseText || '{}') as T;
};
