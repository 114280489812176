import classNames from 'classnames';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Addresses from '../../../../shared/components/addresses/addresses';
import Button from '../../../../shared/components/button/button';
import ControlsComposer from '../../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../../shared/components/form-controls/input/input';
import { Option } from '../../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../../shared/components/form-controls/select/select';
import Link from '../../../../shared/components/link/link';
import Spinner from '../../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import ToggleSwitch from '../../../../shared/components/toggle-switch/toggle-switch';
import useScrollPosition from '../../../../shared/hooks/use-scroll-position';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { getShortDateTimeString } from '../../../../shared/utils/date-utils';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { getShortenedAddress } from '../../../../shared/utils/text-utils';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { useWallet } from '../../../wallet/wallet-context';
import { convertToHexAddress } from '../../../wallet/wallet-service';
import IbcStatusBadge from '../ibc-status-badge/ibc-status-badge';
import { useIbcStatus } from '../ibc-status-context';
import { getStatusesOfBaseStatus, getStatusName } from '../ibc-status-service';
import { IBC_BASE_STATUSES, IBC_TRANSFER_STATUSES, IbcBaseStatus, IbcTransferDetails, IbcTransferStatus } from '../ibc-status-types';
import './ibc-status-list-page.scss';

const DEFAULT_BLOCK_TIME = 5650;

const IbcStatusListPage: React.FC = () => {
    const navigate = useNavigate();
    const { isMobile } = useWindowSize();
    const scrollPosition = useScrollPosition();
    const { hubNetwork, rollAppParams, getNetwork } = useNetwork();
    const { hubWallet } = useWallet();
    const {
        transfers,
        totalCount,
        searchText,
        loading,
        initiatedTransfersLoading,
        initiatedTransferCreating,
        statusesFilter,
        showAll,
        setShowAll,
        refreshData,
        loadMore,
        setSearchText,
        setStatusesFilter,
    } = useIbcStatus();
    const [ baseStatusFilter, setBaseStatusFilter ] = useState<IbcBaseStatus>();

    useEffect(() => {
        if (scrollPosition.target?.scrollTop &&
            scrollPosition.target.scrollTop + 1.5 * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight
        ) {
            loadMore();
        }
    }, [ loadMore, scrollPosition.target?.clientHeight, scrollPosition.target?.scrollHeight, scrollPosition.target?.scrollTop ]);

    const showBaseStatus = useMemo(() => process.env.REACT_APP_ENV !== 'mainnet-staging', []);

    const renderBlockColumn = (transfer: IbcTransferDetails): ReactElement => {
        return (
            <TableColumn className='index-column' contentClassName='index-column-content'>
                {transfer.blockHeight}
            </TableColumn>
        );
    };

    const renderTransactionHashColumn = (transfer: IbcTransferDetails): ReactElement => {
        const baseExploreTxUrl = transfer.status === 'Initiated' ? transfer.sourceNetwork?.exploreTxUrl : hubNetwork?.exploreTxUrl;
        const exploreTxUrl = baseExploreTxUrl ? baseExploreTxUrl + transfer.hash : undefined;
        return (
            <TableColumn className='transaction-hash-column'>
                <Link external={!!exploreTxUrl} className={classNames('hash', { clickable: !!exploreTxUrl })} url={exploreTxUrl}>
                    {getShortenedAddress(transfer.hash, 6, 6)}
                </Link>
            </TableColumn>
        );
    };

    const renderNetworkAddressColumn = (transfer: IbcTransferDetails, direction: 'source' | 'destination'): ReactElement => {
        const network = direction === 'source' ? transfer.sourceNetwork : transfer.destinationNetwork;
        const address = direction === 'source' ? transfer.sender : transfer.receiver;
        const hexAddress = direction === 'source' ? transfer.hexSender : transfer.hexReceiver;
        const channel = (direction === 'source' && transfer.type === 'In') ? transfer.destinationChannel :
            (direction === 'destination' && transfer.type === 'Out') ? transfer.sourceChannel : '';
        const channelLabel = channel.charAt(0).toUpperCase() + channel.slice(1).replace('-', ' ');
        const addresses = [ address ];
        if (network?.type === 'EVM' || network?.evm) {
            try {
                addresses.unshift(hexAddress || convertToHexAddress(address));
            } catch {}
        }
        return (
            <TableColumn contentClassName='network-address-column-content'>
                {network?.chainName && <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />}
                <span className='name-address'>
                    {network?.chainName || channelLabel}
                    <span className='address'>(<Addresses canCopy addresses={addresses} />)</span>
                </span>
            </TableColumn>
        );
    };

    const renderAmountColumn = (transfer: IbcTransferDetails): ReactElement => {
        const assetNetwork = transfer.coins?.ibc ? getNetwork(transfer.coins.ibc.networkId) : transfer.sourceNetwork;

        return (
            <TableColumn className='amount-column'>
                {assetNetwork && transfer.coins &&
                    <img className='asset-logo' src={getCurrencyLogoPath(transfer.coins.currency, assetNetwork)} alt='asset logo' />}
                {transfer.coins ? formatPrice(transfer.coins.amount, transfer.coins.currency.displayDenom) :
                    formatPrice(transfer.amount, transfer.denom.split('/').pop(), { notation: 'compact' })}
            </TableColumn>
        );
    };

    const getStatusTooltipInfo = (transfer: IbcTransferDetails): string | undefined => {
        if (transfer.sourceNetwork?.type !== 'RollApp') {
            return;
        } else if (transfer.status === 'EibcPending') {
            return 'Awaiting Fulfillment';
        } else if (transfer.status === 'Finalizing') {
            const maxArrivalDate = transfer.time + ((rollAppParams?.disputePeriodInBlocks || 0) * DEFAULT_BLOCK_TIME);
            return `ETA: ${getShortDateTimeString(maxArrivalDate)}`;
        }
    };

    const renderStatusColumn = (transfer: IbcTransferDetails): ReactElement => {
        return (
            <TableColumn className='status-column'>
                <IbcStatusBadge
                    transfer={transfer}
                    showBaseStatus={showBaseStatus}
                    getTooltipInfo={() => getStatusTooltipInfo(transfer)}
                />
            </TableColumn>
        );
    };

    const renderTimeColumn = (transfer: IbcTransferDetails): ReactElement => {
        return (
            <TableColumn className='time-column'>
                {getShortDateTimeString(transfer.time)}
            </TableColumn>
        );
    };

    const renderIbcTransferHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn className='index-column' contentClassName='index-column-content'>Block</TableColumn>
                <TableColumn
                    info={<>
                        A transaction hash for the transfer status <b>initiated</b> is associated with source chain.<br />
                        ֿOnce the transfer reaches the transaction has will be associated with <b>Dymension Hub.</b>
                    </>}
                >
                    Transaction Hash
                </TableColumn>
                <TableColumn>From</TableColumn>
                <TableColumn>To</TableColumn>
                <TableColumn>Amount</TableColumn>
                <TableColumn>Status</TableColumn>
                <TableColumn>Time</TableColumn>
            </TableRow>
        );
    };

    const renderIbcTransferRow = (transfer: IbcTransferDetails): ReactElement => {
        return (
            <TableRow key={transfer.id} className='ibc-transfer-row' onSelect={() => navigate(`/ibc/status/${transfer.id}`)}>
                {renderBlockColumn(transfer)}
                {renderTransactionHashColumn(transfer)}
                {renderNetworkAddressColumn(transfer, 'source')}
                {renderNetworkAddressColumn(transfer, 'destination')}
                {renderAmountColumn(transfer)}
                {renderStatusColumn(transfer)}
                {renderTimeColumn(transfer)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading || initiatedTransfersLoading || initiatedTransferCreating) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!transfers.length) {
            return <div className='no-data'>No Transfers</div>;
        }
    };

    const onBaseStatusSelect = (status?: string | number): void => {
        if (showBaseStatus) {
            setBaseStatusFilter(status as IbcBaseStatus);
            setStatusesFilter(status ? getStatusesOfBaseStatus(status as IbcBaseStatus) : undefined);
        } else {
            setStatusesFilter(status ? [ status as IbcTransferStatus ] : undefined);
        }
    };

    return (
        <div className='page'>
            <h5 className='ibc-transfer-list-header'>
                <div className='header-label'>
                    Transfers ({totalCount})
                    {hubWallet && (
                        <ToggleSwitch onCheck={setShowAll} isChecked={showAll} size='small' containerClassName='show-all-switch'>
                            Show All
                        </ToggleSwitch>
                    )}
                    <Button
                        buttonType='icon'
                        className='refresh-button'
                        tooltip='Refresh data'
                        onClick={refreshData}
                    >
                        ↻
                    </Button>
                </div>
                <span className='space' />

                <div className='transfers-controls'>
                    <ControlsComposer className='transfers-filters'>
                        <Input
                            controlSize='medium'
                            value={searchText}
                            type='search'
                            placeholder={isMobile ? 'Search transfers...' : 'Search by address / hash / block / denom / network'}
                            onTypeFinish={(value) => setSearchText(value?.toString() || '')}
                        />
                        <Select
                            value={showBaseStatus ? (baseStatusFilter || '') : (statusesFilter?.length ? statusesFilter[0] : '')}
                            className='transfer-status-select'
                            optionsOverlayAlign='right'
                            onSelect={onBaseStatusSelect}
                            controlSize='medium'
                        >
                            {(showBaseStatus ? IBC_BASE_STATUSES : IBC_TRANSFER_STATUSES).map((status) => (
                                <Option key={status} value={status}>
                                    {showBaseStatus ? status : getStatusName(status as IbcTransferStatus)}
                                </Option>
                            ))}
                            <Option key='all' value=''>All</Option>
                        </Select>
                    </ControlsComposer>
                </div>
            </h5>
            <div className='ibc-transfer-list-container'>
                <Table className='ibc-transfer-list' bottomBar={renderBottomBar()}>
                    {renderIbcTransferHeaderRow()}
                    {initiatedTransfersLoading || initiatedTransferCreating ? [] : transfers.map(renderIbcTransferRow)}
                </Table>
            </div>
        </div>
    );
};

export default IbcStatusListPage;
