import classNames from 'classnames';
import { omit } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Button from '../../../shared/components/button/button';
import Property from '../../../shared/components/property/property';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../shared/components/spinner/spinner';
import { ReactComponent as DepositIcon } from '../../../assets/icons/deposit.svg';
import StatisticsCards from '../../../shared/components/statistics-cards/statistics-cards';
import { getShortDateTimeString, getTimeLeftText } from '../../../shared/utils/date-utils';
import { formatNumber } from '../../../shared/utils/number-utils';
import { useClient } from '../../client/client-context';
import { getMainCurrency } from '../../currency/currency-service';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import { TxError } from '../../tx/tx-error';
import { DeliveryTxCode } from '../../tx/tx-types';
import { getTimeLeft } from '../governance-service';
import ProposalTypeBadge from '../proposal-type-badge/proposal-type-badge';
import DepositDialog from './deposit-dialog/deposit-dialog';
import { useProposal } from './proposal-context';
import VoteDialog from './vote-dialog/vote-dialog';
import VotesSummary from './votes-summary/votes-summary';
import './proposal-page.scss';

const TRANSACTION_IN_PROGRESS_KEY = 'transactionInProgress';

const ProposalPage: React.FC = () => {
    const { removeMessage, showMessage, showErrorMessage, showWarningMessage } = useSnackbar();
    const { clientError } = useClient();
    const { network, proposalState, voteTxState, setVoteOption } = useProposal();
    const [ handledResponses, setHandledResponses ] = useState<{ [key: string]: boolean }>({});
    const [ voteDialogOpen, setVoteDialogOpen ] = useState(false);
    const [ depositDialogOpen, setDepositDialogOpen ] = useState(false);

    const proposal = useMemo(() => proposalState.proposal, [ proposalState.proposal ]);

    // todo: make it generic for tx
    useEffect(() => {
        if (voteTxState?.broadcasting) {
            if (!voteTxState.signing) {
                removeMessage(TRANSACTION_IN_PROGRESS_KEY);
                showMessage({
                    content: (
                        <div className='horizontally-centered'>
                            <Spinner size='small' />&nbsp;&nbsp;Transaction is in progress.
                        </div>
                    ),
                    duration: 600000,
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            } else {
                showMessage({
                    content: 'Your wallet is waiting for confirmation and a signature...',
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            }
        } else {
            setTimeout(() => removeMessage(TRANSACTION_IN_PROGRESS_KEY), 50);
        }
    }, [ removeMessage, showMessage, voteTxState?.broadcasting, voteTxState.signing ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        const markdownLinks = document.querySelectorAll('.proposal-description.markdown a');
        markdownLinks.forEach((link) => link.setAttribute('target', '_blank'));
    }, [ proposal ]);

    useEffect(() => {
        if (!voteTxState.response || handledResponses[voteTxState.response.hash]) {
            return;
        }
        const { hash, network, deliveryTxCode } = voteTxState.response;
        const exploreLink = network.exploreTxUrl ? (new URL(hash, network.exploreTxUrl)).href : null;
        const action: SnackbarMessage['action'] = exploreLink ?
            { label: 'Explore', callback: () => window.open(exploreLink, '_blank') } :
            undefined;
        let content: string;
        switch (deliveryTxCode) {
            case DeliveryTxCode.SUCCESS:
                content = 'Your vote successfully submitted!';
                break;
            case DeliveryTxCode.INSUFFICIENT_FUNDS:
                content = 'Transaction delivery failed - insufficient funds';
                break;
            case DeliveryTxCode.OUT_OF_GAS:
                content = 'Transaction delivery failed - out of gas';
                break;
            default:
                console.log('Transaction delivery failed with code: ' + deliveryTxCode);
                content = 'Transaction delivery failed, please try again later';
        }
        showMessage({ content, action, type: deliveryTxCode === DeliveryTxCode.SUCCESS ? 'success' : 'error', key: hash });
        setHandledResponses({ ...handledResponses, [voteTxState.response.hash]: true });
        if (deliveryTxCode === DeliveryTxCode.SUCCESS) {
            setVoteDialogOpen(false);
            if (proposalState.voteOption) {
                setVoteOption(proposalState.voteOption, proposalState.voteOption);
            }
        }
    }, [ voteTxState.response, showMessage, removeMessage, handledResponses, proposalState.voteOption, setVoteOption ]);

    useEffect(() => {
        if (!voteTxState?.error || !(voteTxState.error instanceof TxError)) {
            return;
        }
        switch (voteTxState.error.code) {
            case 'MISSING_DATA':
                showErrorMessage('Transaction delivery failed: invalid transaction parameters.');
                break;
            default:
                showErrorMessage('Transaction delivery failed, please try again later');
        }
    }, [ voteTxState?.error, showErrorMessage, showWarningMessage ]);

    // todo: handle errors different
    useEffect(() => {
        if (!clientError) {
            return;
        }
        const networkNameLabel = clientError.network?.chainName || 'the';
        switch (clientError.code) {
            case 'FETCH_DATA_FAILED':
                showErrorMessage(`Can't fetch data from ${networkNameLabel} client, please try again later`);
                break;
            case 'INSUFFICIENT_FEES':
                showErrorMessage(`The transaction broadcast encountered a failure due to insufficient fees`);
                break;
            case 'SIMULATE_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to calculate fee, please try again later`);
                break;
            case 'BROADCAST_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to broadcast the transaction, please try again later`);
                break;
            case 'SIGNATURE_VERIFICATION_FAILED':
                showErrorMessage(`Signature verification failed`);
                break;
            case 'NO_BALANCES':
                const currency = clientError.network ? getMainCurrency(clientError.network) : undefined;
                const action: SnackbarMessage['action'] = !currency || !clientError.network?.faucetUrl ? undefined :
                    {
                        label: 'Get ' + currency.displayDenom,
                        callback: () => window.open(clientError.network?.faucetUrl, '_blank'),
                        close: true,
                    };
                showWarningMessage({
                    content: <>
                        There are no balances in your {clientError.network ? `${clientError.network.chainName} ` : ''}account.<br />
                        Send some tokens there before trying to query or make a transaction.
                    </>,
                    action, duration: 20000,
                    key: 'no-balances-' + clientError.network?.chainId,
                });
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage('The request rejected by the user');
                break;
            default:
                // todo: handle errors different
                showErrorMessage(`${networkNameLabel} client connection failed, please try again later`);
        }
    }, [ clientError, showErrorMessage, showWarningMessage ]);

    if (proposalState.loading) {
        return <Spinner size='large' className='proposal-loader' />;
    }
    if (!proposal) {
        return <h5 className='not-found-message'>Proposal not found</h5>;
    }

    const timeLeft = getTimeLeft(proposal);
    return (
        <div className='proposal-page page'>
            {network.type === 'RollApp' ? (
                <PathNav>
                    <PathNavItem label='RollApps' url='/rollapps' />
                    <PathNavItem label={network.chainName} url={`/rollapp/${network.chainId}`} />
                    <PathNavItem label='Governance' url={`/rollapp/${network.chainId}/governance`} />
                    <PathNavItem label={`Proposal #${proposal.id}`} />
                </PathNav>
            ) : (
                <PathNav>
                    <PathNavItem label='Dymension' url='/dymension/metrics' />
                    <PathNavItem label='Governance' url={`/dymension/governance`} />
                    <PathNavItem label={`Proposal #${proposal.id}`} />
                </PathNav>
            )}
            <div className='proposal-actions'>
                {(proposal.status === 'Deposit Period' || proposal.status === 'Voting Period') && (
                    <Button
                        loading={proposalState.voteOptionLoading}
                        buttonType={proposal.status === 'Deposit Period' ? 'primary' : 'secondary'}
                        disabled={proposalState.voteOptionLoading}
                        onClick={() => setDepositDialogOpen(true)}
                    >
                        Deposit&nbsp;&nbsp;<DepositIcon />
                    </Button>
                )}
                {proposal.status === 'Voting Period' && (
                    <Button
                        loading={proposalState.voteOptionLoading}
                        buttonType='primary'
                        disabled={proposalState.voteOptionLoading}
                        className={classNames('vote-button', proposalState.originalVoteOption?.toLowerCase())}
                        onClick={() => setVoteDialogOpen(true)}
                    >
                        {!proposalState.originalVoteOption ? 'Vote' : `Voted: ${proposalState.originalVoteOption}`}
                    </Button>
                )}
            </div>
            <h3 className='proposal-title'>#{proposal.id} {proposal.title}</h3>
            <div className='type-badge-container'>
                <ProposalTypeBadge proposal={proposal} size='large' />
                {proposal.status === 'Voting Period' && (
                    <span className={classNames('expires-in-label', { alert: timeLeft.days < 2 })}>
                        Expires in {getTimeLeftText(timeLeft)}
                    </span>
                )}
            </div>
            <Markdown className='proposal-description markdown' remarkPlugins={[ remarkGfm ]}>{proposal.description}</Markdown>

            <StatisticsCards>
                <Property label='Submit On'>
                    {getShortDateTimeString(proposal.submitTime, true)}
                </Property>
                {proposal.status === 'Deposit Period' && (
                    <Property label='Deposit End Time'>
                        {getShortDateTimeString(proposal.depositEndTime, true)}
                    </Property>
                )}
                {proposal.status !== 'Deposit Period' && (
                    <Property label='Voting Starts'>
                        {getShortDateTimeString(proposal.votingStartTime, true)}
                    </Property>
                )}
                {proposal.status !== 'Deposit Period' && (
                    <Property label='Voting Ends'>
                        {getShortDateTimeString(proposal.votingEndTime, true)}
                    </Property>
                )}
                <Property label='Total Deposit'>
                    {formatNumber(proposal.totalDeposit.amount, { maximumSignificantDigits: 6 })}&nbsp;
                    {proposal.totalDeposit.currency.displayDenom}
                </Property>
                {proposalState.params?.minDeposit && proposal.status === 'Deposit Period' && (
                    <Property label='Target Deposit'>
                        {formatNumber(proposalState.params.minDeposit.amount, { maximumSignificantDigits: 6 })}&nbsp;
                        {proposalState.params.minDeposit.currency.displayDenom}
                    </Property>
                )}
            </StatisticsCards>

            {proposal.status !== 'Deposit Period' && (
                <div className='votes-summary-container'>
                    {proposalState.summaryLoading ? <Spinner /> : <VotesSummary />}
                </div>
            )}

            <h5 className='proposal-content-header'>Message</h5>
            <div className='proposal-content section small'>
                <ReactJson
                    style={{ backgroundColor: 'transparent' }}
                    name='content'
                    src={omit(proposal.content, 'title', 'description')}
                    theme='embers'
                    collapsed={false}
                />
            </div>

            {voteDialogOpen && <VoteDialog closable onRequestClose={() => setVoteDialogOpen(false)} />}

            {depositDialogOpen && <DepositDialog closable onRequestClose={() => setDepositDialogOpen(false)} />}
        </div>
    );
};

export default ProposalPage;
