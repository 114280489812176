import React, { useEffect, useMemo } from 'react';
import Input from '../../../shared/components/form-controls/input/input';
import InfoIndicator from '../../../shared/components/info-indicator/info-indicator';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import { useAmm } from '../amm-context';
import { POOL_SHARE_PREFIX } from '../amm.service';
import AssetList, { AssetListHeader } from './asset-list/asset-list';
import { AssetsContextProvider, useAssets } from './assets-context';
import './assets-page.scss';

const AssetsPage: React.FC = () => {
    const { ammState } = useAmm();
    const { searchText, loadMore, setSearchText } = useAssets();
    const scrollPosition = useScrollPosition();

    const bondedAssetsVisible = useMemo(
        () => ammState.lockedAssets?.some((lockedAsset) => !lockedAsset.coin.denom.includes(POOL_SHARE_PREFIX)),
        [ ammState.lockedAssets ],
    );

    useEffect(() => {
        if (scrollPosition.target?.scrollTop &&
            scrollPosition.target.scrollTop + 1.5 * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight
        ) {
            loadMore();
        }
    }, [ loadMore, scrollPosition.target?.clientHeight, scrollPosition.target?.scrollHeight, scrollPosition.target?.scrollTop ]);

    return (
        <div className='page assets-page'>
            <PathNav>
                <PathNavItem label='Liquidity' />
                <PathNavItem label='Assets' />
            </PathNav>

            {bondedAssetsVisible && <>
                <AssetListHeader className='asset-list-header'>
                    Deposit and Earn
                    <InfoIndicator>Earn assets are eligible for incentives, once bonded, incentives are streamed</InfoIndicator>
                </AssetListHeader>
                <AssetList bondedAssets />
            </>}
            <AssetListHeader className='asset-list-header'>
                All Assets
                <span className='space' />
                <Input
                    controlSize='medium'
                    className='assets-search'
                    value={searchText}
                    type='search'
                    placeholder='Search assets...'
                    onValueChange={setSearchText}
                />
            </AssetListHeader>
            <AssetList />
        </div>
    );
};

const AssetsPageWithContext = () =>
    <AssetsContextProvider><AssetsPage /></AssetsContextProvider>;

export default AssetsPageWithContext;


