import { readStream } from '../../shared/utils/file-utils';

export const loadAnalyticsMap = async <T extends string, R = never>(
    ids: string[],
    queryIdsParam: string,
    analyticsUrl: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    const result = await loadAnalytics<T, R>(ids, queryIdsParam, analyticsUrl, history);
    return { ...ids.reduce((current, id) => ({ ...current, [id]: {} }), {}), ...result }; // todo: find better way
};

export const loadAnalyticsItem = async <T extends string, R = never>(
    id: string,
    queryIdsParam: string,
    analyticsUrl: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    const analyticsMap = await loadAnalytics<T, { [id: string]: R }>([ id ], queryIdsParam, analyticsUrl, history);
    return analyticsMap[id];
};

const loadAnalytics = async <T extends string, R = never>(
    ids: string[],
    queryIdsParam: string,
    analyticsUrl: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    const params = new URLSearchParams();
    ids.map((id) => params.append(`${queryIdsParam}[]`, id));
    if (history) {
        Object.keys(history).map((analyticsType) =>
            (history[analyticsType as T] || []).map((period) => params.append(`history[${analyticsType}]`, period)));
    }
    const response = await fetch(`${analyticsUrl}?${params}`).catch((error) => {
        console.error('Failed to fetch analytics', { error });
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    return JSON.parse(responseText || '{}') as R;
};


