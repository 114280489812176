import { useCallback, useEffect, useState } from 'react';

type ScrollPosition = { left?: number; top?: number, target?: HTMLElement };

const useScrollPosition = (): ScrollPosition => {
    const [ scrollPosition, setScrollPosition ] = useState<ScrollPosition>({});

    const updatePosition = useCallback((event?: Partial<Event>): void => {
        let target = event?.target as HTMLElement;
        if ('scrollingElement' in target) {
            target = target.scrollingElement as HTMLElement;
        }
        setScrollPosition({ left: target?.scrollLeft, top: target?.scrollTop, target });
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', updatePosition, true);
        updatePosition({ target: document });
        return () => document.removeEventListener('scroll', updatePosition, true);
    }, [ updatePosition ]);

    return scrollPosition;
};

export default useScrollPosition;
