import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import InfoIndicator from '../info-indicator/info-indicator';
import './property.scss';

export interface PropertyProps {
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
    valueSuffixClassName?: string;
    infoIndicatorClassName?: string;
    style?: CSSProperties;
    children: ReactNode;
    centered?: boolean;
    label: string;
    info?: ReactNode;
    valueSuffix?: string;
}

const Property: React.FC<PropertyProps> = ({
    children,
    label,
    info,
    valueSuffix,
    centered,
    className,
    labelClassName,
    valueClassName,
    valueSuffixClassName,
    infoIndicatorClassName,
    style,
}) => {
    const propertyClassName = classNames('property', className, { centered });
    const propertyLabelClassName = classNames('property-label', labelClassName, { 'have-info': Boolean(info) });
    const propertyValueClassName = classNames('property-value', valueClassName);
    const propertyValueSuffixClassName = classNames('property-value-suffix', valueSuffixClassName);
    const propertyInfoIndicatorClassName = classNames('column-info-indicator', infoIndicatorClassName);

    return (
        <div className={propertyClassName} style={style}>
            <p className={propertyLabelClassName}>
                {label}
                {info ? <InfoIndicator tooltipPlacement='top' className={propertyInfoIndicatorClassName}>{info}</InfoIndicator> : null}
            </p>
            <div className={propertyValueClassName}>
                {children}
                {valueSuffix && <span className={propertyValueSuffixClassName}>{valueSuffix}</span>}
            </div>
        </div>
    );
};

export default Property;
