import { SKIP_API_URL, SkipRouter, Swap } from '@skip-router/core';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Chain, createWalletClient, custom } from 'viem';
import { toAccount } from 'viem/accounts';
import * as chains from 'viem/chains';
import { usePersistedState } from '../../shared/hooks/use-persisted-state';
import { AccountNetworkState } from '../account/account-network-state';
import { useAccountNetwork } from '../account/use-account-network';
import { useClient } from '../client/client-context';
import { ClientError } from '../client/client-error';
import { getMinDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { useNetwork } from '../network/network-context';
import { Network } from '../network/network-types';
import { AmountTxValue, useAmountTx } from '../tx/amount-tx/use-amount-tx';
import { TxError } from '../tx/tx-error';
import { DeliveryTxCode, TxResponse } from '../tx/tx-types';
import { useWallet } from '../wallet/wallet-context';
import { WalletError } from '../wallet/wallet-error';
import { convertToHexAddress } from '../wallet/wallet-service';
import { EthereumWallet } from '../wallet/wallets/ethereum-wallet';
import { useIbcStatus } from './ibc-status/ibc-status-context';
import { IbcTransferError } from './ibc-transfer-error';
import { createTransferMessage, getSourceChannel } from './ibc-transfer-service';
import { InitializedIbcTransferDetails } from './ibc-transfer-types';

const USE_EIBC_KEY = 'useEIbcKey';

export const EIBC_FEE = 0.0015;
const IBC_MAX_BALANCE_PART = 0.9999999999;

interface IbcTransferContextProps {
    children: ReactNode;
    optionalSourceNetworks?: string[];
    optionalDestinationNetworks?: string[];
    initialSourceId?: string;
    initialDestinationId?: string;
}

interface IbcTransferContextValue extends Omit<AmountTxValue, 'calculateFee' | 'clearFee' | 'txStateDispatch' | 'broadcast'> {
    sourceData: AccountNetworkState;
    destinationData: AccountNetworkState;
    hubNetworkData: AccountNetworkState;
    error?: IbcTransferError;
    transferEnabled: boolean;
    setSource: (network?: Network | string, switchNetwork?: boolean) => void;
    setDestination: (network?: Network | string) => void;
    optionalSourceNetworks?: string[];
    optionalDestinationNetworks?: string[];
    noRoutesBalances?: CoinsAmount[];
    destinationNetworksWithRoutes?: Network[];
    cctpMinAmountToTransfer: number;
    useEIbc: boolean;
    setUseEIbc: (value: boolean) => void;
    transfer: () => void;
    getInitializedIbcTransferDetailsId: () => string;
}

export const IbcTransferContext = createContext<IbcTransferContextValue>({} as IbcTransferContextValue);

export const useIbcTransfer = (): IbcTransferContextValue => useContext(IbcTransferContext);

export const IbcTransferContextProvider: React.FC<IbcTransferContextProps> = ({
    children,
    optionalSourceNetworks,
    optionalDestinationNetworks,
    initialSourceId,
    initialDestinationId,
}) => {
    const { allNetworks, hubNetwork, networkDenoms, getNetwork } = useNetwork();
    const { networkWalletTypeMap, networkWalletMap, hubWallet, handleWalletError, connectWallet, fetchMostSuitableWallet } = useWallet();
    const { clientStateMap, signingClientStateMap, refreshClient, handleClientError } = useClient();
    const { setInitiatedTransferCreating } = useIbcStatus();
    const [ sourceData, setSourceNetwork ] = useAccountNetwork();
    const [ destinationData, setDestinationNetwork ] = useAccountNetwork(undefined, false);
    const [ hubNetworkData ] = useAccountNetwork(hubNetwork, false);
    const [ useEIbc, setUseEIbc ] = usePersistedState<boolean>(USE_EIBC_KEY, true, undefined, true);
    const [ error, setError ] = useState<IbcTransferError>();

    const sourceChannel = useMemo(() => getSourceChannel(sourceData, destinationData), [ sourceData, destinationData ]);

    const transferMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = sourceData.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!coins ||
            !balance ||
            !sourceData.network ||
            !sourceData.balances ||
            !sourceData.address ||
            (sourceData.network.type === 'EVM' || sourceData.network.type === 'Solana' || !sourceChannel) ||
            !destinationData.network ||
            !destinationData.address) {
            return [];
        }
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.min(coins.amount, balance.amount - fee.amount) };
        }
        const balanceWithoutFee = balance.amount - (fee?.amount || 0);
        coins = {
            ...coins,
            amount: coins.amount <= balanceWithoutFee * IBC_MAX_BALANCE_PART ? coins.amount : balanceWithoutFee * IBC_MAX_BALANCE_PART,
        };
        const transferMessage = createTransferMessage({
            sourceData,
            destinationData,
            hubNetworkData,
            balance,
            coins,
            eibc: useEIbc,
        });
        return [ transferMessage ];
    }, [ destinationData, hubNetworkData, sourceChannel, sourceData, useEIbc ]);

    const { txState, amountTxState, setCoins, setAmount, calculateFee, clearFee, broadcast, txStateDispatch } = useAmountTx({
        networkState: sourceData,
        amountTxMessagesCreator: transferMessagesCreator,
    });

    const getNoRouteBalances = useCallback((destinationNetwork: Network | undefined = destinationData.network) => {
        if (destinationNetwork?.type === 'EVM' || destinationNetwork?.type === 'Solana') {
            return [ ...(sourceData.balances || []) ];
        }
        const sourceAllowedDenoms = sourceData.network?.ibc?.allowedDenoms;
        const destinationAllowedDenoms = destinationNetwork?.ibc?.allowedDenoms;
        if (!sourceAllowedDenoms?.length && !destinationAllowedDenoms?.length) {
            return [];
        }
        let balances = sourceData.balances?.filter((balance) => {
            if (sourceAllowedDenoms && !sourceAllowedDenoms.includes(balance.currency.baseDenom)) {
                return true;
            }
            if (!destinationAllowedDenoms?.length) {
                return false;
            }
            const isBalanceAllowed = destinationAllowedDenoms.some((denom) => {
                if (denom !== balance.currency.baseDenom) {
                    return false;
                }
                const currency = destinationNetwork?.currencies.find((currency) => currency.baseDenom === denom);
                if (!currency) {
                    return true;
                }
                if (!currency.ibcRepresentation) {
                    return balance.ibc?.networkId === destinationNetwork?.chainId;
                }
                if (balance.ibc) {
                    return balance.ibc.representation === currency.ibcRepresentation;
                }
                const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
                return sourceData.network?.chainId === networkDenom?.ibcNetworkId;
            });
            return !isBalanceAllowed;
        });

        // todo: temporary code - do it generic
        const usdtBalance = sourceData.balances?.find((balance) => balance.currency.baseDenom === 'erc20/tether/usdt');
        if (usdtBalance && (sourceData.network?.type === 'EVM' && destinationNetwork?.chainId !== 'kava_2222-10') &&
            (!balances || balances?.every((balance) => !isCoinsEquals(usdtBalance, balance)))) {
            balances = balances || [];
            balances.push(usdtBalance);
        }
        return balances;
    }, [
        destinationData.network,
        networkDenoms,
        sourceData.balances,
        sourceData.network?.chainId,
        sourceData.network?.ibc?.allowedDenoms,
        sourceData.network?.type,
    ]);

    const noRoutesBalances = useMemo(getNoRouteBalances, [ getNoRouteBalances ]);

    const destinationNetworksWithRoutes = useMemo(() => allNetworks.filter((network) => {
        if (!sourceData.balances?.length) {
            return true;
        }
        if (network.chainId === sourceData.network?.chainId) {
            return false;
        }
        const noRoutesBalances = getNoRouteBalances(network);
        return (sourceData.balances?.length || 0) > (noRoutesBalances?.length || 0);
    }), [ allNetworks, getNoRouteBalances, sourceData.balances, sourceData.network?.chainId ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof IbcTransferError) {
            setError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError ]);

    const getBridgeToken = useCallback((isSourceBridgeNetwork: boolean): { baseDenom?: string, bridgeDenom?: string } => {
        if (!amountTxState.coins) {
            return {};
        }
        let bridgeDenom: string | undefined;
        let baseDenom: string | undefined = amountTxState.coins.currency.baseDenom;
        if (isSourceBridgeNetwork) {
            bridgeDenom = amountTxState.coins.currency.bridgeDenom;
            const isNativeToken = destinationData.network?.currencies.some((currency) => {
                if (currency.baseDenom !== amountTxState.coins?.currency.baseDenom) {
                    return false;
                }
                const networkDenom =
                    networkDenoms?.find((networkDenom) => networkDenom.denom === amountTxState.coins?.currency.ibcRepresentation);
                return networkDenom?.ibcNetworkId === destinationData.network?.chainId;
            });
            if (!isNativeToken) {
                baseDenom = amountTxState.coins.currency.ibcRepresentation;
            }
        } else {
            bridgeDenom = destinationData.network?.currencies.find((currency) => {
                if (currency.baseDenom !== amountTxState.coins?.currency.baseDenom) {
                    return false;
                }
                if (currency.ibcRepresentation === amountTxState.coins?.ibc?.representation) {
                    return true;
                }
                const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
                return networkDenom?.ibcNetworkId === sourceData.network?.chainId;
            })?.bridgeDenom;
        }
        return { baseDenom, bridgeDenom };
    }, [
        amountTxState.coins,
        destinationData.network?.chainId,
        destinationData.network?.currencies,
        networkDenoms,
        sourceData.network?.chainId,
    ]);

    const getInitializedIbcTransferDetailsId = useCallback(() => {
        if (!txState.response || !hubNetwork?.chainId) {
            return '';
        }
        const sendPacketEvent = txState.response.nativeResponse?.events.find((event) => event.type === 'send_packet');
        if (!sendPacketEvent) {
            return '';
        }
        const packetSequence = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_sequence')?.value;
        const sourceChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_src_channel')?.value;
        const destinationChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_dst_channel')?.value;
        if (!sourceChannel || !destinationChannel || packetSequence === undefined || packetSequence === null) {
            return '';
        }
        return `${hubNetwork?.chainId}-${sourceChannel}-${destinationChannel}-${packetSequence}`;
    }, [ hubNetwork?.chainId, txState.response ]);

    useEffect(() => {
        if (!txState.response || !hubNetwork?.chainId) {
            return;
        }
        const sendPacketEvent = txState.response.nativeResponse?.events.find((event) => event.type === 'send_packet');
        if (!sendPacketEvent) {
            return;
        }
        const packetData = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_data')?.value;
        const packetSequence = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_sequence')?.value;
        const timeoutTimestamp = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_timeout_timestamp')?.value;
        const sourceChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_src_channel')?.value;
        const destinationChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_dst_channel')?.value;
        if (!packetData ||
            !sourceChannel ||
            !destinationChannel ||
            packetSequence === undefined ||
            packetSequence === null ||
            !timeoutTimestamp
        ) {
            return;
        }
        const packetDataValue = JSON.parse(packetData) as { amount: string, denom: string, receiver: string, sender: string };
        if (!packetDataValue.amount || !packetDataValue.denom || !packetDataValue.receiver || !packetDataValue.sender) {
            return;
        }
        let hexSender = '';
        let hexReceiver = '';
        try {
            hexSender = packetDataValue.sender.indexOf('0x') === 0 ? packetDataValue.sender : convertToHexAddress(packetDataValue.sender);
            hexReceiver =
                packetDataValue.receiver.indexOf('0x') === 0 ? packetDataValue.receiver : convertToHexAddress(packetDataValue.receiver);
        } catch {}
        const transfer: InitializedIbcTransferDetails = {
            ...packetDataValue,
            amount: Number(packetDataValue.amount) || 0,
            hexSender,
            hexReceiver,
            hash: txState.response.hash,
            packetSequence: Number(packetSequence) || 0,
            timeout: (Number(timeoutTimestamp) || 0) / Math.pow(10, 6),
            sourceChannel,
            destinationChannel,
            time: new Date().getTime(),
            type: txState.response.network.type === 'Hub' ? 'Out' : 'In',
            network: hubNetwork?.chainId,
        };
        setInitiatedTransferCreating(true);
        fetch(process.env.REACT_APP_ADD_INITIAL_IBC_TRANSFER_DETAILS, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(transfer),
        })
            .catch((error) => console.error('Failed to add initial IBC transfer details', error))
            .finally(() => setInitiatedTransferCreating(false));
    }, [ hubNetwork?.chainId, setInitiatedTransferCreating, txState.response ]);

    const broadcastSkipTransfer = useCallback(async () => {
        if (txState.broadcasting) {
            return;
        }
        const isSourceNonCosmos = sourceData.network?.type === 'EVM' || sourceData.network?.type === 'Solana';
        const [ nonCosmosNetworkData, cosmosNetworkData ] = isSourceNonCosmos ?
            [ sourceData, destinationData ] :
            [ destinationData, sourceData ];
        if (!cosmosNetworkData.address ||
            !cosmosNetworkData.network ||
            !amountTxState.coins) {
            throw new TxError('MISSING_DATA', sourceData.network);
        }
        const nonCosmosChainId = nonCosmosNetworkData.network?.evm ?
            Number(nonCosmosNetworkData.network.evm.chainId).toString() : nonCosmosNetworkData.network?.chainId || '';
        const cosmosChainId = cosmosNetworkData.network.chainId;
        const cosmosChainSigningClient = signingClientStateMap[cosmosChainId]?.client;
        if (!isSourceNonCosmos && !cosmosChainSigningClient) {
            throw new TxError('MISSING_DATA', cosmosNetworkData.network);
        }
        const { baseDenom, bridgeDenom } = getBridgeToken(isSourceNonCosmos);
        if (!bridgeDenom || !baseDenom) {
            throw new TxError('MISSING_DATA');
        }

        txStateDispatch({ type: 'set-broadcasting' });
        txStateDispatch({ type: 'set-route-searching' });

        const client = new SkipRouter({
            endpointOptions: {
                getRpcEndpointForChain: async (chainID) => {
                    // todo: make it generic
                    if (chainID === 'solana') {
                        return 'https://solana-mainnet.g.alchemy.com/v2/Mbaw2zqhViFTXpmU_491k5M1b-hMc22q';
                    }
                    return '';
                },
            },
            apiURL: SKIP_API_URL,
            getCosmosSigner: async (chainId) => {
                const wallet = networkWalletMap[chainId];
                if (!wallet || !cosmosNetworkData.network) {
                    throw new TxError('MISSING_DATA', cosmosNetworkData.network);
                }
                return (await wallet.getOfflineSigner(cosmosNetworkData.network)) as any;
            },
            getEVMSigner: async (chainId) => {
                const evmWallet = networkWalletMap[nonCosmosNetworkData.network?.chainId || chainId] as EthereumWallet;
                if (!nonCosmosNetworkData.hexAddress || !evmWallet) {
                    throw new TxError('MISSING_DATA', nonCosmosNetworkData.network);
                }
                return createWalletClient({
                    chain: (Object.values(chains) as Chain[]).find((chain) => chain.id === Number(chainId)),
                    transport: custom(await evmWallet.getProvider()),
                    account: toAccount(nonCosmosNetworkData.hexAddress as any),
                });
            },
            getSVMSigner: async () => {
                const adapter = new PhantomWalletAdapter();
                await adapter.connect();
                return adapter;
            },
        });

        const route = await client.route({
            amountIn: getMinDenomAmount(amountTxState.coins.amount, amountTxState.coins.currency).toString(),
            sourceAssetDenom: isSourceNonCosmos ? bridgeDenom : baseDenom,
            sourceAssetChainID: isSourceNonCosmos ? nonCosmosChainId : cosmosChainId,
            cumulativeAffiliateFeeBPS: '0',
            destAssetDenom: isSourceNonCosmos ? baseDenom : bridgeDenom,
            destAssetChainID: isSourceNonCosmos ? cosmosChainId : nonCosmosChainId,
            allowMultiTx: false,
            smartRelay: true,
            bridges: amountTxState.coins?.currency.cctp ? [ 'IBC', 'CCTP' ] : undefined,
            experimentalFeatures: [ 'cctp' ],
        }).catch((error) => {
            throw new ClientError('BROADCAST_TX_FAILED', sourceData.network, error);
        });

        txStateDispatch({ type: 'set-route-searching', payload: false });

        if (amountTxState.coins?.currency.cctp && route.doesSwap) {
            throw new ClientError('BROADCAST_TX_FAILED', sourceData.network);
        }

        const userAddresses = await (route.operations as { swap?: Swap }[])
            .filter((operation) => operation.swap)
            .map((operation) => operation.swap?.chainID)
            .filter((networkId) => networkId !== nonCosmosChainId && networkId !== cosmosChainId)
            .map((networkId) => networkId ? getNetwork(networkId) : undefined)
            .reduce(async (current, network) => {
                if (!network) {
                    return current;
                } else if (network.evm || network.type === 'EVM') {
                    return { ...current, [network.chainId]: nonCosmosNetworkData.hexAddress };
                } else if (!cosmosNetworkData.network?.evm) {
                    const wallet = cosmosNetworkData.network && networkWalletMap[cosmosNetworkData.network.chainId];
                    const addresses = await wallet?.getAddress(network);
                    if (addresses?.address) {
                        return { ...current, [network.chainId]: addresses?.address };
                    }
                }
                return current;
            }, Promise.resolve({} as { [chainId: string]: string }));

        await client.executeRoute({
            route,
            userAddresses: {
                [nonCosmosChainId]: (nonCosmosNetworkData.network?.type === 'EVM' ?
                    nonCosmosNetworkData.hexAddress : nonCosmosNetworkData.address) || '',
                [cosmosChainId]: cosmosNetworkData.address,

                // todo: temporary, remove this code after skip will fixed their bug
                // ...route.chainIDs
                //     .filter((networkId) => networkId !== nonCosmosChainId && networkId !== cosmosChainId)
                //     .map((networkId) => getNetwork(networkId))
                //     .reduce((current, network) => !network?.bech32Prefix ? current : ({
                //         ...current,
                //         [network.chainId]: convertToBech32Address(cosmosNetworkData.hexAddress || '', network.bech32Prefix),
                //     }), {}),
                'noble-1': 'noble1zg7kkfwsv2n7rg0w69u6kh4pykyf4v3xwg6yv2',
                'osmosis-1': 'osmo1zg7kkfwsv2n7rg0w69u6kh4pykyf4v3xwsuuzk',

                ...userAddresses,
            },
            getGasPrice: async () => cosmosChainSigningClient?.getGasPrice() as any,
            onTransactionBroadcast: async ({ txHash, chainID }) => {
                txStateDispatch({
                    type: 'set-response',
                    payload: { network: sourceData.network, deliveryTxCode: DeliveryTxCode.SUCCESS, hash: txHash } as TxResponse,
                });
                refreshClient(chainID);
            },
            onTransactionCompleted: async (chainID, txHash, status) => {
                if (status.error) {
                    throw status.error;
                }
            },
        }).catch((error) => {
            throw new ClientError('BROADCAST_TX_FAILED', sourceData.network, error);
        });
    }, [
        amountTxState.coins,
        destinationData,
        getBridgeToken,
        getNetwork,
        networkWalletMap,
        refreshClient,
        signingClientStateMap,
        sourceData,
        txState.broadcasting,
        txStateDispatch,
    ]);

    const transfer = useCallback(() => {
        if (!sourceData.network || !networkWalletMap[sourceData.network.chainId]) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, sourceData.network));
            return;
        }
        if (!destinationData.network || !networkWalletMap[destinationData.network.chainId]) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, destinationData.network));
            return;
        }
        if (sourceData.network?.type === 'EVM' ||
            destinationData.network?.type === 'EVM' ||
            sourceData.network?.type === 'Solana' ||
            destinationData.network?.type === 'Solana') {
            broadcastSkipTransfer().catch((error) => txStateDispatch({ type: 'set-error', payload: error }));
        } else {
            return broadcast();
        }
    }, [
        broadcast,
        broadcastSkipTransfer,
        destinationData.network,
        handleWalletError,
        networkWalletMap,
        sourceData.network,
        txStateDispatch,
    ]);

    const setSource = useCallback((network?: Network | string, switchNetwork = true): void => {
        network = typeof network === 'string' ? getNetwork(network) : network;
        setSourceNetwork(network);
        if (switchNetwork && network) {
            const sourceWallet = networkWalletMap[network.chainId];
            if (sourceWallet) {
                sourceWallet.switchNetwork?.(network).catch(handleError);
            }
        }
    }, [ getNetwork, handleError, networkWalletMap, setSourceNetwork ]);

    const setDestination = useCallback((network?: Network | string): void => {
        network = typeof network === 'string' ? getNetwork(network) : network;
        setDestinationNetwork(network);
    }, [ getNetwork, setDestinationNetwork ]);

    const cctpMinAmountToTransfer = useMemo((): number => {
        if (amountTxState.coins?.currency.cctp && (
            (sourceData.network?.type === 'EVM' || destinationData.network?.type === 'EVM' ||
                sourceData.network?.type === 'Solana' || destinationData.network?.type === 'Solana'))) {
            return Number(process.env.REACT_APP_CCTP_MIN_AMOUNT) || 0;
        }
        return 0;
    }, [ amountTxState.coins?.currency.cctp, destinationData.network?.type, sourceData.network?.type ]);

    const transferEnabled = useMemo(() => Boolean(
        sourceData.network &&
        destinationData.network &&
        !txState.broadcasting &&
        !txState.feeLoading &&
        amountTxState.coins?.amount &&
        amountTxState.coins.amount >= cctpMinAmountToTransfer &&
        (sourceData.network.type === 'EVM' ||
            sourceData.network.type === 'Solana' ||
            !networkWalletMap[sourceData.network.chainId] ||
            !networkWalletMap[destinationData.network.chainId] ||
            clientStateMap[sourceData.network.chainId]?.client),
    ), [
        sourceData.network,
        destinationData.network,
        txState.broadcasting,
        txState.feeLoading,
        amountTxState.coins,
        cctpMinAmountToTransfer,
        networkWalletMap,
        clientStateMap,
    ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => handleError(sourceData.error), [ handleError, sourceData.error ]);

    useEffect(() => handleError(destinationData.error), [ handleError, destinationData.error ]);

    useEffect(() => {
        if (!sourceChannel &&
            sourceData.network &&
            sourceData.network.type !== 'EVM' &&
            sourceData.network.type !== 'Solana' &&
            destinationData.network &&
            destinationData.network.type !== 'EVM' &&
            destinationData.network.type !== 'Solana' &&
            sourceData.network.chainId !== destinationData.network.chainId) {
            handleError(new IbcTransferError('MISSING_CHANNEL', sourceData.network));
        }
    }, [ destinationData.network, handleError, sourceChannel, sourceData.network ]);

    useEffect(() => {
        if (!allNetworks.length) {
            return;
        }
        let sourceNetwork = sourceData.network;
        if (!sourceNetwork) {
            const sourceNetworks =
                allNetworks.filter((network) => !optionalSourceNetworks || optionalSourceNetworks.includes(network.chainId));
            if (sourceNetworks.length) {
                sourceNetwork =
                    sourceNetworks.find((network) => network.chainId === initialSourceId) ||
                    sourceNetworks.find((network) => network.type === 'Hub') ||
                    sourceNetworks[0];
                setSource(sourceNetwork, false);
            }
        }
        if (!destinationData.network && sourceNetwork) {
            const destinationNetworks = destinationNetworksWithRoutes.filter((network) => !optionalDestinationNetworks ||
                optionalDestinationNetworks.includes(network.chainId));
            if (destinationNetworks.length) {
                const optionalNetworks = destinationNetworks.filter((network) => network.chainId !== sourceNetwork?.chainId);
                const destinationNetwork =
                    optionalNetworks.find((network) => network.chainId === initialDestinationId) || optionalNetworks[0];
                setDestination(destinationNetwork);
            }
        }
    }, [
        destinationNetworksWithRoutes,
        destinationData.network,
        initialDestinationId,
        initialSourceId,
        optionalDestinationNetworks,
        optionalSourceNetworks,
        setDestination,
        setSource,
        sourceData.network,
        allNetworks,
    ]);

    useEffect(() => {
        const networkId = sourceData.network?.chainId;
        if (sourceData.network && networkId && !networkWalletTypeMap[networkId] && hubWallet && networkId !== hubNetwork?.chainId) {
            const walletType = fetchMostSuitableWallet(sourceData.network);
            if (walletType) {
                connectWallet(networkId, walletType);
            }
        }
    }, [ connectWallet, fetchMostSuitableWallet, hubNetwork?.chainId, hubWallet, networkWalletTypeMap, sourceData.network ]);

    useEffect(() => {
        const networkId = destinationData.network?.chainId;
        if (destinationData.network && networkId && !networkWalletTypeMap[networkId] && hubWallet && networkId !== hubNetwork?.chainId) {
            const walletType = fetchMostSuitableWallet(destinationData.network);
            if (walletType) {
                connectWallet(networkId, walletType);
            }
        }
    }, [ connectWallet, destinationData.network, fetchMostSuitableWallet, hubNetwork?.chainId, hubWallet, networkWalletTypeMap ]);

    useEffect(() => {
        if (sourceData.address && destinationData.address && sourceChannel && amountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ destinationData.address, sourceData.address, sourceChannel, amountTxState.coins?.currency, calculateFee, clearFee ]);

    useEffect(() => {
        if (sourceData.network?.disabled || destinationData.network?.disabled) {
            setSource(undefined);
            setDestination(undefined);
        }
    }, [ destinationData.network?.disabled, setDestination, setSource, sourceData.network?.disabled ]);

    return (
        <IbcTransferContext.Provider
            value={{
                sourceData,
                destinationData,
                hubNetworkData,
                txState,
                amountTxState,
                noRoutesBalances,
                setSource,
                setDestination,
                error,
                transferEnabled,
                cctpMinAmountToTransfer,
                useEIbc,
                destinationNetworksWithRoutes,
                optionalSourceNetworks,
                optionalDestinationNetworks,
                getInitializedIbcTransferDetailsId,
                setAmount,
                setCoins,
                setUseEIbc,
                transfer,
            }}
        >
            {children}
        </IbcTransferContext.Provider>
    );
};
