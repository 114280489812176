export interface LiquidityState {
    sharesAmount: bigint;
    singleAsset: boolean;
}

export type LiquidityAction =
    { type: 'set-shares-amount', payload: bigint } |
    { type: 'set-single-asset', payload?: boolean };

export const liquidityReducer = (state: LiquidityState, action: LiquidityAction): LiquidityState => {
    switch (action.type) {
        case 'set-shares-amount':
            return { ...state, sharesAmount: action.payload };
        case 'set-single-asset':
            return { ...state, singleAsset: action.payload ?? true };
        default:
            return state;
    }
};

