import { Transaction } from '@solana/web3.js';
import { PublicKey } from 'cosmjs-types/tendermint/crypto/keys';
import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { WalletError } from '../wallet-error';
import { Wallet, WalletType } from '../wallet-types';

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        phantom?: { solana: PhantomSolana };
    }
}

interface PhantomSolana {
    isPhantom?: boolean;
    publicKey?: PublicKey;
    connect: () => Promise<{ publicKey: PublicKey }>;
    disconnect: () => Promise<void>;

    on(eventName: string | symbol, listener: (...args: any[]) => void): this;

    off(eventName: string | symbol, listener: (...args: any[]) => void): this;

    signTransaction(transaction: Transaction): Promise<Transaction>;

    signAndSendTransaction(transaction: Transaction): Promise<{ signature: string }>;
}

export class PhantomWallet implements Wallet {
    private currentAddress?: string;
    private accountChangeListener?: () => void;
    private readonly onAccountChanged: (publicKey?: PublicKey) => Promise<void>;

    constructor() {
        this.onAccountChanged = async (publicKey?: PublicKey): Promise<void> => {
            const previousAddress = this.currentAddress;
            if (publicKey) {
                this.currentAddress = publicKey.toString();
            } else {
                await this.getProvider().connect();
            }
            if (previousAddress && this.currentAddress && previousAddress !== this.currentAddress) {
                this.accountChangeListener?.();
            }
        };
    }

    public setAccountChangesListener(listener: () => void): void {
        this.accountChangeListener = listener;
        this.getProvider().on('accountChanged', this.onAccountChanged);
    }

    public clear(): void {
        const provider = this.getProvider();
        provider.off('accountChanged', this.onAccountChanged);
        provider.disconnect().then();
    }

    public async getAddress(): Promise<{ address?: string; hexAddress?: string }> {
        const provider = this.getProvider();
        if (provider.publicKey) {
            return { address: provider.publicKey.toString() };
        }
        const response = await provider.connect();
        return { address: response.publicKey.toString() };
    }

    public getOfflineSigner(): Promise<OfflineSigner> {
        throw new WalletError('NO_OFFLINE_SIGNER');
    }

    public getWalletType(): WalletType {
        return 'Phantom';
    }

    public async validateWalletInstalled(): Promise<void> {
        await new Promise((res) => setTimeout(res));
        this.getProvider();
    }

    public getProvider(): PhantomSolana {
        const provider = window.phantom?.solana;
        if (!provider?.isPhantom) {
            throw new WalletError('INSTALL_WALLET', this.getWalletType(), undefined, undefined, false);
        }
        return provider;
    }
}

