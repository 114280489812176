import React from 'react';
import { formatNumber } from '../../../shared/utils/number-utils';
import { useAmm } from '../../amm/amm-context';
import { AnalyticsState } from '../../analytics/analytics-state';
import { getMainCurrency, getMaxDenomAmount } from '../../currency/currency-service';
import { Network } from '../../network/network-types';
import { NetworksAnalytics } from '../../network/statistics/analytics/network-analytics-types';
import AreaChart from '../../network/statistics/charts/area-chart/area-chart';

interface TotalTvlChartProps {
    network: Network;
    state: AnalyticsState<NetworksAnalytics>;
    className?: string;
}

export const TotalTvlChart: React.FC<TotalTvlChartProps> = ({ network, state, className }) => {
    const { ammState } = useAmm();
    const mainCurrency = getMainCurrency(network);
    return (
        <AreaChart
            label='TVL'
            valueLabel='Amount'
            info={network.type === 'Hub' ?
                `IBC tokens + ${mainCurrency.displayDenom} in liquidity pools` :
                'Total amount of IBC tokens bridged to the RollApp'}
            loading={state.loading || ammState.paramsLoading}
            data={state.analytics?.totalSupply}
            formatValue={(amount, options) => {
                const value = ammState.params ? getMaxDenomAmount(amount, ammState.params.vsCoins.currency) : 0;
                return formatNumber(value, options);
            }}
            formatValueOptions={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
                notation: 'compact',
                style: 'currency',
                currency: 'USD',
            }}
            fetchComparableValues={(item) => item.tvl || 0}
            className={className}
        />
    );
};

export default TotalTvlChart;
