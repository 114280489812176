const DECIMAL_AMOUNT_FACTOR = Math.pow(10, 18);

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions): string => {
    return Intl.NumberFormat('en', options).format(value);
};

export const formatPrice = (
    value: number,
    currency?: string,
    options?: Intl.NumberFormatOptions,
    minDecimalsForCompact?: number,
): string => {
    return `${formatNumber(value, {
        ...(value < 1 ?
            { minimumSignificantDigits: 2, maximumSignificantDigits: 4 } :
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        ...(currency !== undefined ? {} : { style: 'currency', currency: 'USD' }),
        ...(minDecimalsForCompact !== undefined && value > Math.pow(10, minDecimalsForCompact) ? { notation: 'compact' } : undefined),
        ...options,
    })}${currency ? ' ' + currency : ''}`;
};

export const roundNumber = (value: number, fractionDigits: number, floor?: boolean, ceil?: boolean): number => {
    if (floor || ceil) {
        const power = Math.pow(10, fractionDigits);
        value = (floor ? Math.floor : Math.ceil)(value * power) / power;
    }
    return parseFloat(formatNumber(value, { maximumFractionDigits: fractionDigits }).replace(/,/g, ''));
};

export const convertDecimalToInt = (decimalValue: number): number => {
    return decimalValue / DECIMAL_AMOUNT_FACTOR;
};

export const convertIntToDecimal = (intValue: number): number => {
    return intValue * DECIMAL_AMOUNT_FACTOR;
};
