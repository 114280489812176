import classNames from 'classnames';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../shared/components/button/button';
import Menu, { MenuAction } from '../../../../shared/components/menu/menu';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as MoreMenuIcon } from '../../../../assets/icons/menu-more.svg';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import {
    getCompareValues,
    getHistoryValuesInPeriod,
} from '../../../../shared/components/statistics/statistics-change/statistics-change-service';
import { AnalyticsChangePeriod } from '../../../../shared/components/statistics/statistics-change/statistics-change-types';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import useScrollPosition from '../../../../shared/hooks/use-scroll-position';
import { formatNumber, roundNumber } from '../../../../shared/utils/number-utils';
import { getCurrencyLogoPath, getMaxDenomAmount, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import SimpleLineChart from '../../../network/statistics/charts/simple-line-chart/simple-line-chart';
import { useAmm } from '../../amm-context';
import { useAssets } from '../assets-context';
import { Asset } from '../assets-types';
import ImportTokenDialog from '../import-token-dialog/import-token-dialog';
import AssetBondDialog, { AssetBondDialogProps } from './asset-bond-dialog/asset-bond-dialog';
import './asset-list.scss';

export const AssetListHeader: React.FC<{ children: ReactNode, className?: string }> = ({ children, className }) => {
    return <h5 className={classNames('asset-list-header', className)}>{children}</h5>;
};

interface AssetListProps {
    bondedAssets?: boolean;
}

const AssetList: React.FC<AssetListProps> = ({ bondedAssets }) => {
    const navigate = useNavigate();
    const { ammState, networkState } = useAmm();
    const { sortedFilteredAssets, assetsPoolsAnalyticsState, loadMore } = useAssets();
    const [ importedToken, setImportedToken ] = useState<CoinsAmount>();
    const [ assetBondDialogProps, setAssetBondDialogProps ] = useState<AssetBondDialogProps>();
    const scrollPosition = useScrollPosition();

    const assets = useMemo(() => {
        if (!bondedAssets) {
            return sortedFilteredAssets;
        }
        return ammState.lockedAssets?.map((lockedAsset) =>
                ammState.assets?.find((asset) => (asset.ibc?.representation || asset.currency.baseDenom) === lockedAsset.coin.denom))
            .filter(Boolean) as Asset[];
    }, [ ammState.assets, ammState.lockedAssets, bondedAssets, sortedFilteredAssets ]);

    useEffect(() => {
        if (scrollPosition.target?.scrollTop &&
            scrollPosition.target.scrollTop + 1.5 * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight
        ) {
            loadMore();
        }
    }, [ loadMore, scrollPosition.target?.clientHeight, scrollPosition.target?.scrollHeight, scrollPosition.target?.scrollTop ]);

    const renderAssetNameColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn className='asset-name-container'>
                <img className='currency-logo' src={getCurrencyLogoPath(asset.currency, asset.network)} alt='currency logo' />
                <div className='asset-name'>
                    {asset.currency.displayDenom}
                    <span className='network-name'>{asset.network.chainName}</span>
                    {/*{ammState.incentives?.[baseDenom]?.coins.length ?*/}
                    {/*    <IncentiveBadge className='incentives-badge' size='small' denom={baseDenom} /> : null}*/}
                </div>
            </TableColumn>
        );
    };

    const renderBalanceColumn = (asset: Asset): ReactElement => {
        const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, asset));
        return (
            <TableColumn align='right'>
                {formatNumber(balance?.amount || 0)}
            </TableColumn>
        );
    };

    const renderBondedColumn = (asset: Asset): ReactElement => {
        const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, asset));
        const lock = ammState.lockedAssets?.find((lockedAsset) =>
            (asset.ibc?.representation || asset.currency.baseDenom) === lockedAsset.coin.denom);

        const lockedAmount = getMaxDenomAmount(Number(lock?.coin.amount) || 0, asset.currency);
        return (
            <TableColumn align='right'>
                {formatNumber(lockedAmount)}
                <span className='bonded-percentage'>({roundNumber(lockedAmount * 100 / (balance?.amount || 1), 2)}%)</span>
            </TableColumn>
        );
    };

    const renderAssetPriceColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(
                    asset.price, { style: 'currency', currency: 'USD', ...(asset.price < 1 ? { maximumSignificantDigits: 4 } : undefined) },
                )}
            </TableColumn>
        );
    };

    const renderAssetPricePercentageColumn = (asset: Asset, period: AnalyticsChangePeriod): ReactElement => {
        const data = assetsPoolsAnalyticsState.analyticsMap?.[asset.pools[0].id]?.liquidity;
        const assetIndex = isCoinsEquals(asset.pools[0].assets[0], asset) ? 0 : 1;
        const { currentValue = 0, previousValue = 0 } = data ? getCompareValues(
            data,
            period,
            false,
            ({ asset1Amount, asset2Amount, value }) => (value / 2) / (assetIndex === 0 ? asset1Amount : asset2Amount),
        ) : {};
        const isLoading = assetsPoolsAnalyticsState.loadingMap?.[asset.pools[0].id] !== false;

        return (
            <TableColumn align='right'>
                {!data && isLoading ? <Spinner size='small' /> : (
                    <StatisticsChange
                        className='analytics-change'
                        period={period}
                        currentValue={currentValue}
                        previousValue={previousValue}
                    />
                )}
            </TableColumn>
        );
    };

    const renderAssetPriceChangeColumn = (asset: Asset): ReactElement => {
        const data = assetsPoolsAnalyticsState.analyticsMap?.[asset.pools[0].id]?.liquidity;
        const assetIndex = isCoinsEquals(asset.pools[0].assets[0], asset) ? 0 : 1;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');

        return (
            <TableColumn align='right'>
                {!data && assetsPoolsAnalyticsState.loadingMap?.[asset.pools[0].id] ? <Spinner size='small' /> : !weekData ? undefined : (
                    weekData &&
                    <SimpleLineChart
                        historyList={weekData}
                        fetchComparableValues={
                            ({ asset1Amount, asset2Amount, value }) => (value / 2) / (assetIndex === 0 ? asset1Amount : asset2Amount)}
                    />
                )}
            </TableColumn>
        );
    };

    const renderAssetTradingVolumeColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(
                    asset.volume,
                    { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
            </TableColumn>
        );
    };

    const renderAssetLiquidityColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(
                    asset.liquidity,
                    { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
            </TableColumn>
        );
    };

    const renderMenuColumn = (asset: Asset): JSX.Element => {
        const stakable = false; // Boolean(ammState.incentives?.[asset.ibc?.representation || asset.currency.baseDenom]?.coins);
        return (
            <TableColumn className='menu-column' align='right'>
                <Menu closeWhenScroll trigger={<Button buttonType='icon'><MoreMenuIcon /></Button>}>
                    <MenuAction onClick={() => setImportedToken(asset)}>Import to wallet</MenuAction>
                    <MenuAction disabled={!stakable} onClick={() => setAssetBondDialogProps({ type: 'Bond', coins: asset })}>
                        Bond
                    </MenuAction>
                    <MenuAction disabled={!stakable} onClick={() => setAssetBondDialogProps({ type: 'Unbond', coins: asset })}>
                        Unbond
                    </MenuAction>
                </Menu>
            </TableColumn>
        );
    };

    const renderAssetHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Asset</TableColumn>
                {bondedAssets && <TableColumn align='right'>Balance</TableColumn>}
                {bondedAssets && <TableColumn align='right'>Bonded</TableColumn>}
                {!bondedAssets && <TableColumn align='right'>Price</TableColumn>}
                {!bondedAssets && <TableColumn align='right'>24h %</TableColumn>}
                {!bondedAssets && <TableColumn align='right'>7d %</TableColumn>}
                {!bondedAssets && <TableColumn align='right'>1m %</TableColumn>}
                {!bondedAssets && <TableColumn align='right'>Liquidity</TableColumn>}
                {!bondedAssets && <TableColumn align='right'>Volume (7d)</TableColumn>}
                {!bondedAssets && <TableColumn align='right' nowrap>Price Change (7d)</TableColumn>}
                <TableColumn align='right' />
            </TableRow>
        );
    };

    const renderAssetRow = (asset: Asset): ReactElement => {
        return (
            <TableRow key={asset.key} onSelect={() => navigate(`/amm/asset/${encodeURIComponent(asset.key)}`)}>
                {renderAssetNameColumn(asset)}
                {bondedAssets && renderBalanceColumn(asset)}
                {bondedAssets && renderBondedColumn(asset)}
                {!bondedAssets && renderAssetPriceColumn(asset)}
                {!bondedAssets && renderAssetPricePercentageColumn(asset, 'day')}
                {!bondedAssets && renderAssetPricePercentageColumn(asset, 'week')}
                {!bondedAssets && renderAssetPricePercentageColumn(asset, 'month')}
                {!bondedAssets && renderAssetLiquidityColumn(asset)}
                {!bondedAssets && renderAssetTradingVolumeColumn(asset)}
                {!bondedAssets && renderAssetPriceChangeColumn(asset)}
                {renderMenuColumn(asset)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (ammState.loading || ammState.paramsLoading || ammState.totalLockedValuesLoading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!assets?.length) {
            return <div className='no-data'>No Assets</div>;
        }
    };

    return (
        <div className='asset-list-container'>
            <Table className={classNames('asset-list', { bondedAssets })} indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                {renderAssetHeaderRow()}
                {assets?.map(renderAssetRow)}
            </Table>

            {importedToken && <ImportTokenDialog token={importedToken} onRequestClose={() => setImportedToken(undefined)} />}

            {assetBondDialogProps &&
                <AssetBondDialog {...assetBondDialogProps} onRequestClose={() => setAssetBondDialogProps(undefined)} />}
        </div>
    );
};

export default AssetList;

