import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../shared/components/alert/alert';
import Confirm from '../../shared/components/confirm/confirm';
import InfoIndicator from '../../shared/components/info-indicator/info-indicator';
import { MenuRefProps } from '../../shared/components/menu/menu';
import Spinner from '../../shared/components/spinner/spinner';
import ToggleSwitch from '../../shared/components/toggle-switch/toggle-switch';
import useWindowSize from '../../shared/hooks/use-window-size';
import { formatNumber, roundNumber } from '../../shared/utils/number-utils';
import ImportTokenDialog, { ImportTokenDialogProps } from '../amm/assets/import-token-dialog/import-token-dialog';
import { isCoinsEquals } from '../currency/currency-service';
import NetworkSelector from '../network/network-selector/network-selector';
import GetTokensSection from '../tx/amount-tx/get-tokens-section/get-tokens-section';
import { EIBC_FEE, useIbcTransfer } from './ibc-transfer-context';
import { ReactComponent as SwapIcon } from '../../assets/icons/swap.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as ExplorerIcon } from '../../assets/icons/explorer.svg';
import { useWallet } from '../wallet/wallet-context';
import { useSnackbar } from '../../shared/components/snackbar/snackbar-context';
import AmountTx from '../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../tx/tx-types';
import { SnackbarMessage } from '../../shared/components/snackbar/snackbar-types';
import { AccountNetworkState } from '../account/account-network-state';
import { WalletInfoMap } from '../wallet/wallet-types';
import { IbcDirection, Network } from '../network/network-types';
import Button from '../../shared/components/button/button';
import { useNetwork } from '../network/network-context';
import './ibc-transfer.scss';

export interface IbcTransferProps {
    title?: string;
    enableAddCustomRollapp?: boolean;
    getTokensSectionVisible?: boolean;
}

const IbcTransfer: React.FC<IbcTransferProps> = ({ title, enableAddCustomRollapp, getTokensSectionVisible }) => {
    const navigate = useNavigate();
    const { showErrorMessage } = useSnackbar();
    const { isMobile } = useWindowSize();
    const { allNetworks, rollAppParams, rollAppParamsLoading } = useNetwork();
    const { networkWalletTypeMap } = useWallet();
    const {
        sourceData,
        destinationData,
        hubNetworkData,
        txState,
        amountTxState,
        error,
        optionalSourceNetworks,
        optionalDestinationNetworks,
        transferEnabled,
        noRoutesBalances,
        cctpMinAmountToTransfer,
        useEIbc,
        destinationNetworksWithRoutes,
        getInitializedIbcTransferDetailsId,
        setUseEIbc,
        transfer,
        setSource,
        setDestination,
        setCoins,
    } = useIbcTransfer();
    const [ importTokenDialogProps, setImportTokenDialogProps ] = useState<ImportTokenDialogProps>();
    const [ eibcSwitchConfirmDialogOpen, setEibcSwitchConfirmDialogOpen ] = useState(false);
    const selectRefs = useRef<MenuRefProps[]>([]);

    const noRoute = useMemo(
        () => noRoutesBalances?.some((balance) => amountTxState.coins && isCoinsEquals(balance, amountTxState.coins)),
        [ amountTxState.coins, noRoutesBalances ],
    );

    const intermediateHub = useMemo(
        () => (sourceData.network?.type === 'RollApp' && destinationData.network?.type !== 'Hub') ||
            (sourceData.network?.type !== 'Hub' && destinationData.network?.type === 'RollApp'),
        [ destinationData.network?.type, sourceData.network?.type ],
    );

    const isNetworkSelectable = useCallback((network: Network, direction: IbcDirection, canSwap?: boolean) => {
        if (network.hidden) {
            return false;
        }
        return (direction === 'Source' ?
                (!optionalSourceNetworks || optionalSourceNetworks.includes(network.chainId)) :
                (!optionalDestinationNetworks || optionalDestinationNetworks.includes(network.chainId))) &&
            (canSwap || (direction === 'Source' ?
                network.chainId !== destinationData.network?.chainId : network.chainId !== sourceData.network?.chainId));
    }, [
        destinationData.network?.chainId,
        optionalDestinationNetworks,
        optionalSourceNetworks,
        sourceData.network?.chainId,
    ]);

    const destinationNetworks = useMemo(() => allNetworks.sort((network1, network2) => {
        const network1HaveRoute = destinationNetworksWithRoutes?.some((network) => network.chainId === network1.chainId);
        const network2HaveRoute = destinationNetworksWithRoutes?.some((network) => network.chainId === network2.chainId);
        return (network2HaveRoute ? 1 : 0) - (network1HaveRoute ? 1 : 0);
    }), [ allNetworks, destinationNetworksWithRoutes ]);

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            const destinationWalletType = destinationData.network && networkWalletTypeMap[destinationData.network.chainId];
            const shouldAddTokensToWallet =
                destinationWalletType && destinationWalletType !== 'PortalWallet' && destinationWalletType !== 'Phantom' &&
                (WalletInfoMap[destinationWalletType].type === 'cosmos' || destinationData.network?.type !== 'Hub');
            let tokenToImport = amountTxState.coins;
            if (tokenToImport && tokenToImport.ibc?.networkId === destinationData.network?.chainId) {
                tokenToImport = undefined; // { ...tokenToImport, ibc: { path: '', representation: '', networkId: hubNetworkData.network.chainId } };
            }
            let title = 'IBC transfer successfully sent!';
            if (sourceData.network?.type === 'RollApp') {
                title = useEIbc ? 'Fast withdrawal order successfully sent!' : 'IBC transfer successfully sent!';
            }
            const isEthereumWallet = destinationWalletType && WalletInfoMap[destinationWalletType].type === 'evm';
            const isSkip = sourceData.network?.type === 'EVM' || sourceData.network?.type === 'Solana' ||
                destinationData.network?.type === 'EVM' || destinationData.network?.type === 'Solana';
            const showIbcStatusLink = !isSkip && (sourceData.network?.type === 'Hub' || destinationData.network?.type === 'Hub' ||
                sourceData.network?.type === 'RollApp' || destinationData.network?.type === 'RollApp');

            return {
                content: <>
                    {response.network.type !== 'RollApp' ? title : <>
                        <b>{title}</b>
                        <span className='finalization-text'>
                            {useEIbc ? 'Tokens will be received in your wallet post fulfillment' : <>
                                Pending Hub finalization {
                                shouldAddTokensToWallet && isEthereumWallet &&
                                '- Post token withdrawal you will be able to add the asset to your wallet (on Asset page)'}
                            </>}
                        </span>
                    </>}
                    {isSkip && <><br />It can take about 15 minutes for the bridge to complete</>}
                    {(shouldAddTokensToWallet && tokenToImport && (response.network.type !== 'RollApp' || !isEthereumWallet)) && (
                        <Button
                            onClick={() => tokenToImport && setImportTokenDialogProps({
                                token: tokenToImport,
                                walletType: destinationWalletType,
                                network: destinationData.network,
                            })}
                            size='small'
                            className='import-to-wallet-button'
                            buttonType='primary'
                        >
                            <UploadIcon />&nbsp;&nbsp;Import {tokenToImport.currency.displayDenom} to {destinationWalletType}
                        </Button>
                    )}
                </>,
                key: response.hash,
                action: showIbcStatusLink ? {
                    label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>,
                    close: true,
                    callback: () => navigate(`/ibc/status/${getInitializedIbcTransferDetailsId()}`),
                } : !response.network.exploreTxUrl ? undefined : {
                    label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>,
                    close: true,
                    callback: () => window.open(response.network.exploreTxUrl + response.hash, '_blank'),
                },
            };
        }
    }, [
        amountTxState.coins,
        destinationData.network,
        getInitializedIbcTransferDetailsId,
        navigate,
        networkWalletTypeMap,
        sourceData.network?.type,
        useEIbc,
    ]);

    const swapNetworksEnabled = useMemo(
        () => sourceData.network && destinationData.network &&
            isNetworkSelectable(destinationData.network, 'Source', true) &&
            isNetworkSelectable(sourceData.network, 'Destination', true),
        [ destinationData.network, isNetworkSelectable, sourceData.network ],
    );

    useEffect(() => {
        if (!error) {
            return;
        }
        switch (error.code) {
            case 'MISSING_CHANNEL':
                showErrorMessage(`Missing IBC channel for ${error.network?.chainName || 'the current network'}`);
                break;
            default:
                showErrorMessage(`Can't perform IBC transaction, please try again later`);
        }
    }, [ error, showErrorMessage ]);

    const swapNetworks = (): void => {
        if (swapNetworksEnabled) {
            setSource(destinationData.network);
            setDestination(sourceData.network);
        }
    };

    const renderNetworkSelector = (
        label: string,
        direction: IbcDirection,
        onNetworkSelect: (chainId: string) => void,
        networkState?: AccountNetworkState,
    ): ReactElement => {
        return (
            <div className='network-selectors-container'>
                <label className='network-label'>{label}</label>
                <NetworkSelector
                    ref={(ref) => ref && selectRefs.current.push(ref)}
                    showCommonNetworks
                    size={isMobile ? 'small' : 'medium'}
                    networkData={networkState}
                    networks={direction === 'Destination' ? destinationNetworks : undefined}
                    isNetworkSelectable={(network, commonNetwork) => isNetworkSelectable(network, direction, commonNetwork)}
                    enableAddCustomRollapp={enableAddCustomRollapp}
                    switchNetworkAfterConnect={direction === 'Source'}
                    optionsMenuOpenDisabled={direction === 'Source' ?
                        (optionalSourceNetworks && optionalSourceNetworks?.length <= 1) :
                        (optionalDestinationNetworks && optionalDestinationNetworks.length <= 1)}
                    onNetworkSelect={(chainId, commonNetwork) => {
                        if (commonNetwork) {
                            selectRefs.current.forEach((ref) => ref.toggleMenu(false));
                            if (direction === 'Source' ?
                                destinationData.network?.chainId === chainId :
                                sourceData.network?.chainId === chainId) {
                                swapNetworks();
                                return;
                            }
                        }
                        onNetworkSelect(chainId.toString());
                    }}
                />
            </div>
        );
    };

    return <>
        <section className='section ibc-transfer-section'>
            <h3 className='ibc-transfer-title'>{title || 'Transfer'}</h3>

            {renderNetworkSelector('Source chain', 'Source', setSource, sourceData)}

            <Button
                className='swap-button'
                buttonType='icon'
                size='x-large'
                iconColorMode='stroke'
                disabled={!swapNetworksEnabled}
                onClick={swapNetworks}
            >
                <SwapIcon />
            </Button>

            {renderNetworkSelector('Destination chain', 'Destination', setDestination, destinationData)}

            <label className='amount-label'>Select Token and Amount</label>
            <AmountTx
                txState={txState}
                amountTxState={amountTxState}
                networkState={sourceData}
                getTxResponseMessage={getTxResponseMessage}
                onCoinsChange={setCoins}
                controlSize='large'
                extraFees={sourceData.network?.type === 'RollApp' && useEIbc ? [
                    {
                        label: (
                            <span className='bridging-fee'>
                                Bridging fee
                                <InfoIndicator>
                                    Fast withdrawal fee is paid out to eIBC providers for fulfilling transfers with no dispute periods.
                                </InfoIndicator>
                            </span>
                        ),
                        value: `${roundNumber(EIBC_FEE * 100, 2)}%`,
                    },
                ] : []}
                submitButtonContainer={<>
                    {intermediateHub && (
                        <Alert className='ibc-alert' type='warning'>
                            <span>Current version requires <b>{hubNetworkData.network?.chainName}</b> as an intermediate destination for transferring tokens between <b>{sourceData.network?.chainName}</b> and <b>{destinationData.network?.chainName}</b>.</span>
                        </Alert>
                    )}
                    {noRoute && (
                        // todo: temporary code - do it generic
                        <Alert className='ibc-alert' type='warning'>
                            No routes found for {amountTxState.coins?.currency.displayDenom} between <b>{sourceData.network?.chainName}</b> and <b>{destinationData.network?.chainName}</b>.<br />
                            {amountTxState.coins?.currency.baseDenom === 'erc20/tether/usdt' &&
                                sourceData.network?.type === 'EVM' && destinationData.network?.type === 'Hub' &&
                                <span>Choose <b>Kava</b> as a intermediate destination for transferring USDT to <b>{destinationData.network?.chainName}</b>.</span>}
                            {((destinationData.network?.type === 'EVM' &&
                                        (amountTxState.coins?.currency.baseDenom === 'weth-wei' ||
                                            amountTxState.coins?.currency.baseDenom === 'uusdc')) ||
                                    (destinationData.network?.type === 'Solana' && amountTxState.coins?.currency.baseDenom === 'uusdc')) &&
                                <span>Use <a className='skip-link' href='https://ibc.fun/' target='_blank'>Skip</a> to send&nbsp;
                                    {amountTxState.coins?.currency.displayDenom} to <b>{destinationData.network?.chainName}</b>.</span>}
                        </Alert>
                    )}
                    {!noRoute && !intermediateHub && sourceData.network?.type === 'RollApp' && !useEIbc && (
                        <Alert className='ibc-alert' type='error'>
                            IBC requires a wait period of {rollAppParamsLoading ?
                            <Spinner className='rollapp-params-spinner' size='xs' /> :
                            <b>{formatNumber(rollAppParams?.disputePeriodInBlocks || 0)}</b>} blocks. For fast withdrawals enable eIBC.
                        </Alert>
                    )}
                    {!noRoute && !intermediateHub && cctpMinAmountToTransfer > 0 && (
                        <Alert className='ibc-alert'>
                            Circle CCTP transfer cannot be less than {cctpMinAmountToTransfer} {amountTxState.coins?.currency.displayDenom}.
                        </Alert>
                    )}
                    <Button
                        size='x-large'
                        loading={(txState.broadcasting || txState.feeLoading)}
                        disabled={!transferEnabled || noRoute || intermediateHub}
                        onClick={transfer}
                    >
                        Transfer
                    </Button>
                </>}
            />

            {sourceData.network?.type === 'RollApp' && (
                <ToggleSwitch
                    containerClassName='eibc-switch'
                    isChecked={useEIbc}
                    onCheck={(value) => {
                        if (value) {
                            setUseEIbc(value);
                        } else {
                            setEibcSwitchConfirmDialogOpen(true);
                            return false;
                        }
                    }}
                    size='small'
                >
                    Fast withdrawal enabled (eIBC)
                    <InfoIndicator>
                        RollApp withdrawals default uses Fast Withdrawals (eIBC), regular withdrawal transfers require dymension finalization which would delay the transfer.
                    </InfoIndicator>
                </ToggleSwitch>
            )}
        </section>

        {amountTxState.coins && getTokensSectionVisible &&
            <GetTokensSection coins={amountTxState.coins} coinsNetwork={sourceData.network} className='get-tokens-section' />}

        {importTokenDialogProps &&
            <ImportTokenDialog {...importTokenDialogProps} onRequestClose={() => setImportTokenDialogProps(undefined)} />}

        {eibcSwitchConfirmDialogOpen && <Confirm
            onRequestClose={() => setEibcSwitchConfirmDialogOpen(false)}
            onConfirm={() => setUseEIbc(false)}
            content='Transacting without Fast withdrawals (eIBC) will delay your transfers for the entire dispute period.'
            okLabel='Confirm'
            cancelLabel='Abort'
            warning
        />}
    </>;
};

export default IbcTransfer;
