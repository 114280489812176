import React, { Children, ReactNode } from 'react';
import classNames from 'classnames';
import Swiper from '../swiper/swiper';
import './statistics-cards.scss';

export interface StatisticsCardsProps {
    children: ReactNode;
    className?: string;
    vertically?: boolean;
}

const StatisticsCards: React.FC<StatisticsCardsProps> = ({ children, className, vertically }) => {
    const cards = Children.toArray(children).map((element, elementIndex) =>
        <div key={elementIndex} className='statistics-card section small'>{element}</div>);

    return <>
        <div className={classNames('statistics-cards', className, { vertically })}>{cards}</div>
        <Swiper containerClassName={classNames('statistics-cards-swiper', className)} showOneInMobile>{cards}</Swiper>
    </>;
};

export default StatisticsCards;
