import { useCallback, useEffect, useMemo, useState } from 'react';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { NewDelegationType } from './new-delegation-types';
import { CoinsAmount } from '../../../currency/currency-types';
import { useWallet } from '../../../wallet/wallet-context';
import { AmountTxValue, useAmountTx } from '../../../tx/amount-tx/use-amount-tx';
import { createDelegationMessage } from './new-delegation-service';
import { useStaking } from '../../staking-context';
import { ClientError } from '../../../client/client-error';
import { NewDelegationError } from './new-delegation-error';
import { useClient } from '../../../client/client-context';
import { Validator } from '../../validator/validator-types';
import { getStakingCurrency, isCoinsEquals } from '../../../currency/currency-service';

interface NewDelegationValue extends Omit<AmountTxValue, 'calculateFee' | 'clearFee' | 'txStateDispatch'> {
    alreadyDelegated: number;
    reduceFeeFromBalances: boolean;
    redelegateTo?: Validator;
    availableBalances: CoinsAmount[];
    setRedelegateTo: (validator: Validator) => void;
    error?: NewDelegationError;
}

export const useNewDelegation = (validator: Validator, type: NewDelegationType): NewDelegationValue => {
    const { stakedValidatorsData, network, networkState } = useStaking();
    const { networkWalletMap } = useWallet();
    const { handleClientError } = useClient();
    const [ error, setError ] = useState<NewDelegationError>();
    const [ redelegateTo, setRedelegateTo ] = useState<Validator>();

    const networkWallet = networkWalletMap[network.chainId];

    const stakeCurrency = useMemo(() => getStakingCurrency(network), [ network ]);
    const reduceFeeFromBalances = type === 'delegate';

    const stakeBalance = useMemo((): CoinsAmount => {
        const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, { currency: stakeCurrency, amount: 0 }));
        if (!balance) {
            return { currency: stakeCurrency, amount: 0 };
        }
        return balance;
    }, [ networkState.balances, stakeCurrency ]);

    const delegationMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        if (!coins ||
            !isCoinsEquals(coins, stakeBalance) ||
            (type === 'redelegate' && !redelegateTo) ||
            (type === 'cancel-undelegate' && !validator.unstaking) ||
            !stakeBalance ||
            !networkWallet ||
            !networkState.address) {
            return [];
        }
        if (type === 'delegate' && fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.min(coins.amount, stakeBalance.amount - fee.amount) };
        }
        if (type === 'cancel-undelegate') {
            coins.amount = Math.min(coins.amount, validator.unstaking?.amount || 0);
        }
        const message = createDelegationMessage({
            validator,
            type,
            coins,
            redelegateTo,
            balance: stakeBalance,
            delegatorAddress: networkState.address,
            undelegateHeight: validator.unstaking?.creationHeight,
        });
        return message ? [ message ] : [];
    }, [ networkWallet, networkState.address, redelegateTo, stakeBalance, type, validator ]);

    const alreadyDelegated = useMemo(() => {
        return stakedValidatorsData?.state?.validators
            ?.find((delegatedValidator) => delegatedValidator.name === validator.name)?.amountStaked || 0;
    }, [ stakedValidatorsData?.state?.validators, validator.name ]);

    const availableBalances = useMemo((): CoinsAmount[] => {
        if (type === 'cancel-undelegate') {
            return [ { currency: stakeCurrency, amount: validator.unstaking?.amount || 0 } ];
        }
        if (type === 'delegate') {
            return [ stakeBalance ];
        }
        return [ { currency: stakeCurrency, amount: alreadyDelegated } ];
    }, [ alreadyDelegated, stakeBalance, stakeCurrency, type, validator.unstaking?.amount ]);

    const { txState, amountTxState, setCoins, setAmount, calculateFee, clearFee, broadcast } = useAmountTx({
        networkState: networkState,
        availableBalances,
        reduceFeeFromBalances,
        amountTxMessagesCreator: delegationMessagesCreator,
    });

    useEffect(() => {
        if (type === 'cancel-undelegate') {
            setAmount(validator.unstaking?.amount || 0);
        }
    }, [ setAmount, type, validator.unstaking?.amount ]);

    useEffect(() => {
        if (networkState.network && (type !== 'redelegate' || Boolean(redelegateTo))) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ networkState.network, redelegateTo, type, clearFee, calculateFee ]);

    useEffect((): void => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            if (txState.error?.originalError?.message?.includes?.('too many unbonding delegation entries')) {
                setError(new NewDelegationError(
                    'TOO_MANY_UNSTAKING_DELEGATIONS',
                    txState.error.network,
                    txState.error.originalError,
                ));
            } else if (txState.error?.originalError?.message?.includes?.(
                'redelegation to this validator already in progress')) {
                setError(new NewDelegationError(
                    'REDELEGATION_ALREADY_IN_PROGRESS',
                    txState.error.network,
                    txState.error.originalError,
                ));
            } else if (txState.error?.originalError?.message?.includes?.('invalid delegation amount')) {
                handleClientError(new ClientError(
                    'INSUFFICIENT_FUNDS',
                    txState.error.network,
                    txState.error.originalError,
                ));
            } else {
                handleClientError(txState.error);
            }
        } else {
            console.error(txState.error);
        }
    }, [ txState.error, setError, calculateFee, handleClientError ]);

    return {
        txState,
        amountTxState,
        alreadyDelegated,
        availableBalances,
        reduceFeeFromBalances,
        redelegateTo,
        error,
        setCoins,
        setAmount,
        setRedelegateTo,
        broadcast,
    };
};
