import { CoinsAmount } from '../../currency/currency-types';

export interface AmountTxState {
    coins?: CoinsAmount;
    availableAmount: number;
}

export type AmountTxAction =
    { type: 'set-coins', payload: CoinsAmount | undefined } |
    { type: 'set-coins-amount', payload: number } |
    { type: 'set-available-amount', payload: number };

export const amountTxStateReducer = (state: AmountTxState, action: AmountTxAction): AmountTxState => {
    switch (action.type) {
        case 'set-coins':
            return { ...state, coins: action.payload };
        case 'set-coins-amount':
            return { ...state, coins: state.coins ? { ...state.coins, amount: action.payload } : undefined };
        case 'set-available-amount':
            return { ...state, availableAmount: action.payload };
        default:
            return state;
    }
};
