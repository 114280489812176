import { MutableRefObject, useRef, useState } from 'react';
import { Network } from '../../network/network-types';
import { fetchFullRollappData, saveCustomRollapp } from './add-custom-rollapp-service';
import { useClient } from '../../client/client-context';
import { useNetwork } from '../../network/network-context';
import { Currency } from '../../currency/currency-types';

interface AddCustomRollappValue {
    networkFormRef: MutableRefObject<HTMLFormElement | null>;
    tokensFormRef: MutableRefObject<HTMLFormElement | null>;
    rollapp?: Partial<Network>;
    fullRollapp?: Network;
    loading: boolean;
    isNetworkFormValid: boolean;
    isTokensFormValid: boolean;
    updateRollapp: (data: Partial<Network>) => void;
    updateCurrency: (currencyIndex: number, data: Partial<Currency>) => void;
    fetchRollappData: () => void;
    saveRollapp: () => void;
    error?: any;
}

export const useAddCustomRollapp = (): AddCustomRollappValue => {
    const { hubNetwork } = useNetwork();
    const { clientStateMap } = useClient();
    const [rollapp, setRollapp] = useState<Partial<Network>>({ da: 'Celestia' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();
    const [fullRollapp, setFullRollapp] = useState<Network>();
    const [isNetworkFormValid, setIsNetworkFormValid] = useState(false);
    const [isTokensFormValid, setIsTokensFormValid] = useState(false);

    const networkFormRef = useRef<HTMLFormElement>(null);
    const tokensFormRef = useRef<HTMLFormElement>(null);

    const updateRollapp = (data: Partial<Network>): void => {
        setRollapp({ ...rollapp, ...data });
        setIsNetworkFormValid(networkFormRef.current?.checkValidity() || false);
    };

    const updateCurrency = (currencyIndex: number, data: Partial<Currency>): void => {
        if (!fullRollapp?.currencies) {
            return;
        }
        fullRollapp.currencies[currencyIndex] = { ...fullRollapp.currencies[currencyIndex], ...data };
        setFullRollapp(fullRollapp);
        setIsTokensFormValid(tokensFormRef.current?.checkValidity() || false);
    };

    const fetchRollappData = (): void => {
        const hubClient = hubNetwork && clientStateMap[hubNetwork.chainId]?.client;
        if (!hubClient) {
            setError(new Error('Hub not connected'));
            return;
        }
        setLoading(true);
        fetchFullRollappData(rollapp, hubClient)
            .then(setFullRollapp)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const saveRollapp = (): void => {
        if (fullRollapp) {
            saveCustomRollapp(fullRollapp);
        }
    };

    return {
        networkFormRef,
        tokensFormRef,
        rollapp,
        loading,
        fullRollapp,
        isTokensFormValid,
        isNetworkFormValid,
        error,
        updateRollapp,
        updateCurrency,
        fetchRollappData,
        saveRollapp
    };
};
