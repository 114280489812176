import React from 'react';
import { useNetworkDashboard } from '../../../../network-dashboard/network-dashboard-context';
import AreaChart from '../area-chart/area-chart';

interface NewAddressesChartProps {
    className?: string;
}

export const NewAddressesChart: React.FC<NewAddressesChartProps> = ({ className }) => {
    const { analyticsState } = useNetworkDashboard();

    return (
        <AreaChart
            label='New Addresses'
            valueLabel='Addresses'
            compareDiffs
            className={className}
            loading={analyticsState.loading}
            data={analyticsState.analytics?.activeAddresses}
        />
    );
};

export default NewAddressesChart;
