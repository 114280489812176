import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icons/external-link.svg';
import Button from '../../../shared/components/button/button';
import Spinner from '../../../shared/components/spinner/spinner';
import { getNetworkAppImagePath } from '../../network/network-service';
import { NetworkApp } from '../../network/network-types';
import { useNetworkDashboard } from '../network-dashboard-context';
import './network-apps.scss';

const NetworkApps: React.FC = () => {
    const { network } = useNetworkDashboard();
    const [ openedApp, setOpenedApp ] = useState<NetworkApp>();
    const [ appPreviewVisible, setAppPreviewVisible ] = useState(false);
    const [ appPreviewLoaded, setAppPreviewLoaded ] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAppPreviewVisible(Boolean(openedApp));
            if (!openedApp) {
                setAppPreviewLoaded(false);
            }
        }, openedApp ? 300 : 0);
    }, [ openedApp ]);

    return (
        <div className='page network-apps'>
            {network.apps?.map(((app, appIndex) => (
                <div className='section app-card' onClick={() => setOpenedApp(app)}>
                    <div className='app-image-container'>
                        <img src={getNetworkAppImagePath(network, appIndex)} alt='network app' className='app-image' />
                    </div>
                    <div className='app-content'>
                        <h4 className='app-name'>{app.name}</h4>
                        <div className='app-description'>{app.description}</div>
                    </div>
                </div>
            )))}
            <div className={classNames('app-preview-container', { show: Boolean(openedApp) })}>
                <div className='app-preview-top-bar'>
                    <span className='space' />
                    <Button onClick={() => window.open(openedApp?.url, '_blank')} buttonType='icon'>
                        <ExternalLinkIcon />
                    </Button>
                    <Button onClick={() => setOpenedApp(undefined)} buttonType='icon'>
                        <ClearIcon />
                    </Button>
                </div>
                {openedApp && <>
                    <iframe
                        onLoad={() => setAppPreviewLoaded(true)}
                        title='app-review'
                        className={classNames('app-preview-iframe', { visible: appPreviewVisible && appPreviewLoaded })}
                        src={openedApp.url}
                    />
                    {(!appPreviewVisible || !appPreviewLoaded) && <Spinner className='app-preview-spinner' />}
                </>}
            </div>
        </div>
    );
};

export default NetworkApps;
