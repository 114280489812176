import classNames from 'classnames';
import React, { ReactElement } from 'react';
import Property from '../../../../../shared/components/property/property';
import StatisticsCards from '../../../../../shared/components/statistics-cards/statistics-cards';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import useWindowSize from '../../../../../shared/hooks/use-window-size';
import { formatNumber, formatPrice, roundNumber } from '../../../../../shared/utils/number-utils';
import { isCoinsEquals } from '../../../../currency/currency-service';
import { useAmm } from '../../../amm-context';
import PriceChart from '../charts/price-chart';
import { useAssetDashboard } from '../asset-dashboard-context';
import './asset-statistics.scss';

interface NetworkStatisticsProps {
    className?: string;
}

const TABLET_WIDTH = 1024;

export const AssetStatistics: React.FC<NetworkStatisticsProps> = ({ className }) => {
    const { networkState, getTokenPrice } = useAmm();
    const { asset, analyticsState } = useAssetDashboard();
    const { width } = useWindowSize();

    const renderVolumeProperty = (): ReactElement => {
        return (
            <Property label='Trading volume (7d)'>
                <StatisticsChange period='week' currentValue={asset.volume} previousValue={asset.previousWeekVolume}>
                    {formatNumber(
                        asset.volume,
                        { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                    )}
                </StatisticsChange>
            </Property>
        );
    };

    const renderLiquidityProperty = (): ReactElement => {
        return (
            <Property label='Total liquidity'>
                <StatisticsChange period='week' currentValue={asset.liquidity} previousValue={asset.liquidity - asset.liquidityDiff}>
                    {formatNumber(
                        asset.liquidity,
                        { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                    )}
                </StatisticsChange>
            </Property>
        );
    };

    const renderBondedLiquidityProperty = (): ReactElement => {
        return (
            <Property label='Total bonded liquidity'>
                {formatNumber(
                    asset.locked,
                    { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
                <span className='bonded-percentage'>({roundNumber(asset.locked * 100 / asset.liquidity, 2)}%)</span>
            </Property>
        );
    };

    const renderBalanceProperty = (): ReactElement => {
        const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, asset));
        return (
            <Property label='Your balance' valueClassName='balance-property'>
                {formatNumber(
                    balance?.amount || 0,
                    { minimumFractionDigits: 2, maximumFractionDigits: Math.min(4, balance?.currency.decimals || 4) },
                )} {balance?.currency.displayDenom}&nbsp;
                <span className='vs-price'>({formatPrice((balance && getTokenPrice(balance)) || 0)})</span>
            </Property>
        );
    };

    return (
        <div className={classNames('asset-statistics', className)}>
            <StatisticsCards className='asset-statistics-cards' vertically={width > TABLET_WIDTH}>
                {renderVolumeProperty()}
                {renderLiquidityProperty()}
                {renderBondedLiquidityProperty()}
                {networkState.address ? renderBalanceProperty() : undefined}
            </StatisticsCards>

            <PriceChart asset={asset} analyticsState={analyticsState} />
        </div>
    );
};

export default AssetStatistics;
