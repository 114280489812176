import React, { useMemo } from 'react';
import Addresses from '../../../../shared/components/addresses/addresses';
import { AccountNetworkState } from '../../account-network-state';
import { useWallet } from '../../../wallet/wallet-context';
import WalletLogo from '../../../wallet/wallet-logo';
import { PortalWallet } from '../../../wallet/wallets/portal-wallet/portal-wallet';
import PortalWalletSourceLogo from '../../../wallet/wallets/portal-wallet/portal-wallet-source-logo';
import './wallet-address.scss';

interface WalletAddressProps {
    networkState: AccountNetworkState;
    canCopy?: boolean;
}

const WalletAddress: React.FC<WalletAddressProps> = ({ networkState, canCopy }) => {
    const { networkWalletTypeMap, networkWalletMap } = useWallet();

    const connectedWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : undefined;
    const connectedWalletType = networkState.network ? networkWalletTypeMap[networkState.network.chainId] : undefined;

    const addresses = useMemo(
        () => [ networkState.hexAddress, networkState.address ].filter(Boolean) as string[],
        [ networkState.hexAddress, networkState.address ],
    );

    return (
        <div className='wallet-address-container'>
            {connectedWalletType === 'PortalWallet' ?
                <PortalWalletSourceLogo className='wallet-logo' type={(connectedWallet as PortalWallet)?.getSourceType()} /> :
                <WalletLogo className='wallet-logo' type={connectedWalletType} />}
            <Addresses addresses={addresses} canCopy={canCopy || !networkState.network?.evm} />
        </div>
    );
};

export default WalletAddress;
