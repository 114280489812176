import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';
import { useNetwork } from '../../network-context';
import { Network } from '../../network-types';

interface SequencersBondPropertyProps {
    network: Network;
}

const SequencersBondProperty: React.FC<SequencersBondPropertyProps> = ({ network }) => {
    const { hubCurrency } = useNetwork();
    const { sequencerData } = useNetworkDashboard();

    const totalSequencersBond = useMemo(
        () => sequencerData.sequencers?.reduce((current, sequencer) => current + sequencer.bond[0].amount, 0) || 0,
        [ sequencerData.sequencers ],
    );

    return (
        <Property label='Total Sequencers Bond'>
            {sequencerData.loading ? <Spinner /> : formatPrice(totalSequencersBond, hubCurrency?.displayDenom)}
        </Property>
    );
};

export default SequencersBondProperty;
