import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../shared/components/spinner/spinner';
import { ProposalContextProvider } from '../../governance/proposal-page/proposal-context';
import ProposalPage from '../../governance/proposal-page/proposal-page';
import { useNetwork } from '../../network/network-context';
import './rollapp-proposal-page.scss';

const RollappProposalPage: React.FC = () => {
    const { id, proposalId } = useParams();
    const { loading, getNetwork } = useNetwork();
    const rollapp = id && getNetwork(id);

    if (rollapp) {
        return <ProposalContextProvider proposalId={Number(proposalId)} network={rollapp}><ProposalPage /></ProposalContextProvider>;
    }
    if (loading) {
        return <Spinner size='large' className='rollapp-loader' />;
    }
    return <h5 className='not-found-message'>RollApp not found</h5>;
};

export default RollappProposalPage;
