import React from 'react';
import { useParams } from 'react-router-dom';
import { ProposalContextProvider } from '../governance/proposal-page/proposal-context';
import ProposalPage from '../governance/proposal-page/proposal-page';
import { useNetwork } from '../network/network-context';

const HubProposalPage: React.FC = () => {
    const { proposalId } = useParams();
    const { hubNetwork } = useNetwork();

    return hubNetwork ?
        <ProposalContextProvider proposalId={Number(proposalId)} network={hubNetwork}><ProposalPage /></ProposalContextProvider> :
        <></>;
};

export default HubProposalPage;
