import { GasPrice, SigningStargateClient } from 'cosmjs/packages/stargate';
import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { Decimal } from 'cosmjs/packages/math';
import { Network } from '../../network/network-types';
import { DEFAULT_GAS_PRICE_STEPS } from '../client-types';
import { ClientError } from '../client-error';
import { Wallet } from '../../wallet/wallet-types';
import { StationClient } from './station-client';
import { getFeeCurrency } from '../../currency/currency-service';

export class SigningStationClient extends SigningStargateClient {
    private readonly averageGasPrice: GasPrice;
    private readonly wallet: Wallet;
    private readonly stationQueryClient: StationClient;

    public static async connectForQueryClient(stationQueryClient: StationClient, wallet: Wallet): Promise<SigningStationClient> {
        const network = stationQueryClient.getNetwork();
        if (!network.rpc) {
            throw new ClientError('CONNECT_CLIENT_FAILED', network, 'missing rpc');
        }
        try {
            const signer = await wallet.getOfflineSigner(network);
            const feeCurrency = getFeeCurrency(network);
            const gasPriceStep = network.gasPriceSteps?.average ?? DEFAULT_GAS_PRICE_STEPS.average;
            const gasPrice = new GasPrice(
                Decimal.fromUserInput(gasPriceStep?.toString(), feeCurrency.decimals),
                feeCurrency.baseDenom,
            );
            return new SigningStationClient(stationQueryClient, wallet, signer, gasPrice);
        } catch (error) {
            throw new ClientError('CONNECT_CLIENT_FAILED', network, error);
        }
    }

    public getNetwork(): Network {
        return this.stationQueryClient.getNetwork();
    }

    public getStationQueryClient(): StationClient {
        return this.stationQueryClient;
    }

    public getWallet(): Wallet {
        return this.wallet;
    }

    public getGasPrice(): GasPrice {
        return this.averageGasPrice;
    }

    protected constructor(
        queryClient: StationClient,
        wallet: Wallet,
        signer: OfflineSigner,
        gasPrice: GasPrice,
    ) {
        super(
            queryClient.getTmClient(),
            signer,
            {
                gasPrice,
                evm: Boolean(queryClient.getNetwork().evm) &&
                    (wallet.getWalletType() !== 'PortalWallet' || queryClient.getNetwork().type === 'RollApp'),
                broadcastTimeoutMs: 600_000,
            },
        );
        this.stationQueryClient = queryClient;
        this.wallet = wallet;
        this.averageGasPrice = gasPrice;
    }
}
