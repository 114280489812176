import { ClientUpdateProposal } from 'cosmjs-types/ibc/core/client/v1/client';
import React, { useEffect, useMemo } from 'react';
import Input from '../../../../shared/components/form-controls/input/input';
import './proposal-type.scss';

interface ClientUpdateProps {
    content?: ClientUpdateProposal;
    onInit?: (data: ClientUpdateProposal) => void;
    onChange?: (data: Partial<ClientUpdateProposal>, isValid: boolean) => void;
}

const ClientUpdate: React.FC<ClientUpdateProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(() => Boolean(content?.subjectClientId && content.substituteClientId), [ content ]);

    useEffect(() => onInit?.({ title: '', description: '', subjectClientId: '', substituteClientId: '' }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    return (
        <div className='proposal-type'>
            <label className='proposal-change-label'>Subject Client ID</label>
            <Input
                value={content?.subjectClientId}
                onTypeFinish={(value) => onChange?.({ subjectClientId: value?.toString() || '' }, isValid)}
            />

            <label className='proposal-change-label'>Substitute Client ID</label>
            <Input
                value={content?.substituteClientId}
                onTypeFinish={(value) => onChange?.({ substituteClientId: value?.toString() || '' }, isValid)}
            />
        </div>
    );
};

export default ClientUpdate;
