import { WalletType } from '../wallet-types';
import { EthereumProvider, EthereumWallet } from './ethereum-wallet';

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        ethereum?: MetaMask;
        web3?: { currentProvider?: MetaMask; };
    }
}

export interface MetaMask extends EthereumProvider {
    isMetaMask?: boolean;
}

export class MetaMaskWallet extends EthereumWallet {
    constructor() {
        super('ethereum#initialized');
    }

    public getWalletType(): WalletType {
        return 'MetaMask';
    }

    public getCurrentProvider(): EthereumProvider | undefined {
        const provider = window.web3?.currentProvider || window.ethereum;
        return provider?.isMetaMask ? provider : undefined;
    }
}

