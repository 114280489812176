import { PortalError } from '../error/portal-error';
import { Network } from '../network/network-types';

export type WalletErrorCode = 'UNSUPPORTED_CURRENCY';

export class CurrencyError extends PortalError<WalletErrorCode> {
    public denom?: string;

    constructor(code: WalletErrorCode, denom?: string, network?: Network, originalError?: any) {
        super(code, network, originalError);
        this.denom = denom;
    }
}

