import React, { useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { useAmm } from '../../../../amm/amm-context';
import { getMaxDenomAmount } from '../../../../currency/currency-service';
import { useNetwork } from '../../../../network/network-context';

const TotalTvlProperty: React.FC = () => {
    const { rollapps } = useNetwork();
    const { ammState } = useAmm();

    const { currentValue, previousValue } = useMemo(
        () => rollapps.reduce(({ currentValue, previousValue }, rollapp) => ({
            currentValue: currentValue + (rollapp.totalSupply?.value?.tvl || 0),
            previousValue: previousValue + (rollapp.totalSupply?.previousWeekValue?.tvl || 0),
        }), { currentValue: 0, previousValue: 0 }),
        [ rollapps ],
    );

    return (
        <Property label='Total TVL on RollApps' info='Total amount of IBC tokens bridged to the RollApps'>
            <StatisticsChange period='week' currentValue={currentValue} previousValue={previousValue}>
                {ammState.params &&
                    formatNumber(getMaxDenomAmount(currentValue, ammState.params.vsCoins.currency), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                        notation: 'compact',
                        style: 'currency',
                        currency: 'USD',
                    })}
            </StatisticsChange>
        </Property>
    );
};

export default TotalTvlProperty;
