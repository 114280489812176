import React, { useMemo } from 'react';
import Property from '../../../shared/components/property/property';
import Spinner from '../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../shared/utils/number-utils';
import { useAmm } from '../../amm/amm-context';
import { Network } from '../../network/network-types';

interface TotalVolumePropertyProps {
    network: Network;
}

const TotalVolumeProperty: React.FC<TotalVolumePropertyProps> = ({ network }) => {
    const { ammState } = useAmm();

    const nativeAsset = useMemo(
        () => ammState.assets?.find((asset) => asset.network.chainId === network.chainId && asset.currency.type === 'main'),
        [ ammState.assets, network.chainId ],
    );

    return (
        <Property label='Total Trading Volume (7d)'>
            {!nativeAsset && (ammState.loading || ammState.paramsLoading || ammState.totalLockedValuesLoading) ?
                <Spinner /> : nativeAsset && (
                <StatisticsChange period='week' currentValue={nativeAsset.volume} previousValue={nativeAsset.previousWeekVolume}>
                    {formatPrice(nativeAsset.volume, undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                </StatisticsChange>
            )}
        </Property>
    );
};

export default TotalVolumeProperty;
