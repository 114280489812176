import React, { ReactNode } from 'react';
import Link from '../../shared/components/link/link';
import { validateAndGetChildrenAsArray } from '../../shared/utils/react-utils';
import './path-nav.scss';

interface PathNavItemProps {
    label: string;
    url?: string;
}

interface PathNavProps {
    children: ReactNode;
}

export const PathNavItem: React.FC<PathNavItemProps> = ({ label, url }) => {
    return (
        <Link className='path-nav-item' disabled={!url} url={url}>{label}</Link>
    );
};

const PathNav: React.FC<PathNavProps> = ({ children }) => {
    const pathNavItems = validateAndGetChildrenAsArray(children, PathNavItem);

    return (
        <div className='path-nav section small'>
            {pathNavItems}
        </div>
    );
};

export default PathNav;
