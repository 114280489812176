import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { useAmm } from '../../../amm-context';
import AssetDashboard from '../asset-dashboard';
import { AssetDashboardContextProvider } from '../asset-dashboard-context';
import './asset-dashboard-page.scss';

const AssetDashboardPage: React.FC = () => {
    const { ammState } = useAmm();
    const { key } = useParams();
    const asset = key && ammState.assets?.find((asset) => asset.key === key);

    if (ammState.loading || ammState.paramsLoading || ammState.totalLockedValuesLoading) {
        return <Spinner size='large' className='asset-dashboard-loader' />;
    }
    if (!asset) {
        return <h5 className='not-found-message'>Asset not found</h5>;
    }
    return (
        <AssetDashboardContextProvider asset={asset}>
            <div className='page'><AssetDashboard /></div>
        </AssetDashboardContextProvider>
    );
};

export default AssetDashboardPage;
