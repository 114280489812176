import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Alert from '../../../../../shared/components/alert/alert';
import Button from '../../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import Input from '../../../../../shared/components/form-controls/input/input';
import { AccountNetworkState } from '../../../account-network-state';
import AmountTx from '../../../../tx/amount-tx/amount-tx';
import { useSendFunds } from './use-send-funds';
import './send-funds-dialog.scss';

interface SendFundsDialogProps extends DialogProps {
    networkState: AccountNetworkState;
}

const SendFundsDialog: React.FC<SendFundsDialogProps> = ({ networkState, className, ...otherDialogProps }) => {
    const { destinationAddress, txState, amountTxState, evmContract, setDestinationAddress, setCoins, broadcast } =
        useSendFunds(networkState);
    const [ memo, setMemo ] = useState<string>();

    const optionalAddressPrefixes = useMemo(
        () => networkState.network ? [ networkState.network.bech32Prefix, '0x' ].filter(Boolean) as string[] : [],
        [ networkState.network ],
    );

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !destinationAddress ||
        (amountTxState.coins?.ibc && !evmContract) ||
        optionalAddressPrefixes.every((prefix) => !destinationAddress.startsWith(prefix)) ||
        !amountTxState.coins?.amount);

    return (
        <Dialog closable className={classNames('send-funds-dialog', className)} {...otherDialogProps}>
            <DialogTitle>Send Funds</DialogTitle>
            <DialogContent>
                <label className='address-input-label'>Destination address</label>
                <Input
                    placeholder={optionalAddressPrefixes.map((prefix) => `${prefix}...`).join(' / ')}
                    onValueChange={setDestinationAddress}
                    value={destinationAddress}
                    controlSize='large'
                />

                <label className='address-input-label'>Memo (optional)</label>
                <Input onValueChange={setMemo} value={memo} controlSize='large' />

                <label className='address-input-label'>Token and amount</label>
                <AmountTx
                    controlSize='large'
                    txState={txState}
                    amountTxState={amountTxState}
                    networkState={networkState}
                    onCoinsChange={setCoins}
                    submitButtonContainer={<>
                        {amountTxState.coins?.ibc && !evmContract && (
                            <Alert type='error' className='send-warning'>
                                Non-native tokens without an ERC-20 contract cannot be sent on network {networkState.network?.chainName}
                            </Alert>
                        )}
                        <Alert type='warning' className='send-warning'>
                            <b>Warning:</b> Some centralized exchanges require memo when depositing to a native dym address.
                        </Alert>
                        <Button
                            size='x-large'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={confirmButtonDisabled}
                            onClick={() => broadcast(memo)}
                        >
                            Send
                        </Button>
                    </>}
                />
            </DialogContent>
        </Dialog>
    );
};

export default SendFundsDialog;
