import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import AlertBox from '../../../shared/components/alert-box/alert-box';
import Alert from '../../../shared/components/alert/alert';
import Button from '../../../shared/components/button/button';
import Confirm from '../../../shared/components/confirm/confirm';
import Input from '../../../shared/components/form-controls/input/input';
import Icon from '../../../shared/components/icon/icon';
import { ReactComponent as SwapIcon } from '../../../assets/icons/swap.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../shared/components/spinner/spinner';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { formatNumber, formatPrice, roundNumber } from '../../../shared/utils/number-utils';
import { useClient } from '../../client/client-context';
import { getFeeCurrency, getMaxDenomAmount, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { useAmm } from '../amm-context';
import { DEFAULT_SLIPPAGE_TOLERANCE } from '../types';
import { useTokensSwap } from './use-tokens-swap';
import './tokens-swap.scss';

interface TokensSwapProps {
    persistedData?: boolean;
    initialAsset1?: CoinsAmount;
    initialAsset2?: CoinsAmount;
}

const SWAP_DETAILS_EXPANDED_KEY = 'swapDetailsExpandedKey';

const TokensSwap: React.FC<TokensSwapProps> = ({ persistedData, initialAsset1, initialAsset2 }) => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const { ammState, networkState, commonTokens, getTokenPrice } = useAmm();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        txState,
        availableBalances,
        slippageTolerance,
        getTokensMinAmounts,
        setSlippageTolerance,
        switchTokens,
        updateAsset1Coins,
        updateAsset2Coins,
        broadcast,
    } = useTokensSwap(persistedData, initialAsset1, initialAsset2);
    const [ swapDetailsExpanded, setSwapDetailsExpanded ] = usePersistedState(SWAP_DETAILS_EXPANDED_KEY, true);
    const [ slippage, setSlippage ] = useState(0);
    const [ slippageAlertDialogOpen, setSlippageAlertDialogOpen ] = useState(false);

    const clientState = networkState.network && clientStateMap[networkState.network.chainId];
    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            return { content: 'Tokens swap successfully completed!' };
        }
    }, []);

    const onSlippageToleranceChange = useCallback((value: string): string => {
        if (!value) {
            setSlippageTolerance(undefined);
            return '';
        }
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        const numberValue = Number(value);
        if (numberValue < 0) {
            return '0';
        }
        if (numberValue > 100) {
            return '100';
        }
        setSlippageTolerance(numberValue);
        return numberValue.toString();
    }, [ setSlippageTolerance ]);

    useEffect(() => {
        if (!asset1AmountTxState.coins?.amount ||
            !asset2AmountTxState.coins?.amount) {
            return;
        }
        const oldPrice = getTokenPrice(asset1AmountTxState.coins, undefined, true, asset2AmountTxState.coins);
        if (oldPrice) {
            const newPrice = (asset2AmountTxState.coins.amount) /
                (1 - (ammState.params?.swapFee || 0) - (ammState.params?.takerFee || 0)) / (asset1AmountTxState.coins.amount);
            if (newPrice < oldPrice) {
                setTimeout(() => setSlippage(100 * (oldPrice - newPrice) / oldPrice), 50);
            }
        }
    }, [
        ammState.params?.swapFee,
        ammState.params?.takerFee,
        asset1AmountTxState.coins,
        asset2AmountTxState.coins,
        getTokenPrice,
    ]);

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !asset1AmountTxState.coins?.amount ||
        !asset2AmountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    const geTransactionFee = (): string => {
        const feeDenom = networkState.network && getFeeCurrency(networkState.network)?.displayDenom;
        if (!txState.fee && !txState.feeLoading) {
            return feeDenom ? `0.00 ${feeDenom}` : '';
        }
        return `${formatNumber(txState.fee?.coins.amount || 0, { minimumFractionDigits: 2 })} ${feeDenom}`;
    };

    const renderSlippageAlertDialog = (): ReactNode => {
        if (!asset1AmountTxState.coins || !asset2AmountTxState.coins) {
            return;
        }
        const { tokenOutMinAmount } = getTokensMinAmounts(asset1AmountTxState.coins, asset2AmountTxState.coins);
        const fixedMinAmount = getMaxDenomAmount(Number(tokenOutMinAmount), asset2AmountTxState.coins.currency);

        return (
            <Confirm
                title='Slippage Alert'
                className='slippage-alert-confirm'
                closable
                onRequestClose={() => setSlippageAlertDialogOpen(false)}
                onConfirm={() => broadcast()}
                content={<>
                    Note: slippage is above your tolerance configuration.<br />
                    The minimum amount of tokens received from this swap is {fixedMinAmount} {asset2AmountTxState.coins?.currency.displayDenom}.
                </>}
                okLabel='Confirm'
                cancelLabel='Abort'
                warning

            />
        );
    };

    return <>
        <section className='section tokens-swap-section'>
            <h3 className='tokens-swap-title'>Token Swap</h3>

            <label className='token-label'>From token</label>
            <AmountTx
                txState={{}}
                showPrice
                controlSize='large'
                commonTokens={commonTokens}
                amountTxState={asset1AmountTxState}
                networkState={networkState}
                availableBalances={availableBalances}
                disabledBalances={asset2AmountTxState.coins ? [ asset2AmountTxState.coins ] : []}
                onCoinsChange={(coins) => {
                    if (asset2AmountTxState.coins && isCoinsEquals(coins, asset2AmountTxState.coins)) {
                        switchTokens();
                    } else {
                        updateAsset1Coins(coins);
                    }
                }}
                displayFee={false}
                loading={ammState.loading}
            />

            <Button
                className='swap-button'
                buttonType='icon'
                size='x-large'
                iconColorMode='stroke'
                disabled={!asset1AmountTxState.coins || !asset2AmountTxState.coins}
                onClick={switchTokens}
            >
                <SwapIcon />
            </Button>


            <label className='token-label'>To token</label>
            <AmountTx
                txState={txState}
                controlSize='large'
                showPrice
                commonTokens={commonTokens}
                amountTxState={asset2AmountTxState}
                disabledBalances={asset1AmountTxState.coins ? [ asset1AmountTxState.coins ] : []}
                getTxResponseMessage={getTxResponseMessage}
                availableBalances={availableBalances}
                networkState={networkState}
                loading={ammState.loading}
                displayFee={false}
                onCoinsChange={(coins) => {
                    if (asset1AmountTxState.coins && isCoinsEquals(coins, asset1AmountTxState.coins)) {
                        switchTokens();
                    } else {
                        updateAsset2Coins(coins);
                    }
                }}
                submitButtonContainer={(
                    <Button
                        size='x-large'
                        loading={txState.broadcasting || txState.feeLoading}
                        disabled={confirmButtonDisabled}
                        onClick={() => {
                            if (slippage >= (slippageTolerance ?? DEFAULT_SLIPPAGE_TOLERANCE)) {
                                setSlippageAlertDialogOpen(true);
                            } else {
                                broadcast();
                            }
                        }}
                    >
                        Swap
                    </Button>
                )}
            />

            {slippage >= (slippageTolerance ?? DEFAULT_SLIPPAGE_TOLERANCE) && (
                <Alert className='slippage-alert' type='warning'>
                    Attention, this swap is above {slippageTolerance === undefined ? 'the standard' : 'your'} slippage tolerance.
                </Alert>
            )}

            {asset1AmountTxState.coins && asset2AmountTxState.coins && <>
                <AlertBox hideAlertIcon className={classNames('swap-details-section', { expanded: swapDetailsExpanded })}>
                    <button className='token-price' onClick={() => setSwapDetailsExpanded(!swapDetailsExpanded)}>
                        1 {asset1AmountTxState.coins.currency.displayDenom} ≈&nbsp;
                        {formatPrice(
                            getTokenPrice(asset1AmountTxState.coins, undefined, true, asset2AmountTxState.coins) || 0,
                            asset2AmountTxState.coins.currency.displayDenom,
                        )}&nbsp;
                        <span className='vs-price'>
                            &#40;{formatPrice(getTokenPrice(asset1AmountTxState.coins, undefined, true) || 0)}&#41;
                        </span>
                        <span className='space' />
                        <Icon className='arrow-down-icon'><ArrowDownIcon /></Icon>
                    </button>

                    <div className='fees-container'>
                        <div className='fee-property'>
                            Transaction fee
                            {txState.feeLoading ? <Spinner size='small' /> : <span className='fee-value'>{geTransactionFee()}</span>}
                        </div>
                        <div className='fee-property'>
                            Swap fee <span className='fee-value'>{roundNumber((ammState.params?.swapFee || 0) * 100, 2)}%</span>
                        </div>
                        <div className='fee-property'>
                            Protocol burn fee <span className='fee-value'>{roundNumber((ammState.params?.takerFee || 0) * 100, 2)}%</span>
                        </div>
                        <div className='fee-property border'>
                            Slippage Tolerance
                            <Input
                                className='fee-value'
                                suffix='%'
                                min={0}
                                max={100}
                                type='number'
                                value={slippageTolerance}
                                placeholder={DEFAULT_SLIPPAGE_TOLERANCE.toString()}
                                onValueChange={onSlippageToleranceChange}
                            />
                        </div>
                    </div>
                </AlertBox>
            </>}
        </section>

        {slippageAlertDialogOpen && renderSlippageAlertDialog()}
    </>;
};

export default TokensSwap;
