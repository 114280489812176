import { CommunityPoolSpendProposal } from 'cosmjs-types/cosmos/distribution/v1beta1/distribution';
import { TextProposal } from 'cosmjs-types/cosmos/gov/v1beta1/gov';
import { ParameterChangeProposal } from 'cosmjs-types/cosmos/params/v1beta1/params';
import { CancelSoftwareUpgradeProposal, SoftwareUpgradeProposal } from 'cosmjs-types/cosmos/upgrade/v1beta1/upgrade';
import { ClientUpdateProposal } from 'cosmjs-types/ibc/core/client/v1/client';
import {
    CreateDenomMetadataProposal,
    UpdateDenomMetadataProposal,
} from '../client/station-clients/dymension/generated/denommetadata/gov_denommetadata';
import { SubmitFraudProposal } from '../client/station-clients/dymension/generated/rollapp/proposal';
import {
    ReplaceStreamDistributionProposal,
    UpdateStreamDistributionProposal,
} from '../client/station-clients/dymension/generated/streamer/gov_distribution';
import { CreateStreamProposal, TerminateStreamProposal } from '../client/station-clients/dymension/generated/streamer/gov_stream';
import { CoinsAmount } from '../currency/currency-types';

export const PROPOSAL_STATUSES = [ 'Deposit Period', 'Voting Period', 'Passed', 'Rejected', 'Failed' ] as const;
export type ProposalStatus = typeof PROPOSAL_STATUSES[number];

export const PROPOSAL_TYPES = [
    'Parameter Change',
    'Client Update',
    'Community Pool Spend',
    'Create Stream',
    'Software Upgrade',
    'Cancel Software Upgrade',
    'Submit Fraud Proposal',
    'Create Denom Metadata Proposal',
    'Update Denom Metadata Proposal',
    'Terminate Stream',
    'Text',
    'Update Stream Distribution',
    'Replace Stream Distribution',
] as const;
export type ProposalType = typeof PROPOSAL_TYPES[number];

export const ROLLAPP_PROPOSAL_TYPES: ProposalType[] = [
    'Parameter Change',
    'Client Update',
    'Community Pool Spend',
    'Software Upgrade',
    'Cancel Software Upgrade',
    'Text',
];

export type GovProposalContent =
    CancelSoftwareUpgradeProposal |
    ClientUpdateProposal |
    CommunityPoolSpendProposal |
    CreateStreamProposal |
    SubmitFraudProposal |
    CreateDenomMetadataProposal |
    UpdateDenomMetadataProposal |
    ParameterChangeProposal |
    ReplaceStreamDistributionProposal |
    SoftwareUpgradeProposal |
    TerminateStreamProposal |
    TextProposal |
    UpdateStreamDistributionProposal;

export interface Proposal {
    id: number;
    status: ProposalStatus;
    type: ProposalType;
    title: string;
    description: string;
    content: GovProposalContent;
    submitTime: number;
    depositEndTime: number;
    votingStartTime: number;
    votingEndTime: number;
    totalDeposit: CoinsAmount;
    finalVotesSummary?: ProposalVotesSummary;
}

export const PROPOSAL_VOTE_OPTIONS = [ 'YES', 'NO', 'Veto', 'Abstain' ] as const;
export type ProposalVoteOption = typeof PROPOSAL_VOTE_OPTIONS[number];

export type ProposalVotesSummary = { [option in ProposalVoteOption]: { amount: number, part: number } }

export interface GovernanceParams {
    minDeposit: CoinsAmount | null;
    maxDepositPeriod?: number;
    quorum: number;
    threshold: number;
    vetoThreshold: number;
}
