/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Coin } from '../../cosmos/base/v1beta1/coin';
import { Any } from '../../google/protobuf/any';

export const protobufPackage = 'dymensionxyz.dymension.gamm.v1beta1';

/** GenesisState defines the gamm module's genesis state. */
export interface GenesisState {
    pools: Any[];
    /** will be renamed to next_pool_id in an upcoming version */
    nextPoolNumber: number;
    params: Params | undefined;
}

export interface Params {
    poolCreationFee: Coin[];
    enableGlobalPoolFees: boolean;
    globalFees: GlobalFees | undefined;
    takerFee: string;
}

export interface GlobalFees {
    swapFee: string;
    exitFee: string;
}

function createBaseGenesisState(): GenesisState {
    return { pools: [], nextPoolNumber: 0, params: undefined };
}

export const GenesisState = {
    encode(message: GenesisState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.pools) {
            Any.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        if (message.nextPoolNumber !== 0) {
            writer.uint32(16).uint64(message.nextPoolNumber);
        }
        if (message.params !== undefined) {
            Params.encode(message.params, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): GenesisState {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGenesisState();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.pools.push(Any.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.nextPoolNumber = longToNumber(reader.uint64() as Long);
                    break;
                case 3:
                    message.params = Params.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): GenesisState {
        return {
            pools: Array.isArray(object?.pools) ? object.pools.map((e: any) => Any.fromJSON(e)) : [],
            nextPoolNumber: isSet(object.nextPoolNumber) ? Number(object.nextPoolNumber) : 0,
            params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
        };
    },

    toJSON(message: GenesisState): unknown {
        const obj: any = {};
        if (message.pools) {
            obj.pools = message.pools.map((e) => e ? Any.toJSON(e) : undefined);
        } else {
            obj.pools = [];
        }
        message.nextPoolNumber !== undefined && (obj.nextPoolNumber = Math.round(message.nextPoolNumber));
        message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<GenesisState>, I>>(object: I): GenesisState {
        const message = createBaseGenesisState();
        message.pools = object.pools?.map((e) => Any.fromPartial(e)) || [];
        message.nextPoolNumber = object.nextPoolNumber ?? 0;
        message.params = (object.params !== undefined && object.params !== null)
            ? Params.fromPartial(object.params)
            : undefined;
        return message;
    },
};

function createBaseParams(): Params {
    return { poolCreationFee: [], enableGlobalPoolFees: false, globalFees: undefined, takerFee: '' };
}

export const Params = {
    encode(message: Params, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.poolCreationFee) {
            Coin.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        if (message.enableGlobalPoolFees === true) {
            writer.uint32(16).bool(message.enableGlobalPoolFees);
        }
        if (message.globalFees !== undefined) {
            GlobalFees.encode(message.globalFees, writer.uint32(26).fork()).ldelim();
        }
        if (message.takerFee !== '') {
            writer.uint32(34).string(message.takerFee);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Params {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.poolCreationFee.push(Coin.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.enableGlobalPoolFees = reader.bool();
                    break;
                case 3:
                    message.globalFees = GlobalFees.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.takerFee = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): Params {
        return {
            poolCreationFee: Array.isArray(object?.poolCreationFee)
                ? object.poolCreationFee.map((e: any) => Coin.fromJSON(e))
                : [],
            enableGlobalPoolFees: isSet(object.enableGlobalPoolFees) ? Boolean(object.enableGlobalPoolFees) : false,
            globalFees: isSet(object.globalFees) ? GlobalFees.fromJSON(object.globalFees) : undefined,
            takerFee: isSet(object.takerFee) ? String(object.takerFee) : '',
        };
    },

    toJSON(message: Params): unknown {
        const obj: any = {};
        if (message.poolCreationFee) {
            obj.poolCreationFee = message.poolCreationFee.map((e) => e ? Coin.toJSON(e) : undefined);
        } else {
            obj.poolCreationFee = [];
        }
        message.enableGlobalPoolFees !== undefined && (obj.enableGlobalPoolFees = message.enableGlobalPoolFees);
        message.globalFees !== undefined
        && (obj.globalFees = message.globalFees ? GlobalFees.toJSON(message.globalFees) : undefined);
        message.takerFee !== undefined && (obj.takerFee = message.takerFee);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<Params>, I>>(object: I): Params {
        const message = createBaseParams();
        message.poolCreationFee = object.poolCreationFee?.map((e) => Coin.fromPartial(e)) || [];
        message.enableGlobalPoolFees = object.enableGlobalPoolFees ?? false;
        message.globalFees = (object.globalFees !== undefined && object.globalFees !== null)
            ? GlobalFees.fromPartial(object.globalFees)
            : undefined;
        message.takerFee = object.takerFee ?? '';
        return message;
    },
};

function createBaseGlobalFees(): GlobalFees {
    return { swapFee: '', exitFee: '' };
}

export const GlobalFees = {
    encode(message: GlobalFees, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.swapFee !== '') {
            writer.uint32(10).string(message.swapFee);
        }
        if (message.exitFee !== '') {
            writer.uint32(18).string(message.exitFee);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): GlobalFees {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGlobalFees();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.swapFee = reader.string();
                    break;
                case 2:
                    message.exitFee = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): GlobalFees {
        return {
            swapFee: isSet(object.swapFee) ? String(object.swapFee) : '',
            exitFee: isSet(object.exitFee) ? String(object.exitFee) : '',
        };
    },

    toJSON(message: GlobalFees): unknown {
        const obj: any = {};
        message.swapFee !== undefined && (obj.swapFee = message.swapFee);
        message.exitFee !== undefined && (obj.exitFee = message.exitFee);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<GlobalFees>, I>>(object: I): GlobalFees {
        const message = createBaseGlobalFees();
        message.swapFee = object.swapFee ?? '';
        message.exitFee = object.exitFee ?? '';
        return message;
    },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== 'undefined') {
        return globalThis;
    }
    if (typeof self !== 'undefined') {
        return self;
    }
    if (typeof window !== 'undefined') {
        return window;
    }
    if (typeof global !== 'undefined') {
        return global;
    }
    throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
    : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
        : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
            : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
    : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
    }
    return long.toNumber();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}

function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
