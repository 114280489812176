import React, { useMemo } from 'react';
import Button from '../../../../../shared/components/button/button';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy.svg';
import Link from '../../../../../shared/components/link/link';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import useCopyToClipboard from '../../../../../shared/hooks/use-copy-to-clipboard';
import { getShortenedAddress } from '../../../../../shared/utils/text-utils';
import { useNetwork } from '../../../../network/network-context';
import { useAssetDashboard } from '../asset-dashboard-context';
import './asset-info.scss';

const AssetInfo: React.FC = () => {
    const { vfcsLoading, vfcMap } = useNetwork();
    const copyToClipboard = useCopyToClipboard();
    const { asset } = useAssetDashboard();

    const erc20Contract = useMemo(
        () => asset.ibc?.representation && vfcMap?.[asset.ibc?.representation]?.contractAddress,
        [ asset.ibc?.representation, vfcMap ],
    );

    return <div className='asset-info section small'>
        {asset.ibc?.representation && (
            <Property label='IBC contract' className='info-property' valueClassName='info-property-value'>
                <Button
                    size='small'
                    buttonType='icon'
                    className='info-property-value-content'
                    onClick={() => copyToClipboard(asset.ibc?.representation || '', 'contract address')}
                >
                    {getShortenedAddress(asset.ibc.representation, 12, 8)}&nbsp;&nbsp;
                    <CopyIcon />
                </Button>
            </Property>
        )}

        {asset.network.type !== 'Hub' && (
            <Property label='ERC20 contract' className='info-property' valueClassName='info-property-value'>
                {vfcsLoading ? <Spinner size='small' /> : (
                    <Button
                        size='small'
                        buttonType='icon'
                        disabled={!erc20Contract}
                        className='info-property-value-content website'
                        onClick={() => copyToClipboard(erc20Contract || '', 'contract address')}
                    >
                        <span className='ellipsis'>{erc20Contract || <>0<span>x</span>000000...000000</>}</span>&nbsp;&nbsp;
                        <CopyIcon />
                    </Button>
                )}
            </Property>
        )}

        {asset.network.website && (
            <Property label='Website' className='info-property' valueClassName='info-property-value'>
                <Link url={asset.network.website} external className='info-property-value-content website' size='small'>
                    <span className='ellipsis'>{asset.network.website}</span>
                </Link>
            </Property>
        )}

        <Property label='Issuer network ID' className='info-property' valueClassName='info-property-value'>
            <Button
                size='small'
                buttonType='icon'
                className='info-property-value-content'
                onClick={() => copyToClipboard(asset.network.chainId, 'network ID')}
            >
                {asset.network.chainId}&nbsp;&nbsp;
                <CopyIcon />
            </Button>
        </Property>

        {asset.network.description && (
            <Property label='Description' className='info-property' valueClassName='info-property-value text'>
                {asset.network.description}
            </Property>
        )}
    </div>;
};

export default AssetInfo;
