import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Button from '../../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import { SnackbarMessage } from '../../../../../shared/components/snackbar/snackbar-types';
import { formatNumber, roundNumber } from '../../../../../shared/utils/number-utils';
import { useClient } from '../../../../client/client-context';
import { CoinsAmount } from '../../../../currency/currency-types';
import AmountTx from '../../../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../../../tx/tx-types';
import { useWallet } from '../../../../wallet/wallet-context';
import { useAmm } from '../../../amm-context';
import { AssetBondType, useAssetBond } from './use-asset-bond';
import './asset-bond-dialog.scss';

export interface AssetBondDialogProps {
    coins: CoinsAmount;
    type: 'Bond' | 'Unbond';
    onRequestClose?: () => void;
}

const DEFAULT_AMOUNT_PART: { [type in AssetBondType]: number } = { Bond: 0.5, Unbond: 0.5 };
const AMOUNT_BUTTONS_PARTS = [ 0.25, 0.5, 0.75, 1 ];

const AssetBondDialog: React.FC<AssetBondDialogProps> = ({ coins, type, onRequestClose }) => {
    const { clientStateMap } = useClient();
    const { networkWalletMap } = useWallet();
    const { networkState, getTokenPrice } = useAmm();
    const { txState, amountTxState, availableBalances, setAmount, broadcast } = useAssetBond(coins, type);
    const [ amountPart, setAmountPart ] = useState(DEFAULT_AMOUNT_PART[type]);

    const clientState = networkState.network && clientStateMap[networkState.network.chainId];
    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            switch (type) {
                case 'Bond':
                    return { content: 'Tokens successfully bonded!' };
                case 'Unbond':
                    return { content: 'Tokens successfully unbonded! your balance will be updated within a minute.', duration: 60000 };
            }
        }
    }, [ type ]);

    useEffect(() => {
        const part = DEFAULT_AMOUNT_PART[type];
        const availableBalance = availableBalances[0];
        if (part && availableBalance) {
            const amount = roundNumber(availableBalance.amount * part, availableBalance.currency.decimals);
            setAmount(amount);
        }
    }, [ availableBalances, setAmount, type ]);

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            onRequestClose?.();
        }
    }, [ onRequestClose, txState.response?.deliveryTxCode ]);

    useEffect(() => {
        const availableBalance = availableBalances[0];
        if (amountTxState.coins && availableBalance?.amount) {
            setAmountPart(amountTxState.coins.amount / availableBalance.amount);
        }
    }, [ amountTxState.coins, availableBalances, setAmountPart ]);

    const onAmountPartChange = useCallback((value: number): void => {
        const availableBalance = availableBalances[0];
        if (amountTxState.coins && availableBalance?.amount) {
            const amount = roundNumber(availableBalance.amount * value, coins.currency.decimals);
            setAmount(amount);
        }
    }, [ amountTxState.coins, availableBalances, coins.currency.decimals, setAmount ]);

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !amountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    const renderAmountPartsControls = (): ReactElement => {
        return <>
            <input
                min={0}
                max={1}
                value={amountPart}
                type='range'
                step={0.001}
                className='bond-amount-slider'
                onChange={(event) => onAmountPartChange(Number(event.target.value) || 0)}
            />
            <div className='amount-part-buttons'>
                {AMOUNT_BUTTONS_PARTS.map((part) => (
                    <Button
                        buttonType='secondary'
                        focus={part === roundNumber(amountPart, 3)}
                        key={part}
                        onClick={() => onAmountPartChange(part)}
                    >
                        {part * 100}%
                    </Button>
                ))}
            </div>
        </>;
    };

    const renderPositionHeader = (): ReactElement | undefined => {
        return (
            <div className='position-header'>
                <p className='position-label'>Your bonded liquidity:</p>
                <h1>
                    {formatNumber(
                        (amountTxState.coins && getTokenPrice(amountTxState.coins)) || 0,
                        { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                    )}
                </h1>
            </div>
        );
    };

    return (
        <Dialog closable className='asset-bond-dialog' onRequestClose={onRequestClose}>
            <DialogTitle>{type} Tokens</DialogTitle>

            <DialogContent className='dialog-content'>
                {renderPositionHeader()}

                <AmountTx
                    txState={txState}
                    controlSize='large'
                    amountTxState={amountTxState}
                    networkState={networkState}
                    availableBalances={availableBalances}
                    reduceFeeFromBalances={type === 'Bond'}
                    onCoinsChange={(coins) => setAmount(coins.amount)}
                    getTxResponseMessage={getTxResponseMessage}
                    submitButtonContainer={<>
                        {renderAmountPartsControls()}

                        <Button
                            size='x-large'
                            className='submit-button'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={confirmButtonDisabled}
                            onClick={() => broadcast()}
                        >
                            {type} Tokens
                        </Button>
                    </>}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AssetBondDialog;
