export const readStream = async (readableStream: ReadableStream<Uint8Array>): Promise<string> => {
    const reader = readableStream.getReader();
    if (!reader) {
        return '';
    }
    const pump = (controller: ReadableStreamDefaultController): Promise<void> => {
        return reader.read().then(({ done, value }) => {
            if (!done) {
                controller.enqueue(value);
                return pump(controller);
            }
            return controller.close();
        });
    };
    const stream = new ReadableStream({ start: pump });
    return new Response(stream, { headers: { 'Content-Type': 'text/html' } }).text();
};


export const exportJSON = (element: any, filename: string): void => {
    const data = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(element));
    const anchorElement = document.createElement('a');
    anchorElement?.setAttribute('href', data);
    anchorElement?.setAttribute('download', filename);
    anchorElement?.click();
    anchorElement?.remove();
};
