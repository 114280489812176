import { gasPrice } from 'cosmjs/packages/faucet/build/constants';
import { Decimal } from 'cosmjs/packages/math';
import { GasPrice } from 'cosmjs/packages/stargate';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { roundNumber } from '../../../shared/utils/number-utils';
import { useAmm } from '../../amm/amm-context';
import { DEFAULT_GAS_PRICE_STEPS } from '../../client/client-types';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { SignMethod } from '../tx-types';
import { TxValue, useTx } from '../use-tx';
import { AmountTxState, amountTxStateReducer } from './amount-tx-state';
import { AccountNetworkState } from '../../account/account-network-state';
import { getMainCurrency, isCoinsEquals } from '../../currency/currency-service';

export type AmountTxMessagesCreator = (fee?: CoinsAmount, coins?: CoinsAmount) => EncodeObject[];

export interface AmountTxValue extends Omit<TxValue, 'setGasPrice'> {
    amountTxState: AmountTxState;
    setCoins: (coins: CoinsAmount) => void;
    setAmount: (amount: number) => void;
    evmContract?: string;
}

interface AmountTxParams {
    networkState: AccountNetworkState;
    availableBalances?: CoinsAmount[];
    disabledBalances?: CoinsAmount[];
    reduceFeeFromBalances?: boolean;
    selectInitialCurrency?: boolean;
    amountTxMessagesCreator?: AmountTxMessagesCreator;
    extraFees?: CoinsAmount[];
    signMethod?: SignMethod;
    useEvm?: boolean;
}

export const useAmountTx = ({
    networkState,
    availableBalances,
    amountTxMessagesCreator,
    disabledBalances,
    reduceFeeFromBalances = true,
    selectInitialCurrency = true,
    extraFees,
    signMethod,
    useEvm,
}: AmountTxParams): AmountTxValue => {
    const { vfcMap } = useNetwork();
    const { getTokenPrice } = useAmm();
    const [ amountTxState, amountTxStateDispatch ] = useReducer(amountTxStateReducer, { availableAmount: 0 });
    const [ shouldBroadcast, setShouldBroadcast ] = useState<{ memo?: string }>();

    const txMessagesCreator = useCallback(
        (fee?: CoinsAmount) => amountTxMessagesCreator?.(fee, amountTxState.coins) || [],
        [ amountTxMessagesCreator, amountTxState.coins ],
    );

    const evmContract = useMemo(() => {
        if (amountTxState.coins?.erc20Address) {
            return amountTxState.coins?.erc20Address;
        }
        if (amountTxState.coins?.ibc?.representation) {
            return vfcMap?.[amountTxState.coins?.ibc.representation]?.contractAddress;
        }
    }, [ amountTxState.coins?.erc20Address, amountTxState.coins?.ibc?.representation, vfcMap ]);

    const { txState, setGasPrice, calculateFee, broadcast: txBroadcast, ...otherTxValueProps } = useTx({
        networkState,
        signMethod,
        useEvm,
        evmContract,
        txMessagesCreator,
    });

    const setCoins = useCallback((coins: CoinsAmount) => amountTxStateDispatch({ type: 'set-coins', payload: coins }), []);

    const setAmount = useCallback((amount: number) => amountTxStateDispatch({ type: 'set-coins-amount', payload: amount }), []);

    const broadcast = useCallback(async (memo?: string) => {
        if (!txState.fee?.coins.amount) {
            setShouldBroadcast({ memo });
            calculateFee(undefined, true);
            return;
        }
        return txBroadcast(memo);
    }, [ calculateFee, txBroadcast, txState.fee?.coins.amount ]);

    useEffect(() => {
        if (!networkState.network || !amountTxState.coins || (!networkState.balances && !availableBalances)) {
            amountTxStateDispatch({ type: 'set-available-amount', payload: 0 });
            return;
        }
        const amountTxCoins = amountTxState.coins;
        const availableBalance =
            amountTxCoins && (availableBalances || networkState.balances)?.find((balance) => isCoinsEquals(balance, amountTxCoins));
        let availableAmount = availableBalance?.amount || 0;
        if (reduceFeeFromBalances) {
            availableAmount = [ txState.fee?.coins, ...(extraFees || []) ]
                .filter((fee) => fee && isCoinsEquals(amountTxCoins, fee))
                .reduce((current, fee) => current - (fee?.amount || 0), availableAmount);
        }
        amountTxStateDispatch({
            type: 'set-available-amount',
            payload: roundNumber(Math.max(0, availableAmount), amountTxCoins.currency.decimals),
        });
    }, [
        amountTxState.coins,
        txState.fee?.coins,
        availableBalances,
        extraFees,
        reduceFeeFromBalances,
        networkState.balances,
        networkState.network,
    ]);

    useEffect(() => {
        if (networkState.network) {
            amountTxStateDispatch({ type: 'set-coins', payload: undefined });
        }
    }, [ networkState.network ]);

    useEffect(() => {
        if (networkState.network && selectInitialCurrency) {
            const mainCurrencyCoins: CoinsAmount = { currency: getMainCurrency(networkState.network), amount: 0 };
            const fixedAvailableBalances = (availableBalances || networkState.balances || [ mainCurrencyCoins ])
                .filter((balance) => !disabledBalances ||
                    disabledBalances?.every((disabledBalance) => !isCoinsEquals(balance, disabledBalance)));

            if (!fixedAvailableBalances.length) {
                return;
            }
            const coins = [ amountTxState.coins, mainCurrencyCoins ]
                    .find((coins) => coins && fixedAvailableBalances.some((balance) => isCoinsEquals(balance, coins))) ||
                { ...fixedAvailableBalances[0], amount: 0 };

            if (amountTxState.coins && isCoinsEquals(coins, amountTxState.coins)) {
                return;
            }
            amountTxStateDispatch({ type: 'set-coins', payload: coins });
        }
    }, [ amountTxState.coins, availableBalances, disabledBalances, networkState.balances, networkState.network, selectInitialCurrency ]);

    useEffect(() => {
        if (txState.response) {
            setAmount(0);
        }
    }, [ setAmount, txState.response ]);

    const calculateGasPrice = useCallback(() => {
        const feeCoins = txState.fee?.coins;
        const txCoins = amountTxState.coins;
        const feeGas = txState.fee?.gas;
        if (networkState.network?.type !== 'Hub' ||
            !networkState.balances?.length ||
            !feeGas ||
            !feeCoins ||
            !txCoins ||
            isCoinsEquals(feeCoins, txCoins)) {
            return;
        }
        setGasPrice(undefined);
        const feeBalance = networkState.balances.find((balance) => isCoinsEquals(balance, feeCoins));
        if (feeBalance && feeBalance.amount >= feeCoins.amount) {
            return;
        }
        const txCoinsBalance = networkState.balances.find((balance) => isCoinsEquals(balance, txCoins));
        const txCoinsFeeAmount = getTokenPrice(feeCoins, undefined, false, txCoins);
        if (!txCoinsBalance || !txCoinsFeeAmount || txCoinsBalance.amount < txCoinsFeeAmount) {
            return;
        }
        const updatedFeeCoins = { ...feeCoins, amount: networkState.network.gasPriceSteps?.average ?? DEFAULT_GAS_PRICE_STEPS.average };
        updatedFeeCoins.amount *= Math.pow(10, txCoins.currency.decimals - feeCoins.currency.decimals);
        const txCoinsGasPriceAmount = getTokenPrice(updatedFeeCoins, undefined, false, txCoins);
        if (!txCoinsGasPriceAmount) {
            return;
        }
        const gasPrice = new GasPrice(
            Decimal.fromUserInput(txCoinsGasPriceAmount.toString(), txCoins.currency.decimals),
            txCoins.ibc?.representation || txCoins.currency.baseDenom,
        );
        setGasPrice(gasPrice);
        return gasPrice;
    }, [
        amountTxState.coins,
        getTokenPrice,
        networkState.balances,
        networkState.network?.gasPriceSteps?.average,
        networkState.network?.type,
        setGasPrice,
        txState.fee?.coins,
        txState.fee?.gas,
    ]);

    useEffect(() => {
        if (!shouldBroadcast || txState.feeLoading) {
            return;
        }
        if (txState.fee?.coins.amount) {
            const calculatedGasPrice = calculateGasPrice();
            if (calculatedGasPrice && (!gasPrice || gasPrice.denom !== calculatedGasPrice.denom)) {
                return;
            }
        }
        setShouldBroadcast(undefined);
        txBroadcast(shouldBroadcast.memo).then();
    }, [ calculateGasPrice, shouldBroadcast, txBroadcast, txState.fee, txState.feeLoading ]);

    useEffect(() => {
        calculateGasPrice();
    }, [ calculateGasPrice ]);

    return { amountTxState, setCoins, setAmount, txState, evmContract, calculateFee, broadcast, ...otherTxValueProps };
};
