import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ChannelNetworkMap, Network, RollAppParams, VirtualFrontierContract } from './network-types';
import { readStream } from '../../shared/utils/file-utils';
import { CoinsAmount, Currency, NetworkDenom } from '../currency/currency-types';
import { getLocalNetworks, getNetworkData } from './network-service';
import { getMainCurrency } from '../currency/currency-service';

interface NetworkContextValue {
    allNetworks: Network[];
    hubNetwork?: Network;
    rollapps: Network[];
    commonNetworks: Network[];
    rollAppParams?: RollAppParams;
    networkDenoms: NetworkDenom[] | undefined;
    vfcMap?: { [denom: string]: VirtualFrontierContract };
    hubChannelNetworkMap: ChannelNetworkMap;
    hubCurrency?: Currency;
    getNetwork: (networkId: string) => Network | undefined;
    toHubCoins: (coins: CoinsAmount, containerNetworkId?: string) => CoinsAmount;
    loading: boolean;
    vfcsLoading: boolean;
    rollAppParamsLoading: boolean;
}

export const NetworkContext = createContext<NetworkContextValue>({} as NetworkContextValue);

export const useNetwork = (): NetworkContextValue => useContext(NetworkContext);

export const NetworkContextProvider = ({ children }: { children: ReactNode }) => {
    const [ networks, setNetworks ] = useState<Network[]>([]);
    const [ networkDenoms, setNetworkDenoms ] = useState<NetworkDenom[]>();
    const [ vfcMap, setVfcMap ] = useState<{ [denom: string]: VirtualFrontierContract }>();
    const [ vfcsLoading, setVfcsLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ rollAppParams, setRollAppParams ] = useState<RollAppParams>();
    const [ rollAppParamsLoading, setRollAppParamsLoading ] = useState(true);

    const allNetworks = useMemo(() => networks.length ? [ ...getLocalNetworks(), ...networks ] : [], [ networks ]);

    const hubChannelNetworkMap = useMemo((): ChannelNetworkMap => allNetworks.reduce((current, network) =>
        !network.ibc?.hubChannel ? current : ({ ...current, [network.ibc.hubChannel]: network }), {}), [ allNetworks ]);

    const rollapps = useMemo(() => allNetworks.filter((network) => network.type === 'RollApp'), [ allNetworks ]);

    const hubNetwork = useMemo(() => allNetworks.find((network) => network.type === 'Hub'), [ allNetworks ]);

    const getNetwork = useCallback((networkId: string) => allNetworks.find((network) => network.chainId === networkId), [ allNetworks ]);

    const hubCurrency = useMemo(() => hubNetwork && getMainCurrency(hubNetwork), [ hubNetwork ]);

    const commonNetworks = useMemo(() => allNetworks.filter((network) => network.common && !network.hidden), [ allNetworks ]);

    const toHubCoins = useCallback((coins: CoinsAmount, containerNetworkId?: string): CoinsAmount => {
        if (!containerNetworkId || containerNetworkId === hubNetwork?.chainId) {
            return coins;
        }
        const networkDenom = networkDenoms?.find((networkDenom) =>
            networkDenom.baseDenom === coins.currency.baseDenom &&
            networkDenom.ibcNetworkId === (coins.ibc?.networkId || containerNetworkId));
        return {
            ...coins,
            ibc: !networkDenom ? undefined : {
                representation: networkDenom?.denom || '',
                networkId: networkDenom?.ibcNetworkId || '',
                path: networkDenom?.path || '',
            },
        };
    }, [ hubNetwork?.chainId, networkDenoms ]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_GET_NETWORKS_URL}?staging=${process.env.REACT_APP_ENV === 'mainnet-staging'}`)
            .then((response) => response?.body ? readStream(response.body).catch(() => '') : undefined)
            .then((responseText) => JSON.parse(responseText || '{}') as Network[])
            .then((networks) => {
                if (process.env.REACT_APP_ENV === 'mainnet-staging') {
                    const hub = networks.find((network) => network.type === 'Hub');
                    if (hub) {
                        hub.rpc = 'https://dymension.blockpi.network/rpc/v1/a1704bbe178ac940d7f19d9362455653ec8e1fd3';
                    }
                    const mande = networks.find((network) => network.chainId === 'mande_18071918-1');
                    if (mande?.evm) {
                        mande.rpc = 'https://rpc.mande.evm.ra.mainnet.noisnemyd.xyz';
                        mande.rest = 'https://rest.mande.evm.ra.mainnet.noisnemyd.xyz';
                        mande.evm.rpc = 'https://json-rpc.mande.evm.ra.mainnet.noisnemyd.xyz';
                    }
                }
                return networks;
            })
            // .then((networks) => {
            //     return [
            //         {
            //             'chainId': 'dymension_100-1',
            //             'chainName': 'Dymension Testnet',
            //             'rpc': 'http://0.0.0.0:36657',
            //             'rest': 'http://0.0.0.0:1318',
            //             'evm': {
            //                 'chainId': '0x64',
            //                 'rpc': 'http://0.0.0.0:9545',
            //             },
            //             'bech32Prefix': 'dym',
            //             'currencies': [
            //                 {
            //                     'displayDenom': 'DYM',
            //                     'baseDenom': 'udym',
            //                     'decimals': 18,
            //                     'logo': 'https://raw.githubusercontent.com/dymensionxyz/chain-registry/main/froopyland_100-1/logos/dymension-logo.svg',
            //                     'type': 'main',
            //                 },
            //                 {
            //                     'displayDenom': 'USDT',
            //                     'baseDenom': 'uusd',
            //                     'decimals': 6,
            //                     'logo': 'https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
            //                     'type': 'regular',
            //                 },
            //                 {
            //                     'displayDenom': 'ATOM',
            //                     'baseDenom': 'uatom',
            //                     'decimals': 6,
            //                     'logo': 'https://dynamic-assets.coinbase.com/b92276a1f003b87191983dab71970a9a6d522dde514176e5880a75055af1e67ce5f153b96a2ee5ecd22729a73d3a8739b248d853bde74ab6e643bef2d1b4f88d/asset_icons/9c760bf25bca9823f9ef8d651681b779aadc71a2f543f931070034e59ef10120.png',
            //                     'type': 'regular',
            //                 },
            //                 {
            //                     'displayDenom': 'ZAK',
            //                     'baseDenom': 'uzak',
            //                     'decimals': 6,
            //                     'logo': 'https://raw.githubusercontent.com/dymensionxyz/chain-registry/main/phongdn_1-1/logos/phongdn_1_1.png',
            //                     'type': 'regular',
            //                 },
            //                 {
            //                     'displayDenom': 'RAX',
            //                     'baseDenom': 'urax',
            //                     'decimals': 6,
            //                     'logo': 'https://raw.githubusercontent.com/dymensionxyz/chain-registry/main/chund_6103819-1/logos/chund_6103819-1.png',
            //                     'type': 'regular',
            //                 },
            //                 {
            //                     'displayDenom': 'RAY',
            //                     'baseDenom': 'uray',
            //                     'decimals': 18,
            //                     'logo': 'https://raw.githubusercontent.com/dymensionxyz/chain-registry/main/stingray_7870558-1/logos/stingray_7870558-1.png',
            //                     'type': 'main',
            //                 },
            //             ],
            //             'coinType': 60,
            //             'ibc': {
            //                 'timeout': 172800000,
            //             },
            //             'gasPriceSteps': {
            //                 "low": 20000000000,
            //                 "average": 20000000000,
            //                 "high": 20000000000
            //             },
            //             'logo': 'https://raw.githubusercontent.com/dymensionxyz/chain-registry/main/froopyland_100-1/logos/dymension-logo.svg',
            //             'type': 'Hub',
            //             'website': 'https://portal.dymension.xyz',
            //         } as Network,
            //     ];
            // })
            .then((networks) => networks.sort((network1, network2) => (network2.priority || 0) - (network1.priority || 0)))
            .then(setNetworks)
            .finally(() => setTimeout(() => setLoading(false)));
    }, []);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        fetch(`${process.env.REACT_APP_FETCH_NETWORK_DENOMS_URL}?networkId=${hubNetwork.chainId}`)
            .then((response) => response?.body ? readStream(response.body).catch(() => '') : undefined)
            .then((responseText) => JSON.parse(responseText || '[]') as NetworkDenom[])
            .then(setNetworkDenoms);
    }, [ hubNetwork ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        getNetworkData<VirtualFrontierContract[]>(hubNetwork, 'vfcs')
            .then((vfcs) =>
                setVfcMap(vfcs.filter((vfc) => vfc.enabled).reduce((current, vfc) => ({ ...current, [vfc.minDenom]: vfc }), {})))
            .finally(() => setVfcsLoading(false));
    }, [ hubNetwork ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        getNetworkData<RollAppParams>(hubNetwork, 'rollapp-params', true)
            .then(setRollAppParams)
            .finally(() => setRollAppParamsLoading(false));
    }, [ hubNetwork ]);

    return (
        <NetworkContext.Provider
            value={{
                allNetworks,
                toHubCoins,
                commonNetworks,
                rollapps,
                networkDenoms,
                rollAppParams,
                hubChannelNetworkMap,
                hubNetwork,
                hubCurrency,
                vfcMap,
                vfcsLoading,
                rollAppParamsLoading,
                loading,
                getNetwork,
            }}
        >
            {children}
        </NetworkContext.Provider>
    );
};
