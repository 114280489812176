import { Asset } from './assets/assets-types';
import { PoolPosition, Pool, AmmParams, LockedAsset, Incentive, IncentivesParams } from './types';

export interface AmmState {
    pools?: Pool[];
    assets?: Asset[];
    positions?: PoolPosition[];
    lockedAssets?: LockedAsset[];
    aprs?: { [poolId: number]: number };
    incentives?: { [denom: string]: Incentive[] };
    totalLockedValues?: { [denom: string]: number };
    params?: AmmParams;
    incentivesParams?: IncentivesParams;
    loading?: boolean;
    paramsLoading?: boolean;
    incentivesParamsLoading?: boolean;
    lockedAssetsLoading?: boolean;
    positionsLoading?: boolean;
    incentivesLoading?: boolean;
    totalLockedValuesLoading?: boolean;
    searchText?: string;
}

export const AMM_STATE_DEFAULT: AmmState = {
    loading: true,
    positionsLoading: true,
    lockedAssetsLoading: true,
    paramsLoading: true,
    incentivesLoading: true,
    totalLockedValuesLoading: true,
};

const WEEKS_IN_YEAR = 365 / 7;

export type AmmAction =
    { type: 'set-pools', payload: Pool[] | undefined } |
    { type: 'set-assets', payload: Asset[] | undefined } |
    { type: 'set-params', payload: AmmParams | undefined } |
    { type: 'set-incentives-params', payload: IncentivesParams | undefined } |
    { type: 'set-incentive', payload: { [denom: string]: Incentive[] } | undefined } |
    { type: 'set-total-locked-values', payload: { [denom: string]: number } | undefined } |
    { type: 'set-locked-assets', payload: LockedAsset[] | undefined } |
    { type: 'set-pool-positions', payload: PoolPosition[] | undefined } |
    { type: 'set-pools-loading', payload?: boolean } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-incentives-params-loading', payload?: boolean } |
    { type: 'set-incentive-loading', payload?: boolean } |
    { type: 'set-total-locked-values-loading', payload?: boolean } |
    { type: 'set-locked-assets-loading', payload?: boolean } |
    { type: 'set-pool-positions-loading', payload?: boolean } |
    { type: 'set-search-text', payload: string | undefined };

export const ammReducer = (state: AmmState, action: AmmAction): AmmState => {
    switch (action.type) {
        case 'set-pools':
            return { ...getFixedPools({ ...state, pools: action.payload }), loading: false };
        case 'set-assets':
            return { ...state, assets: action.payload };
        case 'set-pools-loading':
            return { ...state, loading: action.payload ?? true };
        case 'set-pool-positions':
            return { ...getFixedPools({ ...state, positions: action.payload }), positionsLoading: false };
        case 'set-pool-positions-loading':
            return { ...state, positionsLoading: action.payload ?? true };
        case 'set-locked-assets':
            return { ...state, lockedAssets: action.payload, lockedAssetsLoading: false };
        case 'set-locked-assets-loading':
            return { ...state, lockedAssetsLoading: action.payload ?? true };
        case 'set-params':
            return { ...state, params: action.payload, paramsLoading: false };
        case 'set-incentives-params':
            return { ...state, incentivesParams: action.payload, incentivesParamsLoading: false };
        case 'set-incentive':
            return { ...getFixedPools({ ...state, incentives: action.payload }), incentivesLoading: false };
        case 'set-total-locked-values':
            return { ...getFixedPools({ ...state, totalLockedValues: action.payload }), totalLockedValuesLoading: false };
        case 'set-params-loading':
            return { ...state, paramsLoading: action.payload ?? true };
        case 'set-incentives-params-loading':
            return { ...state, incentivesParamsLoading: action.payload ?? true };
        case 'set-incentive-loading':
            return { ...state, incentivesLoading: action.payload ?? true };
        case 'set-total-locked-values-loading':
            return { ...state, totalLockedValuesLoading: action.payload ?? true };
        case 'set-search-text':
            return { ...state, searchText: action.payload };
        default:
            return state;
    }
};

const getFixedPools = (state: AmmState): AmmState => {
    const pools = state.pools?.map((pool) => {
        const { liquidity, tradingVolume } = pool;
        const apr = liquidity?.value.value && tradingVolume?.diffWeek && state.params?.swapFee ?
            tradingVolume.diffWeek * WEEKS_IN_YEAR * state.params.swapFee / liquidity.value.value : 0;
        const incentives = state.incentives?.[pool.lpTokenDenom];
        const totalLockedValue = state.totalLockedValues?.[pool.lpTokenDenom];
        const incentiveApr = !incentives || !totalLockedValue ? 0 : incentives.reduce((current, incentive) => {
            if (incentive.startTime > new Date()) {
                return current;
            }
            const currentIncentiveApr = incentive.value / (totalLockedValue * incentive.yearPart);
            return current + currentIncentiveApr;
        }, 0);
        return { ...pool, apr, incentiveApr, position: state.positions?.find((position) => position.poolId === pool.id) };
    });
    return { ...state, pools };
};
