import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../utils/react-utils';
import Button from '../button/button';
import './buttons-group.scss';

interface ButtonGroupProps {
    children: ReactNode;
    className?: string;
}

function ButtonsGroup({ children, className }: ButtonGroupProps): JSX.Element {
    const buttons = validateAndGetChildrenAsArray(children, Button);
    const buttonsGroupClassName = classNames('buttons-group', className);

    return <div className={buttonsGroupClassName}>{buttons}</div>;
}

export default ButtonsGroup;
