import React, { forwardRef, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icons/external-link.svg';
import Spinner from '../spinner/spinner';
import Tooltip, { TooltipPlacement } from '../tooltip/tooltip';
import './link.scss';

export interface LinkProps {
    children: ReactNode;
    url?: string;
    info?: string;
    className?: string;
    size?: 'medium' | 'small';
    tooltipPlacement?: TooltipPlacement;
    iconColorMode?: 'fill' | 'stroke' | 'original';
    external?: boolean;
    externalIconVisible?: boolean;
    disabled?: boolean;
    loading?: boolean;
    active?: boolean;
    preventRedirect?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    onMouseOver?: (event: React.MouseEvent) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
}

const Link: React.ForwardRefRenderFunction<HTMLAnchorElement, LinkProps> = ({
    url,
    disabled,
    info,
    active,
    external,
    externalIconVisible = true,
    loading,
    className,
    preventRedirect,
    tooltipPlacement,
    iconColorMode = 'fill',
    size = 'medium',
    onClick,
    onMouseOver,
    onMouseLeave,
    children,
}, forwardedRef) => {
    const onLinkClick = (event: React.MouseEvent): void => {
        if (!url || external || disabled || preventRedirect) {
            event.preventDefault();
        }
        if (external && !disabled) {
            window.open(url, '_blank');
        }
        if (!disabled) {
            onClick?.(event);
        }
    };

    const link = (
        <NavLink
            ref={forwardedRef}
            onClick={onLinkClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            tabIndex={disabled ? -1 : undefined}
            className={({ isActive }) => classNames(
                'link',
                `icon-color-${iconColorMode}`,
                size,
                className,
                { active: active || isActive, external, loading, disabled: disabled || loading },
            )}
            to={url || '/'}
        >
            {children}
            {external && externalIconVisible ? <>&nbsp;<ExternalLinkIcon /></> : null}
            {loading ? <Spinner className='link-loader' size={size} /> : null}
        </NavLink>
    );

    return info ? <Tooltip placement={tooltipPlacement} title={info}>{link}</Tooltip> : link;
};

export default forwardRef(Link);
