import classNames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { isArray } from 'lodash';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { getCompareValues } from '../../../../../shared/components/statistics/statistics-change/statistics-change-service';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { AnalyticsData } from '../../../../analytics/analytics-types';
import { getMaxDenomAmount } from '../../../../currency/currency-service';
import Property, { PropertyProps } from '../../../../../shared/components/property/property';
import { Currency } from '../../../../currency/currency-types';
import { AnalyticsChangePeriod } from '../../../../../shared/components/statistics/statistics-change/statistics-change-types';
import './percentage-change-property.scss';

interface PercentageChangePropertyProps<T = number> extends Omit<PropertyProps, 'children'> {
    data?: AnalyticsData<T> | AnalyticsData<T>[],
    period?: AnalyticsChangePeriod;
    fetchComparableValues?: (value: T) => number,
    compareDiffs?: boolean,
    loading?: boolean;
    formatValueOptions?: Intl.NumberFormatOptions;
    formatValue?: (value: number, options?: Intl.NumberFormatOptions) => string;
    currency?: Currency;
}

export default function PercentageChangeProperty<T = number>({
    data,
    loading,
    currency,
    compareDiffs,
    period = 'week',
    formatValue = formatNumber,
    formatValueOptions,
    fetchComparableValues,
    ...otherPropertyProps
}: PercentageChangePropertyProps<T>): ReactElement {
    const dataArray = useMemo(() => {
        if (!data) {
            return [];
        }
        return isArray(data) ? data : [ data ];
    }, [ data ]);

    const compareValues = useMemo<{ currentValue: number, previousValue: number }>(() => {
        let { currentValue, previousValue } = dataArray.reduce((current, dataItem) => {
            if (dataItem) {
                const { currentValue, previousValue } = getCompareValues(dataItem, period, compareDiffs, fetchComparableValues);
                current.currentValue += currentValue;
                current.previousValue += previousValue;
            }
            return current;
        }, { currentValue: 0, previousValue: 0 });

        if (currency) {
            currentValue = getMaxDenomAmount(currentValue, currency);
            previousValue = getMaxDenomAmount(previousValue, currency);
        }
        return { currentValue, previousValue };
    }, [ dataArray, currency, period, compareDiffs, fetchComparableValues ]);

    return (
        <Property
            {...otherPropertyProps}
            valueClassName={classNames('percentage-change-property-value', otherPropertyProps.valueClassName)}
        >
            {loading ? <Spinner /> : (
                <StatisticsChange
                    period={period}
                    currentValue={compareValues.currentValue}
                    previousValue={compareValues.previousValue}
                >
                    {formatValue(compareValues.currentValue, formatValueOptions)} {currency?.displayDenom}
                </StatisticsChange>
            )}
        </Property>
    );
};

