import classNames from 'classnames';
import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../shared/components/button/button';
import Icon from '../../../../shared/components/icon/icon';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as ArrowUpRight } from '../../../../assets/icons/arrow-up-right.svg';
import { ReactComponent as ThunderIcon } from '../../../../assets/icons/thunder.svg';
import { ReactComponent as InfoOutlineIcon } from '../../../../assets/icons/info-outline.svg';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import Tooltip from '../../../../shared/components/tooltip/tooltip';
import { convertDecimalToInt, formatNumber, formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import { getCurrencyLogoPath, getMaxDenomAmount, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { getPositionPart } from '../../amm.service';
import { Asset } from '../../assets/assets-types';
import IncentiveBadge from '../../incentive-badge/incentive-badge';
import LiquidityDialog, { LiquidityDialogProps } from '../../liquidity-dialog/liquidity-dialog';
import { useAmm } from '../../amm-context';
import { Pool } from '../../types';
import './pool-list.scss';

export interface PoolListProps {
    positions?: boolean;
    asset?: Asset;
}

export const PoolListHeader: React.FC<{ children: ReactNode, className?: string }> = ({ children, className }) => {
    return <h5 className={classNames('pool-list-header', className)}>{children}</h5>;
};

const PoolList: React.FC<PoolListProps> = ({ positions, asset }) => {
    const navigate = useNavigate();
    const { getNetwork, hubNetwork } = useNetwork();
    const { getPoolLiquidity, ammState, sortedFilteredPools } = useAmm();
    const [ liquidityDialogProps, setLiquidityDialogProps ] = useState<LiquidityDialogProps>();

    const pools = useMemo<Pool[]>(() => {
        let pools = positions ? (ammState.pools || []).filter((pool) => pool.position) : sortedFilteredPools;
        if (asset) {
            pools = pools.filter((pool) => pool.assets.some((poolAsset) => isCoinsEquals(asset, poolAsset)));
        }
        return pools;
    }, [ ammState.pools, asset, positions, sortedFilteredPools ]);

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = asset.ibc ? getNetwork(asset.ibc.networkId) : hubNetwork;

        return currencyNetwork &&
            <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='currency logo' />;
    };

    const onLiquidityActionClick = (event: React.MouseEvent, pool: Pool): void => {
        event.stopPropagation();
        setLiquidityDialogProps({ pool, type: positions ? 'Bond' : 'Add' });
    };

    const renderPoolIdColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn className='index-column' contentClassName='index-column-content'>
                #{pool.id.toString().padStart(3, '0')}
            </TableColumn>
        );
    };

    const renderPoolNameColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn>
                {renderAssetLogo(pool.assets[0])}
                {renderAssetLogo(pool.assets[1])}
                <span className='pool-assets-names'>
                    {pool.assets[0].currency.displayDenom} / {pool.assets[1].currency.displayDenom}
                    {ammState.incentives?.[pool.lpTokenDenom]?.some((incentive) => incentive.coins.length) ?
                        <IncentiveBadge className='incentives-badge' size='small' denom={pool.lpTokenDenom} /> : null}
                </span>
            </TableColumn>
        );
    };

    const renderPositionLiquidityColumn = (pool: Pool): ReactElement => {
        const liquidity = getPoolLiquidity(pool) || 0;
        const sharesPart = getPositionPart(pool);

        return (
            <TableColumn align='right'>
                {ammState.paramsLoading ?
                    <Spinner size='small' /> : formatPrice(liquidity * sharesPart, undefined, { notation: 'compact' })}
            </TableColumn>
        );
    };

    const renderPoolLiquidityColumn = (pool: Pool): ReactElement => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;

        return (
            <TableColumn align='right'>
                {formatNumber(
                    poolLiquidity,
                    { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
            </TableColumn>
        );
    };

    const renderBondedLiquidityColumn = (pool: Pool): ReactElement => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;
        const totalLocked = ammState.totalLockedValues?.[pool.lpTokenDenom] || 0;
        return (
            <TableColumn align='right'>
                {ammState.totalLockedValuesLoading ? <Spinner size='small' /> : <>
                    {formatNumber(
                        totalLocked,
                        { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                    )}
                    {ammState.totalLockedValuesLoading}
                    <span className='bonded-percentage'>({roundNumber(totalLocked * 100 / poolLiquidity, 2)}%)</span>
                </>}
            </TableColumn>
        );
    };

    const renderTradingVolumeColumn = (pool: Pool): ReactElement => {
        const price = !ammState.params?.vsCoins ? 0 : getMaxDenomAmount(
            (pool.tradingVolume?.value.value || 0) - (pool.tradingVolume?.previousWeekValue?.value || 0), ammState.params.vsCoins.currency);

        return (
            <TableColumn align='right'>
                {formatNumber(
                    price, { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
            </TableColumn>
        );
    };

    const renderAprColumn = (pool: Pool): ReactElement => {
        const incentiveAprTooltipContent = <>
            Base fee: <b>{roundNumber((pool.apr || 0) * 100, 2)}</b>%<br />
            {pool.incentiveApr ? <>Incentive rewards: <b>{roundNumber(pool.incentiveApr * 100, 2)}</b>%</> : null}
        </>;

        return (
            <TableColumn align='right'>
                {ammState.incentivesLoading || ammState.totalLockedValuesLoading ? <Spinner size='small' /> : <>
                    <Tooltip title={incentiveAprTooltipContent} placement='top'>
                        <span className={classNames('apr-value', { 'incentive': Boolean(pool.incentiveApr) })}>
                            {pool.incentiveApr ?
                                <Icon className='thunder-icon'><ThunderIcon /></Icon> :
                                <Icon className='apr-info-icon'><InfoOutlineIcon /></Icon>
                            }
                            {roundNumber(((pool.apr || 0) + (pool.incentiveApr || 0)) * 100, 2)}%
                        </span>
                    </Tooltip>
                </>}
            </TableColumn>
        );
    };

    const renderSharesColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(convertDecimalToInt(Number(pool.position?.shares) || 0))}
            </TableColumn>
        );
    };

    const renderBondSharesColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(convertDecimalToInt(Number(pool.position?.bondedShares) || 0))}
                <span className='bonded-percentage'>
                    ({roundNumber(Number(pool.position?.bondedShares) * 100 / Number(pool.position?.shares), 2)}%)
                </span>
            </TableColumn>
        );
    };

    const renderActionsColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right' className='actions-column'>
                <Button
                    tooltipPlacement='bottom-end'
                    buttonType='secondary'
                    size='small'
                    onClick={(event) => onLiquidityActionClick(event, pool)}
                >
                    {positions ? 'Bond' : 'Add liquidity'}&nbsp;&nbsp;<ArrowUpRight />
                </Button>
            </TableColumn>
        );
    };

    const renderPoolHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn className='index-column' contentClassName='index-column-content'>ID</TableColumn>
                <TableColumn>Name</TableColumn>
                <TableColumn align='right'>{positions ? 'Position' : 'Liquidity'}</TableColumn>
                {positions && <TableColumn align='right'>Shares</TableColumn>}
                {positions && <TableColumn align='right' nowrap>Bonded shares</TableColumn>}
                {!positions && (
                    <TableColumn
                        align='right'
                        nowrap
                        info='Pools may be eligible for additional rewards. Enhance your earnings by bonding your LP shares. Shares unbond instantly.'
                    >
                        Bonded liquidity
                    </TableColumn>
                )}
                {!positions && <TableColumn align='right' nowrap>Volume (7d)</TableColumn>}
                <TableColumn
                    align='right'
                    info={<div className='apr-tooltip'>
                        <p>APR (Annual Percentage Rate) indicates the expected yearly yield for providing liquidity to the pool.</p>
                        <p>You can earn additional rewards by bonding your LP (Liquidity Provider) tokens.</p>
                    </div>}
                >
                    APR
                </TableColumn>
                <TableColumn align='right' className='actions-column' />
            </TableRow>
        );
    };

    const renderPoolRow = (pool: Pool): ReactElement => {
        return (
            <TableRow key={pool.id} className='pool-row' onSelect={() => navigate(`/amm/pool/${pool.id}`)}>
                {renderPoolIdColumn(pool)}
                {renderPoolNameColumn(pool)}
                {positions ? renderPositionLiquidityColumn(pool) : renderPoolLiquidityColumn(pool)}
                {positions && renderSharesColumn(pool)}
                {positions ? renderBondSharesColumn(pool) : renderBondedLiquidityColumn(pool)}
                {!positions && renderTradingVolumeColumn(pool)}
                {renderAprColumn(pool)}
                {renderActionsColumn(pool)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if ((!ammState.pools?.length && ammState.loading) || (positions && !ammState.positions && ammState.positionsLoading)) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!pools?.length) {
            return <div className='no-data'>{positions ? 'No Positions' : 'No Pools'}</div>;
        }
    };
    return (
        <div className='pool-list-container'>
            <Table className='pool-list' firstColumnSticky bottomBar={renderBottomBar()}>
                {renderPoolHeaderRow()}
                {pools?.map(renderPoolRow)}
            </Table>

            {liquidityDialogProps &&
                <LiquidityDialog {...liquidityDialogProps} onRequestClose={() => setLiquidityDialogProps(undefined)} />}
        </div>
    );
};

export default PoolList;
