import { ParamChange, ParameterChangeProposal } from 'cosmjs-types/cosmos/params/v1beta1/params';
import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '../../../../shared/components/button/button';
import Input from '../../../../shared/components/form-controls/input/input';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import { isJsonValid } from '../../../../shared/utils/text-utils';
import ProposalJsonItem from './proposal-json-item/proposal-json-item';
import './proposal-type.scss';

interface ParameterChangeProps {
    content?: ParameterChangeProposal;
    onInit?: (data: ParameterChangeProposal) => void;
    onChange?: (data: Partial<ParameterChangeProposal>, isValid: boolean) => void;
}

const MAX_PARAM_CHANGES = 10;

const ParameterChange: React.FC<ParameterChangeProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(
        () => Boolean(content?.changes?.length &&
            content.changes.every(({ value, key, subspace }) => key && subspace && isJsonValid(value, true))),
        [ content ],
    );

    useEffect(() => onInit?.({ title: '', description: '', changes: [ { key: '', subspace: '', value: '{}' } ] }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    const updateParamChange = useCallback((change: ParamChange, key: keyof ParamChange, value: string) => {
        change[key] = value;
        if (content) {
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const addParamChange = useCallback(() => {
        if (content) {
            content.changes.push({ subspace: '', value: '{}', key: '' });
            onChange?.({ ...content }, false);
        }
    }, [ content, onChange ]);

    const onRemoveParamChange = useCallback((index: number) => {
        if (content) {
            content.changes.splice(index, 1);
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Changes</label>
            {content?.changes?.map((change, changeIndex) => (
                <div className='proposal-change-item' key={changeIndex}>
                    <label className='proposal-change-label'>Subspace</label>
                    <Input
                        value={change.subspace}
                        onTypeFinish={(value) => updateParamChange(change, 'subspace', value?.toString() || '')}
                    />

                    <label className='proposal-change-label'>Key</label>
                    <Input
                        value={change.key}
                        onTypeFinish={(value) => updateParamChange(change, 'key', value?.toString() || '')}
                    />

                    <label className='proposal-change-label'>Value</label>
                    <ProposalJsonItem json={change.value} onChange={(value) => updateParamChange(change, 'value', value)} />

                    <Button
                        disabled={content.changes.length <= 1}
                        className='proposal-remove-item-button'
                        buttonType='icon'
                        onClick={() => onRemoveParamChange(changeIndex)}
                    >
                        <ClearIcon />
                    </Button>
                </div>
            ))}
            <Button
                className='proposal-add-item-button'
                buttonType='secondary'
                disabled={(content?.changes?.length || 0) >= MAX_PARAM_CHANGES}
                onClick={addParamChange}
            >
                Add Parameter
            </Button>
        </div>
    );
};

export default ParameterChange;
