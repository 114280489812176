import classNames from 'classnames';
import React from 'react';
import Badge from '../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { getNetworkLogoPath } from '../../network/network-service';
import { Network } from '../../network/network-types';
import './common-network-badge.scss';

interface CommonNetworkBadgeProps {
    className?: string;
    network: Network;
    showNetworkId?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const CommonNetworkBadge: React.FC<CommonNetworkBadgeProps> = ({ className, network, disabled, onClick, showNetworkId, selected }) => {
    return (
        <Badge
            size='large'
            color={getCssVariableValue('--cream-rgb').split(',').map(Number)}
            className={classNames('common-network', className, { selected })}
            onClick={onClick}
            disabled={disabled}
            label={<>
                {network && <img className='network-logo' src={getNetworkLogoPath(network)} alt='network logo' />}
                <span className='network-name-container'>
                    {network.chainName}
                    {showNetworkId && <span className='network-id'>{network?.chainId}</span>}
                </span>
            </>}
        />
    );
};

export default CommonNetworkBadge;
