import { Params } from 'cosmjs-types/cosmos/distribution/v1beta1/distribution';
import { isEmpty } from 'lodash';
import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { ClientError } from '../client/client-error';
import { StationClient } from '../client/station-clients/station-client';
import { getNetworkData } from '../network/network-service';
import { DistributionParams } from './types';

export const loadDistributionParams = async (client: StationClient): Promise<DistributionParams> => {
    let params: Params | undefined;
    if (client.getNetwork().collectData) {
        params = await getNetworkData<Params>(client.getNetwork(), 'distribution-params', true);
    }
    if (!params || isEmpty(params)) {
        const paramsResponses = await client.getDistributionQueryClient()
            .Params({})
            .catch((error) => {
                throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
            });
        params = paramsResponses.params;
    }
    if (!params) {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), new Error(`Can't fetch distribution params`));
    }
    return convertDistributionParams(params);
};

const convertDistributionParams = async (params: Params): Promise<DistributionParams> => {
    return {
        communityTax: convertDecimalToInt(Number(params.communityTax)),
        baseProposerReward: convertDecimalToInt(Number(params.baseProposerReward)),
        bonusProposerReward: convertDecimalToInt(Number(params.bonusProposerReward)),
    };
};
