import React from 'react';
import { IbcTransferContextProvider } from '../ibc-transfer-context';
import IbcTransfer, { IbcTransferProps } from '../ibc-transfer';
import Dialog, { DialogContent } from '../../../shared/components/dialog/dialog';
import './ibc-transfer-dialog.scss';

export interface IbcTransferDialogProps extends IbcTransferProps {
    onRequestClose?: () => void;
    optionalSourceNetworks?: string[];
    optionalDestinationNetworks?: string[];
    initialSourceNetwork?: string;
    initialDestinationNetwork?: string;
}

const IbcTransferDialog: React.FC<IbcTransferDialogProps> = ({
    onRequestClose,
    optionalSourceNetworks,
    optionalDestinationNetworks,
    initialSourceNetwork,
    initialDestinationNetwork,
    ...ibcTransferProps
}) => {
    return (
        <Dialog closable className='ibc-transfer-dialog' onRequestClose={onRequestClose}>
            <DialogContent>
                <IbcTransfer {...ibcTransferProps} />
            </DialogContent>
        </Dialog>
    );
};

const IbcTransferDialogWithContext = (props: IbcTransferDialogProps) =>
    <IbcTransferContextProvider
        initialSourceId={props.initialSourceNetwork}
        initialDestinationId={props.initialDestinationNetwork}
        optionalSourceNetworks={props.optionalSourceNetworks}
        optionalDestinationNetworks={props.optionalDestinationNetworks}
    >
        <IbcTransferDialog {...props} />
    </IbcTransferContextProvider>;

export default IbcTransferDialogWithContext;

