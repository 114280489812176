import { Window as KeplrWindow } from '@keplr-wallet/types/build/window';
import { WalletType } from '../wallet-types';
import { CosmosProvider, CosmosWallet } from './cosmos-wallet';

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window extends KeplrWindow {}
}

export class KeplrWallet extends CosmosWallet {
    constructor() {
        super('keplr_keystorechange');
    }

    public getWalletType(): WalletType {
        return 'Keplr';
    }

    public getCurrentProvider(): CosmosProvider | undefined {
        return window.keplr;
    };
}

