import React from 'react';
import classNames from 'classnames';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { Currency } from '../../../../currency/currency-types';
import './chart-data-value.scss';

interface ChartDataValueProps{
    value?: number;
    currency?: Currency;
    hideIndicator?: boolean;
    formatValueOptions?: Intl.NumberFormatOptions;
    formatValue?: (value: number, options?: Intl.NumberFormatOptions) => string;
    type?: string;
    className?: string;
}

export const ChartDataValue: React.FC<ChartDataValueProps> = ({
    value = 0,
    formatValueOptions,
    formatValue = formatNumber,
    currency,
    type,
    hideIndicator,
    className
}) => {
    return (
        <div className={classNames('chart-data-value', className, type?.toLowerCase())}>
            {type && <>
                {!hideIndicator && <span className='data-value-type-indicator' />}
                <span className='data-value-type'>{type}:</span>
            </>}
            <span className='data-value'>
                {formatValue(value, formatValueOptions)}
            </span>
            {currency?.displayDenom}
        </div>
    );
};

export default ChartDataValue;
