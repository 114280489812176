import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import Alert from '../../../shared/components/alert/alert';
import Button from '../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../shared/components/dialog/dialog';
import Input from '../../../shared/components/form-controls/input/input';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import { getFormattedLocalDateTime } from '../../../shared/utils/date-utils';
import { formatNumber } from '../../../shared/utils/number-utils';
import { useClient } from '../../client/client-context';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { useAmm } from '../amm-context';
import { MIN_INCENTIVES_VALUE } from '../amm.service';
import { Pool } from '../types';
import { useAddIncentives } from './use-add-incentives';
import './add-incentives-dialog.scss';

export interface AddIncentiveDialogProps extends DialogProps {
    pool: Pool;
}

const AddIncentivesDialog: React.FC<AddIncentiveDialogProps> = ({ pool, className, ...otherBadgeProps }) => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const { ammState, networkState } = useAmm();
    const {
        startTime,
        endTime,
        txState,
        amountTxState,
        incentiveCreationFee,
        setStartTime,
        setEndTime,
        setCoins,
        broadcast,
    } = useAddIncentives(pool);

    const clientState = networkState.network && clientStateMap[networkState.network.chainId];
    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            return { content: 'Incentives successfully added!' };
        }
    }, []);

    const minStartTime = useMemo(() => getFormattedLocalDateTime(new Date()), []);

    const maxStartTime = useMemo(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        return getFormattedLocalDateTime(date);
    }, []);

    const currentStartTime = useMemo(() => getFormattedLocalDateTime(startTime), [ startTime ]);

    const minEndTime = useMemo(() => currentStartTime, [ currentStartTime ]);

    const maxEndTime = useMemo(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 2);
        return getFormattedLocalDateTime(date);
    }, []);

    const currentEndTime = useMemo(() => endTime && getFormattedLocalDateTime(endTime), [ endTime ]);

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            otherBadgeProps.onRequestClose?.();
        }
    }, [ otherBadgeProps, txState.response?.deliveryTxCode ]);

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !endTime || isNaN(startTime as any) || isNaN(endTime as any) || startTime >= endTime ||
        ammState.incentivesParamsLoading ||
        !amountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    return (
        <Dialog closable className={classNames('incentives-dialog', className)} {...otherBadgeProps}>
            <DialogTitle>Add Incentives</DialogTitle>
            <DialogContent className='dialog-content'>
                <label className='input-label'>Start time</label>
                <Input
                    controlSize='large'
                    className='time-input'
                    type='datetime-local'
                    min={minStartTime}
                    max={maxStartTime}
                    value={currentStartTime}
                    onValueChange={(value) => setStartTime(new Date(value))}
                />

                <label className='input-label'>End time</label>
                <Input
                    controlSize='large'
                    className='time-input'
                    type='datetime-local'
                    min={minEndTime}
                    max={maxEndTime}
                    value={currentEndTime}
                    error={endTime && startTime >= endTime}
                    onValueChange={(value) => setEndTime(new Date(value))}
                />
                {endTime && startTime >= endTime && <span className='error-label'>End time must be come after start time</span>}

                <label className='input-label'>Select token and amount</label>
                <AmountTx
                    controlSize='large'
                    txState={txState}
                    getTxResponseMessage={getTxResponseMessage}
                    loading={ammState.incentivesParamsLoading}
                    amountTxState={amountTxState}
                    networkState={networkState}
                    extraFees={[ { label: 'Incentive creation fee', value: incentiveCreationFee } ]}
                    onCoinsChange={setCoins}
                    reduceFeeFromBalances
                    submitButtonContainer={<>
                        <Alert type='warning'>
                            Incentive plans under {
                            formatNumber(
                                MIN_INCENTIVES_VALUE,
                                { maximumFractionDigits: 2, minimumFractionDigits: 0, style: 'currency', currency: 'USD' },
                            )} are not displayed
                        </Alert>

                        <Button
                            size='x-large'
                            className='submit-button'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={confirmButtonDisabled}
                            onClick={() => broadcast()}
                        >
                            Add Incentives
                        </Button>
                    </>}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddIncentivesDialog;
