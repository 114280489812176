import React from 'react';
import TokensSwap from '../tokens-swap';
import './tokens-swap-page.scss';

const TokensSwapPage: React.FC = () => {
    return (
        <div className='page tokens-swap-page'>
            <TokensSwap persistedData />
        </div>
    );
};

export default TokensSwapPage;
