import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Menu, { MenuAction } from '../../../../shared/components/menu/menu';
import Property from '../../../../shared/components/property/property';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import { SnackbarMessage } from '../../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatNumber, formatPrice } from '../../../../shared/utils/number-utils';
import { getValidatorLogoPath } from '../../../staking/validator/validator.service';
import { AccountNetworkState } from '../../account-network-state';
import { useAmm } from '../../../amm/amm-context';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet.svg';
import { ReactComponent as StakeIcon } from '../../../../assets/icons/stake.svg';
import { useClient } from '../../../client/client-context';
import { getMainCurrency, getStakingCurrency } from '../../../currency/currency-service';
import NewDelegationDialog, {
    NewDelegationDialogProps,
} from '../../../staking/delegation/new-delegation/new-delegation-dialog/new-delegation-dialog';
import { NewDelegationType } from '../../../staking/delegation/new-delegation/new-delegation-types';
import { StakingContextProvider, useStaking } from '../../../staking/staking-context';
import { Validator } from '../../../staking/validator/validator-types';
import { TxError } from '../../../tx/tx-error';
import { DeliveryTxCode } from '../../../tx/tx-types';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import AccountTotalStakedValue from './account-total-staked-value/account-total-staked-value';
import './account-stake.scss';

interface AccountStakeProps {
    className?: string;
    networkState: AccountNetworkState;
    onRequestClose?: () => void;
}

const TRANSACTION_IN_PROGRESS_KEY = 'transactionInProgress';

const VALIDATOR_FALLBACK_IMAGE = require('../../../../assets/icons/validator.svg').default;

const AccountStake: React.FC<AccountStakeProps> = ({ className, networkState, onRequestClose }) => {
    const navigate = useNavigate();
    const { networkWalletMap, handleWalletError } = useWallet();
    const { showErrorMessage, removeMessage, showMessage, showWarningMessage } = useSnackbar();
    const { clientError } = useClient();
    const { getTokenPrice } = useAmm();
    const { stakedValidatorsData, stakingDataState, rewardsTxState, withdrawRewards } = useStaking();
    const [ delegationDialogProps, setDelegationDialogProps ] = useState<NewDelegationDialogProps>();

    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];

    const openNewDelegationDialog = (validator: Validator, type: NewDelegationType): void => {
        if (!networkWallet) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED'));
            return;
        }
        setDelegationDialogProps({ validator, type });
    };

    const stakeCurrency = useMemo(() => networkState.network && getStakingCurrency(networkState.network), [ networkState ]);

    const totalRewardsAmount = useMemo(
        () => stakedValidatorsData?.state?.validators?.reduce((current, validator) => current + (validator.reward || 0), 0) || 0,
        [ stakedValidatorsData?.state?.validators ],
    );

    const onImageError = (imageElement: HTMLImageElement | null): void => {
        if (imageElement) {
            imageElement.src = VALIDATOR_FALLBACK_IMAGE;
            imageElement.classList.add('fallback');
        }
    };

    // todo: make it generic for tx
    useEffect(() => {
        if (rewardsTxState?.broadcasting) {
            if (!rewardsTxState.signing) {
                removeMessage(TRANSACTION_IN_PROGRESS_KEY);
                showMessage({
                    content: (
                        <div className='horizontally-centered'>
                            <Spinner size='small' />&nbsp;&nbsp;Transaction is in progress.
                        </div>
                    ),
                    duration: 600000,
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            } else {
                showMessage({
                    content: 'Your wallet is waiting for confirmation and a signature...',
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            }
        } else {
            setTimeout(() => removeMessage(TRANSACTION_IN_PROGRESS_KEY), 50);
        }
    }, [ removeMessage, rewardsTxState?.broadcasting, rewardsTxState?.signing, showMessage ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        if (!rewardsTxState?.response) {
            return;
        }
        const { hash, network, deliveryTxCode } = rewardsTxState.response;
        let exploreLink: string = '';
        try {
            exploreLink = network.exploreTxUrl ? (new URL(hash, network.exploreTxUrl)).href : '';
        } catch {}
        const action: SnackbarMessage['action'] = exploreLink ?
            { label: 'Explore', callback: () => window.open(exploreLink, '_blank') } :
            undefined;
        let content: string;
        switch (deliveryTxCode) {
            case DeliveryTxCode.SUCCESS:
                content = 'Your rewards successfully withdrawn!';
                break;
            case DeliveryTxCode.INSUFFICIENT_FUNDS:
                content = 'Transaction delivery failed - insufficient funds';
                break;
            case DeliveryTxCode.OUT_OF_GAS:
                content = 'Transaction delivery failed - out of gas';
                break;
            default:
                console.log('Transaction delivery failed with code: ' + deliveryTxCode);
                content = 'Transaction delivery failed, please try again later';
        }
        showMessage({ content, action, type: deliveryTxCode === DeliveryTxCode.SUCCESS ? 'success' : 'error', key: hash });
    }, [ rewardsTxState?.response, showMessage ]);

    useEffect(() => {
        if (!rewardsTxState?.error || !(rewardsTxState.error instanceof TxError)) {
            return;
        }
        switch (rewardsTxState.error.code) {
            case 'MISSING_DATA':
                showErrorMessage('Transaction delivery failed: invalid transaction parameters.');
                break;
            default:
                showErrorMessage('Transaction delivery failed, please try again later');
        }
    }, [ rewardsTxState?.error, showErrorMessage, showWarningMessage ]);

    // todo: handle errors different
    useEffect(() => {
        if (!clientError) {
            return;
        }
        const networkNameLabel = clientError.network?.chainName || 'the';
        switch (clientError.code) {
            case 'FETCH_DATA_FAILED':
                showErrorMessage(`Can't fetch data from ${networkNameLabel} client, please try again later`);
                break;
            case 'INSUFFICIENT_FEES':
                showErrorMessage(`The transaction broadcast encountered a failure due to insufficient fees`);
                break;
            case 'SIMULATE_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to calculate fee, please try again later`);
                break;
            case 'BROADCAST_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to broadcast the transaction, please try again later`);
                break;
            case 'SIGNATURE_VERIFICATION_FAILED':
                showErrorMessage(`Signature verification failed`);
                break;
            case 'NO_BALANCES':
                const currency = clientError.network ? getMainCurrency(clientError.network) : undefined;
                const action: SnackbarMessage['action'] = !currency || !clientError.network?.faucetUrl ? undefined :
                    {
                        label: 'Get ' + currency.displayDenom,
                        callback: () => window.open(clientError.network?.faucetUrl, '_blank'),
                        close: true,
                    };
                showWarningMessage({
                    content: <>
                        There are no balances in your {clientError.network ? `${clientError.network.chainName} ` : ''}account.<br />
                        Send some tokens there before trying to query or make a transaction.
                    </>,
                    action, duration: 20000,
                    key: 'no-balances-' + clientError.network?.chainId,
                });
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage('The request rejected by the user');
                break;
            default:
                // todo: handle errors different
                showErrorMessage(`${networkNameLabel} client connection failed, please try again later`);
        }
    }, [ clientError, showErrorMessage, showWarningMessage ]);

    return <>
        <AccountTotalStakedValue networkState={networkState} />
        <div className='account-menu-actions claim-rewards'>
            <Property label='Claimable Rewards' className='account-menu-action claimable-rewards-property'>
                {stakedValidatorsData?.state?.loading || stakingDataState?.rewardsLoading ?
                    <Spinner size='xs' /> :
                    `${(totalRewardsAmount < 0.0001 ? '< 0.0001' : formatNumber(totalRewardsAmount, { maximumFractionDigits: 4 }))}
                    ${stakeCurrency?.displayDenom}`}
            </Property>
            <Button
                className='account-menu-action'
                size='small'
                disabled={!totalRewardsAmount || rewardsTxState?.broadcasting}
                loading={rewardsTxState?.broadcasting}
                onClick={() => withdrawRewards()}
            >
                <WalletIcon />&nbsp;Claim All
            </Button>
        </div>
        <ul className={classNames('account-stake', className)}>
            {stakedValidatorsData?.state.loading && <Spinner className='delegations-loader' />}
            {!stakedValidatorsData?.state.loading && !stakedValidatorsData?.state.validators?.length && (
                <Button
                    className='new-stake-button'
                    onClick={() => {
                        navigate(`/${networkState.network?.type === 'Hub' ?
                            'dymension' : 'rollapp/' + networkState.network?.chainId}/staking`);
                        onRequestClose?.();
                    }}
                >
                    <StakeIcon />&nbsp;New Stake
                </Button>)}
            {stakedValidatorsData?.state.validators?.map((validator, validatorIndex) => {
                const validatorDelegation = (
                    <li className='delegation-row'>
                        <img
                            src={networkState.network ? getValidatorLogoPath(networkState.network, validator) : ''}
                            className='validator-logo'
                            alt='validator-logo'
                            onError={(error) => onImageError(error.target as HTMLImageElement)}
                        />
                        <span className='validator-name'>{validator.name}</span>

                        <span className='delegation-amount'>
                            {formatPrice(validator.amountStaked || 0, stakeCurrency?.displayDenom, undefined, 9)}
                            <span className='delegation-value'>
                                {stakeCurrency && formatPrice(getTokenPrice(
                                    { amount: validator.amountStaked || 0, currency: stakeCurrency }, networkState.network?.chainId,
                                ) || 0)}
                            </span>
                        </span>
                    </li>
                );
                return (
                    <Menu closeWhenScroll trigger={validatorDelegation} key={validatorIndex}>
                        <MenuAction onClick={() => openNewDelegationDialog(validator, 'delegate')}>Stake</MenuAction>
                        <MenuAction onClick={() => openNewDelegationDialog(validator, 'redelegate')} disabled={!validator.amountStaked}>
                            Redelegate
                        </MenuAction>
                        <MenuAction onClick={() => openNewDelegationDialog(validator, 'undelegate')} disabled={!validator.amountStaked}>
                            Unstake
                        </MenuAction>
                        <MenuAction onClick={() => withdrawRewards(validator)} disabled={!validator.reward || rewardsTxState?.broadcasting}>
                            Claim rewards
                        </MenuAction>
                    </Menu>
                );
            })}
        </ul>
        {delegationDialogProps ?
            <NewDelegationDialog {...delegationDialogProps} onRequestClose={() => setDelegationDialogProps(undefined)} /> :
            undefined}
    </>;
};

const AccountStakeWithContext = (props: AccountStakeProps) => props.networkState.network ? (
    <StakingContextProvider network={props.networkState.network} types={[ 'Staked' ]}>
        <AccountStake {...props} />
    </StakingContextProvider>
) : <></>;

export default AccountStakeWithContext;
