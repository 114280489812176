import React, { useEffect, useMemo } from 'react';
import Input from '../../../../shared/components/form-controls/input/input';
import { TerminateStreamProposal } from '../../../client/station-clients/dymension/generated/streamer/gov_stream';
import './proposal-type.scss';

interface TerminateStreamProps {
    content?: TerminateStreamProposal;
    onInit?: (data: TerminateStreamProposal) => void;
    onChange?: (data: Partial<TerminateStreamProposal>, isValid: boolean) => void;
}

const TerminateStream: React.FC<TerminateStreamProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(() => Boolean(content?.streamId), [ content ]);

    useEffect(() => onInit?.({
        title: '',
        description: '',
        streamId: undefined as any,
    }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Stream ID</label>
            <Input
                type='number'
                value={content?.streamId}
                onValueChange={(value) => onChange?.({ streamId: !value ? undefined : Number(value) }, true)}
            />
        </div>
    );
};

export default TerminateStream;
