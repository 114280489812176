import React, { useCallback } from 'react';
import { convertDecimalToInt, roundNumber } from '../../../../shared/utils/number-utils';
import { AnalyticsState } from '../../../analytics/analytics-state';
import { RollappState } from '../../../rollapp/rollapp-state';
import { NetworksAnalytics } from '../analytics/network-analytics-types';
import PercentageChangeProperty from './percentage-change-property/percentage-change-property';

interface StakingAprPropertyProps {
    state: AnalyticsState<NetworksAnalytics>;
    rollappData: RollappState;
}

const StakingAprProperty: React.FC<StakingAprPropertyProps> = ({ state, rollappData }) => {
    const fetchComparableValues = useCallback((value: { amount: number, bondedAmount: number, inflation: number }): number => {
        const inflationValue = convertDecimalToInt(value.inflation || 0) * 100;
        let apr = value.bondedAmount ? inflationValue * value.amount / value.bondedAmount : Infinity;
        if (rollappData.distributionParams) {
            const { communityTax, baseProposerReward, bonusProposerReward } = rollappData.distributionParams;
            apr *= (1 - communityTax - baseProposerReward - bonusProposerReward);
        }
        return apr;
    }, [ rollappData.distributionParams ]);

    return (
        <PercentageChangeProperty
            label='Staking APR'
            loading={state.loading}
            period='week'
            data={state.analytics?.totalSupply}
            fetchComparableValues={fetchComparableValues}
            formatValue={(value) => value === Infinity ? `${Infinity.toString()}%` : `${roundNumber(value, 2)}%`}
        />
    );
};

export default StakingAprProperty;
