import { SignatureLike } from '@ethersproject/bytes';
import { hashMessage } from '@ethersproject/hash';
import { computePublicKey, recoverPublicKey } from '@ethersproject/signing-key';
import { EIP712ToSign } from '@evmos/transactions';
import { fromHex } from 'cosmjs/packages/encoding';
import { AccountData, OfflineSigner } from 'cosmjs/packages/proto-signing';
import { getCurrencyLogoPath, getMainCurrency } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { getNetworkLogoPath } from '../../network/network-service';
import { EvmConfig, Network } from '../../network/network-types';
import { WalletError } from '../wallet-error';
import { convertToBech32Address, convertToHexAddress } from '../wallet-service';
import { Wallet, WalletType } from '../wallet-types';

const WALLET_PUBLIC_KEY_MAP_KEY = '_walletPublicKeyMap';
const NETWORK_NOT_ADDED_ERROR_CODES = [ 4901, 4902 ];
const REQUEST_REJECTED_ERROR_CODE = 4001;
const ALREADY_REQUESTED_ERROR_CODE = -32002;

interface TokenSuggestRequestPromise {
    toNetwork: Network;
    coins: CoinsAmount;
    resolve: () => void;
    reject: (reason?: any) => void;
}

interface SwitchNetworkRequestPromise {
    network: Network;
    resolve: () => void;
    reject: (reason?: any) => void;
}

interface AddEthereumChainParameter {
    chainId: string;
    chainName: string;
    nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls?: string[];
    iconUrls?: string[];
}

export interface EthereumProvider {
    request: (request: { method: string, params?: Object }) => Promise<any>;

    on(eventName: string | symbol, listener: (...args: any[]) => void): this;

    off(eventName: string | symbol, listener: (...args: any[]) => void): this;
}

export abstract class EthereumWallet implements Wallet {
    private static tokenSuggestRequestPromisesMap: { [walletType in WalletType]?: TokenSuggestRequestPromise[] } = {}; // todo: create shared util for this behaviour
    private static switchNetworkRequestPromises: SwitchNetworkRequestPromise[] = []; // todo: create shared util for this behaviour

    private currentAddress?: string;
    private accountChangeListener?: () => void;
    private readonly initializedEvent: string;
    private readonly onAccountChanged: (addresses: string[]) => void;

    protected constructor(initializedEvent: string) {
        this.initializedEvent = initializedEvent;

        this.onAccountChanged = (addresses: string[]): void => {
            const previousAddress = this.currentAddress;
            this.currentAddress = addresses[0];
            if (previousAddress && this.currentAddress && previousAddress !== this.currentAddress) {
                this.accountChangeListener?.();
            }
        };
    }

    abstract getWalletType(): WalletType;

    abstract getCurrentProvider(): EthereumProvider | undefined;

    public setAccountChangesListener(listener: () => void): void {
        this.accountChangeListener = listener;
        this.getProvider().then((provider) => provider.on('accountsChanged', this.onAccountChanged));
    }

    public clear(): void {
        this.getProvider().then((provider) => provider.off('accountsChanged', this.onAccountChanged));
    }

    public async getAddress(network: Network): Promise<{ address?: string; hexAddress?: string }> {
        this.validateNetwork(network);
        const accounts = await this.getAccounts(network);
        const hexAddress = this.currentAddress = accounts[0];
        return { address: network.bech32Prefix && convertToBech32Address(hexAddress, network.bech32Prefix), hexAddress };
    }

    public async getOfflineSigner(network: Network): Promise<OfflineSigner> {
        this.validateNetwork(network);
        return {
            getAccounts: () => this.getWalletAccounts(network),
            signEIP712: async (signerAddress: string, signDoc: EIP712ToSign): Promise<Uint8Array> => {
                await this.switchNetwork(network);
                const hexSignerAddress = convertToHexAddress(signerAddress);
                const eip712Payload = JSON.stringify(signDoc);
                const provider = await this.getProvider();
                const signature = await provider.request({ method: 'eth_signTypedData_v4', params: [ hexSignerAddress, eip712Payload ] });
                return fromHex((signature as string).replace('0x', ''));
            },
        };
    }

    public async suggestToken(coins: CoinsAmount, coinsOriginalNetwork: Network, toNetwork: Network): Promise<void> {
        const provider = await this.getProvider();
        return new Promise<void>((resolve, reject) => {
            if (this.getTokenSuggestRequestPromises().push({ toNetwork, coins, resolve, reject }) === 1) {
                this.suggestNextTokens(provider);
            }
        });
    }

    public async switchNetwork(network: Network): Promise<void> {
        this.validateNetwork(network);
        const provider = await this.getProvider();
        return new Promise<void>((resolve, reject) => {
            if (EthereumWallet.switchNetworkRequestPromises.push({ network, resolve, reject }) === 1) {
                this.requestSwitchNetwork(provider);
            }
        });
    }

    public async requestSwitchNetwork(provider: EthereumProvider): Promise<void> {
        if (EthereumWallet.switchNetworkRequestPromises.length === 0) {
            return;
        }
        const { network, resolve, reject } = EthereumWallet.switchNetworkRequestPromises[0];
        provider.request({ method: 'wallet_switchEthereumChain', params: [ { chainId: network.evm?.chainId } ] })
            .catch(async (error) => {
                if (!NETWORK_NOT_ADDED_ERROR_CODES.includes(error.code)) {
                    throw error;
                }
                await provider.request({ method: 'wallet_addEthereumChain', params: [ this.getChainInfo(network) ] });
                const chainId = await provider.request({ method: 'eth_chainId' });
                if (chainId !== network.evm?.chainId) {
                    throw new WalletError('SWITCH_NETWORK', this.getWalletType(), network);
                }
            })
            .then(resolve)
            .catch((error) => this.handleEthereumWalletError(network, error))
            .catch((error) => reject(error))
            .finally(() => {
                EthereumWallet.switchNetworkRequestPromises.shift();
                this.requestSwitchNetwork(provider);
            });
    }

    public async validateWalletInstalled(): Promise<void> {
        return this.getProvider().then();
    }

    public async getAccounts(network?: Network): Promise<string[]> {
        if (network) {
            this.validateNetwork(network);
        }
        const provider = await this.getProvider();
        const accounts: string[] = await provider.request({ method: 'eth_requestAccounts', params: [] }).catch((error) => {
            throw new WalletError('KEY_NOT_FOUND', this.getWalletType(), network, error);
        });
        if (!accounts.length) {
            throw new WalletError('KEY_NOT_FOUND', this.getWalletType(), network, new Error('Missing Accounts'));
        }
        return accounts;
    }

    public publicKeyRequired(hexAddress: string): boolean {
        const mapKey = `${this.getWalletType()}${WALLET_PUBLIC_KEY_MAP_KEY}`;
        const hexPublicKeysMap = JSON.parse(localStorage.getItem(mapKey) || '{}');
        return !Boolean(hexPublicKeysMap[hexAddress]);
    }

    public async getProvider(timeout = 50): Promise<EthereumProvider> {
        const currentProvider = this.getCurrentProvider();
        if (currentProvider) {
            return currentProvider;
        }
        const provider = await new Promise<EthereumProvider | undefined>((resolve) => {
            let timeoutRef: NodeJS.Timeout;
            const handleEthereum = () => {
                window.clearTimeout(timeoutRef);
                document.removeEventListener(this.initializedEvent, handleEthereum);
                resolve(this.getCurrentProvider());
            };
            document.addEventListener(this.initializedEvent, handleEthereum);
            timeoutRef = setTimeout(handleEthereum, timeout);
        });
        if (!provider) {
            throw new WalletError('INSTALL_WALLET', this.getWalletType(), undefined, undefined, false);
        }
        return provider;
    }

    private suggestNextTokens(provider: EthereumProvider): void {
        const tokenSuggestRequestPromises = this.getTokenSuggestRequestPromises();
        if (tokenSuggestRequestPromises.length === 0) {
            return;
        }
        const { toNetwork, coins, resolve, reject } = tokenSuggestRequestPromises[0];
        this.addTokens(toNetwork, coins)
            .then(resolve)
            .catch((error) => this.handleEthereumWalletError(toNetwork, error))
            .catch((error) => reject(error))
            .finally(() => {
                tokenSuggestRequestPromises.shift();
                this.suggestNextTokens(provider);
            });
    }

    private async addTokens(network: Network, coins: CoinsAmount): Promise<void> {
        await this.switchNetwork(network);
        if (!coins.erc20Address) {
            return;
        }
        const provider = await this.getProvider();
        await provider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: coins.erc20Address,
                    symbol: coins.currency.displayDenom,
                    decimals: coins.currency.decimals,
                    image: getCurrencyLogoPath(coins.currency, network),
                },
            },
        });
    }

    private handleEthereumWalletError(network: Network, error: any): never | undefined {
        if (error instanceof WalletError) {
            throw error;
        }
        // if (error.code === REQUEST_REJECTED_ERROR_CODE) {
        //     throw new WalletError('REQUEST_REJECTED', this.getWalletType(), network, error);
        // }
        // if (error.code === ALREADY_REQUESTED_ERROR_CODE) {
        //     throw new WalletError('ACCOUNTS_ALREADY_REQUESTED', this.getWalletType(), network, error);
        // }
        if (error.code === ALREADY_REQUESTED_ERROR_CODE || error.code === REQUEST_REJECTED_ERROR_CODE) {
            return;
        }
        throw new WalletError('FAILED_INTEGRATE_CHAIN', this.getWalletType(), network, error);
    }

    private getTokenSuggestRequestPromises(): TokenSuggestRequestPromise[] {
        let tokenSuggestRequestPromises = EthereumWallet.tokenSuggestRequestPromisesMap[this.getWalletType()];
        if (!tokenSuggestRequestPromises) {
            tokenSuggestRequestPromises = EthereumWallet.tokenSuggestRequestPromisesMap[this.getWalletType()] = [];
        }
        return tokenSuggestRequestPromises;
    }

    private async getWalletAccounts(network: Network): Promise<AccountData[]> {
        const accounts = await this.getAccounts(network);
        return Promise.all(accounts.map((account, accountIndex) => this.getAccountData(network, account, accountIndex === 0)));
    }

    private async getAccountData(network: Network, hexAddress: string, getPublicKey?: boolean): Promise<AccountData> {
        const address = network.bech32Prefix ? convertToBech32Address(hexAddress, network.bech32Prefix) : hexAddress;
        const pubkey = getPublicKey ? await this.getPublicKey(hexAddress) : Uint8Array.from([]);
        return { address, pubkey, algo: 'secp256k1' };
    }

    private async getPublicKey(hexAddress: string): Promise<Uint8Array> {
        const mapKey = `${this.getWalletType()}${WALLET_PUBLIC_KEY_MAP_KEY}`;
        const hexPublicKeysMap = JSON.parse(localStorage.getItem(mapKey) || '{}');
        if (!hexPublicKeysMap[hexAddress]) {
            const message = 'Verify Public Key';
            const provider = await this.getProvider();
            const signature = await provider.request({ method: 'personal_sign', params: [ message, hexAddress ] });
            const uncompressedPk = recoverPublicKey(hashMessage(message), signature as SignatureLike);
            hexPublicKeysMap[hexAddress] = computePublicKey(uncompressedPk, true);
            localStorage.setItem(mapKey, JSON.stringify(hexPublicKeysMap));
        }
        return fromHex(hexPublicKeysMap[hexAddress].replace('0x', ''));
    }

    private getChainInfo(network: Network): AddEthereumChainParameter | undefined {
        this.validateNetwork(network);

        const nativeCurrency = getMainCurrency(network);
        if (!nativeCurrency || !network.evm?.rpc) {
            return;
        }
        return {
            chainId: network.evm.chainId,
            chainName: network.chainName,
            nativeCurrency: {
                name: nativeCurrency.baseDenom,
                symbol: nativeCurrency.displayDenom,
                decimals: nativeCurrency.decimals,
            },
            rpcUrls: [ network.evm.rpc ],
            blockExplorerUrls: network.explorerUrl ? [ network.explorerUrl ] : undefined,
            iconUrls: [ getNetworkLogoPath(network) ],
        };
    }

    private validateNetwork(network: Network): asserts network is Omit<Network, 'evm'> & { evm: EvmConfig } {
        if (!network.evm) {
            throw new WalletError('UNSUPPORTED_NETWORK', this.getWalletType(), network);
        }
    }
}
