import React from 'react';
import NetworkDashboardPage from '../network-dashboard/network-dashboard-page';
import { NetworkDashboardContextProvider } from '../network-dashboard/network-dashboard-context';
import { useNetwork } from '../network/network-context';

const HubDashboardPage: React.FC = () => {
    const { hubNetwork } = useNetwork();

    return hubNetwork ? <NetworkDashboardContextProvider network={hubNetwork}><NetworkDashboardPage /></NetworkDashboardContextProvider> : <></>;
};

export default HubDashboardPage;
