import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Duration } from '../client/station-clients/dymension/generated/google/protobuf/duration';
import { CoinsAmount } from '../currency/currency-types';
import { PoolAnalyticsSummary } from './statistics/analytics/pool-analytics-types';

export const DEFAULT_SLIPPAGE_TOLERANCE = 0.5;
export const LOCK_DEFAULT_DURATION: Duration = { seconds: 60, nanos: 0 };

export const EPOCH_IDENTIFIERS = [ 'minute', 'day', 'daily', 'week', 'weekly', 'month', 'monthly', 'year', 'yearly' ] as const;
export type EpochIdentifier = typeof EPOCH_IDENTIFIERS[number];

export interface Pool extends PoolAnalyticsSummary { // todo: find better way
    id: number;
    lpTokenDenom: string;
    assets: CoinsAmount[];
    swapFee: number;
    exitFee: number;
    totalShares: bigint;
    position?: PoolPosition;
    apr?: number;
    incentiveApr?: number;
}

export interface Incentive {
    denom: string;
    coins: CoinsAmount[];
    distributedCoins: CoinsAmount[];
    value: number;
    startTime: Date;
    endTime: Date;
    yearPart: number;
}

export interface LockedAsset {
    coin: Coin;
    lockId: number;
}

export interface PoolPosition {
    poolId: number;
    shares: bigint;
    bondedShares: bigint;
    lockId?: number;
}

export interface AmmParams {
    poolCreationTokens: CoinsAmount[];
    swapFee: number;
    exitFee: number;
    takerFee: number;
    vsCoins: CoinsAmount;
}

export interface IncentivesParams {
    epochIdentifier: EpochIdentifier;
}
