import { MAX_VISIBLE_MESSAGES, MESSAGE_DEFAULT_DURATION, SnackbarMessage, SnackbarMessageKey } from './snackbar-types';

export interface SnackbarState {
    messages: SnackbarMessage[];
    messageIndex?: number;
    scheduledMessages: { [messageKey: SnackbarMessageKey]: { message: SnackbarMessage, toSchedule: boolean } };
}

export type SnackbarAction =
    { type: 'add-message', payload: Partial<SnackbarMessage> } |
    { type: 'remove-message', payload: SnackbarMessageKey } |
    { type: 'set-message-scheduled', payload: SnackbarMessageKey };

export const snackbarReducer = (state: SnackbarState, action: SnackbarAction): SnackbarState => {
    switch (action.type) {
        case 'add-message':
            const messageIndex = (state.messageIndex || 0) + 1;
            const message: SnackbarMessage = {
                content: '',
                type: 'info',
                closeAction: true,
                duration: MESSAGE_DEFAULT_DURATION,
                key: typeof action.payload.content === 'string' ? action.payload.content : messageIndex,
                ...action.payload
            };
            if (state.messages.every((otherMessage) => otherMessage.key !== message.key)) {
                state.messages = [...state.messages, message];
                if (state.messages.length > MAX_VISIBLE_MESSAGES) {
                    state.messages.splice(0, 1);
                }
            }
            if (message.duration) {
                state.scheduledMessages[message.key] = { message, toSchedule: true };
            }
            return { ...state, messages: [...state.messages], scheduledMessages: { ...state.scheduledMessages }, messageIndex };

        case 'remove-message':
            const messages = state.messages.filter((message) => message.key !== action.payload);
            delete state.scheduledMessages[action.payload];
            return { ...state, messages, scheduledMessages: { ...state.scheduledMessages } };

        case 'set-message-scheduled':
            const scheduledMessage = state.scheduledMessages[action.payload];
            if (!scheduledMessage || !scheduledMessage.toSchedule) {
                return state;
            }
            scheduledMessage.toSchedule = false;
            return { ...state, scheduledMessages: { ...state.scheduledMessages, [action.payload]: scheduledMessage } };

        default:
            return state;
    }
};
