import React, { ReactElement, useMemo } from 'react';
import { Line, LineChart, XAxis, YAxis } from 'recharts';
import classNames from 'classnames';
import { defaultFetchComparableValues } from '../../../../../shared/components/statistics/statistics-change/statistics-change-service';
import { HistoryList } from '../../../../analytics/analytics-types';
import './simple-line-chart.scss';

interface SimpleLineChartProps<T = number> {
    historyList: HistoryList<T>;
    fetchComparableValues?: (value: T) => number;
}

export default function SimpleLineChart<T = number>({
    historyList,
    fetchComparableValues = defaultFetchComparableValues,
}: SimpleLineChartProps<T>): ReactElement {
    const chartList = useMemo(
        () => historyList.map(({ value, date }) => ({ value: fetchComparableValues(value), date })),
        [ fetchComparableValues, historyList ],
    );

    const minValue = useMemo(() => Math.min(...chartList.map(({ value }) => value)), [ chartList ]);

    const maxValue = useMemo(() => Math.max(...chartList.map(({ value }) => value)), [ chartList ]);

    const diff = chartList[chartList.length - 1]?.value - chartList[0]?.value;
    const valueKey: keyof HistoryList<any>[number] = 'value';
    const dateKey: keyof HistoryList<any>[number] = 'date';

    return (
        <LineChart
            className={classNames('simple-line-chart', { positive: diff >= 0 })}
            width={160}
            height={56}
            data={chartList}
        >
            <XAxis dataKey={dateKey} height={0} />
            <YAxis domain={[ minValue, maxValue ]} width={0} />
            <Line type='monotone' dataKey={valueKey} dot={false} />
        </LineChart>
    );
}
