import React from 'react';
import classNames from 'classnames';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import './availability-indicator.scss';

export interface AvailabilityIndicatorProps {
    className?: string;
    containerClassName?: string;
    status?: boolean;
}

const AvailabilityIndicator: React.FC<AvailabilityIndicatorProps> = ({ status, className, containerClassName }) => {
    const getTooltip = (): string => {
        if (status === undefined) {
            return 'Pending';
        }
        return status ? 'Fully operational' : 'RPC unavailable';
    };
    return (
        <Tooltip title={getTooltip()} placement='top'>
            <div className={classNames('indicator-click-area', containerClassName)}>
                <span
                    className={classNames(
                        'availability-indicator',
                        className,
                        { available: status === true, unavailable: status === false },
                    )}
                />
            </div>
        </Tooltip>
    );
};

export default AvailabilityIndicator;
