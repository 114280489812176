import { Buffer } from 'buffer';
import { Sha512 } from 'cosmjs/packages/crypto';
import { toHex } from 'cosmjs/packages/encoding';
import { DirectSecp256k1HdWallet, OfflineSigner } from 'cosmjs/packages/proto-signing';
import { getAuth, signInWithPopup, GoogleAuthProvider, TwitterAuthProvider, OAuthProvider } from 'firebase/auth';
import { onboarding } from '@dydxprotocol/v4-client-js';
import { generatePinCode } from '../../../../shared/utils/text-utils';
import { Wallet, WalletType } from '../../wallet-types';
import { Network } from '../../../network/network-types';
import { WalletError } from '../../wallet-error';
import { convertToHexAddress } from '../../wallet-service';
import { MetaMaskWallet } from '../meta-mask-wallet';
import { ACCOUNT_SIGNATURE_KEY, PortalWalletInitProps, PortalWalletSourceType } from './types';

export class PortalWallet implements Wallet {
    private sourceType?: PortalWalletSourceType;
    private sourceWallet?: Wallet;
    private signer?: DirectSecp256k1HdWallet;

    public clear(): void {
        localStorage.removeItem(ACCOUNT_SIGNATURE_KEY);
        this.sourceWallet?.clear();
    }

    public getMnemonic(): string | undefined {
        return this.signer?.mnemonic;
    }

    public getWalletType(): WalletType {
        return 'PortalWallet';
    }

    public getSourceType(): PortalWalletSourceType | undefined {
        return this.sourceType;
    }

    public async getAddress(network: Network): Promise<{ address?: string, hexAddress?: string }> {
        const address = (await this.signer?.getAccounts())?.[0].address;
        if (!address) {
            throw new WalletError('KEY_NOT_FOUND', this.getWalletType(), network);
        }
        const hexAddress = convertToHexAddress(address);
        return { address, hexAddress };
    }

    public async getOfflineSigner(): Promise<OfflineSigner> {
        if (!this.signer) {
            throw new WalletError('NO_OFFLINE_SIGNER', this.getWalletType());
        }
        return this.signer;
    }

    public setAccountChangesListener(listener: () => void): void {
        return this.sourceWallet?.setAccountChangesListener(listener);
    }

    public async validateWalletInstalled(): Promise<void> {
        return this.sourceWallet?.validateWalletInstalled();
    }

    public async initFromSource(initProps: PortalWalletInitProps): Promise<{ success: boolean, generatedPinCode?: string }> {
        this.sourceType = initProps.sourceType;
        switch (initProps.sourceType) {
            case 'MetaMask':
                this.sourceWallet = new MetaMaskWallet();
        }
        const result = await this.fetchMnemonic(initProps);
        const { mnemonic, generatedPinCode } = result || {};
        if (mnemonic) {
            this.signer = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic, { prefix: initProps.network?.bech32Prefix });
        }
        return { success: Boolean(this.signer), generatedPinCode: generatedPinCode };
    }

    private async fetchMnemonic(initProps: PortalWalletInitProps): Promise<{ mnemonic: string, privateKey?: string, generatedPinCode?: string } | undefined> {
        let signature = localStorage.getItem(ACCOUNT_SIGNATURE_KEY);
        let generatedPinCode;
        if (!signature && !initProps.cacheOnly) {
            switch (this.sourceType) {
                case 'MetaMask':
                    signature = await this.fetchSignatureFromMetaMask();
                    break;
                case 'Google':
                    const googleResult = await this.fetchSignatureFromGoogle(initProps.pinCode);
                    signature = googleResult.signature;
                    generatedPinCode = googleResult.generatedPinCode;
                    break;
                case 'Twitter X':
                    const xResult = await this.fetchSignatureFromX(initProps.pinCode);
                    signature = xResult.signature;
                    generatedPinCode = xResult.generatedPinCode;
                    break;
                case 'Apple':
                    const appleResult = await this.fetchSignatureFromApple(initProps.pinCode);
                    signature = appleResult.signature;
                    generatedPinCode = appleResult.generatedPinCode;
            }
            if (initProps.saveToCache && signature) {
                localStorage.setItem(ACCOUNT_SIGNATURE_KEY, signature);
            }
        }
        if (signature) {
            const { mnemonic, privateKey } = onboarding.deriveHDKeyFromEthereumSignature(signature);
            return { mnemonic, privateKey: privateKey ? toHex(privateKey) : undefined, generatedPinCode };
        }
    }

    private async fetchSignatureFromMetaMask(): Promise<string> {
        const metamaskWallet = this.sourceWallet as MetaMaskWallet;
        const address = (await metamaskWallet.getAccounts())[0];
        const provider = await metamaskWallet.getProvider();
        return provider.request({ method: 'personal_sign', params: [ `Generate a Dymension Portal key from ${address}`, address ] });
    }

    private async fetchSignatureFromGoogle(pinCode?: string): Promise<{ signature: string, generatedPinCode: string }> {
        const result = await signInWithPopup(getAuth(), new GoogleAuthProvider());
        let generatedPinCode = '';
        if (!pinCode) {
            pinCode = generatedPinCode = generatePinCode();
        }
        return { signature: toHex(new Sha512(Buffer.from(pinCode + result.user.email, 'utf-8')).digest()) + 'FF', generatedPinCode };
    }

    private async fetchSignatureFromApple(pinCode?: string): Promise<{ signature: string, generatedPinCode: string }> {
        const result = await signInWithPopup(getAuth(), new OAuthProvider('apple.com'));
        let generatedPinCode = '';
        if (!pinCode) {
            pinCode = generatedPinCode = generatePinCode();
        }
        return { signature: toHex(new Sha512(Buffer.from(pinCode + result.user.email, 'utf-8')).digest()) + 'FF', generatedPinCode };
    }

    private async fetchSignatureFromX(pinCode?: string): Promise<{ signature: string, generatedPinCode: string }> {
        const result = await signInWithPopup(getAuth(), new TwitterAuthProvider());
        let generatedPinCode = '';
        if (!pinCode) {
            pinCode = generatedPinCode = generatePinCode();
        }
        return { signature: toHex(new Sha512(Buffer.from(pinCode + result.user.email, 'utf-8')).digest()) + 'FF', generatedPinCode };
    }
}

