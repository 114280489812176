import { Plan, SoftwareUpgradeProposal } from 'cosmjs-types/cosmos/upgrade/v1beta1/upgrade';
import Long from 'long';
import React, { useEffect, useMemo } from 'react';
import Input from '../../../../shared/components/form-controls/input/input';
import './proposal-type.scss';

interface SoftwareUpgradeProps {
    content?: SoftwareUpgradeProposal;
    onInit?: (data: SoftwareUpgradeProposal) => void;
    onChange?: (data: Partial<SoftwareUpgradeProposal>, isValid: boolean) => void;
}

const SoftwareUpgrade: React.FC<SoftwareUpgradeProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(() => Boolean(content?.plan?.height && content.plan.info && content.plan.name), [ content ]);

    useEffect(() => onInit?.({
        title: '',
        description: '',
        plan: {
            info: '',
            name: '',
            height: undefined as any,
        },
    }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Plan Name</label>
            <Input
                value={content?.plan?.name}
                onValueChange={(value) => onChange?.({ plan: { ...content?.plan, name: value.toString() || '' } as Plan }, isValid)}
            />

            <label className='proposal-control-label'>Plan Info</label>
            <Input
                value={content?.plan?.info}
                onValueChange={(value) => onChange?.({ plan: { ...content?.plan, info: value.toString() || '' } as Plan }, isValid)}
            />

            <label className='proposal-control-label'>Height</label>
            <Input
                type='number'
                value={content?.plan?.height?.toNumber?.()}
                onValueChange={(value) =>
                    onChange?.({ plan: { ...content?.plan, height: !value ? value : Long.fromValue(value) } as Plan }, isValid)}
            />
        </div>
    );
};

export default SoftwareUpgrade;
