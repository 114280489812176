import React, { useMemo } from 'react';
import Property from '../../shared/components/property/property';
import Spinner from '../../shared/components/spinner/spinner';
import StatisticsCards from '../../shared/components/statistics-cards/statistics-cards';
import { formatNumber } from '../../shared/utils/number-utils';
import { useGovernance } from './governance-context';

const GovernanceAnalytics: React.FC = () => {
    const { governanceState } = useGovernance();

    const votingPeriodCount = useMemo(
        () => governanceState.proposals?.filter((proposal) =>
            proposal.status === 'Voting Period' || proposal.status === 'Deposit Period')?.length || 0,
        [ governanceState.proposals ],
    );

    const passedCount = useMemo(
        () => governanceState.proposals?.filter((proposal) => proposal.status === 'Passed')?.length || 0,
        [ governanceState.proposals ],
    );

    const rejectedCount = useMemo(
        () => governanceState.proposals?.filter((proposal) => proposal.status === 'Rejected')?.length || 0,
        [ governanceState.proposals ],
    );

    return (
        <StatisticsCards>
            <Property label='Total'>
                {governanceState.proposalsLoading ? <Spinner /> : formatNumber(governanceState.proposals?.length || 0)}
            </Property>

            <Property label='Live'>
                {governanceState.proposalsLoading ? <Spinner /> : formatNumber(votingPeriodCount)}
            </Property>

            <Property label='Passed'>
                {governanceState.proposalsLoading ? <Spinner /> : formatNumber(passedCount)}
            </Property>

            <Property label='Rejected'>
                {governanceState.proposalsLoading ? <Spinner /> : formatNumber(rejectedCount)}
            </Property>
        </StatisticsCards>
    );
};

export default GovernanceAnalytics;


