import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';
import Spinner from '../../../shared/components/spinner/spinner';
import { useNetwork } from '../../network/network-context';
import TopBar from '../../top-bar/top-bar';
import Snackbar from '../../../shared/components/snackbar/snackbar';
import { useWallet } from '../../wallet/wallet-context';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import Footer from '../../footer/footer';
import { WalletInfoMap } from '../../wallet/wallet-types';
import './app-layout.scss';

function AppLayout(): JSX.Element {
    const { showMessage, showErrorMessage, showWarningMessage } = useSnackbar();
    const { walletError } = useWallet();
    const { hubNetwork, loading } = useNetwork();


    // todo: handle in some global error handler
    // case 'UNSUPPORTED_CURRENCY':
    //     message.content = `Unsupported currency${networkSuffix}`;
    //     break;

    useEffect(() => {
        if (!walletError) {
            return;
        }
        const walletType = walletError.walletType;
        switch (walletError.code) {
            case 'FAILED_INTEGRATE_CHAIN':
                showErrorMessage(`Failed integrate chain with ${walletType || 'the wallet'}`);
                break;
            case 'FAILED_INIT_WALLET':
                showErrorMessage(`Failed init ${walletType || 'the wallet'}`);
                break;
            case 'KEY_NOT_FOUND':
                showWarningMessage(`Create or import an account in your ${walletType || 'wallet'}`);
                break;
            case 'NO_OFFLINE_SIGNER':
                showErrorMessage(`${walletType || 'The wallet'} account not detected`);
                break;
            case 'UNSUPPORTED_WALLET':
                showErrorMessage(`${walletType || 'The selected wallet is'} not supported`);
                break;
            case 'UPGRADE_WALLET':
                showWarningMessage(`Please use the recent version of ${walletType || 'the wallet'} extension`);
                break;
            case 'WALLET_NOT_CONNECTED':
                showWarningMessage(`No wallet connected for ${walletError.network?.chainName || 'the designated network'}`);
                break;
            case 'INSTALL_WALLET':
                const installUrl = walletType && WalletInfoMap[walletType].installUrl;
                if (walletType === 'MetaMask' && isMobile) {
                    showWarningMessage({ content: 'Open your MetaMask app and use the in-app browser' });
                } else {
                    showWarningMessage({
                        content: `${walletType || 'The'} wallet not installed`,
                        action: !installUrl ? undefined :
                            { label: 'Install', close: true, callback: () => window.open(installUrl, '_blank') },
                    });
                }
                break;
            case 'UNSUPPORTED_NETWORK':
                showErrorMessage(`Can't link ${walletType || 'the'} wallet to the designated network`);
                break;
            case 'ACCOUNTS_ALREADY_REQUESTED':
                showErrorMessage(`Connect to ${walletType || 'the wallet'}`);
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage(`Connecting to ${walletType || 'the wallet'} rejected by the user`);
                break;
            case 'SWITCH_NETWORK':
                showErrorMessage(
                    `Switch to ${walletError.network?.chainName || 'the designated network'} in ${walletType || 'the wallet'}`,
                );
                break;
            case 'UNSUPPORTED_MESSAGE':
                showErrorMessage(`The message is not supported by ${walletType || 'the wallet'}`);
                break;
            default:
                showWarningMessage(`${walletType || 'Wallet'} connection failed, please try again later`);
        }
    }, [ walletError, showMessage, showErrorMessage, showWarningMessage ]);

    return (
        <div className='app-layout'>
            {process.env.REACT_APP_ENV !== 'mainnet' && process.env.REACT_APP_ENV !== 'mainnet-staging' &&
                <div className='test-env'>{hubNetwork?.chainId && <>Dymension&nbsp;<b>{hubNetwork.chainId}</b>&nbsp;Testnet</>}</div>}
            <TopBar />
            <main>
                {!loading ? <Outlet /> : <Spinner className='app-loader' />}
            </main>
            <Footer />
            <Snackbar />
        </div>
    );
}

export default AppLayout;
