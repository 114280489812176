import React, { ReactElement, useState } from 'react';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icons/discord.svg';
import { ReactComponent as DymensionFullLogo } from '../../assets/logos/dymension-full-logo.svg';
import { ReactComponent as MediumIcon } from '../../assets/icons/medium.svg';
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg';
import { ReactComponent as MoreMenuIcon } from '../../assets/icons/menu-more.svg';
import NavBar, { NavBarItem } from '../../shared/components/nav-bar/nav-bar';
import Icon from '../../shared/components/icon/icon';
import classNames from 'classnames';
import { useNetwork } from '../network/network-context';
import './footer.scss';

const Footer: React.FC = () => {
    const { hubNetwork } = useNetwork();
    const [ moreMenuOpen, setMoreMenuOpen ] = useState(false);

    const renderSocialLinks = (): ReactElement => {
        return <>
            <a className='social-link' href='https://medium.com/@dymension' target='_blank' rel='noreferrer'>
                <Icon><MediumIcon /></Icon>
            </a>
            <a className='social-link' href='https://twitter.com/dymension' target='_blank' rel='noreferrer'>
                <Icon><TwitterIcon /></Icon>
            </a>
            <a className='social-link' href='https://discord.gg/dymension' target='_blank' rel='noreferrer'>
                <Icon><DiscordIcon /></Icon>
            </a>
            <a className='social-link' href='https://github.com/dymensionxyz' target='_blank' rel='noreferrer'>
                <Icon><GithubIcon /></Icon>
            </a>
        </>;
    };

    return (
        <div className='footer'>
            <a href='/'>
                <Icon className='full-logo'><DymensionFullLogo /></Icon>
            </a>

            <div className='social-links'>
                {renderSocialLinks()}
            </div>

            <NavBar className='footer-nav-bar'>
                <NavBarItem label='RollApps' url='/rollapps' className='footer-nav-item' />
                <NavBarItem label='Dymension' url='/dymension' preventRedirect className='footer-nav-item'>
                    <NavBarItem label='Metrics' url='/metrics' />
                    <NavBarItem label='Staking' url='/staking' />
                    <NavBarItem label='Governance' url='/governance' />
                </NavBarItem>
                <NavBarItem label='Transfer' url='/ibc' preventRedirect className='footer-nav-item'>
                    <NavBarItem label='Transfer' url='/transfer' />
                    <NavBarItem label='History' url='/status' />
                </NavBarItem>
                <NavBarItem label='Swap' url='/amm' preventRedirect className='footer-nav-item'>
                    <NavBarItem label='Swap' url='/swap' />
                    <NavBarItem label='Pools' url='/pools' />
                    <NavBarItem label='Assets' url='/assets' />
                </NavBarItem>
                <NavBarItem
                    label={<MoreMenuIcon />}
                    hideMoreMenuIcon
                    onClick={() => setMoreMenuOpen(!moreMenuOpen)}
                />
            </NavBar>

            <div className={classNames('section nav-bar-more-menu', { open: moreMenuOpen })}>
                <NavBar className='more-menu-nav-items'>
                    <NavBarItem label='Documentation' url='https://docs.dymension.xyz' external className='more-menu-nav-item' />
                    <NavBarItem label='Forum' url='https://forum.dymension.xyz' external className='more-menu-nav-item' />
                    <NavBarItem
                        label='Explorer'
                        url={hubNetwork?.explorerUrl || ''}
                        disabled={!hubNetwork?.explorerUrl}
                        external
                        className='more-menu-nav-item'
                    />
                    <NavBarItem label='Blog' url='https://medium.com/@dymension' external className='more-menu-nav-item' />
                </NavBar>

                <div className='more-menu-social-links'>
                    {renderSocialLinks()}
                </div>
            </div>
        </div>
    );
};

export default Footer;
