import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../../shared/components/button/button';
import Link from '../../../../shared/components/link/link';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import PathNav, { PathNavItem } from '../../../path-nav/path-nav';
import { useAmm } from '../../amm-context';
import IncentiveBadge from '../../incentive-badge/incentive-badge';
import { ReactComponent as SwapIcon } from '../../../../assets/icons/swap.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet.svg';
import PoolList from '../../pools/pool-list/pool-list';
import TokensSwapDialog from '../../tokens-swap/tokens-swap-dialog/tokens-swap-dialog';
import ImportTokenDialog from '../import-token-dialog/import-token-dialog';
import { useAssetDashboard } from './asset-dashboard-context';
import AssetInfo from './asset-info/asset-info';
import AssetStatistics from './asset-statistics/asset-statistics';
import './asset-dashboard.scss';

const AssetDashboard: React.FC = () => {
    const { ammState } = useAmm();
    const { asset } = useAssetDashboard();
    const [ importTokenDialogOpen, setImportTokenDialogOpen ] = useState(false);
    const [ tokenSwapDialogOpen, setTokenSwapDialogOpen ] = useState(false);

    return <>
        <PathNav>
            <PathNavItem label='Liquidity' />
            <PathNavItem label='Assets' url='/amm/assets' />
            <PathNavItem label={asset.currency.displayDenom} />
        </PathNav>

        <div className='asset-dashboard'>
            <div className='asset-header'>
                <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, asset.network)} alt='asset logo' />
                <h3 className='asset-name'>
                    <span className='asset-display-name'>
                        {asset.currency.displayDenom}
                        {ammState.incentives?.[asset.key]?.some((incentive) => incentive.coins.length) ?
                            <IncentiveBadge denom={asset.key} infoPlacement='bottom' /> : null}
                    </span>

                    <Link
                        className='asset-denom'
                        url={asset.network.type === 'Hub' ? '/dymension/metrics' : `/rollapp/${asset.network.chainId}`}
                        disabled={asset.network.type !== 'Hub' && asset.network.type !== 'RollApp'}
                    >
                        {asset.network.chainName}
                    </Link>
                </h3>

                <div className='asset-dashboard-actions'>
                    <Button
                        className='asset-dashboard-action'
                        buttonType='secondary'
                        size='small'
                        onClick={() => setImportTokenDialogOpen(true)}
                    >
                        Add to Wallet&nbsp;<WalletIcon />
                    </Button>

                    <Button
                        className='asset-dashboard-action'
                        buttonType='primary'
                        size='small'
                        onClick={() => setTokenSwapDialogOpen(true)}
                        iconColorMode='stroke'
                    >
                        Swap&nbsp;<SwapIcon />
                    </Button>
                </div>
            </div>

            <AssetStatistics className='asset-statistics' />

            <div className='asset-data-container'>
                <div className='asset-info-section'>
                    <h5 className='subtitle'>Info</h5>
                    <AssetInfo />
                </div>

                <div className='asset-pools-section'>
                    <h5 className='subtitle'>Pools</h5>
                    <PoolList asset={asset} />
                </div>
            </div>

            {tokenSwapDialogOpen && (
                <TokensSwapDialog fromCoins={asset} onRequestClose={() => setTokenSwapDialogOpen(false)} />
            )}

            {importTokenDialogOpen && <ImportTokenDialog token={asset} onRequestClose={() => setImportTokenDialogOpen(false)} />}
        </div>
    </>;
};

export default AssetDashboard;
