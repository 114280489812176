import classNames from 'classnames';
import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { useNetworkAddress } from '../../../modules/account/use-network-address';
import { getShortenedAddress } from '../../utils/text-utils';
import Icon from '../icon/icon';
import Menu, { MenuAction } from '../menu/menu';
import Tooltip from '../tooltip/tooltip';
import './addresses.scss';

interface AddressesProps {
    addresses: string[];
    canCopy?: boolean;
    className?: string;
    size?: 'small' | 'medium';
    shortAddress?: boolean;
}

const Addresses: React.FC<AddressesProps> = ({ addresses, canCopy, className, size = 'medium', shortAddress = true }) => {
    const { copyAddress } = useNetworkAddress();
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);

    const addressContainer = (
        <div
            className={classNames('address-container', className, size, { canCopy, isMenuOpen })}
            onClick={(event) => {
                if (canCopy) {
                    event.stopPropagation();
                    copyAddress(addresses[0], true);
                }
            }}
        >
            {shortAddress ? getShortenedAddress(addresses[0] || '') : addresses[0]}
        </div>
    );
    if (!canCopy) {
        return addressContainer;
    }
    if (addresses.length === 1) {
        return <Tooltip title='Copy address' clickTitle='Address copied!'>{addressContainer}</Tooltip>;
    }
    return (
        <Menu overlayAlign='center' triggerOnHover trigger={addressContainer} onOpenChange={setIsMenuOpen}>
            {addresses.map((address) => (
                <MenuAction onClick={() => copyAddress(address, true)} key={address} size='small'>
                    <Icon className='menu-address-copy-icon'><CopyIcon /></Icon>&nbsp;&nbsp;
                    {shortAddress ? getShortenedAddress(address) : address}
                </MenuAction>
            ))}
        </Menu>
    );
};

export default Addresses;
