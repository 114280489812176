import classNames from 'classnames';
import React, { useCallback } from 'react';
import Button from '../../../../shared/components/button/button';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import './pin-code-dialog.scss';

interface PinCodeDialogProps extends DialogProps {
    pinCode: string;
}

const PinCodeDialog: React.FC<PinCodeDialogProps> = ({ pinCode, className, ...otherDialogProps }) => {
    const { showSuccessMessage, showErrorMessage } = useSnackbar();

    const copyPinCode = useCallback((): void => {
        navigator.clipboard.writeText(pinCode)
            .then(() => showSuccessMessage({ content: 'Copied PIN to clipboard', key: pinCode, duration: 3000, closeAction: false }))
            .catch((error) => {
                console.error(error);
                showErrorMessage({ content: 'Copied PIN to clipboard failed, please try again later', duration: 5000 });
            });
    }, [ pinCode, showErrorMessage, showSuccessMessage ]);

    return (
        <Dialog className={classNames('pin-code-dialog', className)} {...otherDialogProps}>
            <DialogTitle>Secure this PIN</DialogTitle>

            <DialogContent className='dialog-content'>
                <p>Don't lose this PIN. You'll need it to access your wallet across devices.</p>

                <div className='pin-code'>
                    {pinCode.split('').map((char, chartIndex) => <span key={chartIndex} className='pin-character'>{char}</span>)}
                </div>

                <Button buttonType='secondary' onClick={copyPinCode}>Copy to Clipboard&nbsp;&nbsp;<CopyIcon /></Button>

                <p className='description'>
                    A digital wallet is being created on this device for your account. This PIN allows you to access it across devices.
                </p>
            </DialogContent>

            <DialogAction className='complete-action' primary onClick={otherDialogProps.onRequestClose}>Complete</DialogAction>
        </Dialog>
    );
};

export default PinCodeDialog;
