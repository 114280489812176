import classNames from 'classnames';
import React from 'react';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import AmountTx from '../../../tx/amount-tx/amount-tx';
import { useProposal } from '../proposal-context';
import './deposit-dialog.scss';

const DepositDialog: React.FC<DialogProps> = ({ className, ...otherDialogOptions }) => {
    const {
        proposalState,
        depositTxState,
        depositAmountTxState,
        networkState,
        depositAvailableBalances,
        setDeposit,
        broadcastDepositTx,
    } = useProposal();

    return (
        <Dialog className={classNames('deposit-dialog', className)} closable {...otherDialogOptions}>
            <DialogTitle>Deposit for proposal #{proposalState.proposal?.id}</DialogTitle>
            <DialogContent>
                <p className='deposit-proposal-title'>{proposalState.proposal?.title}</p>

                <AmountTx
                    txState={depositTxState}
                    controlSize='large'
                    amountTxState={depositAmountTxState}
                    networkState={networkState}
                    availableBalances={depositAvailableBalances}
                    onCoinsChange={setDeposit}
                    submitButtonContainer={(
                        <Button
                            disabled={!depositAmountTxState.coins?.amount || depositTxState.broadcasting}
                            loading={depositTxState.broadcasting}
                            onClick={() => broadcastDepositTx()}
                        >
                            Confirm
                        </Button>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default DepositDialog;
