import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import ControlsComposer from '../../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../../shared/components/form-controls/input/input';
import Select from '../../../../shared/components/form-controls/select/select';
import { Option } from '../../../../shared/components/form-controls/options-modal/options-modal';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import { VALIDATOR_STATUSES, ValidatorStatus } from '../validator-types';
import Spinner from '../../../../shared/components/spinner/spinner';
import ValidatorList, { ValidatorListHeader, ValidatorListProps } from '../validator-list/validator-list';
import { ValidatorListData } from '../validator-list/use-validator-list';
import './total-validators.scss';
import { useStaking } from '../../staking-context';

interface TotalValidatorsProps extends ValidatorListProps {
    validatorsData: ValidatorListData;
    simpleMode?: boolean;
    header?: ReactNode;
    headerClassName?: string;
}

export function TotalValidators({
    validatorsData,
    simpleMode,
    header,
    headerClassName,
    ...otherListProps
}: TotalValidatorsProps): JSX.Element {
    const { network } = useStaking();
    const { state, setOrder, setSearchText, setStatus } = validatorsData;
    const validatorsName = network.type === 'RollApp' ? 'Governors' : 'Validators';

    const totalValidatorsCount = useMemo(() => Object.values(state.totalValidatorsMap || {})
        .reduce((current, validators) => current + (validators?.length || 0), 0), [ state.totalValidatorsMap ]);

    return <>
        <ValidatorListHeader className={classNames('total validators-list-header', headerClassName, { 'simple-mode': simpleMode })}>
            {header ?? `All ${validatorsName}`}
            {network.type === 'RollApp' && (
                <InfoIndicator>
                    RollApps separate block production from governance. Sequencers produce blocks while Governors are those in charge for
                    voting w/ delegated stake and distributing revenue or new tokens minted.
                </InfoIndicator>
            )}

            <span className='space' />

            <ControlsComposer className='validators-filters'>
                <Input
                    controlSize='medium'
                    type='search'
                    value={state.searchText}
                    onValueChange={setSearchText}
                    placeholder={`Search ${validatorsName.toLowerCase()}...`}
                />
                <Select
                    className='validators-status-select'
                    value={state.filterStatus}
                    optionsOverlayAlign='right'
                    onSelect={(type) => setStatus(type as ValidatorStatus)}
                    controlSize='medium'
                >
                    {VALIDATOR_STATUSES.map((status) => (
                        <Option key={status} value={status}>
                            {status}
                            {!state.loading ? ` (${state.totalValidatorsMap?.[status].length || 0})` :
                                <Spinner size='small' className='status-loader' />}
                        </Option>
                    ))}
                    <Option key='all' value=''>
                        All {!state.loading ? ` (${totalValidatorsCount})` : <Spinner size='small' className='status-loader' />}
                    </Option>
                </Select>
            </ControlsComposer>
        </ValidatorListHeader>

        <ValidatorList
            state={state}
            onSort={setOrder}
            indexColumn={!simpleMode}
            menuVisibility={!simpleMode}
            fields={simpleMode ? [ 'Name', 'VotingPower', 'Commission' ] : undefined}
            {...otherListProps}
        />
    </>;
}

