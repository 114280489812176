import { WalletType } from '../wallet-types';
import { EthereumProvider, EthereumWallet } from './ethereum-wallet';

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        rabby?: Rabby;
    }
}

export interface Rabby extends EthereumProvider {
    isRabby?: boolean;
}

export class RabbyWallet extends EthereumWallet {
    constructor() {
        super('rabbywallet#initialized');
    }

    public getWalletType(): WalletType {
        return 'Rabby';
    }

    public getCurrentProvider(): EthereumProvider | undefined {
        return window.rabby?.isRabby ? window.rabby : undefined;
    }
}

