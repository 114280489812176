import React, { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../shared/components/button/button';
import { getCurrencyLogoPath } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { ReactComponent as SwapIcon } from '../../../assets/icons/swap.svg';
import { ReactComponent as ThunderIcon } from '../../../assets/icons/thunder.svg';
import { useNetwork } from '../../network/network-context';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import AddIncentivesDialog from '../add-incentives-dialog/add-incentives-dialog';
import { useAmm } from '../amm-context';
import IncentiveBadge from '../incentive-badge/incentive-badge';
import TokensSwapDialog from '../tokens-swap/tokens-swap-dialog/tokens-swap-dialog';
import { usePoolDashboard } from './pool-dashboard-context';
import PoolPositions from './pool-positions/pool-positions';
import PoolStatistics from './pool-statistics/pool-statistics';
import './pool-dashboard.scss';

const PoolDashboard: React.FC = () => {
    const { getNetwork, hubNetwork } = useNetwork();
    const { ammState } = useAmm();
    const { pool } = usePoolDashboard();
    const [ tokenSwapDialogOpen, setTokenSwapDialogOpen ] = useState(false);
    const [ addIncentivesDialogOpen, setAddIncentivesDialogOpen ] = useState(false);

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = asset.ibc ? getNetwork(asset.ibc.networkId) : hubNetwork;

        return currencyNetwork &&
            <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='asset logo' />;
    };

    const renderAssetName = (asset: CoinsAmount): ReactElement => {
        const assetKey = asset.ibc?.representation || asset.currency.baseDenom;
        return <NavLink className='denom-name' to={`/amm/asset/${encodeURIComponent(assetKey)}`}>{asset.currency.displayDenom}</NavLink>;
    };

    return <>
        <PathNav>
            <PathNavItem label='Liquidity' />
            <PathNavItem label='Pools' url='/amm/pools' />
            <PathNavItem label={`Pool #${pool.id.toString().padStart(3, '0')}`} />
        </PathNav>

        <div className='pool-header'>
            {renderAssetLogo(pool.assets[0])}
            {renderAssetLogo(pool.assets[1])}
            <h3 className='pool-name'>
                <span className='pool-asset-names'>
                    {renderAssetName(pool.assets[0])}&nbsp;/&nbsp;{renderAssetName(pool.assets[1])}
                    {ammState.incentives?.[pool.lpTokenDenom]?.some((incentive) => incentive.coins.length) ?
                        <IncentiveBadge denom={pool.lpTokenDenom} infoPlacement='bottom' /> :
                        null}
                </span>
                <span className='pool-number'>Pool #{pool.id.toString().padStart(3, '0')}</span>
            </h3>

            <div className='pool-dashboard-actions'>
                <Button
                    className='pool-dashboard-action'
                    buttonType='secondary'
                    size='small'
                    onClick={() => setAddIncentivesDialogOpen(true)}
                >
                    Add Incentives&nbsp;<ThunderIcon />
                </Button>

                <Button
                    className='pool-dashboard-action'
                    buttonType='primary'
                    size='small'
                    onClick={() => setTokenSwapDialogOpen(true)}
                    iconColorMode='stroke'
                >
                    Trade Pair&nbsp;<SwapIcon />
                </Button>
            </div>
        </div>

        <PoolStatistics className='pool-statistics' />

        <PoolPositions pool={pool} />

        {tokenSwapDialogOpen && (
            <TokensSwapDialog
                fromCoins={pool.assets[0]}
                toCoins={pool.assets[1]}
                onRequestClose={() => setTokenSwapDialogOpen(false)}
            />
        )}

        {addIncentivesDialogOpen && (
            <AddIncentivesDialog
                pool={pool}
                onRequestClose={() => setAddIncentivesDialogOpen(false)}
            />
        )}
    </>;
};

export default PoolDashboard;
