import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import Tooltip, { TooltipPlacement } from '../tooltip/tooltip';
import './badge.scss';

export interface BadgeProps {
    label: ReactNode;
    icon?: ReactElement;
    color?: number[];
    size?: 'small' | 'medium' | 'large';
    rounded?: boolean;
    info?: ReactNode;
    tooltipClassName?: string;
    noWrap?: boolean;
    disabled?: boolean;
    infoPlacement?: TooltipPlacement;
    className?: string;
    onClick?: () => void;
}

const Badge: React.FC<BadgeProps> = ({
    label,
    icon,
    info,
    disabled,
    noWrap,
    infoPlacement,
    onClick,
    color,
    rounded,
    tooltipClassName,
    className,
    size = 'medium',
}) => {
    const badgeContent = (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames('badge', size, className, { rounded, 'no-wrap': noWrap })}
            style={(color ? { '--badge-color': color.join(',') } : {}) as CSSProperties}
        >
            {icon} {label}
        </button>
    );

    return info ? <Tooltip className={tooltipClassName} title={info} placement={infoPlacement}>{badgeContent}</Tooltip> : badgeContent;
};

export default Badge;
