import { CoinsAmount } from '../../currency/currency-types';
import { Network } from '../../network/network-types';

export type TransferType = 'In' | 'Out';

export const IBC_TRANSFER_STATUSES = [
    'Initiated',
    'Pending',
    'Success',
    'Sent',
    'Failure',
    'Refunding',
    'Finalizing',
    'EibcPending',
] as const;
export type IbcTransferStatus = typeof IBC_TRANSFER_STATUSES[number];

export const IBC_BASE_STATUSES = [ 'Pending', 'Success', 'Failed', 'Refunding' ] as const;
export type IbcBaseStatus = typeof IBC_BASE_STATUSES[number];

export const IBC_TRANSFER_FIELDS = [
    'id',
    'eibcOrderId',
    'hash',
    'denom',
    'blockHeight',
    'amount',
    'sender',
    'receiver',
    'hexSender',
    'hexReceiver',
    'sourceChannel',
    'destinationChannel',
    'eibcFee',
    'fulfillerAddress',
    'type',
    'status',
    'time',
];

export interface IbcTransferDetails {
    id: string;
    hash: string;
    denom: string;
    blockHeight: number;
    amount: number;
    sender: string;
    receiver: string;
    hexSender?: string;
    hexReceiver?: string;
    sourceChannel: string;
    destinationChannel: string;
    time: number;
    eibcOrderId?: string;
    eibcFee?: string;
    fulfillerAddress?: string;
    type: TransferType;
    status: IbcTransferStatus;
    baseStatus: IbcBaseStatus;
    coins?: CoinsAmount;
    eibcFeeCoins?: CoinsAmount;
    sourceNetwork?: Network;
    destinationNetwork?: Network;
    searchable?: string[];
}

export type IbcTransferDetailsResponse = {
    data: {
        ibcTransferDetails: {
            totalCount: number,
            nodes: { [key in (keyof IbcTransferDetails)]: string }[]
        }
    }
}

export type IbcSingleTransferDetailsResponse = {
    data: {
        ibcTransferDetail: { [key in (keyof IbcTransferDetails)]: string }[]
    }
}

