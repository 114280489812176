import React from 'react';
import { AnalyticsState } from '../../analytics/analytics-state';
import { useNetwork } from '../../network/network-context';
import AreaChart from '../../network/statistics/charts/area-chart/area-chart';
import { HubAnalytics } from './hub-statistics-types';

interface TokenBurnsChartProps {
    analyticsState: AnalyticsState<HubAnalytics>;
    className?: string;
}

export const TokenBurnsChart: React.FC<TokenBurnsChartProps> = ({ analyticsState, className }) => {
    const { hubCurrency } = useNetwork();

    return (
        <AreaChart
            label={`${hubCurrency?.displayDenom} Burn`}
            valueLabel='Amount'
            className={className}
            compareDiffs
            currency={hubCurrency}
            loading={analyticsState.loading}
            data={analyticsState.analytics?.tokenBurns}
        />
    );
};

export default TokenBurnsChart;
