import { Children, isValidElement, JSXElementConstructor, ReactElement, ReactNode } from 'react';

const getComponentName = <P>(component: JSXElementConstructor<P> | string): string => {
    return typeof component === 'string' ? component : (component.name || (component as any)['render']?.name);
};

export const validateChildren = <P>(
    children: ReactNode,
    ...optionalComponents: JSXElementConstructor<P>[]
): children is (ReactElement<P> | ReactElement<P>[]) => {
    const optionalChildNames = optionalComponents.map(getComponentName);
    Children.forEach(children, (child) => {
        if (child && (!isValidElement(child) || !optionalChildNames.includes(getComponentName(child.type)))) {
            throw new Error(
                `Children should be ${optionalChildNames.length > 1 ? 'one the types:' : 'of type'} '${optionalChildNames.join(`', '`)}'.`);
        }
    });
    return true;
};

export const validateAndGetChildrenAsArray = <P>(
    children: ReactNode,
    ...optionalComponents: JSXElementConstructor<P>[]
): ReactElement<P>[] => {
    if (validateChildren(children, ...optionalComponents)) {
        return Children.toArray(children).filter(Boolean) as ReactElement<P>[];
    }
    return [];
};

export const getChildrenByTypes = <P>(children: ReactNode, ...optionalComponents: JSXElementConstructor<P>[]): ReactElement<P>[] => {
    const optionalChildNames = optionalComponents.map((component) => component.name);
    const filteredChildren = Children.toArray(children).filter((child) =>
        child && isValidElement(child) && optionalChildNames.includes(typeof child.type === 'string' ? child.type : child.type?.name));
    return filteredChildren as ReactElement<P>[];
};
