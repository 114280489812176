import React, { forwardRef, InputHTMLAttributes, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import useWindowSize from '../../../hooks/use-window-size';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Icon from '../../icon/icon';
import Button from '../../button/button';
import { MenuRefProps } from '../../menu/menu';
import OptionsModal, { Option } from '../options-modal/options-modal';
import './input.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    showNumberArrows?: boolean;
    onValueChange?: (value: string, previousValue: string) => string | void | undefined;
    onTypeFinish?: (value: string | ReadonlyArray<string> | number | undefined, validity?: ValidityState) => void;
    searchFilterPredicate?: (searchText: string, value: string | number) => boolean;
    emptySearchResultsLabel?: ReactNode;
    controlSize?: 'medium' | 'large';
    error?: boolean;
    onSuggestionSelect?: (value: string | number) => void;
    suffix?: ReactNode;
    className?: string;
    children?: ReactNode;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
    controlSize,
    showNumberArrows,
    type,
    value,
    onValueChange,
    onTypeFinish,
    error,
    className,
    suffix,
    onSuggestionSelect,
    searchFilterPredicate,
    emptySearchResultsLabel,
    disabled,
    children,
    ...htmlInputProps
}, forwardedRef) => {
    const { isMobile } = useWindowSize();
    const [ currentValue, setCurrentValue ] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>();
    const [ , setTypeFinishTimeout ] = useState<NodeJS.Timeout>();
    const suggestions = children && validateAndGetChildrenAsArray(children, Option);
    const optionsModalRef = useRef<MenuRefProps>(null);

    controlSize = isMobile ? 'medium' : controlSize;

    const filteredSuggestions = useMemo(
        () => !suggestions || !searchFilterPredicate || !currentValue ? suggestions :
            suggestions.filter((option) => searchFilterPredicate(currentValue.toString(), option.props.value)),
        [ currentValue, searchFilterPredicate, suggestions ],
    );

    useEffect(() => setCurrentValue(value?.toString() ?? ''), [ value ]);

    useEffect(() => {
        setTypeFinishTimeout((typeFinishTimeout) => {
            if (!typeFinishTimeout && !currentValue) {
                return;
            }
            if (typeFinishTimeout) {
                clearTimeout(typeFinishTimeout);
            }
            return setTimeout(() => currentValue.toString() !== value?.toString() &&
                onTypeFinish?.(currentValue, inputRef.current?.validity), 300);
        });
    }, [ currentValue, onTypeFinish, value ]);

    const onRef = (element: HTMLInputElement): void => {
        inputRef.current = element || undefined;
        if (typeof forwardedRef === 'function') {
            forwardedRef(element);
        } else if (forwardedRef) {
            forwardedRef.current = element;
        }
    };

    const onInputChange = (value: string): void => {
        value = onValueChange?.(value, currentValue) ?? value;
        setCurrentValue(value);
        optionsModalRef.current?.toggleMenu(true);
    };

    const onInputClear = (event: React.MouseEvent): void => {
        event.stopPropagation();
        onInputChange('');
        inputRef.current?.focus();
    };

    const inputContainerClassName = classNames(
        'input-container',
        'form-control',
        'interactive',
        controlSize,
        className,
        { disabled, error },
    );

    const renderInput = (): ReactElement => {
        return (
            <div className={inputContainerClassName} onClick={() => inputRef.current?.focus()}>
                {type === 'search' ? <Icon iconColorMode='stroke' className='search-icon'><SearchIcon /></Icon> : null}
                <input
                    className={classNames('input', { 'show-number-arrows': showNumberArrows })}
                    disabled={disabled}
                    ref={onRef}
                    value={currentValue}
                    type={type}
                    onInput={(event) => onInputChange((event.target as HTMLInputElement).value)}
                    {...htmlInputProps}
                />
                {type === 'search' && (
                    <Button
                        buttonType='icon'
                        size={controlSize}
                        disabled={!currentValue}
                        className='clear-button'
                        onClick={onInputClear}
                    >
                        <ClearIcon />
                    </Button>
                )}
                {suffix}
            </div>
        );
    };

    const renderFooter = (): ReactElement | undefined => {
        if (emptySearchResultsLabel && filteredSuggestions && !filteredSuggestions.length) {
            return (
                <span className='empty-search-results-label'>
                    {emptySearchResultsLabel}
                </span>
            );
        }
    };

    return !filteredSuggestions ? renderInput() : (
        <OptionsModal
            onOptionSelect={(option) => onSuggestionSelect?.(option.value)}
            ref={optionsModalRef}
            trigger={renderInput()}
            footer={renderFooter()}
        >
            {filteredSuggestions}
        </OptionsModal>
    );
};

export default forwardRef(Input);
