import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Content, JSONEditor, Mode, OnChangeStatus, TextContent } from 'vanilla-jsoneditor';
import './proposal-json-item.scss';

interface ProposalJsonItemProps {
    json: string;
    onChange?: (json: string) => void;
}

const ProposalJsonItem: React.FC<ProposalJsonItemProps> = ({ json, onChange }) => {
    const [ editor, setEditor ] = useState<JSONEditor>();
    const containerRef = useRef<HTMLDivElement>(null);

    const onJsonChange = useCallback((content: Content, _: Content, status: OnChangeStatus): void => {
        if (!status.contentErrors) {
            onChange?.((content as TextContent).text);
        }
    }, [ onChange ]);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        setEditor(new JSONEditor({
            target: containerRef.current,
            props: {
                mainMenuBar: false,
                navigationBar: false,
                validator: undefined,
                onError: undefined,
                focus: undefined,
                askToFormat: false,
                statusBar: false,
                mode: Mode.text,
            },
        }));
        return () => setEditor((editor) => {
            editor?.destroy();
            return undefined;
        });
    }, []);

    useEffect(() => {
        editor?.updateProps({ content: { text: json }, onChange: onJsonChange });
    }, [ editor, json, onJsonChange ]);

    return (
        <div className='proposal-json-item jse-theme-dark' ref={containerRef} />
    );
};

export default ProposalJsonItem;
