import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../shared/components/button/button';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { Asset } from '../../../amm/assets/assets-types';
import { AccountNetworkState } from '../../account-network-state';
import { useAmm } from '../../../amm/amm-context';
import { ReactComponent as CreditCardIcon } from '../../../../assets/icons/card-holder.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/upload.svg';
import { ReactComponent as DepositIcon } from '../../../../assets/icons/download.svg';
import { getCurrencyLogoPath, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import IbcTransferDialog from '../../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';
import AccountTotalValue from './account-total-value/account-total-value';
import SendFundsDialog from './send-funds-dialog/send-funds-dialog';
import './account-balances.scss';

interface AccountBalancesProps {
    className?: string;
    networkState: AccountNetworkState;
    onBalanceClick?: (balance: CoinsAmount) => void;
}

const AccountBalances: React.FC<AccountBalancesProps> = ({ className, networkState, onBalanceClick }) => {
    const navigate = useNavigate();
    const { getTokenPrice, ammState } = useAmm();
    const { getNetwork, hubNetwork, toHubCoins } = useNetwork();
    const [ sendFundsDialogOpen, setSendFundsDialogOpen ] = useState(false);
    const [ depositWithdrawDialogOpen, setDepositWithdrawDialogOpen ] = useState(false);

    const onAssetClick = useCallback((balance: CoinsAmount) => {
        const assetKey = balance.ibc?.representation || balance.currency.baseDenom;
        navigate(`/amm/asset/${encodeURIComponent(assetKey)}`);
        onBalanceClick?.(balance);
    }, [ navigate, onBalanceClick ]);

    const balances = useMemo((): { balance: CoinsAmount, price: number, network: Network, asset?: Asset, showNetworkName: boolean }[] => {
        if (!networkState.balances) {
            return [];
        }
        return networkState.balances
            .map((balance, balanceIndex) => {
                const network = (balance.ibc ? getNetwork(balance.ibc.networkId) : networkState.network) as Network;
                const showNetworkName = networkState.balances?.some((otherBalance, otherBalanceIndex) =>
                    otherBalance.currency.displayDenom.toLowerCase() === balance.currency.displayDenom.toLowerCase() &&
                    balanceIndex !== otherBalanceIndex) || false;
                const price = getTokenPrice(balance, networkState.network?.chainId) || 0;
                const asset = ammState.assets?.find((asset) => isCoinsEquals(asset, toHubCoins(balance, networkState.network?.chainId)));
                return { balance, price, network, asset, showNetworkName };
            })
            .sort((balance1, balance2) => balance2.price - balance1.price);
    }, [ networkState.balances, networkState.network, getNetwork, getTokenPrice, ammState.assets, toHubCoins ]);

    return <>
        <AccountTotalValue networkState={networkState} />
        <div className='account-menu-actions'>
            <Button
                className='account-menu-action'
                size='small'
                disabled
                tooltip='Coming soon'
            >
                <CreditCardIcon />&nbsp;Buy
            </Button>
            <Button className='account-menu-action' size='small' onClick={() => setDepositWithdrawDialogOpen(true)}>
                <DepositIcon />&nbsp;Transfer
            </Button>
            <Button className='account-menu-action' size='small' onClick={() => setSendFundsDialogOpen(true)}>
                <SendIcon />&nbsp;Send
            </Button>
        </div>
        <ul className={classNames('account-balances', className)}>
            {networkState.balancesLoading && <Spinner className='balances-loader' />}
            {!networkState.balancesLoading && !networkState.balances?.length && <span className='no-balances'>No Balances</span>}
            {balances.map(({ balance, price, network, asset, showNetworkName }, balanceIndex) => (
                <li
                    className={classNames('balance-row', { canClick: Boolean(asset) })}
                    key={balanceIndex}
                    onClick={() => asset && onAssetClick(asset)}
                >
                    <img className='currency-logo' src={getCurrencyLogoPath(balance.currency, network)} alt='currency logo' />

                    <span className='currency-name-container'>
                        {balance.currency.displayDenom}
                        {showNetworkName && <span className='currency-network'>{network.chainName}</span>}
                    </span>

                    {balance.amount !== undefined && (
                        <span className='currency-option-balance'>
                            {formatPrice(balance.amount, '', undefined, 10)}
                            <span className='balance-value'>{formatPrice(price)}</span>
                        </span>
                    )}
                </li>
            ))}
        </ul>

        {sendFundsDialogOpen &&
            <SendFundsDialog networkState={networkState} onRequestClose={() => setSendFundsDialogOpen(false)} />}

        {depositWithdrawDialogOpen && hubNetwork &&
            <IbcTransferDialog
                title='Transfer'
                initialSourceNetwork={networkState.network?.chainId}
                initialDestinationNetwork={hubNetwork.chainId}
                onRequestClose={() => setDepositWithdrawDialogOpen(false)}
            />}
    </>;
};

export default AccountBalances;
