import React, { useMemo } from 'react';
import Badge from '../../../shared/components/badge/badge';
import Spinner from '../../../shared/components/spinner/spinner';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { ProposalStatus } from '../governance-types';
import './proposal-status-badge.scss';

interface ProposalStatusBadgeProps {
    status: ProposalStatus;
    size?: 'small' | 'medium' | 'large';
    className?: string;
}

const ProposalStatusBadge: React.FC<ProposalStatusBadgeProps> = ({ status, size = 'medium', className }) => {
    const color = useMemo((): (number[] | undefined) => {
        switch (status) {
            case 'Deposit Period':
            case 'Voting Period':
                return getCssVariableValue('--orange-rgb').split(',').map(Number);
            case 'Passed':
                return getCssVariableValue('--dark-green-rgb').split(',').map(Number);
            case 'Rejected':
            case 'Failed':
                return getCssVariableValue('--red-rgb').split(',').map(Number);
        }
    }, [ status ]);

    const label = <>
        {status === 'Deposit Period' || status === 'Voting Period' ?
            <Spinner className='status-spinner' size={size === 'small' ? 'xs' : 'small'} /> :
            undefined}
        {status}
    </>;

    return <Badge size={size} rounded color={color} className={className} label={label} />;
};

export default ProposalStatusBadge;
