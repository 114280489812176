import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OverlayAlign } from '../../shared/components/overlay/overlay';
import Snackbar from '../../shared/components/snackbar/snackbar';
import { useAccountNetwork } from '../account/use-account-network';
import { useNetwork } from '../network/network-context';
import { useWallet } from '../wallet/wallet-context';
import AccountMenu from '../account/account-menu/account-menu';
import { ConnectOutMessage, ConnectInMessage } from './connect-types';

const NETWORK_IDS_KEY = 'networkIds';

function Connect(): JSX.Element {
    const [ params ] = useSearchParams();
    const { hubNetwork, getNetwork } = useNetwork();
    const { networkWalletTypeMap, networkWalletConnectingMap } = useWallet();
    const [ triggerBoundingRect, setTriggerBoundingRect ] = useState<DOMRect | null>(null);
    const [ modalStyles, setModalStyles ] = useState<CSSProperties>();
    const [ menuAlign, setMenuAlign ] = useState<OverlayAlign>();
    const networkIds = (params.get(NETWORK_IDS_KEY)?.split(',') || []).filter(Boolean);
    const [ networkState, selectNetwork ] = useAccountNetwork(getNetwork(networkIds[0]) || hubNetwork);

    const networkWalletType = networkState.network ? networkWalletTypeMap[networkState.network.chainId] : undefined;
    const connectingWallet = networkState.network ? networkWalletConnectingMap[networkState.network.chainId] : undefined;

    const sendMessage = useCallback((message: ConnectOutMessage) => window.parent.postMessage(message, '*'), []);

    useEffect(() => {
        window.addEventListener('message', (event: MessageEvent<ConnectInMessage>) => {
            if (event.data.type === 'triggerBoundingRectChange') {
                setTriggerBoundingRect(event.data.rect);
            }
            if (event.data.type === 'stylesChange') {
                setModalStyles(event.data.styles);
            }
            if (event.data.type === 'menuAlignChange') {
                setMenuAlign(event.data.align);
            }
        });
    }, []);

    useEffect(() => {
        if (networkState.network && !connectingWallet && !networkState.addressLoading) {
            sendMessage({ type: 'ready' });
        }
    }, [ connectingWallet, networkState.addressLoading, networkState.network, sendMessage ]);

    useEffect(() => {
        if (networkState.address || networkState.hexAddress) {
            sendMessage({ type: 'connect', address: networkState.address, hexAddress: networkState.hexAddress });
        }
    }, [ networkState.address, networkState.hexAddress, sendMessage ]);

    useEffect(() => {
        if (networkState.network && !networkWalletType) {
            sendMessage({ type: 'disconnect' });
        }
    }, [ networkState.network, networkWalletType, sendMessage ]);

    const triggerElement = triggerBoundingRect ? <span style={{ position: 'absolute', ...triggerBoundingRect.toJSON() }} /> : null;

    return (
        <div className='connect-container'>
            {triggerElement && (
                <AccountMenu
                    visibleAlways
                    styles={modalStyles}
                    menuAlign={menuAlign}
                    networkState={networkState}
                    trigger={triggerElement}
                    onNetworkSelect={(networkId) => selectNetwork(getNetwork(networkId))}
                    optionalNetworks={[ ...networkIds, hubNetwork?.chainId ].filter(Boolean) as string[]}
                    forceHubConnect={false}
                    footer={undefined}
                    onClickOutside={() => sendMessage({ type: 'close' })}
                />
            )}
            <Snackbar styles={modalStyles} />
        </div>
    );
}

export default Connect;
