import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import Spinner from '../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAmm } from '../../../amm/amm-context';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';

const NativeTokenProperty: React.FC = () => {
    const { ammState, getTokenPrice } = useAmm();
    const { network } = useNetworkDashboard();

    const nativeAsset = useMemo(
        () => ammState.assets?.find((asset) => asset.network.chainId === network.chainId && asset.currency.type === 'main'),
        [ ammState.assets, network.chainId ],
    );

    return (
        <Property label='Native Token Price'>
            {!nativeAsset && (ammState.loading || ammState.paramsLoading || ammState.totalLockedValuesLoading) ?
                <Spinner /> : nativeAsset && (
                <StatisticsChange
                    period='day'
                    currentValue={nativeAsset.price}
                    previousValue={nativeAsset.previousDayPrice}
                >
                    1 {nativeAsset.currency.displayDenom} ≈&nbsp;
                    {formatPrice(getTokenPrice(nativeAsset, undefined, true) || 0)}
                </StatisticsChange>
            )}
        </Property>
    );
};

export default NativeTokenProperty;
