import classNames from 'classnames';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import { AccountNetworkState } from '../../account-network-state';
import { useAmm } from '../../../amm/amm-context';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DepositIcon } from '../../../../assets/icons/deposit.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload.svg';
import { getPositionPart } from '../../../amm/amm.service';
import CreatePoolDialog from '../../../amm/create-pool-dialog/create-pool-dialog';
import IncentiveBadge from '../../../amm/incentive-badge/incentive-badge';
import { Pool } from '../../../amm/types';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import AccountTotalLiquidityValue from './account-total-liquidity-value/account-total-liquidity-value';
import './account-pools.scss';

interface AccountPoolsProps {
    className?: string;
    networkState: AccountNetworkState;
    onRequestClose?: () => void;
}

const AccountPools: React.FC<AccountPoolsProps> = ({ className, networkState, onRequestClose }) => {
    const navigate = useNavigate();
    const { hubNetwork, getNetwork } = useNetwork();
    const { ammState, getPoolLiquidity } = useAmm();
    const [ createPoolDialogOpen, setCreatePoolDialogOpen ] = useState<boolean>();

    const pools = useMemo<Pool[]>(() => (ammState.pools || []).filter((pool) => pool.position), [ ammState.pools ]);

    const onPoolClick = useCallback((pool: Pool) => {
        navigate(`/amm/pool/${pool.id}`);
        onRequestClose?.();
    }, [ navigate, onRequestClose ]);

    const onNewPositionClick = useCallback(() => {
        navigate('/amm/pools');
        onRequestClose?.();
    }, [ navigate, onRequestClose ]);

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = asset.ibc ? getNetwork(asset.ibc.networkId) : hubNetwork;

        return currencyNetwork &&
            <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='currency logo' />;
    };

    return <>
        <AccountTotalLiquidityValue networkState={networkState} />
        <div className='account-menu-actions'>
            <Button
                className='account-menu-action'
                size='small'
                buttonType='secondary'
                disabled={ammState.loading || !ammState.pools}
                onClick={() => setCreatePoolDialogOpen(true)}
            >
                <UploadIcon />&nbsp;Create New Pool
            </Button>
            <Button className='account-menu-action' size='small' onClick={onNewPositionClick}>
                <DepositIcon />&nbsp;Add Position
            </Button>
        </div>
        <ul className={classNames('account-pools', className)}>
            {ammState.loading && <Spinner className='positions-loader' />}
            {!ammState.loading && !pools.length && <span className='no-positions'>No positions</span>}
            {pools?.map((pool, poolIndex) => {
                const liquidity = getPoolLiquidity(pool) || 0;
                const sharesPart = getPositionPart(pool);
                return (
                    <li className='position-row' key={poolIndex} onClick={() => onPoolClick(pool)}>
                        {renderAssetLogo(pool.assets[0])}
                        {renderAssetLogo(pool.assets[1])}
                        <span className='pool-assets-names'>
                            {pool.assets[0].currency.displayDenom} / {pool.assets[1].currency.displayDenom}
                            {ammState.incentives?.[pool.lpTokenDenom]?.some((incentive) => incentive.coins.length) ?
                                <IncentiveBadge className='incentives-badge' size='small' denom={pool.lpTokenDenom} /> : null}
                        </span>
                        <span className='position-amount'>
                            {formatPrice(liquidity * sharesPart, undefined, undefined, 12)}
                            <span className='bonded-value'>
                                bonded {roundNumber(Number(pool.position?.bondedShares) * 100 / Number(pool.position?.shares), 2)}%
                            </span>
                        </span>
                    </li>
                );
            })}
        </ul>
        {createPoolDialogOpen && <CreatePoolDialog onRequestClose={() => setCreatePoolDialogOpen(false)} />}
    </>;
};

export default AccountPools;
