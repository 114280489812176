import { PortalError } from '../error/portal-error';

export type ClientErrorCode =
    'CONNECT_CLIENT_FAILED'
    | 'FETCH_DATA_FAILED'
    | 'SIMULATE_TX_FAILED'
    | 'BROADCAST_TX_FAILED'
    | 'NO_BALANCES'
    | 'INSUFFICIENT_FUNDS'
    | 'INSUFFICIENT_FEES'
    | 'SIGNATURE_VERIFICATION_FAILED'
    | 'INVALID_COINS'
    | 'REQUEST_REJECTED'
    | 'UNSUPPORTED_MESSAGE'

export class ClientError extends PortalError<ClientErrorCode> {}
