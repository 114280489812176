import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { analyticsReducer, AnalyticsState } from '../../analytics/analytics-state';
import { loadPoolAnalytics } from '../statistics/analytics/pool-analytics-service';
import { PoolAnalytics } from '../statistics/analytics/pool-analytics-types';
import { Pool } from '../types';

interface PoolDashboardContextProps {
    pool: Pool;
    children: ReactNode;
}

interface PoolDashboardContextValue {
    pool: Pool;
    analyticsState: AnalyticsState<PoolAnalytics>;
}

export const PoolDashboardContext = createContext<PoolDashboardContextValue>({} as PoolDashboardContextValue);

export const usePoolDashboard = (): PoolDashboardContextValue => useContext(PoolDashboardContext);

export const PoolDashboardContextProvider: React.FC<PoolDashboardContextProps> = ({ pool, children }) => {
    const [ analyticsState, analyticsStateDispatch ] = useReducer(analyticsReducer, { loading: true });
    const cancelAndSetAnalyticsPromise = useCancelablePromise<PoolAnalytics>();

    useEffect(() => {
        const poolAnalyticsPromise =
            loadPoolAnalytics<keyof PoolAnalytics>(
                pool.id.toString(), { tradingVolume: [ 'day', 'month', 'total' ], liquidity: [ 'day', 'month', 'total' ] },
            );

        cancelAndSetAnalyticsPromise(poolAnalyticsPromise)
            .then((analytics) => analyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => analyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [ cancelAndSetAnalyticsPromise, pool.id ]);

    return (
        <PoolDashboardContext.Provider value={{ pool, analyticsState }}>
            {children}
        </PoolDashboardContext.Provider>
    );
};
