import classNames from 'classnames';
import React, { useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { AccountNetworkState } from '../../../account-network-state';
import { useAmm } from '../../../../amm/amm-context';
import { isCoinsEquals } from '../../../../currency/currency-service';
import './account-total-value.scss';

interface AccountTotalValueProps {
    className?: string;
    networkState: AccountNetworkState;
}

const AccountTotalValue: React.FC<AccountTotalValueProps> = ({ className, networkState }) => {
    const { ammState, getTokenPrice } = useAmm();

    const currentValue = useMemo(
        () => networkState?.balances?.reduce((current, balance) =>
            current + (getTokenPrice(balance, networkState.network?.chainId) || 0), 0) || 0,
        [ getTokenPrice, networkState?.balances, networkState.network?.chainId ],
    );

    const diffValue = useMemo(
        () => networkState?.balances?.reduce((current, balance) => {
            const asset = ammState.assets?.find((asset) => isCoinsEquals(asset, balance));
            return asset ? current + (balance.amount * (asset.price - asset.previousDayPrice)) : current;
        }, 0) || 0,
        [ ammState.assets, networkState?.balances ],
    );

    return (
        <Property
            label='Total Available'
            className={classNames('account-total-value', className)}
            valueClassName='property-value'
            labelClassName='property-label'
        >
            {(ammState.loading || ammState.paramsLoading || ammState.totalLockedValuesLoading) && !currentValue ? <Spinner /> : (
                <StatisticsChange
                    period='day'
                    currentValue={currentValue}
                    previousValue={currentValue - diffValue}
                >
                    {formatPrice(currentValue, undefined, undefined, 10)}&nbsp;
                </StatisticsChange>
            )}
        </Property>
    );
};

export default AccountTotalValue;
