import React, {
    forwardRef,
    ReactNode,
    TextareaHTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import './text-area.scss';
import useWindowSize from '../../../hooks/use-window-size';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    onValueChange?: (value: string, previousValue: string) => string | void | undefined;
    onTypeFinish?: (value: string | ReadonlyArray<string> | number | undefined, validity?: ValidityState) => void;
    controlSize?: 'medium' | 'large';
    className?: string;
    children?: ReactNode;
}

const TextArea: React.ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = ({
    controlSize,
    value,
    onValueChange,
    onTypeFinish,
    className,
    disabled,
    children,
    ...htmTextAreaProps
}, forwardedRef) => {
    const { isMobile } = useWindowSize();
    const [ currentValue, setCurrentValue ] = useState<string>('');
    const textAreaRef = useRef<HTMLTextAreaElement>();
    const [ , setTypeFinishTimeout ] = useState<NodeJS.Timeout>();

    controlSize = isMobile ? 'medium' : controlSize;

    useEffect(() => setCurrentValue(value?.toString() ?? ''), [ value ]);

    useEffect(() => {
        setTypeFinishTimeout((typeFinishTimeout) => {
            if (!typeFinishTimeout && !currentValue) {
                return;
            }
            if (typeFinishTimeout) {
                clearTimeout(typeFinishTimeout);
            }
            return setTimeout(() => currentValue !== value && onTypeFinish?.(currentValue, textAreaRef.current?.validity), 1000);
        });
    }, [ currentValue, onTypeFinish, value ]);

    const onRef = (element: HTMLTextAreaElement): void => {
        textAreaRef.current = element || undefined;
        if (typeof forwardedRef === 'function') {
            forwardedRef(element);
        } else if (forwardedRef) {
            forwardedRef.current = element;
        }
    };

    const onInputChange = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
        const target = event.target as HTMLTextAreaElement;
        const value = onValueChange?.(target.value, currentValue) ?? target.value;
        setCurrentValue(value);

        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${target.scrollHeight}px`;
        }
    };

    return (
        <div className={classNames('text-area-container form-control interactive', controlSize, className, { disabled })}>
            <textarea
                className='text-area'
                disabled={disabled}
                ref={onRef}
                value={currentValue}
                onInput={onInputChange}
                {...htmTextAreaProps}
            />
        </div>
    );
};

export default forwardRef(TextArea);
