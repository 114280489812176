import { loadAnalyticsItem, loadAnalyticsMap } from '../../../analytics/analytics-service';
import { NetworksAnalytics, NetworksAnalyticsMap } from './network-analytics-types';

const analyticsUrl = process.env.REACT_APP_FETCH_ANALYTICS_URL || '';

export const loadNetworksAnalyticsMap = async <T extends string, R extends NetworksAnalyticsMap = NetworksAnalyticsMap>(
    networkIds: string[],
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    return loadAnalyticsMap(networkIds, 'networkIds', analyticsUrl, history);
};

export const loadNetworksAnalytics = async <T extends string, R extends NetworksAnalytics = NetworksAnalytics>(
    networkId: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    return loadAnalyticsItem(networkId, 'networkIds', analyticsUrl, history);
};


