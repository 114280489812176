import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Long } from 'cosmjs-types/helpers';
import { isEmpty, uniq } from 'lodash';
import { convertDecimalToInt, roundNumber } from '../../shared/utils/number-utils';
import { ClientError } from '../client/client-error';
import { PageRequest } from '../client/station-clients/dymension/generated/cosmos/base/query/v1beta1/pagination';
import {
    MsgCreatePoolEncodeObject,
    MsgExitPoolEncodeObject,
    MsgJoinPoolEncodeObject,
    MsgJoinSwapPoolEncodeObject,
    MsgSwapExactAmountInEncodeObject,
} from '../client/station-clients/dymension/generated/gamm/messages';
import { Params as AmmModuleParams } from '../client/station-clients/dymension/generated/gamm/v1beta1/genesis';
import { Params as IncentivesModuleParams } from '../client/station-clients/dymension/generated/incentives/params';
import { Pool as BalancerPool } from '../client/station-clients/dymension/generated/gamm/v1beta1/pool-models/balancer/balancerPool';
import { Gauge } from '../client/station-clients/dymension/generated/incentives/gauge';
import { MsgCreateGaugeEncodeObject } from '../client/station-clients/dymension/generated/incentives/messages';
import { LockQueryType } from '../client/station-clients/dymension/generated/lockup/lock';
import { MsgBeginUnlockingEncodeObject, MsgLockTokensEncodeObject } from '../client/station-clients/dymension/generated/lockup/messages';
import { SwapAmountInRoute } from '../client/station-clients/dymension/generated/poolmanager/v1beta1/swap_route';
import { Stream } from '../client/station-clients/dymension/generated/streamer/stream';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoin, convertToCoinsAmount, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { getNetworkData } from '../network/network-service';
import { PoolAnalyticsSummary } from './statistics/analytics/pool-analytics-types';
import { AmmParams, EpochIdentifier, Incentive, IncentivesParams, LOCK_DEFAULT_DURATION, LockedAsset, Pool, PoolPosition } from './types';

export const POOL_SHARE_PREFIX = 'gamm/pool/';
export const MIN_INCENTIVES_VALUE = 500;

export const loadAmmParams = async (client: StationClient): Promise<AmmParams> => {
    let params: AmmModuleParams | undefined;
    if (client.getNetwork().collectData) {
        params = await getNetworkData<AmmModuleParams>(client.getNetwork(), 'amm-params', true);
    }
    if (!params || isEmpty(params)) {
        const response = await client.getGammQueryClient().Params({}).catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });
        params = response.params;
    }
    if (!params?.poolCreationFee?.length) {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), new Error('Missing whitelist tokens'));
    }
    const [ vsCoins, ...poolCreationFees ] = await Promise.all(
        [ { denom: process.env.REACT_APP_VS_CURRENCY_DENOM, amount: '0' }, ...params.poolCreationFee ]
            .map((coin) => convertToCoinsAmount(coin, client, false)));
    if (!vsCoins) {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), new Error('Missing vs token'));
    }
    return {
        poolCreationTokens: poolCreationFees.filter(Boolean) as CoinsAmount[],
        exitFee: convertDecimalToInt(Number(params.globalFees?.exitFee) || 0),
        swapFee: convertDecimalToInt(Number(params.globalFees?.swapFee) || 0),
        takerFee: convertDecimalToInt(Number(params.takerFee) || 0),
        vsCoins,
    };
};

export const loadIncentivesParams = async (client: StationClient): Promise<IncentivesParams> => {
    let params: IncentivesModuleParams | undefined;
    if (client.getNetwork().collectData) {
        params = await getNetworkData<IncentivesModuleParams>(client.getNetwork(), 'incentives-params', true);
    }
    if (!params || isEmpty(params)) {
        params = { distrEpochIdentifier: 'minute' };
    }
    if (!params) {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), new Error(`Can't fetch incentive params`));
    }
    return { epochIdentifier: params.distrEpochIdentifier as EpochIdentifier };
};

export const loadPools = async (client: StationClient): Promise<Pool[]> => {
    let balancerPools: (BalancerPool & PoolAnalyticsSummary)[] = [];
    if (client.getNetwork().collectData) {
        balancerPools = await getNetworkData<BalancerPool[]>(client.getNetwork(), 'pools');
    }
    if (!balancerPools?.length) {
        const poolsResponse = await client.getGammQueryClient().Pools({
            pagination: {
                limit: Long.MAX_VALUE.toNumber(),
                offset: 0,
                countTotal: true,
                reverse: false,
                key: Uint8Array.of(0),
            },
        }).catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });
        balancerPools = poolsResponse.pools.map((pool) => BalancerPool.decode(pool.value));
    }
    const pools = await Promise.all(balancerPools.map((pool) => convertPool(pool, client)));
    return pools.filter((pool) => pool.assets.length >= 2);
};

export const loadLockedAssets = async (client: StationClient, address: string): Promise<LockedAsset[]> => {
    const network = client.getNetwork();
    const response = await Promise.all([
        client.getLockupQueryClient().AccountLockedCoins({ owner: address }),
        client.getLockupQueryClient().AccountLockedDuration({ owner: address, duration: LOCK_DEFAULT_DURATION }),
    ]).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });
    return response[0].coins.map((lockedCoin) => {
        const lockedDuration = response[1].locks.find((lock) => lock.coins.some((coin) => coin.denom === lockedCoin.denom));
        return lockedDuration ? { coin: lockedCoin, lockId: Number(lockedDuration.ID) } : undefined;
    }).filter(Boolean) as { coin: Coin, lockId: number }[];
};

export const loadPositions = async (client: StationClient, address: string, lockedAssets: LockedAsset[]): Promise<PoolPosition[]> => {
    const network = client.getNetwork();
    const { balances } = await client.getBankQueryClient().AllBalances({
        address,
        pagination: {
            reverse: false,
            limit: Long.MAX_VALUE,
            offset: Long.fromNumber(0),
            countTotal: false,
            key: new Uint8Array(0),
        },
    }).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });
    const lpTokenDenoms = uniq([
        ...balances.map(({ denom }) => denom).filter((denom) => denom.includes(POOL_SHARE_PREFIX)),
        ...lockedAssets.map(({ coin }) => coin.denom).filter((denom) => denom.includes(POOL_SHARE_PREFIX)),
    ]);
    return lpTokenDenoms.map((denom) => {
        const balance = balances.find((balance) => balance.denom === denom);
        const lockedAsset = lockedAssets.find(({ coin }) => coin.denom === denom);
        return {
            poolId: Number((balance || lockedAsset?.coin)?.denom.replace(POOL_SHARE_PREFIX, '')) || 0,
            shares: BigInt(balance?.amount || '0') + BigInt(lockedAsset?.coin.amount || '0'),
            bondedShares: BigInt(lockedAsset?.coin.amount || '0'),
            lockId: Number(lockedAsset?.lockId) || undefined,
        };
    });
};

export const loadTotalLockedValues = async (
    client: StationClient,
    pools: Pool[],
    params: AmmParams,
): Promise<{ [denom: string]: number }> => {
    let totalLockedTokens: Coin[] | undefined;
    if (client.getNetwork().collectData) {
        totalLockedTokens = await getNetworkData<Coin[]>(client.getNetwork(), 'total-locked-tokens', true);
    }
    if (!totalLockedTokens?.length) {
        const response = await client.getLockupQueryClient().ModuleLockedAmount({});
        totalLockedTokens = response.coins;
    }
    const totalLockedValues = await Promise.all(totalLockedTokens?.map(async (coin) => {
        let totalLockedValue = Number(coin.amount) || 0;
        if (!coin.denom.includes(POOL_SHARE_PREFIX)) {
            const lockedCoinsAmount = await convertToCoinsAmount(coin, client);
            totalLockedValue = lockedCoinsAmount ? getPrice(pools, params, lockedCoinsAmount, params.vsCoins) : 0;
        } else {
            const poolId = Number(coin.denom.replace(POOL_SHARE_PREFIX, ''));
            const pool = pools?.find((pool) => pool.id === poolId);
            if (pool) {
                totalLockedValue = (totalLockedValue / Number(pool.totalShares)) *
                    getMaxDenomAmount(pool.liquidity?.value.value || 0, params.vsCoins.currency);
            }
        }
        return { denom: coin.denom, value: totalLockedValue };
    }));
    return totalLockedValues.reduce((current, { denom, value }) => ({ ...current, [denom]: value }), {});
};

export const loadIncentives = async (
    client: StationClient,
    pools: Pool[],
    params: AmmParams,
    incentiveParams: IncentivesParams,
): Promise<{ [denom: string]: Incentive[] }> => {
    let incentivesData: { gauges: Gauge[], streams: Stream[] } | undefined;
    if (client.getNetwork().collectData) {
        incentivesData = await getNetworkData<typeof incentivesData>(client.getNetwork(), 'pool-incentives', true);
    }
    if (!incentivesData?.gauges?.length && !incentivesData?.streams?.length) {
        const pagination: PageRequest = {
            limit: Long.MAX_VALUE.toNumber(),
            offset: 0,
            countTotal: false,
            reverse: false,
            key: Uint8Array.of(0),
        };
        const [ gaugesResponse, upcomingGaugesResponse, streamsResponse, upcomingStreamsResponse ] = await Promise.all([
            client.getIncentivesQueryClient().ActiveGauges({ pagination }),
            client.getIncentivesQueryClient().UpcomingGauges({ pagination }),
            client.getStreamerQueryClient().ActiveStreams({ pagination }),
            client.getStreamerQueryClient().UpcomingStreams({ pagination }),
        ]);
        incentivesData = {
            gauges: [ ...gaugesResponse.data, ...upcomingGaugesResponse.data ],
            streams: [ ...streamsResponse.data, ...upcomingStreamsResponse.data ],
        };
    }

    const streamGauges = incentivesData?.streams.reduce((current, stream) => {
        const records = (stream.distributeTo?.records || []).map((record) => {
            const gauge = incentivesData?.gauges.find((gauge) => gauge.id === record.gaugeId);
            if (!gauge) {
                return undefined;
            }
            return { gauge, stream, weight: Number(record.weight) };
        });
        return [ ...current, ...(records.filter(Boolean) as { gauge: Gauge, stream: Stream, weight: number }[]) ];
    }, [] as { gauge: Gauge, stream: Stream, weight: number }[]);

    const perpetualIncentives = await Promise.all(streamGauges?.map(({ gauge, stream, weight }) =>
        createIncentive(client, pools, params, incentiveParams, gauge, stream, weight)));

    const unPerpetualIncentives = await Promise.all(incentivesData.gauges.filter((gauge) => !gauge.isPerpetual).map((gauge) =>
        createIncentive(client, pools, params, incentiveParams, gauge)));

    const incentives = [ ...perpetualIncentives, ...unPerpetualIncentives ]
        .filter((incentive) => incentive && incentive.value >= MIN_INCENTIVES_VALUE) as Incentive[];
    return incentives.reduce((current, incentive) =>
        ({ ...current, [incentive.denom]: [ ...(current[incentive.denom] || []), incentive ] }), {} as { [denom: string]: Incentive[] });
};

const createIncentive = async (
    client: StationClient,
    pools: Pool[],
    params: AmmParams,
    incentiveParams: IncentivesParams,
    gauge: Gauge,
    stream?: Stream,
    streamGaugeWeight?: number,
): Promise<Incentive | undefined> => {
    const denom = gauge.distributeTo?.denom;
    if (!denom) {
        return undefined;
    }
    let baseCoins = gauge.coins;
    let baseDistributedCoins = gauge.distributedCoins;
    if (stream && streamGaugeWeight) {
        const part = streamGaugeWeight / Number(stream.distributeTo?.totalWeight);
        baseCoins = stream.coins.map((coin) => ({ ...coin, amount: (Number(coin.amount) * part).toString() }));
        baseDistributedCoins = stream.distributedCoins.map((coin) => ({ ...coin, amount: (Number(coin.amount) * part).toString() }));
    }
    const startTime = new Date(stream?.startTime || gauge.startTime || '');
    const numEpochsPaidOver = stream?.numEpochsPaidOver || gauge.numEpochsPaidOver;
    const epochIdentifier = (stream?.distrEpochIdentifier as EpochIdentifier) || incentiveParams.epochIdentifier;

    const filledEpochs = stream?.filledEpochs || gauge.filledEpochs || 0;
    const endTime = Date.now() > startTime.getTime() ? new Date(Date.now()) : startTime;
    endTime.setMilliseconds(endTime.getMilliseconds() + epochToMilliseconds(epochIdentifier) * (numEpochsPaidOver - filledEpochs));

    const coins = (await Promise.all(baseCoins.map(async (coin) => convertToCoinsAmount(coin, client)))).filter(Boolean) as CoinsAmount[];
    const distributedCoins =
        (await Promise.all(baseDistributedCoins.map(async (coin) => convertToCoinsAmount(coin, client)))).filter(Boolean) as CoinsAmount[];
    const value = coins.reduce((current, coinsAmount) =>
        current + (coinsAmount ? getPrice(pools, params, coinsAmount, params.vsCoins) : 0), 0);

    const epochMilliseconds = epochToMilliseconds(epochIdentifier);
    const yearMilliseconds = epochToMilliseconds('year');
    const yearPart = !epochMilliseconds ? 1 : (epochMilliseconds * numEpochsPaidOver / yearMilliseconds);
    return { denom, coins, distributedCoins, value, startTime, endTime, yearPart };
};

export const getOtherAssetPrice = (pool: Pool, coins: CoinsAmount): number => {
    let relation = pool.assets[0].amount / pool.assets[1].amount;
    let decimals = pool.assets[0].currency.decimals;
    if (isCoinsEquals(pool.assets[0], coins)) {
        relation = 1 / relation;
        decimals = pool.assets[1].currency.decimals;
    }
    return roundNumber(coins.amount * relation, decimals);
};

export const getPrice = (pools: Pool[], ammParams: AmmParams, from: CoinsAmount, to: CoinsAmount): number => {
    if (isCoinsEquals(from, to)) {
        return from.amount;
    }
    const connectedPools = getConnectedPools(pools, ammParams, from, to);
    if (!connectedPools?.length) {
        return 0;
    }
    const coins = connectedPools.reduce((current, pool): CoinsAmount => {
        const otherToken = isCoinsEquals(pool.assets[0], current) ? pool.assets[1] : pool.assets[0];
        const otherTokenPrice = getOtherAssetPrice(pool, current);
        return { ...otherToken, amount: otherTokenPrice };
    }, from);
    return coins.amount;
};

export const createLiquidityMessage = (
    pool: Pool,
    sender: string,
    sharesAmount: bigint,
    assetBalances: CoinsAmount[],
    toRemove?: boolean,
): MsgJoinPoolEncodeObject | MsgJoinSwapPoolEncodeObject | MsgExitPoolEncodeObject | undefined => {
    if (!assetBalances.length) {
        return;
    }
    const tokens = assetBalances.map((coin) => convertToCoin(coin, coin.ibc?.representation));
    if (toRemove) {
        tokens.forEach((token) => token.amount = '1');
        return {
            typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgExitPool',
            value: { poolId: pool.id, sender, shareInAmount: sharesAmount.toString(), tokenOutMins: tokens },
        };
    }
    if (tokens.length !== 1) {
        return {
            typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgJoinPool',
            value: { poolId: pool.id, sender, shareOutAmount: sharesAmount.toString(), tokenInMaxs: tokens },
        };
    }
    const token = tokens[0];
    return {
        typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgJoinSwapShareAmountOut',
        value: {
            poolId: pool.id,
            sender,
            shareOutAmount: sharesAmount.toString(),
            tokenInDenom: token.denom,
            tokenInMaxAmount: token.amount,
        },
    };
};

export const createLockMessage = (
    sender: string,
    coin: Coin,
    lockId?: number,
): MsgLockTokensEncodeObject | MsgBeginUnlockingEncodeObject => {
    if (lockId) {
        return {
            typeUrl: '/dymensionxyz.dymension.lockup.MsgBeginUnlocking',
            value: { owner: sender, ID: lockId, coins: [ coin ] },
        };
    }
    return {
        typeUrl: '/dymensionxyz.dymension.lockup.MsgLockTokens',
        value: { owner: sender, duration: LOCK_DEFAULT_DURATION, coins: [ coin ] },
    };
};

export const createSwapMessage = (
    sender: string,
    coins: CoinsAmount,
    connectedPools: Pool[],
    tokenOutMinAmount: string,
    minCoinsInAmount: number,
): MsgSwapExactAmountInEncodeObject => {
    const swapParams = getSwapAmountInParams(coins, connectedPools, minCoinsInAmount);
    return {
        typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgSwapExactAmountIn',
        value: { ...swapParams, sender, tokenOutMinAmount },
    };
};

export const createCreatePoolMessage = (
    sender: string,
    swapFee: number,
    exitFee: number,
    assets: CoinsAmount[],
): MsgCreatePoolEncodeObject => {
    const tokens = assets.map((coins) => convertToCoin(coins, coins.ibc?.representation));
    return {
        typeUrl: '/dymensionxyz.dymension.gamm.poolmodels.balancer.v1beta1.MsgCreateBalancerPool',
        value: {
            sender,
            futurePoolGovernor: '',
            poolParams: { swapFee: swapFee.toString(), exitFee: exitFee.toString(), smoothWeightChangeParams: undefined },
            poolAssets: tokens.map((token) => ({ token, weight: '50' })),
        },
    };
};

export const createCreateGaugeMessage = (
    owner: string,
    coinsAmount: CoinsAmount,
    pool: Pool,
    startTime: Date,
    endTime: Date,
    incentivesParams: IncentivesParams,
): MsgCreateGaugeEncodeObject => {
    const numEpochsPaidOver = Math.round((endTime.getTime() - startTime.getTime()) / epochToMilliseconds(incentivesParams.epochIdentifier));
    const coins = [ convertToCoin(coinsAmount, coinsAmount.ibc?.representation) ];
    return {
        typeUrl: '/dymensionxyz.dymension.incentives.MsgCreateGauge',
        value: {
            owner,
            coins,
            distributeTo: {
                denom: pool.lpTokenDenom,
                lockQueryType: LockQueryType.ByDuration,
                duration: LOCK_DEFAULT_DURATION,
                timestamp: undefined,
            },
            isPerpetual: false,
            numEpochsPaidOver,
            startTime,
        },
    };
};


export const getPositionPart = (pool: Pool): number => {
    return (Number(pool.position?.shares) || 0) / Number(pool.totalShares);
};

export const getPositionBondedPart = (pool: Pool): number => {
    return (Number(pool.position?.bondedShares) || 0) / Number(pool.totalShares);
};

export const getPool = (pools: Pool[], coins1: CoinsAmount, coins2: CoinsAmount) => {
    return pools.find((pool) =>
        pool.assets.some((asset) => isCoinsEquals(asset, coins1) && pool.assets.some((asset) => isCoinsEquals(asset, coins2))),
    );
};

export const getConnectedPools = (
    pools: Pool[],
    ammParams: AmmParams,
    from: CoinsAmount,
    to: CoinsAmount,
    current: Pool[] = [],
    depth = ammParams.poolCreationTokens.length,
): Pool[] => {
    if (depth < 0) {
        return [];
    }
    const pool = getPool(pools, from, to);
    if (pool) {
        return [ ...current, pool ];
    }
    return ammParams.poolCreationTokens
        .map((coins) => getPool(pools, from, coins))
        .reduce((connectedPools, pool) => {
            const otherAsset = pool?.assets.find((asset) => !isCoinsEquals(asset, from));
            if (!pool || !otherAsset) {
                return connectedPools;
            }
            const checkedPools = getConnectedPools(pools, ammParams, otherAsset, to, [ ...current, pool ], depth - 1);
            return checkedPools.length ? checkedPools : connectedPools;
        }, [] as Pool[]);
};

export const epochToMilliseconds = (epochIdentifier: EpochIdentifier): number => {
    const minuteMilliseconds = 60 * 1000;
    const dayMinutes = 24 * 60;

    switch (epochIdentifier) {
        case 'minute':
            return minuteMilliseconds;
        case 'day':
        case 'daily':
            return dayMinutes * minuteMilliseconds;
        case 'week':
        case 'weekly':
            return 7 * dayMinutes * minuteMilliseconds;
        case 'month':
        case 'monthly':
            return 30 * dayMinutes * minuteMilliseconds;
        case 'year':
        case 'yearly':
            return 365 * dayMinutes * minuteMilliseconds;
        default:
            return 0;
    }
};

const convertPool = async (pool: BalancerPool & PoolAnalyticsSummary, client: StationClient): Promise<Pool> => {
    const assets = await Promise.all(pool.poolAssets.filter((asset) => Boolean(asset.token))
        .map((asset) => convertToCoinsAmount(asset.token as Coin, client)));

    return {
        ...pool,
        id: pool.id,
        lpTokenDenom: POOL_SHARE_PREFIX + pool.id,
        assets: assets.filter(Boolean) as CoinsAmount[],
        swapFee: convertDecimalToInt(Number(pool.poolParams?.swapFee) || 0),
        exitFee: convertDecimalToInt(Number(pool.poolParams?.exitFee) || 0),
        totalShares: BigInt(pool.totalShares?.amount || '0'),
    };
};

const getSwapAmountInParams = (
    coins: CoinsAmount,
    connectedPools: Pool[],
    minCoinsInAmount: number,
): { tokenIn: Coin, routes: SwapAmountInRoute[] } => {
    let tokenOut = coins;
    const routes = connectedPools.reduce((current, pool) => {
        tokenOut = isCoinsEquals(pool.assets[0], tokenOut) ? pool.assets[1] : pool.assets[0];
        return [ ...current, { poolId: pool.id, tokenOutDenom: tokenOut.ibc?.representation || tokenOut.currency.baseDenom } ];
    }, [] as SwapAmountInRoute[]);
    const tokenInCoin = convertToCoin(coins, coins.ibc?.representation, minCoinsInAmount);
    return { tokenIn: tokenInCoin, routes };
};
