import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ParamKeyValuePair } from 'react-router-dom/dist/dom';
import IbcTransfer from '../ibc-transfer';
import { IbcTransferContextProvider, useIbcTransfer } from '../ibc-transfer-context';
import './ibc-transfer-page.scss';

const SOURCE_ID_KEY = 'sourceId';
const DESTINATION_ID_KEY = 'destinationId';

const IbcTransferPage: React.FC = () => {
    const { sourceData, destinationData } = useIbcTransfer();
    const [ , setParams ] = useSearchParams();

    useEffect(() => {
        const sourceId = sourceData.network?.chainId;
        const destinationId = destinationData.network?.chainId;
        const params: ParamKeyValuePair[] = [];
        if (sourceId) {
            params.push([ SOURCE_ID_KEY, sourceId ]);
        }
        if (destinationId) {
            params.push([ DESTINATION_ID_KEY, destinationId ]);
        }
        setParams(params);
    }, [ destinationData.network?.chainId, sourceData.network?.chainId, setParams ]);

    return (
        <div className='page ibc-transfer-page'>
            <IbcTransfer enableAddCustomRollapp getTokensSectionVisible />
        </div>
    );
};

const IbcTransferPageWithContext = () => {
    const [ params ] = useSearchParams();
    const sourceId = useMemo(() => params.get(SOURCE_ID_KEY) || undefined, [ params ]);
    const destinationId = useMemo(() => params.get(DESTINATION_ID_KEY) || undefined, [ params ]);

    return (
        <IbcTransferContextProvider initialSourceId={sourceId} initialDestinationId={destinationId}>
            <IbcTransferPage />
        </IbcTransferContextProvider>
    );
};

export default IbcTransferPageWithContext;
