import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import Spinner from '../../../../shared/components/spinner/spinner';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { convertDecimalToInt, formatNumber, roundNumber } from '../../../../shared/utils/number-utils';
import { useAmm } from '../../amm-context';
import { getPositionPart, getPositionBondedPart } from '../../amm.service';
import LiquidityDialog, { LiquidityDialogProps } from '../../liquidity-dialog/liquidity-dialog';
import { Pool } from '../../types';
import './pool-positions.scss';

interface PoolPositionsProps {
    pool: Pool;
    className?: string;
}

interface PoolPositionSectionProps {
    header: string,
    headerTooltip: string;
    sectionTitle: string;
    liquidity: number,
    disabled?: boolean;
    disabledInfo?: string;
    shares: bigint,
    aprValue: number,
    aprLabel: string;
    aprLoading?: boolean,
    actions: { [type in ('create' | 'add' | 'remove')]: { label: string, onClick: () => void } },
}

const PoolPositionSection: React.FC<PoolPositionSectionProps> = ({
    header,
    headerTooltip,
    sectionTitle,
    liquidity,
    disabled,
    disabledInfo,
    shares,
    aprValue,
    aprLabel,
    aprLoading,
    actions,
}) => {
    const {isMobile} = useWindowSize();
    const { ammState } = useAmm();
    return (
        <div className='pool-position-section-container'>
            <h5 className='pool-position-header'>
                {header}
                <InfoIndicator tooltipPlacement='right'>{headerTooltip}</InfoIndicator>
            </h5>
            <div className='section pool-position-section'>
                {ammState.loading || ammState.positionsLoading ? <Spinner size='large' /> : <>
                    <div className='position-liquidity-part'>
                        <p className='pool-position-section-title'>{sectionTitle}</p>
                        <h1>
                            {formatNumber(
                                liquidity, { maximumFractionDigits: 2, minimumFractionDigits: 0, style: 'currency', currency: 'USD' },
                            )}
                        </h1>
                        {isMobile && <span>{formatNumber(convertDecimalToInt(Number(shares)))} shares</span>}
                    </div>

                    <div className='position-actions-part'>
                        {shares ? <>
                            <Button className='pool-position-action' buttonType='secondary' onClick={actions.remove.onClick}>
                                {actions.remove.label}
                            </Button>
                            <Button className='pool-position-action' onClick={actions.add.onClick}>{actions.add.label}</Button>
                        </> : (
                            <Button
                                className='pool-position-action'
                                tooltip={disabledInfo}
                                disabled={disabled}
                                onClick={actions.create.onClick}
                            >
                                {actions.create.label}
                            </Button>
                        )}
                    </div>

                    <div className='bottom-info-texts'>
                        {!isMobile && <span>{formatNumber(convertDecimalToInt(Number(shares)))} shares</span>}

                        <span className='pool-position-apr-info'>
                        {aprLabel} <span className='apr-value'>
                            {aprLoading ? <Spinner className='apr-spinner' size='xs' /> : `${aprValue}%`}
                        </span> APR
                    </span>
                    </div>
                </>}
            </div>
        </div>
    );
};

export const PoolPositions: React.FC<PoolPositionsProps> = ({ pool, className }) => {
    const { ammState, getPoolLiquidity } = useAmm();
    const [ liquidityDialogProps, setLiquidityDialogProps ] = useState<LiquidityDialogProps>();

    const poolLiquidity = useMemo(() => getPoolLiquidity(pool) || 0, [ getPoolLiquidity, pool ]);
    const positionLiquidity = useMemo(() => poolLiquidity * getPositionPart(pool), [ pool, poolLiquidity ]);
    const positionBondedLiquidity = useMemo(() => poolLiquidity * getPositionBondedPart(pool), [ pool, poolLiquidity ]);

    return (
        <div className={classNames('pool-positions-container', className)}>
            <PoolPositionSection
                header='My Position'
                headerTooltip='Utilize your resources effectively and generate a profit through spreads on every swap.'
                sectionTitle='Position liquidity'
                liquidity={positionLiquidity}
                shares={pool.position?.shares || BigInt(0)}
                aprValue={roundNumber((pool.apr || 0) * 100, 2)}
                aprLabel='Convert your tokens into shares and earn from swap fees'
                aprLoading={ammState.paramsLoading}
                actions={{
                    add: { label: 'Add liquidity', onClick: () => setLiquidityDialogProps({ pool, type: 'Add' }) },
                    remove: { label: 'Remove liquidity', onClick: () => setLiquidityDialogProps({ pool, type: 'Remove' }) },
                    create: { label: 'Create a position', onClick: () => setLiquidityDialogProps({ pool, type: 'Add' }) },
                }}
            />

            <PoolPositionSection
                header='My Bonded Liquidity'
                headerTooltip='Pools may be eligible for additional rewards. Enhance your earnings by bonding your LP shares. Shares unbond instantly.'
                sectionTitle='Bonded liquidity'
                disabled={!pool.position?.shares}
                disabledInfo={!pool.position?.shares ? 'First, create a position' : undefined}
                liquidity={positionBondedLiquidity}
                shares={pool.position?.bondedShares || BigInt(0)}
                aprValue={roundNumber((pool.incentiveApr || 0) * 100, 2)}
                aprLabel='Rewards are streamed directly to your wallet earning'
                aprLoading={ammState.incentivesLoading || ammState.totalLockedValuesLoading}
                actions={{
                    add: { label: 'Bond shares', onClick: () => setLiquidityDialogProps({ pool, type: 'Bond' }) },
                    remove: { label: 'Unbond shares', onClick: () => setLiquidityDialogProps({ pool, type: 'Unbond' }) },
                    create: { label: 'Bond shares', onClick: () => setLiquidityDialogProps({ pool, type: 'Bond' }) },
                }}
            />

            {liquidityDialogProps &&
                <LiquidityDialog {...liquidityDialogProps} onRequestClose={() => setLiquidityDialogProps(undefined)} />}
        </div>
    );
};

export default PoolPositions;
