import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { AccountNetworkState } from '../account/account-network-state';
import { useAccountNetwork } from '../account/use-account-network';
import { useClient } from '../client/client-context';
import { getMaxDenomAmount } from '../currency/currency-service';
import { CoinsAmount, Currency } from '../currency/currency-types';
import { useNetwork } from '../network/network-context';
import { useWallet } from '../wallet/wallet-context';
import {
    getPrice,
    loadAmmParams,
    loadIncentives,
    loadIncentivesParams,
    loadLockedAssets,
    loadPools,
    loadPositions,
    loadTotalLockedValues,
} from './amm.service';
import { Asset } from './assets/assets-types';
import { AmmParams, Incentive, IncentivesParams, LockedAsset, Pool, PoolPosition } from './types';
import { AMM_STATE_DEFAULT, ammReducer, AmmState } from './amm-state';

interface AmmContextValue {
    ammState: AmmState;
    networkState: AccountNetworkState;
    sortedFilteredPools: Pool[];
    getTokenPrice: (
        coins: CoinsAmount,
        containerNetworkId?: string,
        priceOfOneToken?: boolean,
        vsCoins?: CoinsAmount,
    ) => number | undefined;
    getPoolLiquidity: (pool: Pool) => number | undefined;
    commonTokens: CoinsAmount[];
    loadMore: () => void;
    setSearchText: (searchText: string) => void;
}

const PAGE_SIZE = 15;

export const AmmContext = createContext<AmmContextValue>({} as AmmContextValue);

export const useAmm = (): AmmContextValue => useContext(AmmContext);

export const AmmContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { allNetworks, hubNetwork, networkDenoms, getNetwork, toHubCoins } = useNetwork();
    const { hubWallet } = useWallet();
    const { clientStateMap, handleClientError } = useClient();
    const [ ammState, ammStateDispatch ] = useReducer(ammReducer, AMM_STATE_DEFAULT);
    const [ networkState ] = useAccountNetwork(hubNetwork);
    const [ page, setPage ] = useState(0);
    const cancelAndSetPoolsPromise = useCancelablePromise<Pool[]>();
    const cancelAndSetLockedAssetsPromise = useCancelablePromise<LockedAsset[]>();
    const cancelAndSetPositionsPromise = useCancelablePromise<PoolPosition[]>();
    const cancelAndSetParamsPromise = useCancelablePromise<AmmParams>();
    const cancelAndSetIncentivesParamsPromise = useCancelablePromise<IncentivesParams>();
    const cancelAndSetIncentivesPromise = useCancelablePromise<{ [denom: string]: Incentive[] }>();
    const cancelAndSetTotalLockedValuesPromise = useCancelablePromise<{ [denom: string]: number }>();

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    const commonTokens = useMemo(() => allNetworks.reduce((current, network) => {
        if (!networkDenoms?.length) {
            return [];
        }
        return [
            ...current,
            ...network.currencies
                .filter((currency) => currency.common)
                .map<CoinsAmount>((currency) => {
                    const networkDenom = networkDenoms?.find((networkDenom) =>
                        networkDenom.baseDenom === currency.baseDenom && networkDenom.ibcNetworkId === network.chainId);
                    return {
                        currency,
                        amount: 0,
                        ibc: network.type === 'Hub' ? undefined : {
                            representation: networkDenom?.denom || '',
                            networkId: networkDenom?.ibcNetworkId || network.chainId,
                            path: networkDenom?.path || '',
                        },
                    };
                }),
        ];
    }, [] as CoinsAmount[]), [ allNetworks, networkDenoms ]);

    const getTokenPrice = useCallback((
        coins: CoinsAmount,
        containerNetworkId?: string,
        priceOfOneToken?: boolean,
        vsCoins?: CoinsAmount,
    ): number | undefined => {
        if (ammState.pools && ammState.params) {
            coins = priceOfOneToken ? { ...coins, amount: 1 } : coins;
            coins = toHubCoins(coins, containerNetworkId);
            return getPrice(ammState.pools, ammState.params, coins, vsCoins || ammState.params.vsCoins);
        }
    }, [ ammState.params, ammState.pools, toHubCoins ]);

    const getPoolLiquidity = useCallback((pool: Pool): number | undefined => {
        if (ammState.pools && ammState.params && pool.assets.length) {
            return getPrice(ammState.pools, ammState.params, pool.assets[0], ammState.params.vsCoins) * 2;
        }
    }, [ ammState.pools, ammState.params ]);

    const sortedFilteredPools = useMemo(() => {
        let filteredPools = ammState.pools || [];
        if (ammState.searchText) {
            const searchRegExp = new RegExp(ammState.searchText.trim(), 'i');
            filteredPools = filteredPools.filter(pool => pool.assets.some(asset =>
                searchRegExp.test(asset.currency.displayDenom) || searchRegExp.test(asset.currency.baseDenom)));
        }
        return filteredPools
            .sort((pool1, pool2) => (getPoolLiquidity(pool2) || 0) - (getPoolLiquidity(pool1) || 0))
            .slice(0, (page + 1) * PAGE_SIZE);
    }, [ ammState.pools, ammState.searchText, getPoolLiquidity, page ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === sortedFilteredPools.length) {
            setPage(page + 1);
        }
    }, [ page, sortedFilteredPools.length ]);

    const setSearchText = useCallback((searchText: string) => ammStateDispatch({ type: 'set-search-text', payload: searchText }), []);

    const updateAsset = useCallback((pool: Pool, assetIndex: 0 | 1, vsCoins: Currency, assetMap: { [assetDenom: string]: Asset }) => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;
        const poolAsset = pool.assets[assetIndex];
        const assetKey = poolAsset.ibc?.representation || poolAsset.currency.baseDenom;
        let currentAsset = assetMap[assetKey];
        if (!currentAsset) {
            const network = poolAsset.ibc ? getNetwork(poolAsset.ibc.networkId) : hubNetwork;
            if (!network) {
                return;
            }
            const price = (poolLiquidity / 2) / poolAsset.amount;
            let previousDayPrice = 0;
            if (pool.liquidity?.previousDayValue) {
                const previousDayLiquidity = getMaxDenomAmount(pool.liquidity.previousDayValue.value || 0, vsCoins);
                const previousDayAssetAmount = getMaxDenomAmount(assetIndex === 0 ?
                    pool.liquidity.previousDayValue.asset1Amount : pool.liquidity.previousDayValue.asset2Amount, poolAsset.currency);
                previousDayPrice = (previousDayLiquidity / 2) / previousDayAssetAmount;
            }
            assetMap[assetKey] = currentAsset = {
                ...poolAsset,
                key: assetKey,
                network,
                price,
                liquidity: 0,
                locked: 0,
                pools: [],
                liquidityDiff: 0,
                volume: 0,
                previousDayPrice,
                previousWeekVolume: 0,
            };
        }
        currentAsset.pools.push(pool);
        currentAsset.volume +=
            getMaxDenomAmount((pool.tradingVolume?.value.value || 0) - (pool.tradingVolume?.previousWeekValue?.value || 0), vsCoins);
        currentAsset.previousWeekVolume += getMaxDenomAmount((pool.tradingVolume?.previousWeekValue?.value || 0) -
            (pool.tradingVolume?.previousTwoWeeksValue?.value || 0), vsCoins);

        currentAsset.liquidity += poolLiquidity;
        if (pool.liquidity) {
            const analyticsLiquidity = getMaxDenomAmount(pool.liquidity.value.value || 0, vsCoins);
            const currentLiquidityDiff = (poolLiquidity - analyticsLiquidity) + getMaxDenomAmount(pool.liquidity.diffWeek || 0, vsCoins);
            currentAsset.liquidityDiff += currentLiquidityDiff;
        }
        currentAsset.locked += ammState.totalLockedValues?.[pool.lpTokenDenom] || 0;
    }, [ ammState.totalLockedValues, getNetwork, getPoolLiquidity, hubNetwork ]);

    useEffect(() => {
        const vsCoins = ammState.params?.vsCoins.currency;
        if (!vsCoins || !ammState.pools || !ammState.totalLockedValues) {
            return;
        }
        const assetMap = ammState.pools.reduce((current, pool) => {
            updateAsset(pool, 0, vsCoins, current);
            updateAsset(pool, 1, vsCoins, current);
            return current;
        }, {} as { [assetDenom: string]: Asset });
        ammStateDispatch({ type: 'set-assets', payload: Object.values(assetMap) });
    }, [ ammState.params, ammState.pools, ammState.totalLockedValues, updateAsset ]);

    useEffect(() => {
        if (hubWallet) {
            ammStateDispatch({ type: 'set-pool-positions-loading' });
            ammStateDispatch({ type: 'set-locked-assets-loading' });
        } else {
            ammStateDispatch({ type: 'set-pool-positions', payload: undefined });
            ammStateDispatch({ type: 'set-locked-assets', payload: undefined });
        }
    }, [ hubWallet ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-pools-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        ammStateDispatch({ type: 'set-pools-loading' });
        cancelAndSetPoolsPromise(loadPools(clientState.client))
            .then((pools) => ammStateDispatch({ type: 'set-pools', payload: pools }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-pools-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetPoolsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-locked-assets-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address) {
            return;
        }
        ammStateDispatch({ type: 'set-locked-assets-loading' });
        cancelAndSetLockedAssetsPromise(loadLockedAssets(clientState.client, networkState.address))
            .then((lockedAssets) => ammStateDispatch({ type: 'set-locked-assets', payload: lockedAssets }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-locked-assets-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetLockedAssetsPromise, clientState, handleClientError, networkState.address ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-pool-positions-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address || !ammState.lockedAssets) {
            return;
        }
        ammStateDispatch({ type: 'set-pool-positions-loading' });
        cancelAndSetPositionsPromise(loadPositions(clientState.client, networkState.address, ammState.lockedAssets))
            .then((positions) => ammStateDispatch({ type: 'set-pool-positions', payload: positions }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-pool-positions-loading', payload: false });
                handleClientError(error);
            });
    }, [ ammState.lockedAssets, cancelAndSetPositionsPromise, clientState, handleClientError, networkState.address ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        ammStateDispatch({ type: 'set-params-loading' });
        cancelAndSetParamsPromise(loadAmmParams(clientState.client))
            .then((params) => ammStateDispatch({ type: 'set-params', payload: params }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-params-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetParamsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-incentives-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        ammStateDispatch({ type: 'set-incentives-params-loading' });
        cancelAndSetIncentivesParamsPromise(loadIncentivesParams(clientState.client))
            .then((params) => ammStateDispatch({ type: 'set-incentives-params', payload: params }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-incentives-params-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetIncentivesParamsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-incentive-loading', payload: false });
            return;
        }
        if (!ammState.pools ||
            !ammState.params ||
            !ammState.incentivesParams ||
            ammState.incentives ||
            !clientState?.client ||
            clientState?.connecting) {
            return;
        }
        ammStateDispatch({ type: 'set-incentive-loading' });
        cancelAndSetIncentivesPromise(loadIncentives(clientState.client, ammState.pools, ammState.params, ammState.incentivesParams))
            .then((incentives) => ammStateDispatch({ type: 'set-incentive', payload: incentives }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-incentive-loading', payload: false });
                handleClientError(error);
            });
    }, [
        ammState.incentives,
        ammState.incentivesParams,
        ammState.params,
        ammState.pools,
        cancelAndSetIncentivesPromise,
        clientState,
        handleClientError,
    ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-total-locked-values-loading', payload: false });
            return;
        }
        if (!ammState.pools || !ammState.params || !clientState?.client || clientState?.connecting || ammState.totalLockedValues) {
            return;
        }
        ammStateDispatch({ type: 'set-total-locked-values-loading' });
        cancelAndSetTotalLockedValuesPromise(loadTotalLockedValues(clientState.client, ammState.pools, ammState.params))
            .then((values) => ammStateDispatch({ type: 'set-total-locked-values', payload: values }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-total-locked-values-loading', payload: false });
                handleClientError(error);
            });
    }, [
        ammState.totalLockedValues,
        ammState.params,
        ammState.pools,
        cancelAndSetTotalLockedValuesPromise,
        clientState,
        handleClientError,
    ]);

    return (
        <AmmContext.Provider
            value={{
                ammState,
                commonTokens,
                sortedFilteredPools,
                getTokenPrice,
                getPoolLiquidity,
                networkState,
                loadMore,
                setSearchText,
            }}
        >
            {children}
        </AmmContext.Provider>
    );
};
