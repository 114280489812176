import React, { useMemo } from 'react';
import Property from '../../../shared/components/property/property';
import Spinner from '../../../shared/components/spinner/spinner';
import StatisticsCards from '../../../shared/components/statistics-cards/statistics-cards';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import { getStakingCurrency, isCoinsEquals } from '../../currency/currency-service';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import { useStaking } from '../staking-context';
import { formatNumber } from '../../../shared/utils/number-utils';
import Button from '../../../shared/components/button/button';
import './staking-analytics.scss';

export default function StakingAnalytics(): JSX.Element {
    const {
        network,
        networkState,
        stakingDataState,
        stakedValidatorsData,
        unstakingValidatorsData,
        rewardsTxState,
        withdrawRewards,
    } = useStaking();

    const stakingCurrency = useMemo(() => getStakingCurrency(network), [ network ]);

    const availableBalanceAmount = useMemo(() => {
        const amount = networkState.balances?.find((balance) => isCoinsEquals(balance, { currency: stakingCurrency, amount: 0 }))?.amount ||
            0;
        return formatNumber(amount, { maximumFractionDigits: 4 });
    }, [ networkState.balances, stakingCurrency ]);

    const stakedAmount = useMemo(() => {
        const amount = stakingDataState?.delegations
            ?.reduce((current, validator) => current + (validator?.coins?.amount || 0), 0) || 0;
        return formatNumber(amount, { maximumFractionDigits: 4 });
    }, [ stakingDataState?.delegations ]);

    const unstakingAmount = useMemo(() => {
        const amount = unstakingValidatorsData?.state?.validators
            ?.reduce((current, validator) => current + (validator.unstaking?.amount || 0), 0) || 0;
        return formatNumber(amount, { maximumFractionDigits: 4 });
    }, [ unstakingValidatorsData?.state?.validators ]);

    const totalRewardsAmount = useMemo(
        () => stakedValidatorsData?.state?.validators?.reduce((current, validator) => current + (validator.reward || 0), 0) || 0,
        [ stakedValidatorsData?.state?.validators ],
    );

    return (
        <StatisticsCards>
            <Property label='Available Balance'>
                {networkState.balancesLoading ? <Spinner /> : `${availableBalanceAmount} ${stakingCurrency.displayDenom}`}
            </Property>

            <Property label='Staked Amount'>
                {stakingDataState?.delegationsLoading ? <Spinner /> : `${stakedAmount} ${stakingCurrency.displayDenom}`}
            </Property>

            <Property label='Unstaking Amount'>
                {unstakingValidatorsData?.state?.loading ? <Spinner /> : `${unstakingAmount} ${stakingCurrency.displayDenom}`}
            </Property>

            <Property label='Claimable Rewards' className='withdraw-property'>
                {stakedValidatorsData?.state?.loading || stakingDataState?.rewardsLoading ?
                    <Spinner /> :
                    `${(totalRewardsAmount < 0.0001 ? '< 0.0001' : formatNumber(totalRewardsAmount, { maximumFractionDigits: 4 }))}
                    ${stakingCurrency.displayDenom}`}

                <Tooltip title='Claim all' placement='left'>
                    <Button
                        className='withdraw-button'
                        buttonType='icon'
                        disabled={!totalRewardsAmount || rewardsTxState?.broadcasting}
                        loading={rewardsTxState?.broadcasting}
                        onClick={() => withdrawRewards()}
                    >
                        <WalletIcon />
                    </Button>
                </Tooltip>
            </Property>
        </StatisticsCards>
    );
}


