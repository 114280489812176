import classNames from 'classnames';
import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import Badge, { BadgeProps } from '../../../shared/components/badge/badge';
import Spinner from '../../../shared/components/spinner/spinner';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { formatNumber } from '../../../shared/utils/number-utils';
import { getCurrencyLogoPath } from '../../currency/currency-service';
import { useNetwork } from '../../network/network-context';
import { useAmm } from '../amm-context';
import { Incentive } from '../types';
import './incentive-badge.scss';

export interface IncentiveBadgeProps extends Omit<BadgeProps, 'label'> {
    denom: string;
    label?: ReactNode;
}

const IncentiveBadge: React.FC<IncentiveBadgeProps> = ({
    denom,
    label = 'Incentives',
    infoPlacement = 'top',
    className,
    ...otherBadgeProps
}) => {
    const { isMobile } = useWindowSize();
    const { getNetwork, hubNetwork } = useNetwork();
    const { ammState } = useAmm();

    const activeIncentives = useMemo(
        () => ammState.incentives?.[denom].filter((incentive) => incentive.startTime <= new Date()),
        [ ammState.incentives, denom ],
    );

    const upcomingIncentives = useMemo(
        () => ammState.incentives?.[denom].filter((incentive) => incentive.startTime > new Date()),
        [ ammState.incentives, denom ],
    );

    const getDisplayedTargetTime = useCallback((date: Date): string => {
        const diffDate = date.getTime() - new Date().getTime();
        const minutes = Math.round(diffDate / (1000 * 60));
        let hours = 0;
        let days = 0;
        if (minutes > 120) {
            hours = Math.round(minutes / 60);
            if (hours > 48) {
                days = Math.round(hours / 24);
            }
        }
        return days ? `${days} days` : hours ? `${hours} hours` : `${minutes} minutes`;
    }, []);

    const renderIncentivesBadgeInfo = (): ReactElement => {
        if (!ammState.incentives) {
            return <Spinner />;
        }
        return <div className='tooltip-content'>
            {activeIncentives?.length ? (
                <>
                    <h6>Incentive plans:</h6>
                    {renderIncentives(activeIncentives)}
                </>
            ) : undefined}
            {activeIncentives?.length && upcomingIncentives?.length ? <span className='border' /> : undefined}
            {upcomingIncentives?.length ? (
                <>
                    <h6>Upcoming incentive plans:</h6>
                    {renderIncentives(upcomingIncentives, true)}
                </>
            ) : undefined}
        </div>;
    };

    const renderActiveIncentiveAmounts = (amount: number, incentive: Incentive, coinsIndex: number): ReactElement => {
        const distributedAmount = incentive.distributedCoins[coinsIndex]?.amount || 0;
        return <>
            {formatNumber(distributedAmount, { maximumFractionDigits: 2, notation: 'compact' })}
            / {formatNumber(amount, { maximumFractionDigits: 2, notation: 'compact' })} streamed&nbsp;&nbsp;
            <span className='end-time'>
              ends in {getDisplayedTargetTime(incentive.endTime)}
            </span>
        </>;
    };

    const renderUpcomingIncentiveAmounts = (amount: number, incentive: Incentive): ReactElement => {
        return <>
            {formatNumber(amount, { maximumFractionDigits: 2, notation: 'compact' })} total&nbsp;&nbsp;
            <span className='start-time'>
                starts in {getDisplayedTargetTime(incentive.startTime)}
            </span>
        </>;
    };

    const renderIncentives = (incentives: Incentive[], upcoming?: boolean): ReactElement => {
        return (
            <ul className='incentive-coins-list'>
                {incentives.map((incentive, incentiveIndex) => incentive.coins.map((coins, coinsIndex) => {
                    const currencyNetwork = coins.ibc ? getNetwork(coins.ibc.networkId) : hubNetwork;
                    if (!currencyNetwork) {
                        return undefined;
                    }
                    return (
                        <li className='coins' key={`${incentiveIndex}-${coinsIndex}`}>
                            <img className='currency-logo' src={getCurrencyLogoPath(coins.currency, currencyNetwork)} alt='currency logo' />
                            {coins.currency.displayDenom}
                            <span className='amount'>
                                {upcoming ?
                                    renderUpcomingIncentiveAmounts(coins.amount, incentive) :
                                    renderActiveIncentiveAmounts(coins.amount, incentive, coinsIndex)}
                            </span>
                        </li>
                    );
                }))}
            </ul>
        );
    };

    return (
        <Badge
            className={classNames(
                'incentives-badge', className,
                { upcoming: Boolean(!activeIncentives?.length && upcomingIncentives?.length) },
            )}
            label={label}
            rounded={otherBadgeProps.rounded ?? isMobile}
            info={renderIncentivesBadgeInfo()}
            tooltipClassName='tooltip-container'
            infoPlacement={infoPlacement}
            {...otherBadgeProps}
        />
    );
};

export default IncentiveBadge;
