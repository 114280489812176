import React from 'react';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { AnalyticsState } from '../../../analytics/analytics-state';
import { getMaxDenomAmount } from '../../../currency/currency-service';
import AreaChart from '../../../network/statistics/charts/area-chart/area-chart';
import { useAmm } from '../../amm-context';
import { PoolAnalytics } from '../../statistics/analytics/pool-analytics-types';

interface TradingVolumeChartProps {
    analyticsState: AnalyticsState<PoolAnalytics>;
    chartOptions?: string[];
    selectedChart?: string;
    className?: string;
    onChartSelect?: (value: string) => void;
}

export const TradingVolumeChart: React.FC<TradingVolumeChartProps> = ({
    analyticsState,
    className,
    selectedChart,
    onChartSelect,
    chartOptions,
}) => {
    const { ammState } = useAmm();

    return (
        <AreaChart
            label='Trading Volume'
            valueLabel='Amount'
            selectedChart={selectedChart}
            chartOptions={chartOptions}
            onChartSelect={onChartSelect}
            fetchComparableValues={(value) => value.value}
            compareDiffs
            formatValue={(value, options) =>
                formatNumber(ammState.params ? getMaxDenomAmount(value, ammState.params?.vsCoins.currency) : 0, options)}
            formatValueOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 1, style: 'currency', currency: 'USD' }}
            className={className}
            loading={analyticsState.loading}
            data={analyticsState.analytics?.tradingVolume}
        />
    );
};

export default TradingVolumeChart;
