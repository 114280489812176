import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from '../../../shared/components/form-controls/select/select';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Input from '../../../shared/components/form-controls/input/input';
import Button from '../../../shared/components/button/button';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import { MenuRefProps } from '../../../shared/components/menu/menu';
import { useAmm } from '../../amm/amm-context';
import { useClient } from '../../client/client-context';
import { useWallet } from '../../wallet/wallet-context';
import { getCurrencyLogoPath, getFeeCurrency, getMainCurrency, isCoinsEquals } from '../../currency/currency-service';
import { formatNumber, formatPrice } from '../../../shared/utils/number-utils';
import { CoinsAmount } from '../../currency/currency-types';
import { TxState } from '../tx-state';
import { AmountTxState } from './amount-tx-state';
import { ReactComponent as ExplorerIcon } from '../../../assets/icons/explorer.svg';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { DeliveryTxCode, TxResponse } from '../tx-types';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import { TxError } from '../tx-error';
import { AccountNetworkState } from '../../account/account-network-state';
import Spinner from '../../../shared/components/spinner/spinner';
import { useNetwork } from '../../network/network-context';
import CommonTokenBadge from './common-token-badge/common-token-badge';
import './amount-tx.scss';

interface Fee {
    label: ReactNode;
    value?: CoinsAmount | string | number;
    loading?: boolean;
}

interface AmountTxProps {
    txState: TxState,
    amountTxState: AmountTxState,
    networkState: AccountNetworkState;
    availableBalances?: CoinsAmount[];
    disabledBalances?: CoinsAmount[];
    commonTokens?: CoinsAmount[];
    reduceFeeFromBalances?: boolean;
    showPrice?: boolean;
    onCoinsChange: (coins: CoinsAmount) => void;
    getTxResponseMessage?: (response: TxResponse) => Partial<SnackbarMessage> | undefined;
    controlSize?: 'medium' | 'large';
    submitButtonContainer?: ReactElement;
    displayFee?: boolean;
    hideMaxValueAction?: boolean;
    amountDisabled?: boolean;
    loading?: boolean;
    inputLoading?: boolean;
    extraFees?: Fee[];
}

const TRANSACTION_IN_PROGRESS_KEY = 'transactionInProgress';

const AmountTx: React.FC<AmountTxProps> = ({
    txState,
    amountTxState,
    networkState,
    availableBalances,
    disabledBalances,
    commonTokens,
    reduceFeeFromBalances = true,
    amountDisabled,
    getTxResponseMessage,
    onCoinsChange,
    controlSize,
    submitButtonContainer,
    loading,
    inputLoading,
    displayFee = true,
    showPrice,
    extraFees = [],
    hideMaxValueAction,
}) => {
    const { showErrorMessage, showMessage, showWarningMessage, removeMessage } = useSnackbar();
    const { getNetwork } = useNetwork();
    const { networkWalletMap } = useWallet();
    const { clientError } = useClient();
    const { getTokenPrice } = useAmm();
    const [ currentAmount, setCurrentAmount ] = useState<string>('');
    const [ handledResponses, setHandledResponses ] = useState<{ [key: string]: boolean }>({});
    const containerRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<MenuRefProps>(null);

    const networkWallet = networkState.network ? networkWalletMap[networkState.network?.chainId] : null;

    useEffect(() => setCurrentAmount(''), [ networkState.network ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        if (currentAmount.endsWith('.')) {
            return;
        }
        if (amountTxState.coins?.amount !== undefined) {
            setCurrentAmount(amountTxState.coins.amount ? amountTxState.coins.amount.toString() : '');
        } else if (!Number(currentAmount)) {
            setCurrentAmount('');
        }
    }, [ amountTxState.coins?.amount, currentAmount ]);

    // todo: when sending all ibc tokens (from rollapp), the balances removed the token and the currency selector is empty

    useEffect(() => {
        if (txState.broadcasting) {
            removeMessage(TRANSACTION_IN_PROGRESS_KEY);
            if (txState.signing) {
                showMessage({
                    content: 'Your wallet is waiting for confirmation and a signature...',
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            } else if (txState.routeSearching) {
                showMessage({
                    content: 'Searching for best route… might take a while.',
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            } else {
                showMessage({
                    content: (
                        <div className='horizontally-centered'>
                            <Spinner size='small' />&nbsp;&nbsp;Transaction is in progress.
                        </div>
                    ),
                    duration: 600000,
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            }
        } else {
            setTimeout(() => removeMessage(TRANSACTION_IN_PROGRESS_KEY), 50);
        }
    }, [ removeMessage, showMessage, txState.broadcasting, txState.routeSearching, txState.signing ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        if (!txState.response || handledResponses[txState.response.hash]) {
            return;
        }
        const { hash, network, deliveryTxCode } = txState.response;
        let exploreLink: string = '';
        try {
            exploreLink = network.exploreTxUrl ? (new URL(hash, network.exploreTxUrl)).href : '';
        } catch {}
        const action: SnackbarMessage['action'] = exploreLink ?
            { label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>, callback: () => window.open(exploreLink, '_blank') } :
            undefined;
        const message = getTxResponseMessage?.(txState.response);
        let content = message?.content;
        if (!content) {
            switch (deliveryTxCode) {
                case DeliveryTxCode.SUCCESS:
                    content = 'Transaction successfully submitted!';
                    break;
                case DeliveryTxCode.INSUFFICIENT_FUNDS:
                    content = 'Transaction delivery failed - insufficient funds';
                    break;
                case DeliveryTxCode.OUT_OF_GAS:
                    content = 'Transaction delivery failed - out of gas';
                    break;
                default:
                    console.log('Transaction delivery failed with code: ' + deliveryTxCode);
                    content = 'Transaction delivery failed, please try again later';
            }
        }
        showMessage({
            content,
            action,
            type: deliveryTxCode === DeliveryTxCode.SUCCESS ? 'success' : 'error',
            key: message?.key || hash,
            ...message,
        });
        setCurrentAmount('');
        setHandledResponses({ ...handledResponses, [txState.response.hash]: true });
    }, [ getTxResponseMessage, txState.response, showMessage, handledResponses, removeMessage ]);

    useEffect(() => {
        if (!(txState.error instanceof TxError)) {
            return;
        }
        switch (txState.error.code) {
            case 'MISSING_DATA':
                showErrorMessage('Transaction delivery failed: invalid transaction parameters.');
                break;
            default:
                showErrorMessage('Transaction delivery failed, please try again later');
        }
    }, [ txState.error, showErrorMessage ]);

    // todo: handle errors different
    useEffect(() => {
        if (!clientError) {
            return;
        }
        const network = clientError.network;
        const networkNameLabel = clientError.network?.chainName || 'the';
        switch (clientError.code) {
            case 'FETCH_DATA_FAILED':
                showErrorMessage(`Can't fetch data from ${networkNameLabel} client, please try again later`);
                break;
            case 'SIMULATE_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to calculate fee, please try again later`);
                break;
            case 'BROADCAST_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to broadcast the transaction, please try again later`);
                break;
            case 'NO_BALANCES':
                const currency = network ? getMainCurrency(network) : undefined;
                const action: SnackbarMessage['action'] = !currency || !network?.faucetUrl ? undefined :
                    {
                        label: 'Get ' + currency.displayDenom,
                        callback: () => window.open(network?.faucetUrl, '_blank'),
                        close: true,
                    };
                showWarningMessage({
                    content: <>
                        There are no balances in your {network ? `${network.chainName} ` : ''}account.<br />
                        Send some tokens there before trying to query or make a transaction.
                    </>,
                    action, duration: 20000,
                    key: 'no-balances-' + network?.chainId,
                });
                break;
            case 'UNSUPPORTED_MESSAGE':
                showErrorMessage(`This transaction not supported by ${networkWallet?.getWalletType() || 'the connected wallet'}`);
                break;
            case 'INSUFFICIENT_FUNDS':
                showWarningMessage(`Insufficient balance in your ${network ? `${network.chainName} ` : ''}account`);
                break;
            case 'SIGNATURE_VERIFICATION_FAILED':
                showErrorMessage(`Signature verification failed`);
                break;
            case 'INSUFFICIENT_FEES':
                showErrorMessage(`The transaction broadcast encountered a failure due to insufficient fees`);
                break;
            case 'INVALID_COINS':
                showErrorMessage('Insufficient coins type or amount');
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage('The request rejected by the user');
                break;
            default:
                // todo: handle errors different
                showErrorMessage(`${networkNameLabel} client connection failed, please try again later`);
        }
    }, [ clientError, networkWallet, showErrorMessage, showWarningMessage ]);

    const renderCurrencyOption = (balance = amountTxState.coins, showAmount?: boolean): ReactElement | null => {
        if (!balance) {
            return null;
        }
        const showNetworkId = balances.some((otherBalance) =>
            otherBalance.currency.displayDenom === balance.currency.displayDenom && !isCoinsEquals(otherBalance, balance),
        );
        const currencyNetwork = balance.ibc ? getNetwork(balance.ibc.networkId) : networkState.network;
        return <>
            {currencyNetwork ? (
                <img className='currency-logo' src={getCurrencyLogoPath(balance.currency, currencyNetwork)} alt='currency logo' />
            ) : null}

            <span className='currency-name-container'>
                {balance.currency.displayDenom}
                {showNetworkId && (
                    <span className='currency-network-id'>{balance.ibc?.networkId || networkState.network?.chainId}</span>
                )}
            </span>

            {showAmount && balance.amount !== undefined && <span className='currency-option-balance'>
                {formatNumber(balance.amount, { minimumFractionDigits: 2, maximumFractionDigits: Math.max(2, balance.currency.decimals) })}
            </span>}
        </>;
    };

    const balances = useMemo(
        () => availableBalances || networkState.balances ||
            networkState.network?.currencies.map((currency) => ({ currency, amount: 0 })) as CoinsAmount[] || [],
        [ availableBalances, networkState.balances, networkState.network?.currencies ],
    );

    const currencyOptionValue = useMemo(
        () => balances.findIndex((balance) => amountTxState.coins && isCoinsEquals(balance, amountTxState.coins)),
        [ amountTxState.coins, balances ],
    );

    const fees = useMemo((): Fee[] => {
        let feeCoins = txState.fee?.coins;
        if (!feeCoins) {
            const networkFeeCurrency = networkState.network && getFeeCurrency(networkState.network);
            feeCoins = networkFeeCurrency && { currency: networkFeeCurrency, amount: 0 };
        }
        return [ { label: 'Estimated fee', value: feeCoins, loading: txState.feeLoading }, ...extraFees ];
    }, [ extraFees, networkState.network, txState.fee?.coins, txState.feeLoading ]);

    const renderCurrencyOptions = (): ReactElement[] => {
        return balances.map((balance, balanceIndex) => (
            <Option
                className='currency-option'
                value={balanceIndex}
                key={balanceIndex}
                disabled={disabledBalances?.some((disabledBalance) => isCoinsEquals(disabledBalance, balance))}
            >
                {renderCurrencyOption(balance, true)}
            </Option>
        ));
    };

    const getSelectedCurrencyBalance = (): string => {
        if ((reduceFeeFromBalances && (networkState.balancesLoading || txState.feeLoading)) || loading) {
            return 'Available: loading...';
        }
        const { coins, availableAmount } = amountTxState;
        if (!networkState.network || !coins) {
            return '';
        }
        if (!networkWallet) {
            return `Available: 0.00 ${coins.currency.displayDenom}`;
        }
        const balance = formatNumber(
            availableAmount, { minimumFractionDigits: 2, maximumFractionDigits: Math.max(2, coins.currency.decimals) },
        );
        return `Available: ${balance} ${coins.currency.displayDenom}`;
    };

    const getFeeValue = (fee?: CoinsAmount | string | number, feeLoading?: boolean): ReactElement | string => {
        if (loading || feeLoading) {
            return <Spinner size='small' />;
        }
        if (typeof fee === 'string' || typeof fee === 'number') {
            return fee.toString();
        }
        const feeDenom = fee?.currency.displayDenom;
        if (!networkState.network || !networkWallet || !fee?.amount) {
            return feeDenom ? `- ${feeDenom}` : '';
        }
        if (fee.amount < 0.0001) {
            return `< 0.0001 ${feeDenom}`;
        }
        return `${formatNumber(fee?.amount || 0, { minimumFractionDigits: 2, maximumFractionDigits: 4 })} ${feeDenom}`;
    };

    const onCurrencySelect = (currencyIndex: number): void => {
        const balance = balances[currencyIndex];
        if (balance?.currency) {
            setCurrentAmount('');
            onCoinsChange({ ...balance, amount: 0 });
        }
    };

    const onAmountChange = (value: string, previousValue: string): string => {
        let { coins, availableAmount } = amountTxState;
        if (!networkState.network || !coins) {
            return value;
        }
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        const amountPattern = new RegExp('^[0-9]*(\\.[0-9]{0,' + coins.currency.decimals + '})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        let amount = Number(value);
        if (availableAmount && !hideMaxValueAction && amount > availableAmount) {
            amount = availableAmount;
            value = amount.toString();
        }
        onCoinsChange({ ...coins, amount });
        return value;
    };

    const setFullAmount = (): void => {
        const { coins, availableAmount } = amountTxState;
        if (!coins) {
            return;
        }
        setCurrentAmount(availableAmount.toString());
        onCoinsChange({ ...coins, amount: availableAmount });
    };

    const renderInputAmountSuffix = (): ReactElement | undefined => {
        return <>
            {inputLoading && <Spinner size={'small'} className='input-loader' />}

            {showPrice && (
                <span className='amount-price'>
                    ≈&nbsp;
                    {formatPrice((amountTxState.coins && getTokenPrice(amountTxState.coins)) || 0, undefined, { notation: 'compact' })}
                </span>
            )}

            {!hideMaxValueAction && (
                <Button
                    className='amount-action'
                    buttonType='secondary'
                    size='xs'
                    disabled={inputDisabled ||
                        !amountTxState.coins || (reduceFeeFromBalances && (networkState.balancesLoading || txState.feeLoading))}
                    onClick={setFullAmount}
                >
                    MAX
                </Button>
            )}
        </>;
    };

    const currencyOptions = renderCurrencyOptions();

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const coin = balances?.[value as number];
        if (!coin) {
            return false;
        }
        const searchRegExp = new RegExp(searchText, 'i');
        return searchRegExp.test(coin.currency.displayDenom) || searchRegExp.test(coin.currency.baseDenom);
    }, [ balances ]);

    const inputDisabled = useMemo(
        () => amountDisabled || disabledBalances?.some(
            (disabledBalance) => amountTxState.coins && isCoinsEquals(disabledBalance, amountTxState.coins)),
        [ amountDisabled, amountTxState.coins, disabledBalances ],
    );

    const renderHeader = (): ReactElement | null => {
        if (!commonTokens?.length) {
            return null;
        }
        return (
            <div className='common-tokens' style={{ width: containerRef.current?.offsetWidth }}>
                {commonTokens.map((token, tokenIndex) => {
                    const showNetworkId = commonTokens?.some((otherToken, otherTokenIndex) =>
                        tokenIndex !== otherTokenIndex &&
                        token.currency.displayDenom.toLowerCase() === otherToken.currency.displayDenom.toLowerCase());
                    return (
                        <CommonTokenBadge
                            key={tokenIndex}
                            token={token}
                            showNetworkId={showNetworkId}
                            selected={amountTxState.coins && isCoinsEquals(amountTxState.coins, token)}
                            onClick={() => {
                                selectRef.current?.toggleMenu(false);
                                setCurrentAmount('');
                                onCoinsChange(token);
                            }}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className='amount-tx-container' ref={containerRef}>
            <ControlsComposer className='amount-controls'>
                <Select
                    ref={selectRef}
                    searchPlaceholder='Search...'
                    searchFilterPredicate={searchFilterPredicate}
                    className='token-select'
                    emptySearchResultsLabel='No results found'
                    controlSize={controlSize}
                    header={renderHeader()}
                    value={currencyOptionValue}
                    disabled={!networkState.network}
                    placeholder='Select a token'
                    optionsMenuOpenDisabled={currencyOptionValue >= 0 && availableBalances?.length === 1}
                    moreOptionsLoading={Boolean(!availableBalances && networkState.balancesLoading)}
                    renderTriggerSelectedOption={() => renderCurrencyOption()}
                    onSelect={(currencyIndex) => onCurrencySelect(Number(currencyIndex))}
                    loading={loading}
                >
                    {currencyOptions}
                </Select>
                <Input
                    placeholder='0.00'
                    controlSize={controlSize}
                    value={currentAmount}
                    disabled={inputDisabled}
                    suffix={renderInputAmountSuffix()}
                    onValueChange={onAmountChange}
                />
            </ControlsComposer>

            <p className='selected-currency-balance'>{getSelectedCurrencyBalance()}</p>
            {submitButtonContainer}
            <span className='transaction-bottom-space' />
            {displayFee && fees.length ? (
                <div className='transaction-fees-container section small'>
                    {fees.map((fee, feeIndex) =>
                        <p key={feeIndex} className='transaction-fee'>
                            {fee.label} <span className='transaction-fee-value'>{getFeeValue(fee.value, fee.loading)}</span>
                        </p>)}
                </div>
            ) : undefined}
        </div>
    );
};

export default AmountTx;
