import { GovernanceParams, Proposal, ProposalStatus } from './governance-types';

export interface GovernanceState {
    proposals?: Proposal[];
    filteredSortedProposals?: Proposal[];
    params?: GovernanceParams;
    paramsLoading?: boolean;
    proposalsLoading?: boolean;
    searchText?: string;
    statusFilter?: ProposalStatus;
    error?: any;
}

export type GovernanceAction =
    { type: 'set-proposals', payload: Proposal[] | undefined } |
    { type: 'set-proposals-loading', payload?: boolean } |
    { type: 'set-params', payload: GovernanceParams } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-search-text', payload: string | undefined } |
    { type: 'set-status-filter', payload: ProposalStatus | undefined } |
    { type: 'set-error', payload: any };

export const GOVERNANCE_STATE_DEFAULT: GovernanceState = {
    proposalsLoading: true,
    paramsLoading: true,
};

export const governanceReducer = (state: GovernanceState, action: GovernanceAction): GovernanceState => {
    switch (action.type) {
        case 'set-proposals':
            return getFilteredAndSortedProposals({ ...state, proposals: action.payload, proposalsLoading: false });
        case 'set-proposals-loading':
            return { ...state, proposalsLoading: action.payload ?? true };
        case 'set-search-text':
            return getFilteredAndSortedProposals({ ...state, searchText: action.payload });
        case 'set-status-filter':
            return getFilteredAndSortedProposals({ ...state, statusFilter: action.payload });
        case 'set-params':
            return getFilteredAndSortedProposals({ ...state, params: action.payload, paramsLoading: false });
        case 'set-params-loading':
            return { ...state, paramsLoading: action.payload ?? true };
        case 'set-error':
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

const getFilteredAndSortedProposals = (state: GovernanceState): GovernanceState => {
    if (state.params && state.proposals) {
        state.proposals = state.proposals.filter((proposal) =>
            !proposal.finalVotesSummary || !state.params || proposal.finalVotesSummary.Veto.part < state.params.vetoThreshold);
    }
    let filteredSortedProposals = state.proposals || [];
    if (state.statusFilter) {
        filteredSortedProposals = filteredSortedProposals.filter((proposal) => proposal.status === state.statusFilter);
    }
    if (state.searchText) {
        const searchRegExp = new RegExp(state.searchText.trim(), 'i');
        filteredSortedProposals =
            filteredSortedProposals.filter((proposal) => searchRegExp.test(proposal.title) || searchRegExp.test(proposal.description));
    }
    filteredSortedProposals = filteredSortedProposals.sort((proposal1, proposal2) => proposal2.submitTime - proposal1.submitTime);
    return { ...state, filteredSortedProposals };
};
