import classNames from 'classnames';
import React, { useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { AccountNetworkState } from '../../../account-network-state';
import { useAmm } from '../../../../amm/amm-context';
import { getStakingCurrency } from '../../../../currency/currency-service';
import { useStaking } from '../../../../staking/staking-context';
import './account-total-staked-value.scss';

interface AccountTotalValueProps {
    className?: string;
    networkState: AccountNetworkState;
}

const AccountTotalStakedValue: React.FC<AccountTotalValueProps> = ({ className, networkState }) => {
    const { getTokenPrice } = useAmm();
    const { stakedValidatorsData, stakingDataState } = useStaking();

    const stakeCurrency = useMemo(() => networkState.network && getStakingCurrency(networkState.network), [ networkState ]);

    const totalStakedValue = useMemo(
        () => {
            const totalStakedAmount = stakedValidatorsData?.state.validators?.reduce((current, validator) => current +
                (validator.amountStaked || 0), 0) || 0;
            return (stakeCurrency &&
                getTokenPrice({ amount: totalStakedAmount, currency: stakeCurrency }, networkState.network?.chainId)) || 0;
        },
        [ getTokenPrice, networkState.network?.chainId, stakeCurrency, stakedValidatorsData?.state.validators ],
    );

    return (
        <Property
            label='Total Staked'
            className={classNames('account-total-staked-value', className)}
            valueClassName='property-value'
            labelClassName='property-label'
        >
            {stakingDataState?.delegationsLoading && !totalStakedValue ?
                <Spinner /> :
                formatPrice(totalStakedValue, undefined, undefined, 10)}
        </Property>
    );
};

export default AccountTotalStakedValue;
