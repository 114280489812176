import { loadAnalyticsItem, loadAnalyticsMap } from '../../../analytics/analytics-service';
import { PoolAnalytics, PoolsAnalyticsMap } from './pool-analytics-types';

const analyticsUrl = process.env.REACT_APP_FETCH_POOL_ANALYTICS_URL || '';

export const loadPoolAnalyticsMap = async <T extends string, R extends PoolsAnalyticsMap = PoolsAnalyticsMap>(
    poolIds: string[],
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    return loadAnalyticsMap(poolIds, 'poolIds', analyticsUrl, history);
};

export const loadPoolAnalytics = async <T extends string, R extends PoolAnalytics = PoolAnalytics>(
    poolId: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    return loadAnalyticsItem(poolId, 'poolIds', analyticsUrl, history);
};
