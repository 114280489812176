export const DEFAULT_PIN_CODE_LENGTH = 6;
export const PIN_CODE_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const escapeRegExp = (text: string): string => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const generatePinCode = (length = DEFAULT_PIN_CODE_LENGTH): string => {
    return Array.from({ length })
        .reduce<string>((current) => current + PIN_CODE_CHARACTERS.charAt(Math.floor(Math.random() * PIN_CODE_CHARACTERS.length)), '');
};

export const getShortenedAddress = (address: string, prefixLetters = 6, suffixLetters = 4): string => {
    return `${address.slice(0, prefixLetters)}...${address.slice(address.length - suffixLetters)}`;
};

export const isJsonValid = (json: string | object, andNotEmpty?: boolean): boolean => {
    try {
        let jsonObject: object | number | string;
        if (typeof json === 'string') {
            jsonObject = JSON.parse(json);
        } else {
            JSON.stringify(json);
            jsonObject = json;
        }
        return !andNotEmpty || typeof jsonObject === 'number' || Object.keys(jsonObject).length > 0;
    } catch {
        return false;
    }
};
