import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { validateAndGetChildrenAsArray } from '../../utils/react-utils';
import Menu, { MenuAction } from '../menu/menu';
import Link, { LinkProps } from '../link/link';
import './nav-bar.scss';

export interface NavBarProps {
    children: ReactNode;
    className?: string;
}

export interface NavBarItemProps extends Partial<LinkProps> {
    label: ReactNode;
    hideMoreMenuIcon?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ children, className }) => {
    const navItems = validateAndGetChildrenAsArray(children, NavBarItem);

    return (
        <nav className={classNames('nav-bar', className)}>
            <ul className='nav-item-list'>{navItems}</ul>
        </nav>
    );
};

export const NavBarItem: React.FC<NavBarItemProps> = ({
    label,
    url,
    children,
    preventRedirect,
    hideMoreMenuIcon,
    onClick,
    ...otherLinkProps
}) => {
    const navigate = useNavigate();
    const subMenuItems = validateAndGetChildrenAsArray(children, NavBarItem);

    const onNavItemClick = (event: React.MouseEvent): void => {
        if (subMenuItems.length && !preventRedirect) {
            navigate(`${url || ''}${subMenuItems[0].props.url}`);
        }
        onClick?.(event);
    };

    const navItem = (
        <Link
            className='nav-bar-item'
            url={url}
            preventRedirect={preventRedirect || subMenuItems.length > 0}
            onClick={onNavItemClick}
            {...otherLinkProps}
        >
            {label} {subMenuItems.length && !hideMoreMenuIcon ? <ArrowDownIcon className='arrow-down' /> : null}
        </Link>
    );

    if (!subMenuItems.length) {
        return navItem;
    }

    return (
        <Menu trigger={navItem} triggerOnHover>
            {subMenuItems.map((item, itemIndex) => (
                <MenuAction key={itemIndex} {...item.props} url={`${url || ''}${item.props.url}`}>{item.props.label}</MenuAction>
            ))}
        </Menu>
    );
};

export default NavBar;
