import { Sequencer as CosmosSequencer } from '../client/station-clients/dymension/generated/sequencer/sequencer';
import { ClientError } from '../client/client-error';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoinsAmount } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { getNetworkData } from '../network/network-service';
import { Network } from '../network/network-types';
import { Sequencer } from './types';

export const loadSequencers = async (hubClient: StationClient, rollapp: Network): Promise<Sequencer[]> => {
    let sequencers: CosmosSequencer[] = [];
    if (hubClient.getNetwork().collectData) {
        sequencers = await getNetworkData<CosmosSequencer[]>(rollapp, 'sequencers');
    }
    if (!sequencers.length) {
        const sequencersResponses = await hubClient.getSequencerQueryClient()
            .SequencersByRollapp({ rollappId: rollapp.chainId })
            .catch((error) => {
                throw new ClientError('FETCH_DATA_FAILED', hubClient.getNetwork(), error);
            });
        sequencers = sequencersResponses.sequencers;
    }
    return Promise.all(sequencers.map((sequencer) => convertSequencer(hubClient, sequencer)));
};

const convertSequencer = async (client: StationClient, sequencer: CosmosSequencer): Promise<Sequencer> => {
    return {
        bond: (await Promise.all(sequencer.tokens.map((coin) => convertToCoinsAmount(coin, client)))).filter(Boolean) as CoinsAmount[],
    };
};
