import React, {
    cloneElement,
    useRef,
    useState,
    useEffect,
    ReactNode,
    ReactElement,
    useCallback,
    useImperativeHandle,
    forwardRef,
} from 'react';
import classNames from 'classnames';
import Overlay, { OverlayProps, OverlayRefProps } from '../overlay/overlay';
import { getChildrenByTypes } from '../../utils/react-utils';
import useScrollPosition from '../../hooks/use-scroll-position';
import useKeyPress from '../../hooks/use-key-press';
import Link, { LinkProps } from '../link/link';
import './menu.scss';

export interface MenuActionProps extends LinkProps {
    notCloseAfterClick?: boolean;
}

export interface MenuProps extends OverlayProps {
    trigger: ReactElement | null;
    header?: ReactNode;
    footer?: ReactNode;
    disabled?: boolean;
    visibleAlways?: boolean;
    closeWhenScroll?: boolean;
    triggerOnHover?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export interface MenuRefProps {
    toggleMenu: (value: boolean) => void;
}

export const MenuAction: React.FC<MenuActionProps> = (linkProps) => <Link tooltipPlacement='right' {...linkProps} />;

const Menu: React.ForwardRefRenderFunction<MenuRefProps, MenuProps> = ({
    trigger,
    header,
    footer,
    children,
    className,
    closeWhenScroll,
    triggerOnHover,
    visibleAlways,
    disabled,
    onOpenChange,
    onMouseLeave,
    ...otherOverlayProps
}, menuRef) => {
    const [ open, setOpen ] = useState(visibleAlways);
    const scrollPosition = useScrollPosition();
    const overlayRef = useRef<OverlayRefProps>(null);
    const triggerRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
    const isEscapePressed = useKeyPress('Escape');

    const toggleMenu = useCallback((value: boolean) => {
        if (!disabled && !visibleAlways) {
            setOpen(value);
            onOpenChange?.(value);
        }
    }, [ disabled, visibleAlways, onOpenChange ]);

    useImperativeHandle(menuRef, () => ({ toggleMenu }));

    useEffect(() => {
        if (trigger) {
            overlayRef.current?.calcOverlayBounds?.();
        }
    }, [ open, trigger ]);

    useEffect(() => {
        if (closeWhenScroll && scrollPosition.target !== overlayRef.current && scrollPosition.target?.contains(overlayRef.current)) {
            toggleMenu(false);
        }
    }, [ closeWhenScroll, scrollPosition, toggleMenu ]);

    useEffect(() => {
        if (isEscapePressed && open) {
            toggleMenu(false);
        }
    }, [ open, isEscapePressed, toggleMenu ]);

    const onClickOutside = (event: MouseEvent): void => {
        if (!triggerRef.current?.contains(event.target as Node)) {
            toggleMenu(false);
        }
    };

    const onMouseLeaveOverlay = (event: React.MouseEvent): void => {
        if (triggerOnHover &&
            (!overlayRef.current?.contains?.(event.relatedTarget as Node) && !triggerRef.current?.contains(event.relatedTarget as Node))) {
            toggleMenu(false);
        }
    };

    const renderMenuModal = (): JSX.Element | null => {
        const actions = getChildrenByTypes(children, MenuAction);

        return !open ? null : (
            <Overlay
                onClickOutside={onClickOutside}
                onMouseLeave={(event) => {
                    onMouseLeaveOverlay(event);
                    onMouseLeave?.(event);
                }}
                ref={overlayRef}
                styles={{ minWidth: triggerRef.current?.clientWidth }}
                attachedTo={triggerRef.current}
                dimContainer={!trigger}
                className={classNames('menu-modal', className)}
                {...otherOverlayProps}
            >
                {header}
                {!actions?.length ? children : (
                    <ul className='menu-action-list'>
                        {actions.map((action, actionIndex) => {
                            const clonedAction = cloneElement(action, {
                                onClick: (event: React.MouseEvent): void => {
                                    event.stopPropagation();
                                    action.props.onClick?.(event);
                                    if (!action.props.notCloseAfterClick) {
                                        toggleMenu(false);
                                    }
                                },
                            });
                            return <li key={actionIndex} className='menu-action-list-item'>{clonedAction}</li>;
                        })}
                    </ul>
                )}
                {footer}
            </Overlay>
        );
    };

    const triggerElement = trigger && cloneElement(
        trigger, {
            onClick: (event: React.MouseEvent) => {
                event.stopPropagation();
                trigger.props?.onClick?.(event);
                toggleMenu(!open);
            },
            onMouseOver: (event: React.MouseEvent) => {
                if (triggerOnHover) {
                    trigger.props?.onMouseOver?.(event);
                    toggleMenu(true);
                }
            },
            onMouseLeave: (event: React.MouseEvent) => {
                trigger.props?.onMouseLeave?.(event);
                onMouseLeaveOverlay(event);
            },
            ref: triggerRef,
        },
    );

    return <>{triggerElement}{renderMenuModal()}</>;
};

export default forwardRef(Menu);
