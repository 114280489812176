import React from 'react';
import classNames from 'classnames';
import TokenBurnsChart from '../../../hub/statistics/token-burns-chart';
import TotalTvlChart from '../../../hub/statistics/total-tvl-chart';
import IbcTransfersChart from '../../../network/statistics/charts/ibc-transfers-chart/ibc-transfers-chart';
import NewAddressesChart from '../../../network/statistics/charts/new-addresses-chart/new-addresses-chart';
import { useNetworkDashboard } from '../../network-dashboard-context';
import './network-charts.scss';

interface NetworkChartsProps {
    className?: string;
}

export const NetworkCharts: React.FC<NetworkChartsProps> = ({ className }) => {
    const { network, analyticsState } = useNetworkDashboard();

    return (
        <div className={classNames('network-charts', className)}>
            <TotalTvlChart
                className={classNames('network-chart-container', { 'full-width': network.type === 'RollApp' })}
                network={network}
                state={analyticsState}
            />

            {network.type === 'Hub' && <>
                <TokenBurnsChart className='network-chart-container' analyticsState={analyticsState} />
                {/*<RollappsDeployedChart className='network-chart-container' />*/}
            </>}
            <IbcTransfersChart className='network-chart-container' />
            <NewAddressesChart className='network-chart-container' />
        </div>
    );
};

export default NetworkCharts;
