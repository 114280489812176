import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as SparkleIcon } from '../../../assets/icons/sparkle.svg';
import Icon from '../icon/icon';
import './alert-box.scss';

interface AlertBoxProps {
    className?: string;
    children: ReactNode;
    hideAlertIcon?: boolean;
}

const AlertBox: React.FC<AlertBoxProps> = ({ className, children, hideAlertIcon }) => {
    return (
        <div className={classNames('alert-box', className)}>
            {!hideAlertIcon && <Icon className='alert-box-icon'><SparkleIcon /></Icon>}
            {children}
        </div>
    );
};

export default AlertBox;
