import React, { ReactNode } from 'react';
import Tooltip, { TooltipBounds, TooltipPlacement } from '../tooltip/tooltip';
import { ReactComponent as InfoOutlineIcon } from '../../../assets/icons/info-outline.svg';
import Button, { ButtonProps } from '../button/button';

interface InfoIndicatorProps {
    children: ReactNode;
    className?: string;
    bounds?: TooltipBounds;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: string;
    indicatorSize?: ButtonProps['size'];
}

const InfoIndicator: React.FC<InfoIndicatorProps> = ({
    children,
    tooltipPlacement,
    bounds,
    className,
    tooltipClassName,
    indicatorSize,
}) => {
    return (
        <Tooltip className={tooltipClassName} placement={tooltipPlacement} title={children} bounds={bounds}>
            <Button buttonType='icon' size={indicatorSize} className={className}><InfoOutlineIcon /></Button>
        </Tooltip>
    );
};

export default InfoIndicator;
