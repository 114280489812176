import React from 'react';
import ValidatorList, { ValidatorListHeader } from '../validator-list/validator-list';
import Select from '../../../../shared/components/form-controls/select/select';
import { Option } from '../../../../shared/components/form-controls/options-modal/options-modal';
import { ValidatorsType } from '../validator-types';
import { useStaking } from '../../staking-context';

export default function StakedValidators(): JSX.Element {
    const { stakingDataState, stakedValidatorsData, unstakingValidatorsData, stakeValidatorsType, setStakeValidatorsType } = useStaking();

    const optionalStakeTypes: ValidatorsType[] = ['Staked', 'Unstaking'];

    return <>
        <ValidatorListHeader>
            My Delegations

            <span className='space' />

            <Select
                value={stakeValidatorsType}
                optionsOverlayAlign='right'
                onSelect={(type) => setStakeValidatorsType(type as ValidatorsType)}
                controlSize='medium'
            >
                {optionalStakeTypes.map((type) => <Option key={type} value={type}>{type}</Option>)}
            </Select>
        </ValidatorListHeader>

        {stakeValidatorsType === 'Staked' ?
            <ValidatorList
                state={stakedValidatorsData?.state}
                delegationLoading={stakingDataState?.delegationsLoading}
                rewardsLoading={stakingDataState?.rewardsLoading}
                onSort={stakedValidatorsData?.setOrder}
                fields={['Name', 'AmountStaked', 'Rewards']}
            /> : <ValidatorList
                state={unstakingValidatorsData?.state}
                delegationLoading={stakingDataState?.delegationsLoading}
                rewardsLoading={stakingDataState?.rewardsLoading}
                onSort={unstakingValidatorsData?.setOrder}
                fields={['Name', 'AmountUnstaking', 'UnstakingCompletionTime']}
            />
        }
    </>;
}

