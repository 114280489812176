import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useMouseHover = <T extends HTMLElement>(): [MutableRefObject<T | undefined>, boolean] => {
    const [value, setValue] = useState<boolean>(false);
    const triggerRef = useRef<T>();

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(() => {
        const triggerElement = triggerRef.current;
        if (triggerElement) {
            triggerElement.addEventListener('mouseover', handleMouseOver);
            triggerElement.addEventListener('mouseout', handleMouseOut);

            return () => {
                triggerElement.removeEventListener('mouseover', handleMouseOver);
                triggerElement.removeEventListener('mouseout', handleMouseOut);
            };
        }
    }, []);

    return [triggerRef, value];
};

export default useMouseHover;