import React from 'react';
import Badge from '../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { Proposal } from '../governance-types';

interface ProposalTypeBadgeProps {
    size?: 'small' | 'medium' | 'large'
    proposal: Proposal;
    className?: string;
}

const ProposalTypeBadge: React.FC<ProposalTypeBadgeProps> = ({ proposal, size = 'medium', className }) => {
    return (
        <Badge
            size={size}
            className={className}
            rounded
            color={getCssVariableValue('--cream-very-dark-rgb').split(',').map(Number)}
            label={proposal.type}
        />
    );
};

export default ProposalTypeBadge;
