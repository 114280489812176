import { Keplr as Leap } from '@keplr-wallet/types';
import { WalletType } from '../wallet-types';
import { CosmosProvider, CosmosWallet } from './cosmos-wallet';


declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        leap?: Leap;
    }
}

export class LeapWallet extends CosmosWallet {
    constructor() {
        super('leap_keystorechange');
    }

    public getWalletType(): WalletType {
        return 'Leap';
    }

    public getCurrentProvider(): CosmosProvider | undefined {
        return window.leap;
    };
}

