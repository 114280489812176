import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as StakeIcon } from '../../../assets/icons/stake.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import Button from '../../../shared/components/button/button';
import Link from '../../../shared/components/link/link';
import { AccountNetworkState } from '../../account/account-network-state';
import { useAmm } from '../../amm/amm-context';
import { Asset } from '../../amm/assets/assets-types';
import { getMainCurrency } from '../../currency/currency-service';
import IbcTransferDialog from '../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import { useNetwork } from '../../network/network-context';
import { getNetworkLogoPath } from '../../network/network-service';
import './network-header.scss';

interface NetworkHeaderProps {
    networkState: AccountNetworkState;
    className?: string;
}

export const NetworkHeader: React.FC<NetworkHeaderProps> = ({ networkState, className }) => {
    const navigate = useNavigate();
    const { hubNetwork } = useNetwork();
    const { ammState } = useAmm();
    const [ depositWithdrawDialogOpen, setDepositWithdrawDialogOpen ] = useState(false);

    const network = networkState.network;

    const nativeAsset = useMemo((): Asset | undefined => {
        const mainCurrency = network && getMainCurrency(network);
        return ammState.assets?.find((asset) =>
            asset.currency.baseDenom === mainCurrency?.baseDenom && asset.network.chainId === network?.chainId);
    }, [ ammState.assets, network ]);

    if (!network) {
        return <></>;
    }

    return (
        <div className={classNames('network-header-container', className)}>
            <div className='network-header'>
                <img className='network-logo' src={getNetworkLogoPath(network)} alt='network logo' />
                <div className='network-details'>
                    <h2 className='network-name'>
                        {network.chainName}
                        <Link className='native-token' url={`/amm/asset/${encodeURIComponent(nativeAsset?.key || '')}`}>
                            {nativeAsset?.currency.displayDenom}
                        </Link>
                    </h2>
                    {(network.shortDescription || network.website) &&
                        <span className='network-short-description'>
                            {network.shortDescription ||
                                <a href={network.website} target='_blank' rel='noreferrer' className='website'>{network.website}</a>}
                        </span>
                    }
                </div>
            </div>

            <div className='network-actions'>
                <Button buttonType='secondary' size='small' onClick={() => setDepositWithdrawDialogOpen(true)}>
                    Deposit / Withdraw&nbsp;<DownloadIcon />
                </Button>

                <Button
                    size='small'
                    onClick={() => navigate(`/rollapp/${network.chainId}/staking`)}
                    disabled={!nativeAsset}
                >
                    Stake&nbsp;<StakeIcon />
                </Button>
            </div>

            {depositWithdrawDialogOpen && hubNetwork && (
                <IbcTransferDialog
                    title='Deposit / Withdraw'
                    optionalSourceNetworks={network.chainId === hubNetwork.chainId ? undefined : [ network.chainId, hubNetwork?.chainId ]}
                    optionalDestinationNetworks={
                        network.chainId === hubNetwork.chainId ? undefined : [ network.chainId, hubNetwork?.chainId ]}
                    onRequestClose={() => setDepositWithdrawDialogOpen(false)}
                    initialSourceNetwork={network.chainId}
                />
            )}
        </div>
    );
};

export default NetworkHeader;
