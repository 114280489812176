import React, { forwardRef, ButtonHTMLAttributes, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import useWindowSize from '../../hooks/use-window-size';
import Spinner, { SpinnerProps } from '../spinner/spinner';
import Tooltip, { TooltipPlacement } from '../tooltip/tooltip';
import './button.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    loading?: boolean; // todo: need to handle
    buttonType?: 'primary' | 'secondary' | 'icon';
    size?: 'xs' | 'small' | 'medium' | 'large' | 'x-large';
    iconColorMode?: 'fill' | 'stroke' | 'original';
    active?: boolean;
    focus?: boolean;
    tooltip?: ReactNode;
    tooltipPlacement?: TooltipPlacement;
}

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = ({
    children,
    className,
    loading,
    active,
    focus,
    onClick,
    disabled,
    tooltip,
    tooltipPlacement,
    iconColorMode = 'fill',
    buttonType = 'primary',
    size = 'medium',
    ...htmlButtonProps
}, forwardedRef) => {
    const { isMobile } = useWindowSize();

    size = isMobile && size === 'x-large' ? 'large' : size;

    const spinnerSize = useMemo((): SpinnerProps['size'] => {
        if (size === 'xs') {
            return 'small';
        } else if (size === 'x-large') {
            return 'large';
        }
        return size;
    }, [ size ]);

    const buttonClassName = classNames(
        'button',
        className,
        buttonType,
        size,
        `icon-color-${iconColorMode}`,
        { loading, disabled, active, focus },
    );

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (disabled) {
            event.preventDefault();
        } else {
            onClick?.(event);
        }
    };

    const renderButton = (): JSX.Element => {
        return (
            <button
                type='button'
                {...htmlButtonProps}
                className={buttonClassName}
                onClick={onButtonClick}
                ref={forwardedRef}
                tabIndex={disabled ? -1 : 0}
            >
                {children}
                {loading ? <Spinner className='button-loader' size={spinnerSize} /> : null}
            </button>
        );
    };

    return tooltip ? <Tooltip title={tooltip} placement={tooltipPlacement}>{renderButton()}</Tooltip> : renderButton();
};

export default forwardRef(Button);
