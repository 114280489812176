import { MutableRefObject, useEffect, useRef } from 'react';

const useOutsideClick = <T extends HTMLElement>(callback?: (event: MouseEvent) => void): MutableRefObject<T | undefined> => {
    const ref = useRef<T>();

    useEffect(() => {
        const handleClick = (event: MouseEvent): void => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback?.(event);
            }
        };
        document.addEventListener('click', handleClick, true);
        return () => document.removeEventListener('click', handleClick, true);
    }, [callback, ref]);

    return ref;
};

export default useOutsideClick;
