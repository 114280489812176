import classNames from 'classnames';
import React, { useMemo } from 'react';
import useWindowSize from '../../../shared/hooks/use-window-size';
import TotalSupplyChart from '../../network/statistics/charts/total-supply-chart/total-supply-chart';
import { useNetworkDashboard } from '../network-dashboard-context';
import NetworkCharts from './network-charts/network-charts';
import NetworkInfo from './network-info/network-info';
import NetworkStatistics from './network-statistics';
import './network-summary.scss';

const SMALL_SCREEN_WIDTH = 1280;

export const NetworkSummary: React.FC = () => {
    const { width } = useWindowSize();
    const { network } = useNetworkDashboard();

    const bigWidth = useMemo(() => width >= SMALL_SCREEN_WIDTH, [ width ]);

    return <>
        <div className={classNames('network-header', { 'big-width': bigWidth })}>
            <NetworkStatistics className='network-statistics-cards' vertically={bigWidth} />
            <TotalSupplyChart
                className='network-top-chart'
                showSupplyDivisionChart={
                    network.type === 'Hub' && (process.env.REACT_APP_ENV === 'mainnet' || process.env.REACT_APP_ENV === 'mainnet-staging')}
                showInflationDistributionChart={network.type === 'RollApp'}
            />
        </div>

        <div className={classNames('network-data-container', { 'big-width': bigWidth })}>
            {network.type === 'RollApp' && (
                <div className='network-info-section'>
                    <h5 className='subtitle'>Info</h5>
                    <NetworkInfo network={network} />
                </div>
            )}

            <div className='network-charts-section'>
                <h5 className='subtitle'>Charts</h5>
                <NetworkCharts />
            </div>
        </div>
    </>;
};

export default NetworkSummary;
