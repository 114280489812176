import { Network } from '../../../network/network-types';

export const PORTAL_WALLET_SOURCE_TYPES = [ 'Google', 'Apple', 'Twitter X', 'MetaMask' ] as const;
export type PortalWalletSourceType = typeof PORTAL_WALLET_SOURCE_TYPES[number];

export interface PortalWalletInitProps {
    sourceType: PortalWalletSourceType;
    network?: Network;
    pinCode?: string;
    cacheOnly?: boolean;
    saveToCache?: boolean;
}

export const ACCOUNT_SIGNATURE_KEY = 'accountSignatureKey';

