import React, { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/button/button';
import Input from '../../shared/components/form-controls/input/input';
import { Option } from '../../shared/components/form-controls/options-modal/options-modal';
import AccountMenu from '../account/account-menu/account-menu';
import { getMainCurrency } from '../currency/currency-service';
import { getNetworkLogoPath } from '../network/network-service';
import { Network } from '../network/network-types';
import { removeCustomRollapp } from '../rollapp/add-custom-rollapp/add-custom-rollapp-service';
import AvailabilityIndicator from '../rollapp/availability-indicator/availability-indicator';
import { ReactComponent as DymensionLogo } from '../../assets/logos/dymension-logo.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/clear.svg';
import NavBar, { NavBarItem } from '../../shared/components/nav-bar/nav-bar';
import Icon from '../../shared/components/icon/icon';
import { useAccountNetwork } from '../account/use-account-network';
import { useNetwork } from '../network/network-context';
import './top-bar.scss';

const TopBar: React.FC = () => {
    const navigate = useNavigate();
    const { rollapps, hubNetwork, getNetwork } = useNetwork();
    const [ networkState, setNetwork ] = useAccountNetwork(hubNetwork);

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const searchRegExp = new RegExp(searchText, 'i');
        const network = getNetwork(value.toString());
        const currency = network && getMainCurrency(network);
        if (!network || !currency) {
            return false;
        }
        return Boolean(
            searchRegExp.test(network.chainName) || searchRegExp.test(network.chainId) || searchRegExp.test(currency.displayDenom));
    }, [ getNetwork ]);

    const sortedRollapps = useMemo(() => {
        return rollapps
            .sort((rollapp1, rollapp2) => {
                if (rollapp1.custom !== rollapp2.custom) {
                    return (rollapp2.custom ? 1 : 0) - (rollapp1.custom ? 1 : 0);
                }
                const status1 = rollapp1.availabilityStatus?.value;
                const status2 = rollapp2.availabilityStatus?.value;
                if (status1 !== status2) {
                    return (status2 ? 1 : 0) - (status1 ? 1 : 0);
                }
                return (rollapp2.totalSupply?.value.tvl || 0) - (rollapp1.totalSupply?.value.tvl || 0);
            });
    }, [ rollapps ]);

    const accountMenuNetworks = useMemo(
        () => hubNetwork ? [ hubNetwork.chainId, ...sortedRollapps.map((network) => network.chainId) ] : [],
        [ hubNetwork, sortedRollapps ],
    );

    const renderNetworkOption = (network?: Network, canRemoved?: boolean): ReactNode => {
        if (!network) {
            return undefined;
        }
        const currency = getMainCurrency(network);
        return <>
            <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />
            <span className='network-name'>{network.chainName}</span>
            <span className='main-currency-container'>
                {currency?.displayDenom}
                <AvailabilityIndicator
                    containerClassName='availability-indicator-container'
                    status={network.availabilityStatus?.value}
                />
            </span>
            <span className='space' />
            {canRemoved && (
                <Button buttonType='icon' onClick={() => removeCustomRollapp(network.chainId)} className='remove-custom-rollapp-button'>
                    <ClearIcon />
                </Button>
            )}
        </>;
    };

    return (
        <div className='top-bar'>
            <a href='/' className='logo'>
                <Icon><DymensionLogo /></Icon>
            </a>
            <NavBar className='nav-bar'>
                <NavBarItem label='RollApps' url='/rollapps' />
                <NavBarItem label='Dymension' url='/dymension'>
                    <NavBarItem label='Metrics' url='/metrics' />
                    <NavBarItem label='Staking' url='/staking' />
                    <NavBarItem label='Governance' url='/governance' />
                </NavBarItem>
                <NavBarItem label='Transfer' url='/ibc'>
                    <NavBarItem label='Transfer' url='/transfer' />
                    <NavBarItem label='History' url='/status' />
                </NavBarItem>
                <NavBarItem label='Liquidity' url='/amm'>
                    <NavBarItem label='Swap' url='/swap' />
                    <NavBarItem label='Pools' url='/pools' />
                    <NavBarItem label='Assets' url='/assets' />
                </NavBarItem>
                <NavBarItem label='Info'>
                    <NavBarItem label='Documentation' url='https://docs.dymension.xyz' external />
                    <NavBarItem label='Forum' url='https://forum.dymension.xyz' external />
                    <NavBarItem label='Explorer' url={hubNetwork?.explorerUrl || ''} disabled={!hubNetwork?.explorerUrl} external />
                    <NavBarItem label='Blog' url='https://medium.com/@dymension' external />
                </NavBarItem>
            </NavBar>

            <span className='space top-bar-middle-space' />

            <Input
                type='search'
                placeholder='Search RollApps...'
                className='rollapps-search'
                searchFilterPredicate={searchFilterPredicate}
                emptySearchResultsLabel='No RollApps'
                onSuggestionSelect={(rollappId) => navigate(`/rollapp/${rollappId}`)}
            >
                {sortedRollapps.map((rollapp) => (
                    <Option value={rollapp.chainId} key={rollapp.chainId} disabled={rollapp.disabled}>
                        {renderNetworkOption(rollapp, rollapp.custom)}
                    </Option>
                ))}
            </Input>

            <AccountMenu
                networkState={networkState}
                menuAlign='right'
                optionalNetworks={accountMenuNetworks}
                onNetworkSelect={(networkId) => setNetwork(getNetwork(networkId))}
            />
        </div>
    );
};

export default TopBar;
