import { SoftwareUpgradeProposal } from 'cosmjs-types/cosmos/upgrade/v1beta1/upgrade';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import Long from 'long';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { AccountNetworkState } from '../../account/account-network-state';
import { useAccountNetwork } from '../../account/use-account-network';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { CreateStreamProposal } from '../../client/station-clients/dymension/generated/streamer/gov_stream';
import { getMainCurrency, isDenomsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { AmountTxState } from '../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../tx/tx-state';
import { Network } from '../../network/network-types';
import { DeliveryTxCode } from '../../tx/tx-types';
import { createProposalMessage } from '../governance-service';
import { GovProposalContent, PROPOSAL_TYPES, ProposalType } from '../governance-types';
import { createProposalReducer, CreateProposalState } from './create-proposal-state';

const PROPOSAL_TYPE_KEY = 'proposalTypeKey';
const PROPOSAL_CONTENT_KEY = 'proposalContentKey';

interface UseCreateProposalValue {
    createProposalState: CreateProposalState;
    networkState: AccountNetworkState;
    txState: TxState;
    amountTxState: AmountTxState;
    availableBalances: CoinsAmount[];
    isProposalValid: boolean;
    setType: (type: ProposalType) => void;
    initContent: (data: GovProposalContent, valid?: boolean) => void;
    updateContent: (data: Partial<GovProposalContent>, valid?: boolean) => void;
    setInitialDeposit: (coins: CoinsAmount) => void;
    broadcast: (memo?: string) => void;
}

export const useCreateProposal = (network: Network): UseCreateProposalValue => {
    const navigate = useNavigate();
    const { showErrorMessage } = useSnackbar();
    const { handleClientError } = useClient();
    const [ createProposalState, createProposalStateDispatch ] = useReducer(createProposalReducer, {});
    const [ networkState ] = useAccountNetwork(network);

    const isProposalValid = useMemo(
        () => Boolean(createProposalState.type &&
            createProposalState.content?.title &&
            createProposalState.content.description &&
            createProposalState.valid),
        [
            createProposalState.content?.description,
            createProposalState.content?.title,
            createProposalState.type,
            createProposalState.valid,
        ],
    );

    const createProposalMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        if (!networkState.address || !coins || !createProposalState.type || !createProposalState.content || !isProposalValid) {
            return [];
        }
        return [ createProposalMessage(createProposalState.type, networkState.address, coins, createProposalState.content) ];
    }, [ createProposalState.content, createProposalState.type, isProposalValid, networkState.address ]);

    const availableBalances = useMemo((): CoinsAmount[] => {
        const mainCurrency = getMainCurrency(network);
        const amount = networkState.balances?.find((balance) => isDenomsEquals(balance, mainCurrency.baseDenom))?.amount || 0;
        return [ { currency: mainCurrency, amount } ];
    }, [ network, networkState.balances ]);

    const { txState, amountTxState, broadcast, setCoins: setInitialDeposit, calculateFee, clearFee } = useAmountTx({
        networkState: networkState,
        selectInitialCurrency: true,
        reduceFeeFromBalances: true,
        availableBalances,
        amountTxMessagesCreator: createProposalMessagesCreator,
        signMethod: 'direct',
    });

    useEffect(() => {
        try {
            if (createProposalState.type) {
                localStorage.setItem(PROPOSAL_TYPE_KEY, createProposalState.type);
            }
            if (createProposalState.content) {
                localStorage.setItem(PROPOSAL_CONTENT_KEY, JSON.stringify(createProposalState.content));
            }
        } catch {}
    }, [ createProposalState.content, createProposalState.type ]);

    useEffect(() => {
        try {
            const proposalType = localStorage.getItem(PROPOSAL_TYPE_KEY) as ProposalType;
            if (PROPOSAL_TYPES.includes(proposalType)) {
                createProposalStateDispatch({ type: 'set-type', payload: proposalType });
            }
            const content = JSON.parse(localStorage.getItem(PROPOSAL_CONTENT_KEY) || '{}');
            if (proposalType === 'Create Stream') {
                const createStreamContent = content as CreateStreamProposal;
                createStreamContent.startTime = createStreamContent.startTime && new Date(createStreamContent.startTime);
            }
            if (proposalType === 'Software Upgrade') {
                const softwareUpgradeProposal = content as SoftwareUpgradeProposal;
                if (softwareUpgradeProposal.plan?.height) {
                    softwareUpgradeProposal.plan.height = Long.fromValue(softwareUpgradeProposal.plan.height);
                }
            }
            createProposalStateDispatch({ type: 'init-content', payload: { data: content } });
        } catch {}
    }, []);

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            createProposalStateDispatch({ type: 'clear' });
            localStorage.removeItem(PROPOSAL_TYPE_KEY);
            localStorage.removeItem(PROPOSAL_CONTENT_KEY);
            navigate('../governance', { relative: 'path' });
        }
    }, [ navigate, txState.response?.deliveryTxCode ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            if (txState.error.code === 'BROADCAST_TX_FAILED') {
                let result = /parameter .* not registered/.exec(txState.error.originalError.message);
                if (result?.length) {
                    showErrorMessage(`Can't create proposal: ${result[0]}`);
                    return;
                }
                result = /[:,\t\n\r] *[^:,\t\n\r]*:( *invalid request:)? *invalid proposal content/.exec(txState.error.originalError.message);
                if (result?.length) {
                    if (txState.error.originalError?.message?.includes('decoding bech32 failed')) {
                        showErrorMessage('Can\'t create proposal: invalid address');
                    } else {
                        const errorMessage = result[0].split(/[:,\t\n\r]/)[1].trim();
                        if (errorMessage.includes('failed to set parameter')) {
                            showErrorMessage(`Can't create proposal: parameter value invalid`);
                        } else if (txState.error.originalError?.message?.includes('decoding bech32 failed')) {
                            showErrorMessage('Can\'t create proposal: invalid address');
                        } else {
                            showErrorMessage(`Can't create proposal: ${errorMessage}`);
                        }
                    }
                    return;
                }
                if (txState.error.originalError?.message?.includes('invalid client identifier')) {
                    showErrorMessage('Can\'t create proposal: client identifier invalid');
                    return;
                }
            }
            handleClientError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, txState.error, handleClientError, showErrorMessage ]);

    useEffect(() => {
        if (!networkState.address ||
            !createProposalState.type ||
            !amountTxState.coins?.currency ||
            !isProposalValid ||
            !createProposalState.content) {
            clearFee();
        } else {
            calculateFee();
        }
    }, [
        amountTxState.coins?.currency,
        calculateFee,
        clearFee,
        createProposalState.content,
        createProposalState.type,
        isProposalValid,
        networkState.address,
    ]);

    const setType = useCallback((type: ProposalType) => createProposalStateDispatch({ type: 'set-type', payload: type }), []);

    const initContent = useCallback((data: GovProposalContent, valid?: boolean) =>
        createProposalStateDispatch({ type: 'init-content', payload: { data, valid } }), []);

    const updateContent = useCallback((data: Partial<GovProposalContent>, valid?: boolean) =>
        createProposalStateDispatch({ type: 'update-content', payload: { data, valid } }), []);

    return {
        createProposalState,
        availableBalances,
        networkState,
        txState,
        amountTxState,
        isProposalValid,
        setType,
        initContent,
        updateContent,
        setInitialDeposit,
        broadcast,
    };
};
