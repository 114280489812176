import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../shared/components/spinner/spinner';
import { useAmm } from '../../amm-context';
import PoolDashboard from '../pool-dashboard';
import { PoolDashboardContextProvider } from '../pool-dashboard-context';
import './pool-dashboard-page.scss';

const PoolDashboardPage: React.FC = () => {
    const { id } = useParams();
    const { ammState } = useAmm();
    const pool = id && ammState.pools?.find((pool) => pool.id === Number(id));

    if (pool) {
        return (
            <PoolDashboardContextProvider pool={pool}>
                <div className='page'><PoolDashboard /></div>
            </PoolDashboardContextProvider>
        );
    }
    if (ammState.loading) {
        return <Spinner size='large' className='pool-dashboard-loader' />;
    }
    return <h5 className='not-found-message'>Pool not found</h5>;
};

export default PoolDashboardPage;
