export const CURRENCY_TYPES = ['main', 'staking', 'fee', 'regular'] as const;
export type CurrencyType = typeof CURRENCY_TYPES[number];

export interface Currency {
    displayDenom: string,
    baseDenom: string,
    bridgeDenom?: string;
    name?: string;
    decimals: number,
    logo?: string;
    ibcRepresentation?: string;
    cctp?: boolean;
    common?: boolean;
    type: CurrencyType;
    solanaMintAccount?: string;
}

export interface CoinsAmount {
    amount: number;
    currency: Currency;
    erc20Address?: string;
    ibc?: { representation: string; path: string; networkId: string; };
}

export interface NetworkDenom {
    networkId: string;
    ibcNetworkId: string;
    denom: string;
    baseDenom: string;
    path: string;
}

