import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { useAmm } from '../../../amm/amm-context';
import TokensSwapDialog from '../../../amm/tokens-swap/tokens-swap-dialog/tokens-swap-dialog';
import { isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';
import './get-tokens-section.scss';

export interface GetTokensSectionProps {
    coins: CoinsAmount;
    coinsNetwork?: Network;
    className?: string;
}

const GetTokensSection: React.FC<GetTokensSectionProps> = ({ coins, coinsNetwork, className }) => {
    const { isMobile } = useWindowSize();
    const { hubNetwork, hubCurrency, networkDenoms } = useNetwork();
    const { ammState } = useAmm();
    const [ tokenSwapDialogOpen, setTokenSwapDialogOpen ] = useState(false);

    const toCoins = useMemo((): CoinsAmount => {
        if (!coinsNetwork || coinsNetwork.type === 'Hub' || coins.ibc) {
            return coins;
        }
        const networkDenom = networkDenoms?.find((networkDenom) =>
            networkDenom.denom === coins.currency.ibcRepresentation ||
            (networkDenom.baseDenom === coins.currency.baseDenom && networkDenom.ibcNetworkId === coinsNetwork.chainId));
        return {
            ...coins,
            ibc: {
                representation: networkDenom?.denom || '',
                networkId: networkDenom?.ibcNetworkId || coinsNetwork.chainId,
                path: networkDenom?.path || '',
            },
        };
    }, [ coins, coinsNetwork, networkDenoms ]);

    const fromCoins = useMemo(
        (): CoinsAmount | undefined => ammState.params && isCoinsEquals(toCoins, ammState.params.vsCoins) ?
            (hubNetwork && hubCurrency && { currency: hubCurrency, amount: 0 }) : ammState.params?.vsCoins,
        [ ammState.params, toCoins, hubCurrency, hubNetwork ],
    );

    const noPool = useMemo(() => !ammState.assets?.some((asset) => isCoinsEquals(toCoins, asset)), [ ammState.assets, toCoins ]);

    return (
        <section className={classNames('section', 'get-tokens-section', className)}>
            <p className='get-tokens-header'>
                Need {coins.currency.displayDenom}? swap assets on the liquidity layer
            </p>

            <Button
                size={isMobile ? 'small' : 'medium'}
                buttonType='secondary'
                className='get-tokens-button'
                tooltipPlacement='top'
                tooltip={noPool ? `At present, there isn't a liquidity pool available for ${coins.currency.displayDenom}` : ''}
                disabled={noPool}
                onClick={() => setTokenSwapDialogOpen(true)}
            >
                Swap {fromCoins?.currency.displayDenom} / {coins.currency.displayDenom}
            </Button>

            {tokenSwapDialogOpen && (
                <TokensSwapDialog
                    closable
                    fromCoins={fromCoins}
                    toCoins={toCoins}
                    onRequestClose={() => setTokenSwapDialogOpen(false)}
                />
            )}
        </section>
    );
};

export default GetTokensSection;
