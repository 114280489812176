import React from 'react';
import CreateProposal from '../governance/create-proposal/create-proposal';
import { useNetwork } from '../network/network-context';

const HubCreateProposalPage: React.FC = () => {
    const { hubNetwork } = useNetwork();
    return hubNetwork ? <CreateProposal network={hubNetwork} /> : <></>;
};

export default HubCreateProposalPage;
