import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import Button from '../../../../shared/components/button/button';
import Input from '../../../../shared/components/form-controls/input/input';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import { DistrRecord } from '../../../client/station-clients/dymension/generated/streamer/distr_info';
import {
    ReplaceStreamDistributionProposal,
    UpdateStreamDistributionProposal,
} from '../../../client/station-clients/dymension/generated/streamer/gov_distribution';
import './proposal-type.scss';

interface UpdateReplaceStreamDistributionProps {
    content?: UpdateStreamDistributionProposal | ReplaceStreamDistributionProposal;
    onInit?: (data: UpdateStreamDistributionProposal | ReplaceStreamDistributionProposal) => void;
    onChange?: (data: Partial<UpdateStreamDistributionProposal | ReplaceStreamDistributionProposal>, isValid: boolean) => void;
}

const MAX_GAUGES = 50;

const UpdateReplaceStreamDistribution: React.FC<UpdateReplaceStreamDistributionProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(
        () => Boolean(content?.streamId && content?.records?.length && content.records.every(({ gaugeId, weight }) => gaugeId && weight)),
        [ content ],
    );

    useEffect(() => onInit?.({
        title: '',
        description: '',
        streamId: undefined as any,
        records: [ { gaugeId: undefined as any, weight: '' } ],
    }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    const updateDistributionRecord = useCallback((record: DistrRecord, key: keyof DistrRecord, value: any) => {
        (record[key] as any) = value as any;
        if (content) {
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const addDistributeToRecord = useCallback(() => {
        if (content) {
            content.records.push({ gaugeId: undefined as any, weight: '' });
            onChange?.({ ...content }, false);
        }
    }, [ content, onChange ]);

    const onRemoveDistributeToRecord = useCallback((index: number) => {
        if (content) {
            content.records.splice(index, 1);
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const renderDistributeToSection = (): ReactElement => {
        return (
            <div className='proposal-content-section'>
                <label className='proposal-control-label'>Distribution</label>
                {content?.records?.map((record, recordIndex) => (
                    <div className='proposal-change-item' key={recordIndex}>
                        <label className='proposal-change-label'>Gauge ID</label>
                        <Input
                            type='number'
                            value={record.gaugeId}
                            onTypeFinish={(value) => updateDistributionRecord(record, 'gaugeId', Number(value))}
                        />
                        <label className='proposal-change-label'>Weight</label>
                        <Input
                            value={record.weight}
                            type='number'
                            onTypeFinish={(value) => updateDistributionRecord(record, 'weight', value?.toString() || '')}
                        />
                        <Button
                            disabled={content?.records.length <= 1}
                            className='proposal-remove-item-button'
                            buttonType='icon'
                            onClick={() => onRemoveDistributeToRecord(recordIndex)}
                        >
                            <ClearIcon />
                        </Button>
                    </div>
                ))}
                <Button
                    className='proposal-add-item-button'
                    buttonType='secondary'
                    disabled={(content?.records?.length || 0) >= MAX_GAUGES}
                    onClick={addDistributeToRecord}
                >
                    Add Gauge
                </Button>
            </div>
        );
    };

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Stream ID</label>
            <Input
                type='number'
                value={content?.streamId}
                onValueChange={(value) => onChange?.({ streamId: !value ? undefined : Number(value) }, isValid)}
            />
            {renderDistributeToSection()}
        </div>
    );
};

export default UpdateReplaceStreamDistribution;
