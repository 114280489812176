import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { analyticsMapReducer, AnalyticsMapState } from '../../analytics/analytics-map-state';
import { useAmm } from '../amm-context';
import { loadPoolAnalyticsMap } from '../statistics/analytics/pool-analytics-service';
import { PoolAnalytics, PoolsAnalyticsMap } from '../statistics/analytics/pool-analytics-types';
import { Asset } from './assets-types';

interface AssetsContextValue {
    sortedFilteredAssets: Asset[];
    assetsPoolsAnalyticsState: AnalyticsMapState<{ [poolId: string]: PoolAnalytics }>;
    searchText: string;
    loadMore: () => void;
    setSearchText: (searchText: string) => void;
}

const PAGE_SIZE = 25;

export const AssetsContext = createContext<AssetsContextValue>({} as AssetsContextValue);

export const useAssets = (): AssetsContextValue => useContext(AssetsContext);

export const AssetsContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { ammState } = useAmm();
    const [ assetsPoolsAnalyticsState, assetsPoolsAnalyticsStateDispatch ] = useReducer(analyticsMapReducer, {});
    const [ searchText, setSearchText ] = useState<string>('');
    const [ page, setPage ] = useState(0);
    const cancelAndSetAssetsPoolsAnalyticsPromise = useCancelablePromise<PoolsAnalyticsMap>();

    const sortedFilteredAssets = useMemo(() => {
        let filteredAssets = ammState.assets || [];
        if (searchText) {
            const searchRegExp = new RegExp(searchText.trim(), 'i');
            filteredAssets = filteredAssets.filter((asset) =>
                searchRegExp.test(asset.currency.displayDenom) ||
                searchRegExp.test(asset.currency.baseDenom) ||
                searchRegExp.test(asset.network.chainName));
        }
        return filteredAssets
            .sort((asset1, asset2) => asset2.liquidity - asset1.liquidity)
            .slice(0, (page + 1) * PAGE_SIZE);
    }, [ ammState.assets, page, searchText ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === sortedFilteredAssets.length) {
            setPage(page + 1);
        }
    }, [ page, sortedFilteredAssets.length ]);

    useEffect(() => {
        if (ammState.loading || !ammState.assets?.length) {
            return;
        }
        const poolIds = sortedFilteredAssets
            .map((asset) => asset.pools[0].id.toString())
            .filter((poolId) => !assetsPoolsAnalyticsState?.analyticsMap?.[poolId] && !assetsPoolsAnalyticsState?.loadingMap?.[poolId]);

        if (!poolIds.length) {
            return;
        }
        assetsPoolsAnalyticsStateDispatch({ type: 'set-loading', payload: { ids: poolIds } });
        const assetsPoolsAnalyticsMapPromise = loadPoolAnalyticsMap<keyof PoolAnalytics>(
            poolIds, { liquidity: [ 'day', 'month' ] },
        );
        cancelAndSetAssetsPoolsAnalyticsPromise(assetsPoolsAnalyticsMapPromise)
            .then((analytics) => assetsPoolsAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => assetsPoolsAnalyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [
        ammState.loading,
        ammState.assets?.length,
        assetsPoolsAnalyticsState?.analyticsMap,
        assetsPoolsAnalyticsState?.loadingMap,
        cancelAndSetAssetsPoolsAnalyticsPromise,
        sortedFilteredAssets,
    ]);

    return (
        <AssetsContext.Provider value={{ sortedFilteredAssets, assetsPoolsAnalyticsState, searchText, loadMore, setSearchText }}>
            {children}
        </AssetsContext.Provider>
    );
};
