import { parseCoins } from 'cosmjs/packages/proto-signing';
import { isNaN } from 'lodash';
import { readStream } from '../../../shared/utils/file-utils';
import { StationClient } from '../../client/station-clients/station-client';
import { convertToCoinsAmount, getCurrency, getIbcSourceNetwork, getMaxDenomAmount } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { ChannelNetworkMap, Network } from '../../network/network-types';
import {
    IBC_TRANSFER_FIELDS,
    IbcBaseStatus,
    IbcSingleTransferDetailsResponse,
    IbcTransferDetails,
    IbcTransferDetailsResponse,
    IbcTransferStatus,
} from './ibc-status-types';

export const loadIbcTransfer = async (transferId: string): Promise<IbcTransferDetails | undefined> => {
    const query = `{
        ibcTransferDetail(id: "${transferId}") {
            ${IBC_TRANSFER_FIELDS.join(' ')}
        }
    }`;
    const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    });
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcSingleTransferDetailsResponse);
    return fetchTransferFromResponse(ibcTransferDetails);
};

export const loadIbcTransfers = async (
    networkId: string,
    pageSize: number,
    page = 0,
    statuses?: IbcTransferStatus[],
    searchText?: string,
    channels?: string[],
    address?: string,
): Promise<{ totalCount: number, transfers: IbcTransferDetails[] }> => {
    const isHexHash = searchText?.replaceAll(/^0x/g, '').match(/^[0-9a-fA-F]{30,}$/)?.length;
    const hexText = isHexHash ? searchText?.replaceAll(/^0x/g, '') : '';
    const query = `{
        ibcTransferDetails(
            offset: ${page * pageSize},
            first: ${pageSize},
            orderBy: TIME_DESC,
            filter: {
                network: {equalTo: "${networkId}"}
                ${address ? `and: { or: [
                    {receiver: {equalToInsensitive: "${address}"}},
                    {sender: {equalToInsensitive: "${address}"}},
                ] }` : ''}
                ${statuses ? `status: {in: [${statuses.filter((status) => status !== 'Initiated').join(',')}]}` : ''}
                ${searchText ? `or: [
                    ${searchText.length >= 3 && searchText.length <= 8 ? `{denom: {endsWithInsensitive: "${searchText}"}},` : ''}
                    ${!isHexHash && !isNaN(Number(searchText)) ? `{blockHeight: {equalTo: "${searchText}"}},` : ''}
                    ${searchText.length >= 30 ? `
                        {receiver: {equalToInsensitive: "${searchText}"}},
                        {sender: {equalToInsensitive: "${searchText}"}},
                    ` : ''}
                    ${isHexHash ? `
                        {hash: {equalToInsensitive: "${hexText}"}},
                        {hexReceiver: {equalToInsensitive: "0x${hexText}"}},
                        {hexSender: {equalToInsensitive: "0x${hexText}"}},
                    ` : ''}
                    ${channels?.length && channels.length < 50 ? `
                        {sourceChannel: {in: ["${channels.join('","')}"]}},
                        {destinationChannel: {in: ["${channels.join('","')}"]}},
                    ` : ''}
                ]` : ''}
            }
        ) {
            totalCount
            nodes { ${IBC_TRANSFER_FIELDS.join(' ')} }
        }
    }`;
    const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    });
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcTransferDetailsResponse);
    return fetchTransfersFromResponse(ibcTransferDetails);
};

export const loadInitiatedTransfers = async (networkId: string, address?: string): Promise<IbcTransferDetails[]> => {
    let response = await fetch(`${process.env.REACT_APP_GET_INITIALIZED_IBC_TRANSFER_DETAILS}?networkId=${networkId}&address=${address}`);
    let responseText = response?.body ? await readStream(response.body) : undefined;
    const initiatedTransfers = JSON.parse(responseText || '[]') as IbcTransferDetails[];
    if (!initiatedTransfers.length) {
        return initiatedTransfers;
    }
    const query = `{
        ibcTransferDetails(filter: {id: { in: [${initiatedTransfers.map((transfer) => `"${transfer.id}"`)}] } }) {
            nodes { ${IBC_TRANSFER_FIELDS.join(' ')} }
        }
    }`;
    response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    });
    responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const ibcTransferDetails = (JSON.parse(responseText || '{}') as IbcTransferDetailsResponse);
    const { transfers } = fetchTransfersFromResponse(ibcTransferDetails);
    if (!transfers.length) {
        return initiatedTransfers;
    }
    fetch(`${process.env.REACT_APP_REMOVE_INITIALIZED_IBC_TRANSFERS}?ids=${transfers.map((transfer) => transfer.id).join(',')}`)
        .then(() => console.log('Initiated transfers removed'))
        .catch((error) => console.error('Failed to remove initiated transfers', error));
    return initiatedTransfers.filter((initiatedTransfer) => !transfers.some((transfer) => transfer.id === initiatedTransfer.id));
};

export const getFilteredInitiatedTransfers = (
    transfers: IbcTransferDetails[],
    statuses?: IbcTransferStatus[],
    searchText?: string,
    channels?: string[],
): IbcTransferDetails[] => {
    return transfers.filter((transfer) => {
        if (statuses && !statuses.includes(transfer.status)) {
            return false;
        }
        if (channels?.length && !channels.includes(transfer.sourceChannel) && !channels.includes(transfer.destinationChannel)) {
            return false;
        }
        if (!searchText) {
            return true;
        }
        if (searchText.length >= 3 && searchText.length <= 8 && transfer.denom.includes(searchText)) {
            return true;
        }
        if (!isNaN(Number(searchText)) && transfer.blockHeight === Number(searchText)) {
            return true;
        }
        const isHexHash = searchText?.replaceAll(/^0x/g, '').match(/^[0-9a-fA-F]{30,}$/)?.length;
        const lowerCaseSearchText = searchText.toLowerCase();
        const hexText = isHexHash ? lowerCaseSearchText?.replaceAll(/^0x/g, '') : '';
        return searchText.length >= 30 && (transfer.searchable?.includes(lowerCaseSearchText) ||
            transfer.searchable?.includes(hexText) || transfer.hash.toLowerCase() === hexText);
    });
};

export const getTransferCoins = (
    sourceNetwork: Network,
    hubNetwork: Network,
    transferDenom: string,
    transferAmount: number,
    hubChannelNetworkMap: ChannelNetworkMap,
): CoinsAmount | undefined => {
    if (!sourceNetwork) {
        return;
    }
    const pathParts = transferDenom.split('/');
    const denom = pathParts.pop();
    if (!pathParts.length) {
        const currency = denom && getCurrency(sourceNetwork, denom);
        return currency ? { currency, amount: getMaxDenomAmount(transferAmount, currency) } : undefined;
    }
    const path = pathParts.join('/');
    const ibcSourceNetwork = getIbcSourceNetwork(sourceNetwork, hubNetwork, hubChannelNetworkMap, path);
    const currency = denom && ibcSourceNetwork && getCurrency(ibcSourceNetwork, denom);
    if (!currency || !ibcSourceNetwork) {
        return;
    }
    const ibc = { representation: '', path, networkId: ibcSourceNetwork.chainId };
    const amount = getMaxDenomAmount(transferAmount, currency);
    return { amount, currency, ibc };
};

export const getTransferEibcFeeCoins = (eibcFee: string, client: StationClient): Promise<CoinsAmount | null> => {
    const coins = parseCoins(eibcFee);
    return convertToCoinsAmount(coins[0], client);
};

export const getBaseStatus = (status: IbcTransferStatus): IbcBaseStatus => {
    switch (status) {
        case 'Success':
        case 'Sent':
            return 'Success';
        case 'Failure':
            return 'Failed';
        case 'Refunding':
            return 'Refunding';
    }
    return 'Pending';
};

export const getStatusesOfBaseStatus = (baseStatus: IbcBaseStatus): IbcTransferStatus[] => {
    switch (baseStatus) {
        case 'Success':
            return [ 'Success', 'Sent' ];
        case 'Failed':
            return [ 'Failure' ];
        case 'Refunding':
            return [ 'Refunding' ];
    }
    return [ 'Pending', 'EibcPending', 'Finalizing', 'Initiated' ];
};

export const getStatusTooltipInfo = (status: IbcTransferStatus): string => {
    switch (status) {
        case 'Initiated':
            return 'Transaction sent on the source chain but yet to arrive to Dymension';
        case 'Pending':
            return 'Transaction arrived to the destination chain but still not handled';
        case 'EibcPending':
            return 'Awaiting fast withdrawal eIBC order fulfillment';
        case 'Finalizing':
            return 'Standard IBC finalization period';
        case 'Sent':
            return 'Transaction successfully sent';
        case 'Success':
            return 'Transfer has completed successfully';
        case 'Refunding':
            return 'Transaction to RollApp timed out and awaiting refund fulfillment';
        case 'Failure':
            return 'Transfer failed';
    }
};

export const getStatusName = (status: IbcTransferStatus): string => {
    if (status === 'EibcPending') {
        return 'eIBC Pending';
    }
    return status;
}

const fetchTransfersFromResponse = (ibcTransferDetailsResponse: IbcTransferDetailsResponse): { transfers: IbcTransferDetails[], totalCount: number } => {
    if (!ibcTransferDetailsResponse.data?.ibcTransferDetails?.nodes) {
        return { totalCount: 0, transfers: [] };
    }
    const { totalCount, nodes } = ibcTransferDetailsResponse.data.ibcTransferDetails;
    const transfers = nodes.map((node) => IBC_TRANSFER_FIELDS.reduce((current, field) => {
        let value: string = node[field as keyof IbcTransferDetails];
        return { ...current, [field]: field === 'amount' || field === 'blockHeight' || field === 'time' ? Number(value) : value };
    }, {} as IbcTransferDetails));
    return { transfers, totalCount };
};

const fetchTransferFromResponse = (ibcSingleTransferDetailsResponse: IbcSingleTransferDetailsResponse): IbcTransferDetails | undefined => {
    if (!ibcSingleTransferDetailsResponse.data.ibcTransferDetail) {
        return undefined;
    }
    const fields = ibcSingleTransferDetailsResponse.data.ibcTransferDetail as any;
    return IBC_TRANSFER_FIELDS.reduce((current, field) => {
        let value: string = fields[field as keyof IbcTransferDetails];
        return { ...current, [field]: field === 'amount' || field === 'blockHeight' || field === 'time' ? Number(value) : value };
    }, {} as IbcTransferDetails);
};

