import classNames from 'classnames';
import React, { ReactElement } from 'react';
import Spinner from '../../../../shared/components/spinner/spinner';
import StatisticsCards from '../../../../shared/components/statistics-cards/statistics-cards';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { usePersistedState } from '../../../../shared/hooks/use-persisted-state';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { formatNumber, formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import { ReactComponent as SwapIcon } from '../../../../assets/icons/swap.svg';
import { CoinsAmount } from '../../../currency/currency-types';
import { useAmm } from '../../amm-context';
import AssetLogo from '../../asset-logo';
import PoolPriceChart from '../charts/pool-price-chart';
import TradingVolumeChart from '../charts/trading-volume-chart';
import { usePoolDashboard } from '../pool-dashboard-context';
import './pool-statistics.scss';

interface NetworkStatisticsProps {
    className?: string;
}

const TABLET_WIDTH = 1024;
const CHART_OPTIONS = [ 'Volume', 'Price' ] as const;
type ChartOption = typeof CHART_OPTIONS[number];

export const PoolStatistics: React.FC<NetworkStatisticsProps> = ({ className }) => {
    const { pool, analyticsState } = usePoolDashboard();
    const { getPoolLiquidity, ammState } = useAmm();
    const { width } = useWindowSize();
    const [ activeChart, setActiveChart ] = usePersistedState<ChartOption>('active-pool-chart', 'Volume', CHART_OPTIONS, true);

    const renderLiquidityProperty = (): ReactElement => {
        const currentValue = pool.liquidity?.value.value || 0;
        const previousValue = currentValue - (pool.liquidity?.diffWeek || 0);

        return (
            <Property label='Liquidity'>
                <StatisticsChange period='week' currentValue={currentValue} previousValue={previousValue}>
                    {formatNumber(
                        getPoolLiquidity(pool) || 0,
                        { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                    )}
                </StatisticsChange>
            </Property>
        );
    };

    const renderBondedLiquidityProperty = (): ReactElement => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;
        const totalLocked = ammState.totalLockedValues?.[pool.lpTokenDenom] || 0;
        return (
            <Property label='Bonded liquidity'>
                {ammState.totalLockedValuesLoading ? <Spinner size='small' /> : <>
                    {formatNumber(
                        totalLocked,
                        { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                    )}
                    <span className='bonded-percentage'>({roundNumber(totalLocked * 100 / poolLiquidity, 2)}%)</span>
                </>}
            </Property>
        );
    };

    const renderPriceProperty = (): ReactElement => {
        return (
            <Property label='Current price'>
                1 {pool.assets[1].currency.displayDenom} ≈&nbsp;
                {formatPrice(pool.assets[0].amount / pool.assets[1].amount, pool.assets[0].currency.displayDenom)}&nbsp;
            </Property>
        );
    };

    const renderAsset = (asset: CoinsAmount): ReactElement => {
        return (
            <div className='pool-asset'>
                <AssetLogo asset={asset} className='currency-logo' />
                <span className='asset-denom'>{asset.currency.displayDenom}</span>
                {formatNumber(asset.amount, { notation: 'compact', maximumFractionDigits: 2 })}
            </div>
        );
    };

    const renderAssetsProperty = (): ReactElement => {
        return (
            <Property label='Pool assets' valueClassName='pool-assets-value'>
                {renderAsset(pool.assets[0])}
                <SwapIcon className='swap-icon' />
                {renderAsset(pool.assets[1])}
            </Property>
        );
    };

    return (
        <div className={classNames('pool-statistics', className)}>
            <StatisticsCards className='pool-statistics-cards' vertically={width > TABLET_WIDTH}>
                {renderAssetsProperty()}
                {renderPriceProperty()}
                {renderLiquidityProperty()}
                {renderBondedLiquidityProperty()}
            </StatisticsCards>

            {activeChart === 'Volume' ?
                <TradingVolumeChart
                    chartOptions={[ ...CHART_OPTIONS ]}
                    selectedChart={activeChart}
                    onChartSelect={(value) => setActiveChart(value as ChartOption)}
                    analyticsState={analyticsState}
                /> :
                <PoolPriceChart
                    pool={pool}
                    chartOptions={[ ...CHART_OPTIONS ]}
                    selectedChart={activeChart}
                    onChartSelect={(value) => setActiveChart(value as ChartOption)}
                    analyticsState={analyticsState}
                />
            }
        </div>
    );
};

export default PoolStatistics;
