import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { Network } from '../network/network-types';
import { CoinsAmount } from '../currency/currency-types';

export const WALLET_TYPES = [ 'MetaMask', 'Leap', 'Rabby', 'Keplr', 'PortalWallet', 'Phantom' ] as const;

export const WalletInfoMap: { [type in WalletType]: WalletInfo } = {
    Keplr: { type: 'cosmos', installUrl: 'https://www.keplr.app/' },
    MetaMask: { type: 'evm', installUrl: 'https://metamask.io/' },
    Rabby: { type: 'evm', installUrl: 'https://rabby.io/' },
    Leap: { type: 'cosmos', installUrl: 'https://www.leapwallet.io/' },
    Phantom: { type: 'solana', installUrl: 'https://phantom.app/' },
    PortalWallet: { type: 'cosmos' },
};

export type WalletType = typeof WALLET_TYPES[number];

export interface WalletInfo {
    type: 'evm' | 'cosmos' | 'solana';
    installUrl?: string;
}

export interface Wallet {
    getWalletType(): WalletType;

    validateWalletInstalled(): Promise<void>;

    getAddress(network: Network): Promise<{ address?: string, hexAddress?: string }>;

    getOfflineSigner(network: Network): Promise<OfflineSigner>;

    setAccountChangesListener(listener: () => void): void;

    clear(): void;

    switchNetwork?(network: Network): Promise<void>;

    suggestToken?(coins: CoinsAmount, coinsOriginalNetwork: Network, toNetwork?: Network): Promise<void>;

    getMnemonic?(): string | undefined;

    getPrivateKey?(): string | undefined;

    publicKeyRequired?(hexAddress: string): boolean | undefined;
}
