import React, { useMemo } from 'react';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { AnalyticsState } from '../../../../analytics/analytics-state';
import { isCoinsEquals } from '../../../../currency/currency-service';
import AreaChart from '../../../../network/statistics/charts/area-chart/area-chart';
import { useAmm } from '../../../amm-context';
import { PoolAnalytics } from '../../../statistics/analytics/pool-analytics-types';
import { Asset } from '../../assets-types';

interface PriceChartProps {
    asset: Asset;
    analyticsState: AnalyticsState<PoolAnalytics>;
    className?: string;
}

export const PriceChart: React.FC<PriceChartProps> = ({ asset, analyticsState, className }) => {
    const { ammState } = useAmm();

    const assetIndex = useMemo(() => isCoinsEquals(asset.pools[0].assets[0], asset) ? 0 : 1, [ asset ]);
    const decimalsValueFactor = useMemo(
        () => !ammState.params ? 0 :
            Math.pow(10, asset.pools[0].assets[assetIndex].currency.decimals - ammState.params.vsCoins.currency.decimals),
        [ ammState.params, asset.pools, assetIndex ],
    );

    return (
        <AreaChart
            label='Price'
            valueLabel='Price'
            fetchComparableValues={
                ({ asset1Amount, asset2Amount, value }) => (value / 2) / (assetIndex === 0 ? asset1Amount : asset2Amount)}
            formatValue={(value, options) => {
                const fixedValue = ammState.params ? value * decimalsValueFactor : 0;
                return formatPrice(
                    fixedValue, undefined, { ...(fixedValue >= 1 && fixedValue < 10 ? { maximumFractionDigits: 4 } : {}), ...options });
            }}
            className={className}
            loading={analyticsState.loading}
            data={analyticsState.analytics?.liquidity}
        />
    );
};

export default PriceChart;
