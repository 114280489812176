import classNames from 'classnames';
import React, { forwardRef, ReactElement, ReactNode, useCallback, useState } from 'react';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear.svg';
import Addresses from '../../../shared/components/addresses/addresses';
import Button from '../../../shared/components/button/button';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import { MenuRefProps } from '../../../shared/components/menu/menu';
import HubWalletSelectorDialog from '../../account/account-menu/hub-wallet-selector-dialog/hub-wallet-selector-dialog';
import { AccountNetworkState } from '../../account/account-network-state';
import CommonNetworkBadge from '../../ibc-transfer/common-network-badge/common-network-badge';
import AddCustomRollappDialog from '../../rollapp/add-custom-rollapp/add-custom-rollapp-dialog/add-custom-rollapp-dialog';
import { removeCustomRollapp } from '../../rollapp/add-custom-rollapp/add-custom-rollapp-service';
import { useWallet } from '../../wallet/wallet-context';
import WalletLogo from '../../wallet/wallet-logo';
import { isWalletSupported } from '../../wallet/wallet-service';
import { WALLET_TYPES, WalletType } from '../../wallet/wallet-types';
import { PortalWallet } from '../../wallet/wallets/portal-wallet/portal-wallet';
import PortalWalletSourceLogo from '../../wallet/wallets/portal-wallet/portal-wallet-source-logo';
import { useNetwork } from '../network-context';
import { getNetworkLogoPath } from '../network-service';
import { Network } from '../network-types';
import './network-selector.scss';

interface NetworkSelectorProps {
    networkData?: AccountNetworkState;
    enableAddCustomRollapp?: boolean;
    switchNetworkAfterConnect?: boolean;
    optionsMenuOpenDisabled?: boolean;
    onNetworkSelect?: (networkId: string, commonNetwork?: boolean) => void,
    className?: string;
    walletSelectClassName?: string;
    networkSelectClassName?: string;
    walletSelectorLast?: boolean;
    showCommonNetworks?: boolean;
    isNetworkSelectable: (network: Network, commonNetwork?: boolean) => boolean;
    networks?: Network[];
    size?: 'small' | 'medium';
}

const NetworkSelector: React.ForwardRefRenderFunction<MenuRefProps, NetworkSelectorProps> = ({
    networkData,
    onNetworkSelect,
    isNetworkSelectable,
    networks,
    enableAddCustomRollapp,
    optionsMenuOpenDisabled,
    switchNetworkAfterConnect,
    walletSelectorLast,
    className,
    walletSelectClassName,
    networkSelectClassName,
    showCommonNetworks,
    size = 'medium',
}, selectRef) => {
    const { allNetworks, commonNetworks, getNetwork } = useNetwork();
    const { networkWalletMap, networkWalletTypeMap, connectWallet } = useWallet();
    const [ addCustomDialogOpen, setAddCustomDialogOpen ] = useState(false);
    const [ hubWalletSelectorDialogOpen, setHubWalletSelectorDialogOpen ] = useState(false);

    const connectedWallet = networkData?.network ? networkWalletMap[networkData.network.chainId] : undefined;

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const searchRegExp = new RegExp(searchText, 'i');
        const network = getNetwork(value.toString());
        return Boolean(network && (searchRegExp.test(network.chainName) || searchRegExp.test(network.chainId)));
    }, [ getNetwork ]);

    const renderNetworkOption = (network?: Network, addresses?: string[], canRemoved?: boolean): ReactNode => {
        if (!network) {
            return undefined;
        }
        return <>
            <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />
            <span className='network-name'>{network.chainName}</span>
            {addresses?.length ? <Addresses size='small' addresses={addresses} canCopy className='network-address' /> : undefined}
            <span className='space' />
            {canRemoved && (
                <Button buttonType='icon' onClick={() => removeCustomRollapp(network.chainId)} className='remove-custom-rollapp-button'>
                    <ClearIcon />
                </Button>
            )}
        </>;
    };

    const renderAddCustomRollappButton = (): ReactElement => {
        return <>
            <Button buttonType='secondary' className='add-custom-rollapp-button' onClick={() => setAddCustomDialogOpen(true)}>
                <span className='plus-sign'>+</span> Add your RollApp
            </Button>

            {addCustomDialogOpen && <AddCustomRollappDialog onRequestClose={() => setAddCustomDialogOpen(false)} />}
        </>;
    };

    const renderNetworksSelectHeader = (): ReactElement | null => {
        if (!showCommonNetworks || !commonNetworks?.length) {
            return null;
        }
        return (
            <div className='common-networks'>
                {commonNetworks.map((network, networkIndex) => {
                    const showNetworkId = commonNetworks?.some((otherNetwork, otherNetworkIndex) =>
                        networkIndex !== otherNetworkIndex && network.chainName.toLowerCase() === otherNetwork.chainName.toLowerCase());

                    return (
                        <CommonNetworkBadge
                            key={networkIndex}
                            network={network}
                            disabled={!isNetworkSelectable(network, true)}
                            showNetworkId={showNetworkId}
                            selected={networkData?.network?.chainId === network.chainId}
                            onClick={() => onNetworkSelect?.(network.chainId.toString(), true)}
                        />
                    );
                })}
            </div>
        );
    };

    const renderNetworkWalletSelect = (): ReactElement => {
        const network = networkData?.network;
        return (
            <Select
                controlSize={size === 'small' ? 'medium' : 'large'}
                className={classNames('network-wallet-select', walletSelectClassName, size)}
                optionsModalClassName={network?.type === 'Hub' ? 'hub-options-modal' : ''}
                placeholder={<WalletIcon />}
                disabled={!network?.chainId}
                value={network && networkWalletTypeMap[network.chainId]}
                onSelect={(type) => network && connectWallet(network.chainId, type as WalletType, true, switchNetworkAfterConnect)}
                onOptionsModalOpen={(isOpen) => isOpen && network?.type === 'Hub' ? setHubWalletSelectorDialogOpen(isOpen) : undefined}
                renderTriggerSelectedOption={(type) => type === 'PortalWallet' ?
                    <PortalWalletSourceLogo type={(connectedWallet as PortalWallet)?.getSourceType()} /> :
                    <WalletLogo type={type as WalletType} />}
            >
                {WALLET_TYPES.filter((type) => network?.type === 'Hub' || type !== 'PortalWallet')
                    .map((walletType) => (
                        <Option
                            key={walletType}
                            value={walletType}
                            iconColorMode='original'
                            disabled={!network || !isWalletSupported(network, walletType)}
                        >
                            <WalletLogo type={walletType} />&nbsp;&nbsp;{walletType}
                        </Option>
                    ))}
            </Select>
        );
    };

    const renderNetworkSelect = (): ReactElement => {
        const addresses = networkData?.address ? [ networkData.address ] : [];
        if (networkData?.hexAddress && (networkData.network?.type === 'EVM' || networkData.network?.evm)) {
            try {
                addresses.unshift(networkData.hexAddress);
            } catch {}
        }
        return (
            <Select
                ref={selectRef}
                controlSize={size === 'small' ? 'medium' : 'large'}
                placeholder='Select network'
                className={classNames('network-select', networkSelectClassName)}
                searchFilterPredicate={searchFilterPredicate}
                searchPlaceholder='Search...'
                header={renderNetworksSelectHeader()}
                emptySearchResultsLabel='No Networks found'
                footer={enableAddCustomRollapp && renderAddCustomRollappButton()}
                optionsMenuOpenDisabled={optionsMenuOpenDisabled}
                value={networkData?.network?.chainId}
                onSelect={(chainId) => onNetworkSelect?.(chainId?.toString())}
                renderTriggerSelectedOption={() => renderNetworkOption(networkData?.network, addresses)}
            >
                {(networks || allNetworks)
                    .filter((network) => isNetworkSelectable(network))
                    .map((network) => (
                        <Option value={network.chainId} key={network.chainId} disabled={network.disabled}>
                            {renderNetworkOption(network, undefined, network.custom)}
                        </Option>
                    ))}
            </Select>
        );
    };

    return <>
        <ControlsComposer className={classNames('network-selector', size, className)} reverse={walletSelectorLast}>
            {renderNetworkWalletSelect()}
            {renderNetworkSelect()}
        </ControlsComposer>

        {networkData && hubWalletSelectorDialogOpen &&
            <HubWalletSelectorDialog networkState={networkData} onRequestClose={() => setHubWalletSelectorDialogOpen(false)} />}
    </>;
};

export default forwardRef(NetworkSelector);
