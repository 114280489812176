import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AssetDashboardPage from '../amm/assets/asset-dashboard/asset-dashboard-page/asset-dashboard-page';
import AssetsPage from '../amm/assets/assets-page';
import PoolDashboardPage from '../amm/pool-dashboard/pool-dashboard-page/pool-dashboard-page';
import PoolsPage from '../amm/pools/pools-page';
import TokensSwapPage from '../amm/tokens-swap/tokens-swap-page/tokens-swap-page';
import Connect from '../connect/connect';
import HubCreateProposalPage from '../hub/hub-create-proposal';
import HubProposalPage from '../hub/hub-proposal-page';
import IbcStatusListPage from '../ibc-transfer/ibc-status/ibc-status-list-page/ibc-status-list-page';
import IbcTransferStatusPage from '../ibc-transfer/ibc-status/ibc-transfer-status/ibc-transfer-status-page/ibc-transfer-status-page';
import RollappCreateProposalPage from '../rollapp/rollapp-create-proposal-page/rollapp-create-proposal-page';
import RollappProposalPage from '../rollapp/rollapp-proposal-page/rollapp-proposal-page';
import AppLayout from './layout/app-layout';
import IbcTransferPage from '../ibc-transfer/ibc-transfer-page/ibc-transfer-page';
import RollappsDashboardPage from '../rollapp/rollapps-page/rollapps-page';
import HubDashboardPage from '../hub/hub-dashboard-page';
import RollappDashboardPage from '../rollapp/rollapp-dashboard-page/rollapp-dashboard-page';

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<AppLayout />}>
                    <Route path='dymension/:pageKey' element={<HubDashboardPage />} />
                    <Route path='dymension/proposal/:proposalId' element={<HubProposalPage />} />
                    <Route path='dymension/create-proposal' element={<HubCreateProposalPage />} />
                    <Route path='rollapps' element={<RollappsDashboardPage />} />
                    <Route path='rollapp/:id' element={<RollappDashboardPage />} />
                    <Route path='rollapp/:id/:pageKey' element={<RollappDashboardPage />} />
                    <Route path='rollapp/:id/proposal/:proposalId' element={<RollappProposalPage />} />
                    <Route path='rollapp/:id/create-proposal' element={<RollappCreateProposalPage />} />
                    <Route path='amm/swap' element={<TokensSwapPage />} />
                    <Route path='amm/pools' element={<PoolsPage />} />
                    <Route path='amm/pool/:id' element={<PoolDashboardPage />} />
                    <Route path='amm/assets' element={<AssetsPage />} />
                    <Route path='amm/asset/:key' element={<AssetDashboardPage />} />
                    <Route path='ibc/transfer' element={<IbcTransferPage />} />
                    <Route path='ibc/status' element={<IbcStatusListPage />} />
                    <Route path='ibc/status/:id' element={<IbcTransferStatusPage />} />
                    <Route path='' element={<Navigate to='/dymension/metrics' />} />
                    <Route path='*' element={<Navigate to='/dymension/metrics' />} />
                </Route>
                <Route path='connect' element={<Connect />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
