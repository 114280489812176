import { DistributionParams } from './types';

export interface RollappState {
    distributionParams?: DistributionParams;
    loading?: boolean;
}

export type RollAppAction =
    { type: 'set-distribution-params', payload: DistributionParams | undefined } |
    { type: 'set-loading', payload?: boolean } |
    { type: 'clear' };

export const rollAppReducer = (state: RollappState, action: RollAppAction): RollappState => {
    switch (action.type) {
        case 'set-distribution-params':
            return { ...state, distributionParams: action.payload, loading: false };
        case 'set-loading':
            return { ...state, loading: action.payload ?? true };
        case 'clear':
            return {};
        default:
            return state;
    }
};
