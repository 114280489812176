import React, { useCallback } from 'react';
import Badge from '../../../../shared/components/badge/badge';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { getStatusName } from '../ibc-status-service';
import { IbcTransferDetails, IbcTransferStatus } from '../ibc-status-types';
import './ibc-status-badge.scss';

interface IbcStatusBadgeProps {
    transfer: IbcTransferDetails;
    showBaseStatus?: boolean;
    getTooltipInfo?: () => string | undefined;
}

const IbcStatusBadge: React.FC<IbcStatusBadgeProps> = ({ transfer, showBaseStatus, getTooltipInfo }) => {
    const { isMobile } = useWindowSize();

    const getStatusColor = useCallback((status: IbcTransferStatus): (number[] | undefined) => {
        switch (status) {
            case 'Success':
            case 'Sent':
                return getCssVariableValue('--light-green-rgb').split(',').map(Number);
            case 'Failure':
                return getCssVariableValue('--red-rgb').split(',').map(Number);
        }
        return getCssVariableValue('--orange-rgb').split(',').map(Number);
    }, []);

    return (
        <Badge
            className='ibc-status-badge'
            size={isMobile ? 'small' : 'medium'}
            label={showBaseStatus ? transfer.baseStatus : getStatusName(transfer.status)}
            color={getStatusColor(transfer.status)}
            info={getTooltipInfo?.()}
        />
    );
};

export default IbcStatusBadge;
