import { serialize } from '@ethersproject/transactions';
import { UnsignedTransaction } from '@ethersproject/transactions/src.ts';
import { EthSignType } from '@keplr-wallet/types';
import { MsgSend } from 'cosmjs-types/cosmos/bank/v1beta1/tx';
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { calculateFee, GasPrice, isMsgSendEncodeObject } from 'cosmjs/packages/stargate';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { ClientError } from '../client/client-error';
import { DEFAULT_GAS_ADJUSTMENT } from '../client/client-types';
import { SigningStationClient } from '../client/station-clients/signing-station-client';
import { CurrencyError } from '../currency/currency-error';
import { convertToCoinsAmount } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { Network } from '../network/network-types';
import { WalletError } from '../wallet/wallet-error';
import { convertToHexAddress } from '../wallet/wallet-service';
import { WalletInfoMap } from '../wallet/wallet-types';
import { CosmosWallet } from '../wallet/wallets/cosmos-wallet';
import { EthereumWallet } from '../wallet/wallets/ethereum-wallet';
import { DeliveryTxCode, SignMethod, TxResponse } from './tx-types';

interface PerformTxParams {
    client: SigningStationClient;
    messages: EncodeObject[];
    network: Network;
    memo?: string;
    signerAddress: string;
    gasEstimation?: number;
    signMethod?: SignMethod;
    useEvm?: boolean;
    evmContract?: string;
    gasPrice?: GasPrice;
}

const GAS_FACTOR = 1.001;
const DEFAULT_TIMEOUT = 600_000;
const EIP_1559_TYPE = 2;

export const simulateTx = async ({
    client,
    network,
    signerAddress,
    messages,
    useEvm,
    evmContract,
    gasPrice,
}: PerformTxParams): Promise<{ gas: number, coins: CoinsAmount }> => {
    let gasValue: number | bigint;
    if (useEvm) {
        validateEvmTx(network, messages);
        const message = messages[0].value as MsgSend;
        const provider = new ethers.JsonRpcProvider(network.evm?.rpc);
        gasValue = await provider.estimateGas({
            from: convertToHexAddress(signerAddress),
            to: evmContract || convertToHexAddress(message.toAddress),
            value: evmContract ? undefined : message.amount[0].amount,
            data: evmContract ? getSendERC20DataField(message) : undefined,
        }).catch((error) => {
            throw new ClientError('SIMULATE_TX_FAILED', network, error);
        });
    } else {
        gasValue = await client.simulate(signerAddress, messages, '').catch((error) => {
            throw new ClientError('SIMULATE_TX_FAILED', network, error);
        });
    }
    const gas = Math.round(Number(gasValue) * GAS_FACTOR * (network.gasAdjustment || DEFAULT_GAS_ADJUSTMENT));
    const { amount } = calculateFee(gas, gasPrice || client.getGasPrice());
    const coins = await convertToCoinsAmount(amount[0], client.getStationQueryClient());
    if (!coins) {
        throw new CurrencyError('UNSUPPORTED_CURRENCY', amount[0].denom, network);
    }
    return { gas, coins };
};

export const signTx = async ({
    client,
    network,
    signerAddress,
    messages,
    memo,
    gasEstimation,
    signMethod,
    gasPrice,
}: PerformTxParams): Promise<Uint8Array> => {
    if (gasEstimation === undefined) {
        const { gas } = await simulateTx({ client, network, signerAddress, messages, gasPrice });
        gasEstimation = gas;
    }
    const fee = calculateFee(gasEstimation, gasPrice || client.getGasPrice());
    const txRaw = await client.sign(signerAddress, messages, fee, memo || '', undefined, signMethod).catch((error) => {
        throw new ClientError('BROADCAST_TX_FAILED', network, error);
    });
    return TxRaw.encode(txRaw).finish();
};

export const broadcastTx = async (client: SigningStationClient, signedTx: Uint8Array): Promise<TxResponse> => {
    const network = client.getNetwork();
    const response = await client.broadcastTx(signedTx, network.ibc?.timeout || DEFAULT_TIMEOUT).catch((error) => {
        throw new ClientError('BROADCAST_TX_FAILED', network, error);
    });
    return { hash: response.transactionHash, network, deliveryTxCode: response.code, nativeResponse: response };
};

export const sendEvmTx = async ({
    client,
    network,
    gasEstimation,
    messages,
    signerAddress,
    evmContract,
}: PerformTxParams): Promise<TxResponse> => {
    validateEvmTx(network, messages);

    const message = messages[0].value as MsgSend;
    const data = evmContract ? getSendERC20DataField(message) : undefined;
    if (gasEstimation === undefined) {
        const { gas } = await simulateTx({ client, network, signerAddress, messages, evmContract });
        gasEstimation = gas;
    }
    const gasPrice = client.getGasPrice().amount.toFloatApproximation();

    const walletInfoType = WalletInfoMap[client.getWallet().getWalletType()].type;
    if (walletInfoType === 'evm') {
        const ethWallet = client.getWallet() as EthereumWallet;
        await ethWallet.switchNetwork(network).catch((error) => {
            throw new WalletError('REQUEST_REJECTED', ethWallet.getWalletType(), network, error);
        });
        const provider = new ethers.BrowserProvider(await ethWallet.getProvider());
        const signer = await provider.getSigner();
        const response = await signer.sendTransaction({
            from: convertToHexAddress(signerAddress),
            to: evmContract || convertToHexAddress(message.toAddress),
            gasLimit: gasEstimation,
            gasPrice,
            value: evmContract ? undefined : message.amount[0].amount,
            data,
        }).catch((error) => {
            throw new ClientError('BROADCAST_TX_FAILED', network, error);
        });
        return getResponseFromEvmTx(response, network);
    } else if (walletInfoType === 'cosmos') {
        const cosmosWallet = client.getWallet() as CosmosWallet;
        const provider = await cosmosWallet.getProvider();
        const sequenceResponse = await client.getSequence(signerAddress);
        const tx: UnsignedTransaction = {
            type: EIP_1559_TYPE,
            to: evmContract || convertToHexAddress(message.toAddress),
            gasLimit: gasEstimation,
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: gasPrice,
            gasPrice,
            value: evmContract ? undefined : message.amount[0].amount,
            data,
            nonce: sequenceResponse.sequence,
            chainId: Number(network.evm?.chainId),
        };
        const signature = await provider.signEthereum(network.chainId, signerAddress, JSON.stringify(tx), EthSignType.TRANSACTION)
            .catch((error) => {
                throw new ClientError('BROADCAST_TX_FAILED', network, error);
            });
        const rpcProvider = new ethers.JsonRpcProvider(network.evm?.rpc);
        const txHash = await rpcProvider.broadcastTransaction(serialize(tx, signature)).catch((error) => {
            throw new ClientError('BROADCAST_TX_FAILED', network, error);
        });
        return getResponseFromEvmTx(txHash, network);
    }
    throw new ClientError('BROADCAST_TX_FAILED', network, new Event('Unsupported wallet info type: ' + walletInfoType));
};

const getResponseFromEvmTx = async (tx: (string | ethers.TransactionResponse), network: Network): Promise<TxResponse> => {
    let txReceiptPromise: Promise<null | ethers.TransactionReceipt>;
    if (typeof tx === 'string') {
        const provider = new ethers.JsonRpcProvider(network.evm?.rpc);
        txReceiptPromise = getTransactionReceipt(tx, provider);
    } else {
        txReceiptPromise = tx.wait();
    }
    const txReceipt = await txReceiptPromise.catch((error) => {
        throw new ClientError('BROADCAST_TX_FAILED', network, error);
    });
    if (!txReceipt) {
        return { hash: typeof tx === 'string' ? tx : tx.hash, network, deliveryTxCode: DeliveryTxCode.SUCCESS };
    }
    return { hash: txReceipt.hash, network, deliveryTxCode: txReceipt.status === 1 ? DeliveryTxCode.SUCCESS : DeliveryTxCode.UNKNOWN };
};

const getTransactionReceipt = async (
    hash: string,
    provider: ethers.JsonRpcProvider,
    attempts = 10,
): Promise<ethers.TransactionReceipt | null> => {
    const txReceipt = await provider.getTransactionReceipt(hash);
    if (txReceipt) {
        return txReceipt;
    }
    if (attempts <= 0) {
        return null;
    }
    return new Promise((resolve, reject) =>
        setTimeout(() => getTransactionReceipt(hash, provider, attempts - 1).then(resolve).catch(reject), 3000));
};

const getSendERC20DataField = (message: MsgSend): string => {
    const TRANSFER_FUNCTION_ABI = {
        constant: false,
        inputs: [ { 'name': '_to', 'type': 'address' }, { 'name': '_value', 'type': 'uint256' } ],
        name: 'transfer',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    };
    return new Web3().eth.abi.encodeFunctionCall(
        TRANSFER_FUNCTION_ABI as any,
        [ convertToHexAddress(message.toAddress), message.amount[0].amount ],
    );
};

const validateEvmTx = (network: Network, messages: EncodeObject[]): void => {
    if (!isMsgSendEncodeObject(messages[0])) {
        throw new ClientError('BROADCAST_TX_FAILED', network, new Error('Unsupported EVM tx: ' + messages[0].typeUrl));
    }
};
