import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { convertIntToDecimal, roundNumber } from '../../../shared/utils/number-utils';
import { isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { AmountTxState } from '../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../tx/tx-state';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useAmm } from '../amm-context';
import {
    createLiquidityMessage,
    createLockMessage,
    getPositionPart,
    getPositionBondedPart,
    getPrice,
} from '../amm.service';
import { calcJoinPoolNoSwapShares, calcJoinPoolShares } from '../pools-service';
import { Pool } from '../types';
import { LiquidityType } from './liquidity-types';
import { liquidityReducer, LiquidityState } from './liquiduty-state';

interface LiquidityValue {
    asset1AmountTxState: AmountTxState;
    asset2AmountTxState: AmountTxState;
    asset1AvailableBalances: CoinsAmount[];
    asset2AvailableBalances: CoinsAmount[];
    updateAsset1Coins: (coins: CoinsAmount) => void;
    updateAsset2Coins: (coins: CoinsAmount) => void;
    liquidityState: LiquidityState;
    txState: TxState;
    sharesPart: number;
    broadcast: (memo?: string) => void;
    setSingleAsset: (value?: boolean) => void;
    setSharesPart: (part: number) => void;
}

export const DEFAULT_SHARES_PART: { [type in LiquidityType]: number } = { Add: 0, Remove: 0.5, Bond: 1, Unbond: 1 };
const JOIN_SHARES_FACTOR = 0.999;

export const useLiquidity = (pool: Pool, type: LiquidityType): LiquidityValue => {
    const { networkState, ammState } = useAmm();
    const { hubWallet, handleWalletError } = useWallet();
    const [ liquidityState, liquidityStateDispatch ] = useReducer(liquidityReducer, { sharesAmount: BigInt(0), singleAsset: false });
    const [ sharesPart, setSharesPart ] = useState(DEFAULT_SHARES_PART[type]);

    const getAssetBalance = useCallback((asset: CoinsAmount): CoinsAmount => {
        const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, asset));
        return balance || { ...asset, amount: 0 };
    }, [ networkState.balances ]);

    const getAvailableBalances = useCallback((asset: CoinsAmount) => {
        if (type === 'Add') {
            return [ getAssetBalance(asset) ];
        }
        const part = type === 'Unbond' ? getPositionBondedPart(pool) : getPositionPart(pool) - getPositionBondedPart(pool);
        return [ { ...asset, amount: roundNumber(asset.amount * part, asset.currency.decimals) } ];
    }, [ getAssetBalance, pool, type ]);

    const asset1AvailableBalances = useMemo(
        () => liquidityState.singleAsset ? [] : getAvailableBalances(pool.assets[0]),
        [ getAvailableBalances, liquidityState.singleAsset, pool.assets ],
    );

    const asset2AvailableBalances = useMemo(() => {
        if (!liquidityState.singleAsset) {
            return getAvailableBalances(pool.assets[1]);
        }
        const asset1Balance = getAssetBalance(pool.assets[0]);
        const asset2Balance = getAssetBalance(pool.assets[1]);
        asset1Balance.amount = Math.min(asset1Balance.amount, pool.assets[0].amount);
        asset2Balance.amount = Math.min(asset2Balance.amount, pool.assets[1].amount);
        return [ asset1Balance, asset2Balance ];
    }, [ getAssetBalance, getAvailableBalances, liquidityState.singleAsset, pool.assets ]);

    const {
        amountTxState: asset1AmountTxState,
        setCoins: setAsset1Coins,
        setAmount: setAsset1Amount,
    } = useAmountTx({ availableBalances: asset1AvailableBalances, networkState, reduceFeeFromBalances: true });

    const liquidityMessagesCreator = useCallback((fee?: CoinsAmount, coins2?: CoinsAmount): EncodeObject[] => {
        if (!networkState.address || !coins2) {
            return [];
        }
        // if (fee && isCoinsEquals(coins1, fee)) {
        //     coins1 = { ...coins1, amount: Math.min(coins1.amount, asset1AvailableBalances[0].amount - fee.amount) };
        // } else if (fee && isCoinsEquals(coins2, fee)) {
        //     coins2 = { ...coins2, amount: Math.min(coins2.amount, asset2AvailableBalances[0].amount - fee.amount) };
        // }
        const coins = liquidityState.singleAsset ?
            [ asset2AvailableBalances.find((balance) => isCoinsEquals(balance, coins2)) || coins2 ] :
            [ asset1AvailableBalances[0], asset2AvailableBalances[0] ];
        const sharesAmount = liquidityState.sharesAmount || BigInt(convertIntToDecimal(1));

        let message = undefined;
        if (type === 'Add' || type === 'Remove') {
            message = createLiquidityMessage(pool, networkState.address, sharesAmount, coins, type === 'Remove');
        } else if (type === 'Bond' || type === 'Unbond') {
            const coin = { denom: pool.lpTokenDenom, amount: BigInt(sharesAmount).toString() };
            const lockId = type === 'Unbond' ? pool.position?.lockId : undefined;
            message = createLockMessage(networkState.address, coin, lockId);
        }
        return message ? [ message ] : [];
    }, [ networkState.address, liquidityState, asset1AvailableBalances, asset2AvailableBalances, type, pool ]);

    const {
        txState,
        amountTxState: asset2AmountTxState,
        setCoins: setAsset2Coins,
        setAmount: setAsset2Amount,
        broadcast,
        calculateFee,
        clearFee,
    } = useAmountTx({
        availableBalances: asset2AvailableBalances,
        networkState,
        amountTxMessagesCreator: liquidityMessagesCreator,
        reduceFeeFromBalances: type === 'Add',
    });

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleWalletError, txState.error ]);

    useEffect(() => {
        if (hubWallet && networkState.network && pool && asset2AmountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ asset2AmountTxState.coins?.currency, calculateFee, clearFee, hubWallet, networkState.network, pool ]);

    const getUpdatedAssetsCoins = useCallback((
        coins: CoinsAmount,
        otherAsset: CoinsAmount,
        availableAmount?: number,
        otherAssetAvailableAmount?: number,
    ) => {
        if (!ammState.pools || !ammState.params) {
            return {};
        }
        const assetCoins = { ...coins };
        const otherAssetCoins = { ...otherAsset, amount: 0 };
        if (availableAmount && assetCoins.amount > availableAmount) {
            assetCoins.amount = availableAmount;
        }
        otherAssetCoins.amount = getPrice(ammState.pools, ammState.params, assetCoins, otherAssetCoins);
        if (otherAssetAvailableAmount && otherAssetCoins.amount > otherAssetAvailableAmount) {
            otherAssetCoins.amount = otherAssetAvailableAmount;
            assetCoins.amount = getPrice(ammState.pools, ammState.params, otherAssetCoins, assetCoins);
        }
        return { assetCoins, otherAssetCoins };
    }, [ ammState.pools, ammState.params ]);

    const updateAsset1Coins = useCallback((coins: CoinsAmount) => {
        const { assetCoins, otherAssetCoins } =
            getUpdatedAssetsCoins(coins, pool.assets[1], asset1AmountTxState.availableAmount, asset2AmountTxState.availableAmount);
        if (assetCoins) {
            setAsset1Coins(assetCoins);
        }
        if (otherAssetCoins) {
            setAsset2Coins(otherAssetCoins);
        }
    }, [
        asset1AmountTxState.availableAmount,
        asset2AmountTxState.availableAmount,
        getUpdatedAssetsCoins,
        pool.assets,
        setAsset1Coins,
        setAsset2Coins,
    ]);

    const updateAsset2Coins = useCallback((coins: CoinsAmount) => {
        if (liquidityState.singleAsset) {
            setAsset2Coins(coins);
            return;
        }
        const { assetCoins, otherAssetCoins } =
            getUpdatedAssetsCoins(coins, pool.assets[0], asset2AmountTxState.availableAmount, asset1AmountTxState.availableAmount);
        if (assetCoins) {
            setAsset2Coins(assetCoins);
        }
        if (otherAssetCoins) {
            setAsset1Coins(otherAssetCoins);
        }
    }, [
        asset1AmountTxState.availableAmount,
        asset2AmountTxState.availableAmount,
        getUpdatedAssetsCoins,
        liquidityState.singleAsset,
        pool.assets,
        setAsset1Coins,
        setAsset2Coins,
    ]);

    const setSingleAsset = useCallback((value?: boolean) => {
        setAsset1Amount(0);
        setAsset2Amount(0);
        liquidityStateDispatch({ type: 'set-single-asset', payload: value });
        calculateFee();
    }, [ calculateFee, setAsset1Amount, setAsset2Amount ]);

    useEffect(() => {
        if ((type === 'Remove' || type === 'Bond' || type === 'Unbond') && pool.position) {
            let sharesAmount: bigint;
            if (type === 'Remove' || type === 'Bond') {
                sharesAmount = pool.position.shares - pool.position.bondedShares;
            } else {
                sharesAmount = pool.position.bondedShares;
            }
            sharesAmount = sharesPart === 1 ? sharesAmount : BigInt(Math.round(Number(sharesAmount) * sharesPart));
            liquidityStateDispatch({ type: 'set-shares-amount', payload: sharesAmount });
        }
    }, [ pool.position, sharesPart, type ]);

    useEffect(() => {
        if (type !== 'Add' || !asset2AmountTxState.coins || !ammState.params) {
            return;
        }
        if (!liquidityState.singleAsset && !asset1AmountTxState.coins) {
            return;
        }
        const shares = liquidityState.singleAsset ?
            calcJoinPoolShares(pool, asset2AmountTxState.coins, ammState.params.swapFee) :
            calcJoinPoolNoSwapShares(pool, [ asset1AmountTxState.coins, asset2AmountTxState.coins ].filter(Boolean) as CoinsAmount[]);

        liquidityStateDispatch({ type: 'set-shares-amount', payload: BigInt(Math.round(shares * JOIN_SHARES_FACTOR)) });
    }, [ ammState.params, asset1AmountTxState.coins, asset2AmountTxState.coins, liquidityState.singleAsset, pool, type ]);

    return {
        asset1AmountTxState,
        asset2AmountTxState,
        asset1AvailableBalances,
        asset2AvailableBalances,
        txState,
        updateAsset1Coins,
        updateAsset2Coins,
        liquidityState,
        sharesPart,
        setSingleAsset,
        broadcast,
        setSharesPart,
    };
};


