import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { analyticsMapReducer, AnalyticsMapState } from '../../analytics/analytics-map-state';
import { analyticsReducer, AnalyticsState } from '../../analytics/analytics-state';
import { Network } from '../../network/network-types';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { NetworksAnalytics } from '../../network/statistics/analytics/network-analytics-types';
import { loadNetworksAnalytics, loadNetworksAnalyticsMap } from '../../network/statistics/analytics/network-analytics-service';
import { RollappAnalytics } from '../statistics/rollapp-statistics-types';
import { HubAnalytics } from '../../hub/statistics/hub-statistics-types';
import { useNetwork } from '../../network/network-context';

interface RollappContextValue {
    rollappsAnalyticsState: AnalyticsMapState<{ [rollappId: string]: RollappAnalytics }>;
    hubAnalyticsState: AnalyticsState<HubAnalytics>;
    sortedRollapps: Network[];
    loadMore: () => void;
}

const PAGE_SIZE = 10;

export const RollappsContext = createContext<RollappContextValue>({} as RollappContextValue);

export const useRollapps = (): RollappContextValue => useContext(RollappsContext);

export const RollappsContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { hubNetwork, rollapps } = useNetwork();
    const [ rollappsAnalyticsState, rollappsAnalyticsStateDispatch ] = useReducer(analyticsMapReducer, {});
    const [ hubAnalyticsState, hubAnalyticsStateDispatch ] = useReducer(analyticsReducer, { loading: true });
    const [ page, setPage ] = useState(0);
    const cancelAndSetHubAnalyticsPromise = useCancelablePromise<NetworksAnalytics>();

    const sortedRollapps = useMemo(() => {
        return rollapps
            .sort((rollapp1, rollapp2) => {
                if (rollapp1.custom !== rollapp2.custom) {
                    return (rollapp2.custom ? 1 : 0) - (rollapp1.custom ? 1 : 0);
                }
                const status1 = rollapp1.availabilityStatus?.value;
                const status2 = rollapp2.availabilityStatus?.value;
                if (status1 !== status2) {
                    return (status2 ? 1 : 0) - (status1 ? 1 : 0);
                }
                return (rollapp2.totalSupply?.value.tvl || 0) - (rollapp1.totalSupply?.value.tvl || 0);
            })
            .slice(0, (page + 1) * PAGE_SIZE);
    }, [ page, rollapps ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === sortedRollapps.length) {
            setPage(page + 1);
        }
    }, [ page, sortedRollapps.length ]);

    useEffect(() => {
        const networkIds = sortedRollapps
            .map((rollapp) => rollapp.chainId)
            .filter((networkId) => !rollappsAnalyticsState?.analyticsMap?.[networkId] && !rollappsAnalyticsState?.loadingMap?.[networkId]);

        if (!networkIds.length) {
            return;
        }
        rollappsAnalyticsStateDispatch({ type: 'set-loading', payload: { ids: networkIds } });
        loadNetworksAnalyticsMap<keyof RollappAnalytics>(networkIds, { totalSupply: [ 'month' ] })
            .then((analytics) => rollappsAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => rollappsAnalyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [ sortedRollapps, rollappsAnalyticsState?.analyticsMap, rollappsAnalyticsState?.loadingMap ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        const networksAnalyticsPromise =
            loadNetworksAnalytics<keyof HubAnalytics>(hubNetwork.chainId, { rollappsCount: [ 'month' ], ibcTransfers: [ 'month' ] });
        cancelAndSetHubAnalyticsPromise(networksAnalyticsPromise)
            .then((analytics) => hubAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => hubAnalyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [ cancelAndSetHubAnalyticsPromise, hubNetwork ]);

    return (
        <RollappsContext.Provider value={{ rollappsAnalyticsState, hubAnalyticsState, sortedRollapps, loadMore }}>
            {children}
        </RollappsContext.Provider>
    );
};
