import { GovernanceParams, Proposal, ProposalVoteOption, ProposalVotesSummary } from '../governance-types';

export interface ProposalState {
    proposal?: Proposal;
    summary?: ProposalVotesSummary;
    votesCount?: number;
    canVote?: boolean;
    voteOption?: ProposalVoteOption;
    originalVoteOption?: ProposalVoteOption;
    params?: GovernanceParams;
    paramsLoading?: boolean;
    loading?: boolean;
    summaryLoading?: boolean;
    votesCountLoading?: boolean;
    voteOptionLoading?: boolean;
    canVoteLoading?: boolean;
    error?: any;
}

export type ProposalAction =
    { type: 'set-proposal', payload: Proposal | undefined } |
    { type: 'set-summary', payload: ProposalVotesSummary | undefined } |
    { type: 'set-vote-option', payload: { option: ProposalVoteOption, originalOption?: ProposalVoteOption } | undefined } |
    { type: 'set-params', payload: GovernanceParams } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-can-vote', payload?: boolean } |
    { type: 'set-loading', payload?: boolean } |
    { type: 'set-votes-count', payload: number | undefined } |
    { type: 'set-summary-loading', payload?: boolean } |
    { type: 'set-votes-count-loading', payload?: boolean } |
    { type: 'set-vote-option-loading', payload?: boolean } |
    { type: 'set-can-vote-loading', payload?: boolean } |
    { type: 'set-error', payload: any };

export const PROPOSAL_STATE_DEFAULT: ProposalState = {
    loading: true,
    summaryLoading: true,
    paramsLoading: true,
};

export const proposalReducer = (state: ProposalState, action: ProposalAction): ProposalState => {
    switch (action.type) {
        case 'set-proposal':
            return {
                ...state,
                proposal: action.payload,
                summary: action.payload?.finalVotesSummary || state.summary,
                loading: false,
                summaryLoading: action.payload?.finalVotesSummary ? false : state.summaryLoading,
            };
        case 'set-summary':
            return { ...state, summary: state.proposal?.finalVotesSummary || action.payload, summaryLoading: false };
        case 'set-votes-count':
            return { ...state, votesCount: action.payload, votesCountLoading: false };
        case 'set-vote-option':
            return {
                ...state,
                voteOption: action.payload?.option,
                originalVoteOption: action.payload?.originalOption,
                voteOptionLoading: false,
            };
        case 'set-can-vote':
            return { ...state, canVote: action.payload, canVoteLoading: false };
        case 'set-params':
            return { ...state, params: action.payload, paramsLoading: false };
        case 'set-params-loading':
            return { ...state, paramsLoading: action.payload ?? true };
        case 'set-loading':
            return { ...state, loading: action.payload ?? true };
        case 'set-summary-loading':
            return { ...state, summaryLoading: action.payload ?? true };
        case 'set-votes-count-loading':
            return { ...state, votesCountLoading: action.payload ?? true };
        case 'set-vote-option-loading':
            return { ...state, voteOptionLoading: action.payload ?? true };
        case 'set-can-vote-loading':
            return { ...state, canVoteLoading: action.payload ?? true };
        case 'set-error':
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

