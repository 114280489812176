import React, { useEffect, useMemo } from 'react';
import Input from '../../../../shared/components/form-controls/input/input';
import { SubmitFraudProposal } from '../../../client/station-clients/dymension/generated/rollapp/proposal';
import './proposal-type.scss';

interface SubmitFraudProps {
    content?: SubmitFraudProposal;
    onInit?: (data: SubmitFraudProposal) => void;
    onChange?: (data: Partial<SubmitFraudProposal>, isValid: boolean) => void;
}

const SubmitFraud: React.FC<SubmitFraudProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(
        () => Boolean(content?.rollappId && content?.ibcClientId && content?.fraudelentHeight && content?.fraudelentSequencerAddress),
        [ content ],
    );

    useEffect(() => onInit?.({
        title: '',
        description: '',
        rollappId: '',
        ibcClientId: '',
        fraudelentHeight: undefined as any,
        fraudelentSequencerAddress: '',
    }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    return (
        <div className='proposal-type'>
            <label className='proposal-change-label'>RollApp ID</label>
            <Input
                value={content?.rollappId}
                onTypeFinish={(value) => onChange?.({ rollappId: value?.toString() || '' }, isValid)}
            />

            <label className='proposal-change-label'>IBC client ID</label>
            <Input
                value={content?.ibcClientId}
                onTypeFinish={(value) => onChange?.({ ibcClientId: value?.toString() || '' }, isValid)}
            />

            <label className='proposal-control-label'>Fraudelent Height</label>
            <Input
                type='number'
                value={content?.fraudelentHeight}
                onValueChange={(value) => onChange?.({ fraudelentHeight: !value ? undefined : Number(value) }, isValid)}
            />

            <label className='proposal-change-label'>Fraudelent sequencer address</label>
            <Input
                value={content?.fraudelentSequencerAddress}
                onTypeFinish={(value) => onChange?.({ fraudelentSequencerAddress: value?.toString() || '' }, isValid)}
            />
        </div>
    );
};

export default SubmitFraud;
