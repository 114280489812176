import React from 'react';
import StatisticsCards, { StatisticsCardsProps } from '../../../shared/components/statistics-cards/statistics-cards';
import TotalVolumeProperty from '../../hub/statistics/total-volume-property';
import MarketCapProperty from '../../network/statistics/properties/market-cap-property';
import StakingAprProperty from '../../network/statistics/properties/staking-apr-property';
import SequencersBondProperty from '../../network/statistics/properties/sequencers-bond-property';
import NativeTokenProperty from '../../rollapp/statistics/properties/native-token-property';
import { useNetworkDashboard } from '../network-dashboard-context';

export const NetworkStatistics: React.FC<Omit<StatisticsCardsProps, 'children'>> = (props) => {
    const { network, analyticsState, rollappData } = useNetworkDashboard();

    return (
        <StatisticsCards {...props}>
            <NativeTokenProperty />

            <MarketCapProperty network={network} fullyDiluted={network.type !== 'Hub'} />

            {network.type === 'Hub' ?
                <TotalVolumeProperty network={network} /> :
                <SequencersBondProperty network={network} />}

            <StakingAprProperty state={analyticsState} rollappData={rollappData} />
        </StatisticsCards>
    );
};

export default NetworkStatistics;
