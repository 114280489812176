import { Proposal } from '../../modules/governance/governance-types';

export const MINUTE_MILLISECONDS = 60 * 1000;
export const HOUR_MILLISECONDS = MINUTE_MILLISECONDS * 60;
export const DAY_MILLISECONDS = HOUR_MILLISECONDS * 24;

export const getShortDateString = (value?: Date | string | number, showYearAlways?: boolean): string => {
    const date = value ? new Date(value) : new Date();
    let dateString = date.toDateString().replace(/^\S+\s/, '');
    if (!showYearAlways && date.getFullYear() === new Date().getFullYear()) {
        dateString = dateString.replace(/\s[0-9]{4}$/, '');
    }
    return dateString;
};

export const getShortTimeString = (value?: Date | string | number): string => {
    const date = value ? new Date(value) : new Date();
    let timeString = date.toTimeString();
    return timeString.split(':').slice(0, 2).join(':');
};

export const getShortDateTimeString = (value?: Date | string | number, showYearAlways?: boolean): string => {
    return getShortDateString(value, showYearAlways) + ', ' + getShortTimeString(value);
};

export const getFormattedLocalDateTime = (date: string | Date): string => {
    date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getTimeOffset = (date: number | Date): { days: number, hours: number, minutes: number, seconds: number } => {
    const timeOffset = Math.abs((typeof date === 'number' ? date : date.getTime()) - new Date().getTime());
    const seconds = Math.round(timeOffset / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return { days, hours: hours % 24, minutes: minutes % 60, seconds: seconds % 60 };
};

export const getTimeLeftText = ({
    days,
    hours,
    minutes,
    seconds,
}: { days: number, hours: number, minutes: number, seconds: number }, oneTimeUnit?: boolean, longTimeUnitFormat?: boolean): string => {
    const daysFormat = longTimeUnitFormat ? ' days' : 'd';
    const hoursFormat = longTimeUnitFormat ? ' hrs' : 'h';
    const minutesFormat = longTimeUnitFormat ? ' mins' : 'm';
    const secondsFormat = longTimeUnitFormat ? ' secs' : 's';

    if (days > 0) {
        return `${days}${daysFormat}` + (!oneTimeUnit && hours > 0 ? ` ${hours}${hoursFormat}` : '');
    }
    if (hours > 0) {
        return `${hours}${hoursFormat}` + (!oneTimeUnit && minutes > 0 ? ` ${minutes}${minutesFormat}` : '');
    }
    if (minutes > 0) {
        return `${minutes}${minutesFormat}` + (!oneTimeUnit && seconds > 0 ? ` ${seconds}${secondsFormat}` : '');
    }
    return `${seconds}${secondsFormat}`;
};
