import React from 'react';
import { Network } from '../network/network-types';
import GovernanceAnalytics from './governance-analytics';
import { GovernanceContextProvider } from './governance-context';
import LiveProposals from './live-proposals/live-proposals';
import ProposalList from './proposal-list/proposal-list';

interface GovernanceProps {
    network: Network;
}

const Governance: React.FC<GovernanceProps> = ({ network }) => {
    return <>
        <GovernanceAnalytics />
        <LiveProposals />
        <ProposalList />
    </>;
};

const GovernanceWithContext = (props: GovernanceProps) =>
    <GovernanceContextProvider network={props.network}>
        <Governance {...props} />
    </GovernanceContextProvider>;

export default GovernanceWithContext;
