import classNames from 'classnames';
import React, { useMemo } from 'react';
import Badge from '../../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import './common-token-badge.scss';
import { useNetwork } from '../../../network/network-context';

interface CommonTokenBadgeProps {
    className?: string;
    token: CoinsAmount;
    showNetworkId?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const CommonTokenBadge: React.FC<CommonTokenBadgeProps> = ({ className, token, disabled, onClick, showNetworkId, selected }) => {
    const { hubNetwork, getNetwork } = useNetwork();

    const network = useMemo(
        () => token.ibc?.networkId ? getNetwork(token.ibc.networkId) : hubNetwork,
        [ getNetwork, hubNetwork, token.ibc?.networkId ],
    );

    return (
        <Badge
            size='large'
            color={getCssVariableValue('--cream-rgb').split(',').map(Number)}
            className={classNames('common-token', className, { selected })}
            onClick={onClick}
            disabled={disabled}
            label={<>
                {network && <img className='currency-logo' src={getCurrencyLogoPath(token.currency, network)} alt='currency logo' />}
                <span className='currency-name-container'>
                    {token.currency.displayDenom}
                    {showNetworkId && <span className='currency-network-id'>{network?.chainId}</span>}
                </span>
            </>}
        />
    );
};

export default CommonTokenBadge;
