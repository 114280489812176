import React from 'react';
import Icon from '../../../../shared/components/icon/icon';
import { PortalWalletSourceType } from './types';
import { ReactComponent as EthereumLogo } from '../../../../assets/logos/ethereum-logo2.svg';
import { ReactComponent as GoogleLogo } from '../../../../assets/logos/auth-providers/google.svg';
import { ReactComponent as AppleLogo } from '../../../../assets/logos/auth-providers/apple.svg';
import { ReactComponent as TwitterLogo } from '../../../../assets/logos/auth-providers/twitter-x.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet.svg';

interface PortalWalletSourceLogoProps {
    type?: PortalWalletSourceType;
    className?: string;
}

const PortalWalletSourceLogo: React.FC<PortalWalletSourceLogoProps> = ({ type, className }) => {
    let logo = null;
    if (type === 'MetaMask') {
        logo = <EthereumLogo />;
    } else if (type === 'Google') {
        logo = <GoogleLogo />;
    } else if (type === 'Apple') {
        logo = <AppleLogo />;
    } else if (type === 'Twitter X') {
        logo = <TwitterLogo />;
    }
    if (logo) {
        return <Icon className={className} iconColorMode='original'>{logo}</Icon>;
    }
    return className ? <Icon className={className}><WalletIcon /></Icon> : <WalletIcon />;
};

export default PortalWalletSourceLogo;
