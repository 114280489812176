import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../shared/components/spinner/spinner';
import CreateProposal from '../../governance/create-proposal/create-proposal';
import { useNetwork } from '../../network/network-context';
import './rollapp-create-proposal-page.scss';

const RollappCreateProposalPage: React.FC = () => {
    const { id } = useParams();
    const { loading, getNetwork } = useNetwork();
    const rollapp = id && getNetwork(id);

    if (rollapp) {
        return <CreateProposal network={rollapp} />;
    }
    if (loading) {
        return <Spinner size='large' className='rollapp-loader' />;
    }
    return <h5 className='not-found-message'>RollApp not found</h5>;
};

export default RollappCreateProposalPage;
