import { CoinsAmount } from '../../../currency/currency-types';
import { Network } from '../../../network/network-types';
import { WalletType } from '../../../wallet/wallet-types';

export interface ImportTokenState {
    walletType: WalletType;
    network?: Network;
    coinsToImportLoading?: boolean;
    coinsToImport?: CoinsAmount;
    importing?: boolean;
}

export type ImportTokenAction =
    { type: 'set-wallet', payload: WalletType } |
    { type: 'set-network', payload?: Network } |
    { type: 'set-coins-to-import', payload?: CoinsAmount } |
    { type: 'set-coins-to-import-loading', payload?: boolean } |
    { type: 'set-importing', payload?: boolean };

export const importTokenReducer = (state: ImportTokenState, action: ImportTokenAction): ImportTokenState => {
    switch (action.type) {
        case 'set-wallet':
            return { ...state, walletType: action.payload, coinsToImport: undefined };
        case 'set-network':
            return { ...state, network: action.payload, coinsToImport: undefined };
        case 'set-coins-to-import':
            return { ...state, coinsToImport: action.payload, coinsToImportLoading: false };
        case 'set-coins-to-import-loading':
            return { ...state, coinsToImportLoading: action.payload ?? true };
        case 'set-importing':
            return { ...state, importing: action.payload ?? true };
        default:
            return state;
    }
};

