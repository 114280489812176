import { DataType } from 'csstype';
import { CSSProperties } from 'react';

export const THEME_COLORS = [
    'blue',
    'green',
    'orange',
    'red',
    'gray',
    'cream',
    'black',
    'dark-blue',
    'dark-green',
    'dark-orange',
    'dark-red',
    'dark-gray',
    'dark-cream',
    'dark-black'
] as const;

export type ThemeColor = typeof THEME_COLORS[number];

export type PortalColor = ThemeColor | DataType.Color;

export const LIGHT_THEME_COLORS: PortalColor[] = ['orange', 'cream', 'dark-cream'];

export const createColorStyleObject = (color: PortalColor, fill?: boolean): CSSProperties => {
    if (THEME_COLORS.includes(color as ThemeColor)) {
        color = `var(--${color})`;
    }
    if (!fill) {
        return { '--color': color, '--fill-color': 'transparent' } as CSSProperties;
    }
    return { '--color': getInverseColor(color), '--fill-color': color } as CSSProperties;
};

export const getInverseColor = (color: PortalColor): string => {
    return LIGHT_THEME_COLORS.some((lightColor) => color.includes(lightColor)) ? 'var(--black)' : 'var(--cream)';
}

export const getCssVariableValue = (variableName: string): string | '' => {
    const root = document.documentElement;
    const style = window.getComputedStyle(root);
    return style.getPropertyValue(variableName).trim() || '';
};
